<div class="task" [ngClass]="taskClass">
    <div class="task--name cursor-pointer"
         [ngClass]="{'expired': expired}"
         (click)="goToTask()"
         [innerHTML]="(task.name | safe: 'text') | yellowSearchText : searchText | safe: 'html'"
    ></div>

    <button mat-flat-button class="btn-sm rounded-2 header-btn task-menu"
            [matMenuTriggerFor]="more"
            *ngIf="canEdit">
        <i class="material-icons dolineo-icon-gray" style="font-size: 20px;">more_vert</i>
    </button>
    <mat-menu #more="matMenu">
        <button mat-menu-item (click)="onEdit()">
            {{ 'Common.Edit' | translate }}
        </button>
        <button mat-menu-item (click)="onDuplicate()">
            {{'Tasks.DuplicateTask' | translate }}
        </button>
        <button mat-menu-item (click)="onRemove()">
            {{ 'Tasks.RemoveTask' | translate }}
        </button>
    </mat-menu>

    <div class="task--info">
        <div class="task--realization-dates" [ngClass]="{'expired': highlight}">
            <div class="task--realization-dates__from" *ngIf="task.blocked">{{ 'Common.From' | translate }} {{ task.realizationFrom | date: 'dd.MM.YYYY, HH:mm' }}</div>
            <div class="task--realization-dates__to" *ngIf="task.realizationTo">{{ 'Common.To' | translate }} {{ task.realizationTo | date: 'dd.MM.YYYY, HH:mm' }}</div>
        </div>

        <div class="task--action-bar">
            <span *ngIf="task.completionType == 'percent'">
                {{task.progressValue ? task.progressValue : 0}}%
            </span>
            <span *ngIf="task.completionType == 'number'">
                {{task.progressValue ? task.progressValue : 0}}/{{task.requiredValue}}
            </span>

            <img *ngIf="task.repetitionType != 'never'"
                 src="assets/icons/cyclical.svg"
                 matTooltip="{{repeatInfo}}"
                 matTooltipPosition="above"
                 matTooltipClass='above'
            >

            <div class="comment-container" *ngIf="task.commentCount">
                <img class="comment-icon"
                     src="assets/icons/comment.svg"
                >
                <div class="unread-comments" *ngIf="task.hasUnreadComments"></div>
            </div>

            <img *ngIf="task.blocked"
                 src="assets/icons/lock.svg"
                 matTooltip="{{ 'Tasks.TaskBlocked' | translate}}"
                 matTooltipPosition="above"
                 matTooltipClass='above'
            >

            <div class="begin-button"
                 *ngIf="canBegin"
                 (click)="changeTaskStatus()"
            >{{ 'Tasks.Begin' | translate }}</div>

            <div class="update-button cursor-pointer"
                 *ngIf="canUpdate"
                 (click)="changeTaskStatus()"
            >{{ 'Tasks.Update' | translate }}</div>

            <div class="update-button cursor-pointer"
                 *ngIf="task.forAcceptance"
                 (click)="goToTask()"
            >{{ 'Tasks.Pending' | translate }}</div>

            <div class="see-button"
                 *ngIf="canGoTo"
                 (click)="goToTask()"
            >{{ 'Tasks.See' | translate }}</div>

        </div>
    </div>
</div>
import { Directive, ElementRef, Input, HostListener, ViewContainerRef, ComponentRef, ComponentFactoryResolver } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HtmlTooltipComponent} from '../components/html-tooltip/html-tooltip.component';

@Directive({
    selector: '[appHtmlTooltip]'
})
export class HtmlTooltipDirective {
    @Input('appHtmlTooltip') tooltipContent!: string;

    private tooltipComponentRef!: ComponentRef<HtmlTooltipComponent>;
    private hideTimeout: any;

    constructor(
        private el: ElementRef,
        private resolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private sanitizer: DomSanitizer
    ) {}

    @HostListener('mouseenter') onMouseEnter() {
        if (this.tooltipComponentRef) return;
        const factory = this.resolver.resolveComponentFactory(HtmlTooltipComponent);
        this.tooltipComponentRef = this.viewContainerRef.createComponent(factory);
        this.tooltipComponentRef.instance.content = this.sanitizer.bypassSecurityTrustHtml(this.tooltipContent);

        const rect = this.el.nativeElement.getBoundingClientRect();
        this.tooltipComponentRef.instance.position = {
            top: '-55px',
        };

        // @ts-ignore
        this.tooltipComponentRef.instance.mouseEnter.subscribe(() => {
            clearTimeout(this.hideTimeout);
        });
        // @ts-ignore
        this.tooltipComponentRef.instance.mouseLeave.subscribe(() => {
            this.scheduleTooltipRemoval();
        });
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.scheduleTooltipRemoval();
    }

    private scheduleTooltipRemoval() {
        this.hideTimeout = setTimeout(() => {
            if (this.tooltipComponentRef) {
                this.tooltipComponentRef.destroy();
                this.tooltipComponentRef = undefined!;
            }
        }, 50);
    }
}

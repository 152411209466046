<h1 mat-dialog-title tabIndex="0">
    {{ 'Tasks.AddTask' | translate }}
    <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
       matTooltip="{{'Common.Close' | translate}}"
       matTooltipPosition="below"
       matTooltipClass='below-top'
       (click)="dialogRef.close()"></i>
</h1>

<div mat-dialog-content>
    <div class="task-creator" [formGroup]="form">
        <div class="task-creator--header">
            <img src="assets/icons/name.svg" alt="name">
            <span>{{ 'Tasks.TaskName' | translate}}</span>
        </div>
        <div class="task-creator--input name">
            <mat-form-field>
                <input matInput
                       appUpperCaseFirstLetter
                       placeholder="{{ 'Tasks.TypeName' | translate}} *"
                       formControlName="name"
                       maxlength="60">
                <mat-hint align="end">{{ this.form.get('name').value?.length || 0}} / 60</mat-hint>
                <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate}}</mat-error>
            </mat-form-field>
        </div>

        <div class="task-creator--header">
            <img src="assets/icons/add-user-view.svg" alt="name">
            <span>{{ 'Tasks.ChooseUsers' | translate}}</span>
        </div>
        <div class="task-creator--input assign-button">
            <div class="assignments">
                <div *ngIf="assignments.assignedUsers.length" class="assignments--row">
                    <span>{{'Tasks.AccessForUsers' | translate}} </span>
                    <span class="cut-text" style="width: auto">{{assignments.assignedUsers | showAtMost: 'userName'}}</span>
                    <div class="number-selected">
                        {{assignments.assignedUsers.length}}
                    </div>
                </div>

                <div *ngIf="assignments.assignedPositions.length" class="assignments--row">
                    <span>{{'Tasks.AccessForPositions' | translate}} </span>
                    <span class="cut-text" style="width: auto">{{assignments.assignedPositions | showAtMost: 'name'}}</span>
                    <div class="number-selected">
                        {{assignments.assignedPositions.length}}
                    </div>
                </div>

                <div *ngIf="assignments.assignedGroups.length" class="assignments--row">
                    <span>{{'Tasks.AccessForGroups' | translate}}</span>
                    <span class="cut-text" style="width: auto">{{assignments.assignedGroups | showAtMost: 'name'}}</span>
                    <div class="number-selected">
                        {{assignments.assignedGroups.length}}
                    </div>
                </div>
            </div>

            <button mat-button
                    color="primary"
                    class="btn-sm btn-dolineo-icon"
                    (click)="onAssign()">
                <img src="assets/icons/add_user.svg"
                     loading="lazy"
                     alt="add_user">
                <span style="margin-left: 6px;">{{ 'Tasks.AssignTask' | translate}}</span>
            </button>
        </div>

        <div class="task-creator--header">
            <img src="assets/icons/calendar_creator.svg" alt="name">
            <span>{{ 'Tasks.RealizationDates' | translate}}</span>
        </div>
        <div class="task-creator--input realization-dates">
            <div class="realization-dates--double">
                <mat-form-field>
                    <input [id]="'realizationFrom'"
                           matInput
                           ngxDaterangepickerMd
                           showCancel="true"
                           showClearButton="true"
                           placeholder="{{ 'Tasks.RealizationFrom' | translate }} *"
                           readonly="readonly"
                           formControlName="realizationFrom"
                           [timePicker]="true"
                           [minDate]="minStartDate"
                           [timePicker24Hour]="true"
                           [locale]="defaultLocale"
                           [singleDatePicker]="true"
                           [showDropdowns]="true"
                           [lockStartDate]="false"
                           [customRangeDirection]="false"
                           (datesUpdated)="clear($event, 'realizationFrom')"/>
                    <mat-datepicker-toggle matSuffix
                                           (click)="openDatePicker('realizationFrom')"></mat-datepicker-toggle>
                    <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate}}</mat-error>
                </mat-form-field>
                <span>-</span>
                <mat-form-field>
                    <input [id]="'realizationTo'"
                           matInput
                           ngxDaterangepickerMd
                           showCancel="true"
                           showClearButton="true"
                           placeholder="{{ 'Tasks.RealizationTo' | translate }}"
                           readonly="readonly"
                           formControlName="realizationTo"
                           [timePicker]="true"
                           [minDate]="minEndDate"
                           [timePicker24Hour]="true"
                           [locale]="defaultLocale"
                           [singleDatePicker]="true"
                           [showDropdowns]="true"
                           [lockStartDate]="false"
                           [customRangeDirection]="false"
                           (datesUpdated)="clear($event, 'realizationTo')"/>
                    <mat-datepicker-toggle matSuffix (click)="openDatePicker('realizationTo')"></mat-datepicker-toggle>
                    <mat-error>{{ 'Tasks.RealizationFromError' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="realization-dates--double">
                <mat-form-field style="min-width: 165px;">
                    <mat-select formControlName="repeat">
                        <mat-option *ngFor="let repeatOption of repeatOptions"
                                    value="{{ repeatOption.value }}">{{ repeatOption.label | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container *ngIf="form.get('repeat').value == 'daily'">
                    <span>{{ 'Tasks.RepeatEvery' | translate }}</span>
                    <mat-form-field style="min-width: 180px;">
                        <mat-select formControlName="days" multiple>
                            <mat-option *ngFor="let dailyOption of dailyOptions"
                                        value="{{ dailyOption.value }}">{{ dailyOption.label | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
            <div class="realization-dates--double" *ngIf="form.get('repeat').value !== 'never'">
                <span>{{ 'Tasks.End' | translate }}</span>
                <mat-form-field style="min-width: 105px;">
                    <mat-select formControlName="end" >
                        <mat-option *ngFor="let endOption of endOptions"
                                    value="{{ endOption.value }}">{{ endOption.label | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <ng-container *ngIf="form.get('end').value == 'date'">
                    <mat-form-field>
                        <input [id]="'endDay'"
                               matInput
                               ngxDaterangepickerMd
                               showCancel="true"
                               showClearButton="true"
                               placeholder="{{ 'Common.Choose' | translate }}"
                               readonly="readonly"
                               formControlName="endDay"
                               [timePicker]="false"
                               [minDate]="minEndDate"
                               [timePicker24Hour]="false"
                               [locale]="defaultLocale"
                               [singleDatePicker]="true"
                               [showDropdowns]="true"
                               [lockStartDate]="false"
                               [customRangeDirection]="false"
                               (datesUpdated)="clear($event, 'endDay')"/>
                        <mat-datepicker-toggle matSuffix (click)="openDatePicker('endDay')"></mat-datepicker-toggle>
                        <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate}}</mat-error>
                    </mat-form-field>
                </ng-container>
            </div>

            <div class="task-creator--info" *ngIf="form.get('repeat').value != 'never'">{{ repeatInfo() }}</div>
        </div>

        <div class="task-creator--show-more"
             (click)="onShowMore()"> {{ (showMore ? 'Common.LessParams' : 'Common.MoreParams') | translate }}</div>

        <div [ngClass]="{'d-none': !showMore}">
            <div class="task-creator--header">
                <img src="assets/icons/configure_create.svg" alt="name">
                <span>{{ 'Tasks.OptionalConfiguration' | translate}}</span>
            </div>

            <div class="task-creator--input description">
                <div class="editor-wrapper" id="editor-wrapper">
                    <label>{{ 'Common.Description' | translate}}</label>
                    <div id="quill-editor" #quillEditor></div>
                </div>
            </div>

            <div class="task-creator--input files">
                <app-file-upload
                        [files]="loadedFiles"
                        [multiFiles] = true
                        [sortable] = true
                        [imageCropper] = true
                        [allowedExtensions]="'pdf, doc, docx, ppt, pptx, xls, xlsx, png, jpg, jpeg, svg, mp4, webm, avi, mow, wmv, ogv, mp3, zip, rar'"
                        (onFileUpload)="setFiles($event)"
                        [optionalUrl] = true
                        (onUrlChange)="setUrl($event)"
                        [url]="form.get('url').value"
                ></app-file-upload>
            </div>

            <div class="task-creator--input completion-type">
                <span>{{ 'Tasks.CompletionType' | translate }}</span>

                <mat-form-field style="min-width: 165px;">
                    <mat-select formControlName="completionType" >
                        <mat-option value="normal">{{ 'Tasks.YesNo' | translate}}</mat-option>
                        <mat-option value="percent">{{ 'Tasks.CompletionTypePercent' | translate}}</mat-option>
                        <mat-option value="number">{{ 'Tasks.CompletionTypeNumber' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <ng-container *ngIf="form.get('completionType').value == 'number'">
                    <span>{{ 'Tasks.Quantity' | translate }}</span>
                    <mat-form-field style="width: 50px;">
                        <input matInput
                               formControlName="requiredValue"
                               digitOnly
                               maxlength="4"
                               (change)= "form.get('requiredValue').value < 0 ? form.get('requiredValue').setValue(1) : null">
                        <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate}}</mat-error>
                    </mat-form-field>
                </ng-container>
            </div>

            <div class="task-creator--input completion-type">
                <span>{{ 'Tasks.AttachmentRequiredLabel' | translate }}</span>

                <mat-form-field style="min-width: 165px;">
                    <mat-select formControlName="attachmentRequired">
                        <mat-option value="0">{{ 'Tasks.NotRequired' | translate}}</mat-option>
                        <mat-option value="1">{{ 'Tasks.Required' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="task-creator--input completion-type" style="margin-bottom: 12px;">
                <mat-slide-toggle color="primary" formControlName="requireAcceptor" [disabled]="!canRequireAcceptor">
                    {{ 'Tasks.AdditionalAcceptanceRequired' | translate }}
                </mat-slide-toggle>

                <mat-form-field style="min-width: 200px" *ngIf="form.get('requireAcceptor').value">
                    <mat-select formControlName="acceptorId"
                                placeholder="{{ 'Tasks.Acceptor' | translate}} *">
                        <mat-form-field class="full-width pl-3 pr-3 pt-2">
                            <input matInput (keyup)="onUserSearch($event.target.value)"
                                   (keydown)="$event.stopPropagation()"
                                   #acceptorName
                                   placeholder="{{'TaskCreator.Employee' | translate}} *">
                            <button mat-icon-button
                                    matSuffix
                                    aria-label="Clear"
                                    *ngIf="acceptorName.value"
                                    (click)="acceptorName.value=''; onUserSearch(acceptorName.value)"
                                    color="primary">
                                <i class="material-icons clear-button"
                                   matTooltip="{{'Common.Clear' | translate}}"
                                   matTooltipPosition="below"
                                   matTooltipClass='below-top'
                                >close</i>
                            </button>
                        </mat-form-field>
                        <mat-option *ngFor="let user of users"
                                    [value]="user.id">{{ user.name }}</mat-option>
                    </mat-select>

                    <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
                </mat-form-field>
            </div>

            <div class="task-creator--input completion-type">
                <mat-slide-toggle color="primary" formControlName="blockedAfterExpiration">
                    {{ 'Tasks.BlockAfterEndDate' | translate }}
                </mat-slide-toggle>
            </div>

        </div>
    </div>
</div>

<div mat-dialog-actions class="dialog-footer">
    <button (click)="dialogRef.close(false)" mat-button
            class="cancel-button">{{ 'Common.Cancel' | translate}}</button>
    <button mat-button color="primary" (click)="save()"
            class="dolineo-button blue-button">{{ (form.get('id').value ? 'Common.Update' : 'Common.Add') | translate}}</button>
</div>
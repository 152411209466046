import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AppLocaleService } from '../../../core/services/locale.service';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
    selector: 'app-table-with-data',
    templateUrl: './table-with-data.component.html',
    styleUrls: ['./table-with-data.component.scss']
})
export class TableWithDataComponent implements OnInit {
    @ViewChildren(DaterangepickerDirective) datePickers: QueryList<DaterangepickerDirective>;

    @Input() set columns(elements: any[]) {
        if (elements && elements.length) {
            this._columns = [...elements];
        }
    }

    @Input() set items(items: any[]) {
        this._items = [...items];
        this.mainItems = [...items];
        this.search();
        if (this.selectedItemsArray.length === 0) {
            const selectedItems = this._items.filter(value => value.selected === true);
            this.selectedItemsArray = [...selectedItems]
        }
    }

    @Input() extraParams: any = {};
    @Input() selection: boolean = true;
    @Input() radioSelect: boolean = false;

    @Input() set refreshSelectedItemsArray(value: boolean) {
        this._refreshSelectedItemsArray = value;
        if (value) {
            this.selectedItemsArray = [];
        }
        this._selectedAll = this._items.length === this._items.filter(item => this.selectedItemsArray.find(sItem => JSON.stringify(item) === JSON.stringify(sItem))).length && this.selectedItemsArray.length > 0 && this._items.length > 0;
    }

    @Output() itemCheckboxSelected = new EventEmitter();
    @Output() itemRadioSelected = new EventEmitter();
    @Input() hideHead: boolean = false;
    @Input() showMoreButton: boolean = false;

    selectedIndex = null;

    selectedItemsArray: any[] = [];
    public filesPath = environment.filesPath;

    defaultLocale: any = {
        daysOfWeek: [
            this._translate.instant('Common.ShortSunday'),
            this._translate.instant('Common.ShortMonday'),
            this._translate.instant('Common.ShortTuesday'),
            this._translate.instant('Common.ShortWednesday'),
            this._translate.instant('Common.ShortThursday'),
            this._translate.instant('Common.ShortFriday'),
            this._translate.instant('Common.ShortSaturday')
        ],
        monthNames: [
            this._translate.instant('Common.January'),
            this._translate.instant('Common.February'),
            this._translate.instant('Common.March'),
            this._translate.instant('Common.April'),
            this._translate.instant('Common.May'),
            this._translate.instant('Common.June'),
            this._translate.instant('Common.July'),
            this._translate.instant('Common.August'),
            this._translate.instant('Common.September'),
            this._translate.instant('Common.October'),
            this._translate.instant('Common.November'),
            this._translate.instant('Common.December')
        ]
    };

    _items: any[] = [];
    _columns: any[] = [];
    _selectedAll: boolean = false;
    _refreshSelectedItemsArray: boolean = false;
    mainItems: any[] = [];

    sort = {
        field: 'id',
        direction: 'asc'
    };
    isMobile: boolean = false;
    showRows = 2;

    constructor(private datePipe: DatePipe,
                private _localeService: AppLocaleService,
                private _translate: TranslateService,
                private deviceService: DeviceDetectorService,) {
        this.isMobile = !this.deviceService.isDesktop();
        _localeService.currentLocale().subscribe(q => {
            moment.locale(q);
        });
        this.createDefaultLocal();
    }

    ngOnInit(): void {
        //
    }

    createDefaultLocal() {
        this.defaultLocale = {
            ...this.defaultLocale,
            separator: ' - ',
            cancelLabel: this._translate.instant('Common.Cancel'),
            applyLabel: this._translate.instant('Common.Accept'),
            clearLabel: this._translate.instant('Common.Clear')
        }
    }

    getLocale(column) {
        return Object.assign(this.defaultLocale, (column.locale || {}));
    }

    openDatePicker(id) {
        this.datePickers.forEach(d => {
            const t = {
                elementRef: {
                    nativeElement: {
                        id: null
                    }
                }
            };
            Object.assign(t, d);
            if (id === t.elementRef.nativeElement.id) {
                d.open();
                return;
            }
        });
    }

    selectRadio(e: any, index: number) {
        this.selectedIndex = index;
        this.itemRadioSelected.emit(e);
    }

    selectedCheckbox(event: any, index: number) {
        this._items[index].selected = event.checked;

        if (event.checked) {
            this.selectedItemsArray.push(this._items[index])
        } else {
            this.selectedItemsArray.splice(this.selectedItemsArray.findIndex(val =>
                    JSON.stringify(val).split('selected')[0] === JSON.stringify(this._items[index]).split('selected')[0]),
                1)
        }

        this.selectedItemsArray = [...new Map(this.selectedItemsArray.map(v => [JSON.stringify({
            ...v,
            selected: event.checked
        }), { ...v, selected: event.checked }])).values()];
        this.itemCheckboxSelected.emit(this.selectedItemsArray);
        this._selectedAll = this._items.length === this._items.filter(item => this.selectedItemsArray.find(sItem => JSON.stringify(item) === JSON.stringify(sItem))).length && this.selectedItemsArray.length > 0 && this._items.length > 0;
    }

    selectAll(event) {
        this._items.forEach(v => {
            v.selected = event.checked && !v.disabled;
            if (event.checked && !v.disabled) {
                this.selectedItemsArray.push(v)
            } else if (!v.disabled) {
                this.selectedItemsArray.splice(this.selectedItemsArray.findIndex(val =>
                        JSON.stringify(val).split('selected')[0] === JSON.stringify(v).split('selected')[0]),
                    1)
            }
        });

        this.selectedItemsArray = [...new Map(this.selectedItemsArray.map(v => [JSON.stringify({
            ...v,
            selected: event.checked
        }), { ...v, selected: event.checked }])).values()];

        this.itemCheckboxSelected.emit(this.selectedItemsArray);
        this._selectedAll = true;
    }

    get selectedItems(): string {
        return this._items.filter(value => value.selected).length.toString();
    }

    changeSort(field: string, type: string): void {
        if (field === this.sort.field) {
            if (type !== 'NUMBER') {
                if (this.sort.direction === 'asc') {
                    this.sort.direction = 'desc';
                    this.sortThisArray(this._items, -1)
                } else {
                    this.sort.direction = 'asc';
                    this.sortThisArray(this._items, 1)
                }
            } else {
                if (this.sort.direction === 'asc') {
                    this.sort.direction = 'desc';
                    this._items.sort((a, b) => b[this.sort.field] - a[this.sort.field])
                } else {
                    this.sort.direction = 'asc';
                    this._items.sort((a, b) => a[this.sort.field] - b[this.sort.field])
                }
            }
        } else {
            this.sort.direction = 'asc';
            this.sort.field = field;
        }
    }

    sortThisArray(arr, direction) {
        const s = this.sorter(direction);
        return arr.sort((a, b) => {
            return direction * s(a, b)
        });
    }

    sorter(direction) {
        direction = direction || 1;
        const up = direction > 0;

        return (a, b) => {
            let r = -1;
            const aa = a[this.sort.field] === null ? '' : a[this.sort.field].toString().toUpperCase();
            const bb = b[this.sort.field] === null ? '' : b[this.sort.field].toString().toUpperCase();
            const careAbout = up ? aa : bb;

            if (aa === bb) {
                r = 0;
            } else if (aa > bb || careAbout === undefined) {
                r = 1
            }
            return r;
        }
    }

    searchString(column) {
        this._items = this._items.filter(val => {
            return val[column.index] ? val[column.index].toUpperCase().includes(column.value.toUpperCase()) : !val;
        })
    }

    setNumber(column, numbers: any) {
        column.valueRange = { ...numbers }

        if (numbers.from && !numbers.to) {
            column.value = 'Od ' + numbers.from;
        } else if (!numbers.from && numbers.to) {
            column.value = 'Do ' + numbers.to;
        } else if (numbers.from && numbers.to) {
            column.value = 'Od ' + numbers.from + ' Do ' + numbers.to;
        }
    }

    searchNumber(column) {
        const numbersFrom = column.valueRange?.from ? column.valueRange.from : null;
        const numbersTo = column.valueRange?.to ? column.valueRange.to : null;
        if (numbersFrom || numbersTo) {
            this._items = this._items.filter(val => {
                if (numbersFrom && numbersTo) {
                    return Number(val[column.index]) >= numbersFrom && Number(val[column.index]) <= numbersTo;
                } else if (numbersFrom) {
                    return Number(val[column.index]) >= numbersFrom;
                } else {
                    return Number(val[column.index]) <= numbersTo;
                }
            })
        }
    }

    setArray(column, selected: any) {
        column.valueRange = column.multiple ? [...selected.value] : [selected.value];
        this.search();
    }

    searchArray(column) {
        if (column.valueRange?.length > 0) {
            if (column.isArrayInside && !column.multiple) {
                this._items = this._items.filter(val => {
                    if(val[column.arrayInside].length === 0 && column.valueRange[0] === 'novalue'){
                        return true;
                    }
                    return val[column.arrayInside].find(ele => ele[column.index] === column.valueRange[0] || ele === column.valueRange[0])
                })
            } else if (column.isArrayInside && column.multiple) {
                this._items = this._items.filter(val => {
                    if(val[column.arrayInside].length === 0 && column.valueRange.includes('novalue')){
                        return true;
                    }
                    return val[column.arrayInside].find(ele => column.valueRange.find(valueRange => {
                        return ele[column.index] === valueRange || ele === valueRange
                    }))
                })
            } else {
                this._items = this._items.filter(val => {
                    if(column.valueRange[0] === 'novalue' && (val[column.index] === '' || val[column.index] === null)){
                        return true;
                    }
                    return val[column.index] === column.valueRange[0];
                })
            }
        }
    }

    searchDateRange(column) {
        const startDate = column.valueRange?.startDate ? column.valueRange.startDate : null;
        const endDate = column.valueRange?.endDate ? column.valueRange.endDate : null;

        if (startDate && endDate) {
            this._items = this._items.filter(val => {
                return moment(val[column.index]).isSameOrBefore(endDate) && moment(val[column.index]).isSameOrAfter(startDate);
            })
        }
    }

    searchDataRangeArray(column) {
        const startDate = column.valueRange?.startDate ? column.valueRange.startDate : null;
        const endDate = column.valueRange?.endDate ? column.valueRange.endDate : null;
        if (startDate && endDate) {
            this._items = this._items.filter(val => {
                return val[column.arrayInside]
                    .find((value) => moment(value[column.index]).isSameOrBefore(endDate) && moment(value[column.index]).isSameOrAfter(startDate))
            })
        }
    }

    search() {
        this._columns.forEach((column: any, index: number) => {
            if (index === 0) {
                this._items = [...this.mainItems];
            }

            if (column.type === 'STRING' && column.value !== '') {
                this.searchString(column);
            }

            if (column.type === 'NUMBER') {
                this.searchNumber(column);
            }

            if (column.type === 'ARRAY') {
                this.searchArray(column);
            }

            if (column.type === 'DATE_RANGE') {
                this.searchDateRange(column);
            }

            if (column.type === 'DATE_RANGE_ARRAY') {
                this.searchDataRangeArray(column);
            }

            this._selectedAll = this._items.length === this._items.filter(item => this.selectedItemsArray.find(sItem => JSON.stringify(item) === JSON.stringify(sItem))).length && this.selectedItemsArray.length > 0 && this._items.length > 0;
        })
    }

    showMoreRows(){
        this.showRows += 3;
    }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { HelperService } from '../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UsersVisibilityDialogComponent } from '../users-visibility-dialog/users-visibility-dialog.component';
import { AssignUnassignDialogComponent } from '../assign-unassign-dialog/assign-unassign-dialog.component';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as he from 'he';

@Component({
    selector: 'assign-dialog',
    templateUrl: './assign-dialog.component.html',
    styleUrls: ['./assign-dialog.component.scss']
})
export class AssignDialogComponent implements OnInit, OnDestroy {
    step = 0;

    public assignedGroups: any[] = [];
    public assignedPositions: any[] = [];
    public assignedUsers: any[] = [];

    public unassignedUsers: any[] = [];
    public unassignedPositions: any[] = [];
    public unassignedGroups: any[] = [];

    usersColumns: any[] = [];
    positionsColumns: any[] = [];
    groupsColumns: any[] = [];

    usersColumnsAvailability: any[] = [];
    positionsColumnsAvailability: any[] = [];
    groupsColumnsAvailability: any[] = [];

    hasUsers: boolean;
    hasPositions: boolean;
    hasGroups: boolean;

    usersSelectedToAdd: any[] = [];
    positionsSelectedToAdd: any[] = [];
    groupsSelectedToAdd: any[] = [];
    usersSelectedToRemove: any[] = [];
    positionsSelectedToRemove: any[] = [];
    groupsSelectedToRemove: any[] = [];
    selectedSectionName: string = 'users';

    users: any[] = [];
    userToPreview: any[] = [];

    positions: any[] = [];

    translations = {
        title: '',
        accessAdded: 'AccessAdded',
        accessRemoved: 'AccessRemoved'
    };

    public hasPermissions: boolean = false;
    activeTab: string = 'unassign';

    taskPayload;
    closeButton: string = 'Common.Close';

    constructor(public dialogRef: MatDialogRef<AssignDialogComponent>,
                private _toastrService: ToastrService,
                private api: ApiService,
                public _helper: HelperService,
                private _dialog: MatDialog,
                private deviceService: DeviceDetectorService,
                private _translate: TranslateService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        if(data.closeButton){
            this.closeButton = data.closeButton;
        }
    }

    ngOnInit(): void {
        this.hasPermissions = this._helper.hasPermissions([
            'content:path:assign',
            'content:training:assign',
            'content:pools:assign',
            'content:survey:assign',
            'content:article-category:assign',
            'carer:article:manage',
            'carer:article-category:manage',
            'carer:path:manage',
            'carer:training:manage',
            'carer:pool:manage',
            'carer:survey:manage',
            'content:gamification:assign',
            'carer:gamification:manage',
            'task:add:self',
            'task:add:team',
            'task:add:company',
            'task:manager:add:self',
            'task:manager:add:team',
            'task:manager:add:company',
            'task:admin:add:company',
            'task:admin:edit:company'
        ]);

        this.data.users.forEach(u => {
            u.positionsArray = [];
            if (u.position) {
                u.positionsArray = [u.position];
            }
        });

        this.data.positions.forEach(u => {
            u.positionsArray = [u.name];
        });

        let transDict = '';
        if (this.data.type === 'path') {
            transDict = 'PathCreator.';
        } else if (this.data.type === 'training') {
            transDict = 'TrainingCreator.';
        } else if (this.data.type === 'pool') {
            transDict = 'PoolCreator.';
        } else if (this.data.type === 'survey') {
            transDict = 'SurveyCreator.';
        } else if (this.data.type === 'category') {
            transDict = 'KnowledgeBase.';
        } else if (this.data.type === 'gamification') {
            transDict = 'GamificationCreator.';
        } else if (this.data.type === 'task') {
            transDict = 'Tasks.';
        } else {
            transDict = 'KnowledgeBase.';
        }

        this.translations.title = transDict + 'SaveUsers';
        this.translations.accessAdded = this._translate.instant(transDict + this.translations.accessAdded);
        this.translations.accessRemoved = this._translate.instant(transDict + this.translations.accessRemoved);

        this.setAssignedUnassigned();

        this.api.Company.positions(
            this._helper.getUser().companyId,
            1,
            -159,
            'name',
            'asc',
            '').subscribe((res) => {
            this.positions = res.map(obj => {
                return {
                    id: obj.name,
                    value: obj.name
                };
            });
            this.createUsersColumn();
            this.createPositionsColumn();
            this.createGroupsColumn();
            this.createPoolsColumns();
        });

        this.setPreviewUsers();
    }

    createPoolsColumns() {
        if (this.data.type === 'pool' || this.data.type === 'survey') {
            this.usersColumnsAvailability.pop();
            this.positionsColumnsAvailability.pop();
            this.groupsColumnsAvailability.pop();

            this.usersColumnsAvailability = [
                ...this.usersColumnsAvailability,
                {
                    index: 'startDate',
                    name: 'Common.RealisationFrom',
                    searchable: true,
                    sortable: true,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'DATE_RANGE',
                    valueRange: null,
                    timePicker: true,
                    locale: {
                        format: 'YYYY-MM-DD HH:mm',
                        displayFormat: 'DD.MM.YYYY, HH:mm'
                    },
                    css: {
                        'min-width': '130px',
                        'width': '130px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return v.startDate ? moment(v.startDate).format('DD.MM.YYYY, HH:mm') : '';
                    }
                },
                {
                    index: 'endDate',
                    name: 'Common.RealisationTo',
                    searchable: true,
                    sortable: true,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'DATE_RANGE',
                    valueRange: null,
                    timePicker: true,
                    locale: {
                        format: 'YYYY-MM-DD HH:mm',
                        displayFormat: 'DD.MM.YYYY  HH:mm'
                    },
                    css: {
                        'min-width': '130px',
                        'width': '130px',
                        'padding-right': '20px',
                    },
                    render: (v) => {
                        return v.endDate ? moment(v.endDate).format('DD.MM.YYYY, HH:mm') : '';
                    }
                },
                {
                    index: 'isMandatory',
                    name: 'Pool.Mandatory',
                    value: '',
                    defaultValue: '',
                    type: 'ARRAY',
                    sortable: true,
                    searchable: true,
                    visible: true,
                    multiple: false,
                    options: [
                        { id: true, value: 'Common.Yes' },
                        { id: false, value: 'Common.No' }
                    ],
                    css: {
                        'min-width': '100px',
                        'width': '100px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return v.isMandatory ? this._translate.instant('Common.Yes') : this._translate.instant('Common.No');
                    }
                }
            ];

            this.positionsColumnsAvailability = [
                ...this.positionsColumnsAvailability,
                {
                    index: 'startDate',
                    name: 'Common.RealisationFrom',
                    searchable: true,
                    sortable: true,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'DATE_RANGE',
                    valueRange: null,
                    timePicker: true,
                    locale: {
                        format: 'YYYY-MM-DD HH:mm',
                        displayFormat: 'DD.MM.YYYY  HH:mm'
                    },
                    css: {
                        'min-width': '130px',
                        'width': '130px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return v.startDate ? moment(v.startDate).format('DD.MM.YYYY, HH:mm') : '';
                    }
                },
                {
                    index: 'endDate',
                    name: 'Common.RealisationTo',
                    searchable: true,
                    sortable: true,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'DATE_RANGE',
                    valueRange: null,
                    timePicker: true,
                    locale: {
                        format: 'YYYY-MM-DD HH:mm',
                        displayFormat: 'DD.MM.YYYY  HH:mm'
                    },
                    css: {
                        'min-width': '130px',
                        'width': '130px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return v.endDate ? moment(v.endDate).format('DD.MM.YYYY, HH:mm') : '';
                    }
                },
                {
                    index: 'isMandatory',
                    name: 'Pool.Mandatory',
                    value: '',
                    defaultValue: '',
                    type: 'ARRAY',
                    sortable: true,
                    searchable: true,
                    visible: true,
                    multiple: false,
                    options: [
                        { id: true, value: 'Common.Yes' },
                        { id: false, value: 'Common.No' }
                    ],
                    css: {
                        'min-width': '100px',
                        'width': '100px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return v.isMandatory ? this._translate.instant('Common.Yes') : this._translate.instant('Common.No');
                    }
                }
            ];

            this.groupsColumnsAvailability = [
                ...this.groupsColumnsAvailability,
                {
                    index: 'startDate',
                    name: 'Common.RealisationFrom',
                    searchable: true,
                    sortable: true,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'DATE_RANGE',
                    valueRange: null,
                    timePicker: true,
                    locale: {
                        format: 'YYYY-MM-DD HH:mm',
                        displayFormat: 'DD.MM.YYYY  HH:mm'
                    },
                    css: {
                        'min-width': '130px',
                        'width': '130px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return v.startDate ? moment(v.startDate).format('DD.MM.YYYY, HH:mm') : '';
                    }
                },
                {
                    index: 'endDate',
                    name: 'Common.RealisationTo',
                    searchable: true,
                    sortable: true,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'DATE_RANGE',
                    valueRange: null,
                    timePicker: true,
                    locale: {
                        format: 'YYYY-MM-DD HH:mm',
                        displayFormat: 'DD.MM.YYYY  HH:mm'
                    },
                    css: {
                        'min-width': '130px',
                        'width': '130px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        return v.endDate ? moment(v.endDate).format('DD.MM.YYYY, HH:mm') : '';
                    }
                },
                {
                    index: 'isMandatory',
                    name: 'Pool.Mandatory',
                    value: '',
                    defaultValue: '',
                    type: 'ARRAY',
                    sortable: true,
                    searchable: true,
                    visible: true,
                    multiple: false,
                    options: [
                        { id: true, value: 'Common.Yes' },
                        { id: false, value: 'Common.No' }
                    ],
                    css: {
                        'min-width': '100px',
                        'width': '100px',
                        'padding-right': '20px',
                    },
                    render: (v) => {
                        return v.isMandatory ? this._translate.instant('Common.Yes') : this._translate.instant('Common.No');
                    }
                }
            ]
        }
    }

    setPreviewUsers(openDialog: boolean = false) {
        if (this.data.type === 'training') {
            this.getTrainingAssigned(openDialog)
        }

        if (this.data.type === 'path') {
            this.getPathAssigned(openDialog)
        }

        if (this.data.type === 'pool') {
            this.getPoolAssigned(openDialog)
        }

        if (this.data.type === 'survey') {
            this.getSurveyAssigned(openDialog)
        }

        if (this.data.type === 'category') {
            this.getCategoryAssigned(openDialog)
        }

        if (this.data.type === 'gamification') {
            this.getGamificationAssigned(openDialog)
        }

        if (this.data.type === 'task') {
            this.getTaskAssigned(openDialog)
        }
    }

    setAssignedUnassigned() {
        this.assignedGroups = this.data.groups.filter(value => value.isAssigned);
        this.assignedPositions = this.data.positions.filter(value => value.isAssigned);
        this.assignedUsers = this.data.users.filter(value => value.isAssigned);

        this.unassignedGroups = this.data.groups.filter(value => !value.isAssigned);
        this.unassignedPositions = this.data.positions.filter(value => !value.isAssigned);
        this.unassignedUsers = this.data.users.filter(value => !value.isAssigned);

        this.hasUsers = this.data.users.length > 0;
        this.hasPositions = this.data.positions.length > 0;
        this.hasGroups = this.data.groups.length > 0;
    }

    ngOnDestroy() {
        // document.body.classList.add('nav-collapsed');
    }

    setStep(index: number, name: string) {
        this.selectedSectionName = name;
        this.step = this.step === index ? -1 : index;
    }

    createUsersColumn() {
        this.usersColumns = [
            {
                index: 'userName',
                name: 'Common.FirstNameLastName',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '150px',
                    'padding-right': '20px'
                },
                class: 'ft-auto',
            },
            {
                index: 'supervisor',
                name: 'Common.Supervisor',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '139px',
                    'width': '139px',
                    'padding-right': '20px'
                }
            },
            {
                index: 'position',
                name: 'Common.Position',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                multiple: true,
                isArrayInside: true,
                arrayInside: 'positionsArray',
                options: [...this.positions],
                css: {
                    'min-width': '137px',
                    'width': '137px',
                    'padding-right': '20px'
                },
                novalue: true
            }
        ];

        this.usersColumnsAvailability = [
            {
                index: 'userName',
                name: 'Common.FirstNameLastName',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '141px',
                    'width': '141px',
                    'padding-right': '20px'
                },
                class: 'ft-auto',
                render: (v) => {
                    if (v.isNew) {
                        return '<div>' + he.encode(v.userName) + '</div>' + `<span class="new-brand"> ${this._translate.instant('AssignDialog.NewUser')} </span>`;
                    } else {
                        return '<div class="cut-text">' + he.encode(v.userName) + '</div>';
                    }
                }
            },
            {
                index: 'supervisor',
                name: 'Common.Supervisor',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '139px',
                    'width': '139px',
                    'padding-right': '20px'
                }
            },
            {
                index: 'position',
                name: 'Common.Position',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                multiple: true,
                isArrayInside: true,
                arrayInside: 'positionsArray',
                options: [...this.positions],
                css: {
                    'min-width': '137px',
                    'width': '137px',
                    'padding-right': '20px'
                },
                novalue: true
            },
            {
                index: 'visibleFromDate',
                name: 'Common.VisibleFromDate',
                searchable: true,
                sortable: true,
                visible: this.data.type === 'training' || this.data.type === 'path',
                value: '',
                defaultValue: '',
                type: 'DATE_RANGE',
                valueRange: null,
                timePicker: false,
                locale: {
                    format: 'YYYY-MM-DD',
                    displayFormat: 'DD.MM.YYYY'
                },
                css: {
                    'min-width': '150px',
                    'width': '150px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return v.visibleFromDate ? moment(v.visibleFromDate).format('DD.MM.YYYY') : '';
                }
            },
            {
                index: 'expiryDate',
                name: 'Common.RealisationDate',
                searchable: true,
                sortable: true,
                visible: this.data.type !== 'category' && this.data.type !== 'gamification' && this.data.type !== 'task',
                value: '',
                defaultValue: '',
                type: 'DATE_RANGE',
                valueRange: null,
                timePicker: false,
                locale: {
                    format: 'YYYY-MM-DD',
                    displayFormat: 'DD.MM.YYYY'
                },
                css: {
                    'min-width': '170px',
                    'width': '170px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return v.expiryDate ? moment(v.expiryDate).format('DD.MM.YYYY') : '';
                }
            }
        ];
    }

    createPositionsColumn() {
        this.positionsColumns = [
            {
                index: 'name',
                name: 'Common.Position',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                multiple: true,
                isArrayInside: true,
                arrayInside: 'positionsArray',
                options: [...this.positions],
                css: {
                    'padding-left': '20px',
                    'min-width': '150px',
                    'padding-right': '20px'
                },
                class: 'ft-auto',
                render: (v) => {
                    if (v.isNew) {
                        return '<div>' + he.encode(v.name) + '</div>' + `<span class="new-brand"> ${this._translate.instant('AssignDialog.NewPosition')} </span>`;
                    } else {
                        return he.encode(v.name);
                    }
                }
            },
            {
                index: 'numberOfUsers',
                name: 'Common.NumberOfPeople',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: false,
                css: {
                    'width': '150px',
                    'min-width': '150px',
                    'padding-right': '20px'
                },
            }
        ];

        this.positionsColumnsAvailability = [
            {
                index: 'name',
                name: 'Common.Position',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                multiple: true,
                isArrayInside: true,
                arrayInside: 'positionsArray',
                options: [...this.positions],
                css: {
                    'padding-left': '20px',
                    'min-width': '150px',
                    'padding-right': '20px'
                },
                class: 'ft-auto',
                render: (v) => {
                    if (v.isNew) {
                        return '<div>' + he.encode(v.name) + '</div>' + `<span class="new-brand"> ${this._translate.instant('AssignDialog.NewPosition')} </span>`;
                    } else {
                        return he.encode(v.name);
                    }
                }
            },
            {
                index: 'numberOfUsers',
                name: 'Common.NumberOfPeople',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: false,
                css: {
                    'width': '150px',
                    'min-width': '150px',
                    'padding-right': '20px'
                }
            },
            {
                index: 'visibleFromDate',
                name: 'Common.VisibleFromDate',
                searchable: true,
                sortable: true,
                visible: this.data.type === 'training' || this.data.type === 'path',
                value: '',
                defaultValue: '',
                type: 'DATE_RANGE',
                valueRange: null,
                timePicker: false,
                locale: {
                    format: 'YYYY-MM-DD',
                    displayFormat: 'DD.MM.YYYY'
                },
                css: {
                    'min-width': '150px',
                    'width': '150px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return v.visibleFromDate ? moment(v.visibleFromDate).format('DD.MM.YYYY') : '';
                }
            },
            {
                index: 'expiryDate',
                name: 'Common.RealisationDate',
                searchable: true,
                sortable: true,
                visible: this.data.type !== 'category' && this.data.type !== 'gamification' && this.data.type !== 'task',
                value: '',
                defaultValue: '',
                type: 'DATE_RANGE',
                valueRange: null,
                timePicker: false,
                locale: {
                    format: 'YYYY-MM-DD',
                    displayFormat: 'DD.MM.YYYY'
                },
                css: {
                    'min-width': '150px',
                    'width': '150px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return v.expiryDate ? moment(v.expiryDate).format('DD.MM.YYYY') : '';
                }
            },
            {
                index: 'realizationForNewAccount',
                name: 'AssignDialog.RealizationForNewAccounts',
                searchable: true,
                sortable: true,
                visible: this.data.type === 'training' || this.data.type === 'path',
                value: '',
                defaultValue: '',
                type: 'NUMBER',
                valueRange: null,
                timePicker: false,
                css: {
                    'min-width': '170px',
                    'width': '170px',
                    'padding-right': '20px'
                },
            }
        ];
    }

    createGroupsColumn() {
        this.groupsColumns = [
            {
                index: 'name',
                name: 'Common.Group',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'padding-left': '20px',
                    'min-width': '150px',
                    'padding-right': '20px'
                },
                class: 'ft-auto'
            },
            {
                index: 'numberOfUsers',
                name: 'Common.NumberOfPeople',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: false,
                css: {
                    'width': '150px',
                    'min-width': '150px',
                    'padding-right': '20px'
                }
            }
        ];
        this.groupsColumnsAvailability = [
            {
                index: 'name',
                name: 'Common.Group',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'padding-left': '20px',
                    'min-width': '150px',
                    'padding-right': '20px'
                },
                class: 'ft-auto',
                render: (v) => {
                    if (v.isNew) {
                        return '<div>' + he.encode(v.name) + '</div>' + `<span class="new-brand"> ${this._translate.instant('AssignDialog.NewGroup')} </span>`;
                    } else {
                        return he.encode(v.name);
                    }
                }
            },
            {
                index: 'numberOfUsers',
                name: 'Common.NumberOfPeople',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: false,
                css: {
                    'width': '150px',
                    'min-width': '150px',
                    'padding-right': '20px'
                }
            },
            {
                index: 'visibleFromDate',
                name: 'Common.VisibleFromDate',
                searchable: true,
                sortable: true,
                visible: this.data.type === 'training' || this.data.type === 'path',
                value: '',
                defaultValue: '',
                type: 'DATE_RANGE',
                valueRange: null,
                timePicker: false,
                locale: {
                    format: 'YYYY-MM-DD',
                    displayFormat: 'DD.MM.YYYY'
                },
                css: {
                    'min-width': '150px',
                    'width': '150px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return v.visibleFromDate ? moment(v.visibleFromDate).format('DD.MM.YYYY') : '';
                }
            },
            {
                index: 'expiryDate',
                name: 'Common.RealisationDate',
                searchable: true,
                sortable: true,
                visible: this.data.type !== 'category' && this.data.type !== 'gamification' && this.data.type !== 'task',
                value: '',
                defaultValue: '',
                type: 'DATE_RANGE',
                valueRange: null,
                timePicker: false,
                locale: {
                    format: 'YYYY-MM-DD',
                    displayFormat: 'DD.MM.YYYY'
                },
                css: {
                    'min-width': '150px',
                    'width': '150px',
                    'padding-right': '20px'
                },
                render: (v) => {
                    return v.expiryDate ? moment(v.expiryDate).format('DD.MM.YYYY') : '';
                }
            },
            {
                index: 'realizationForNewAccount',
                name: 'AssignDialog.RealizationForNewAccounts',
                searchable: true,
                sortable: true,
                visible: this.data.type === 'training' || this.data.type === 'path',
                value: '',
                defaultValue: '',
                valueRange: null,
                type: 'NUMBER',
                hasMinutes: false,
                css: {
                    'width': '150px',
                    'min-width': '150px',
                    'padding-right': '20px'
                },
            }
        ];
    }

    choseTextToAssignDialog(content: string): any {
        const texts = {
            headerText: '',
            contentText: '',
        };
        switch (content) {
            case 'users':
                texts.headerText = 'AssignDialog.AssignUsers';
                texts.contentText = 'AddRemoveVisibility.SelectedUsers';
                break;
            case 'positions':
                texts.headerText = 'AssignDialog.AssignPositions';
                texts.contentText = 'AddRemoveVisibility.SelectedPositions';
                break;
            case 'groups':
                texts.headerText = 'AssignDialog.AssignGroups';
                texts.contentText = 'AddRemoveVisibility.SelectedGroups';
                break;
        }
        return texts;
    }

    choseTextToUnassignedDialog(content: string): any {
        const texts = {
            headerText: '',
            contentText: '',
        };
        switch (content) {
            case 'users':
                texts.headerText = 'AssignDialog.UnassignUsers';
                texts.contentText = 'AddRemoveVisibility.SelectedUsers';
                break;
            case 'positions':
                texts.headerText = 'AssignDialog.UnassignPositions';
                texts.contentText = 'AddRemoveVisibility.SelectedPositions';
                break;
            case 'groups':
                texts.headerText = 'AssignDialog.UnassignGroups';
                texts.contentText = 'AddRemoveVisibility.SelectedGroups';
                break;
        }
        return texts;
    }

    assign(content: string, changeDate: boolean = false) {
        const texts = this.choseTextToAssignDialog(content);
        this._dialog.open(AssignUnassignDialogComponent, {
            disableClose: true,
            width: this.data.type === 'survey' || this.data.type === 'pool' ? '560px' : '540px',
            panelClass: 'assign-unassign-popup',
            data: {
                type: this.data.type,
                selected: changeDate ? this[content + 'SelectedToRemove'] : this[content + 'SelectedToAdd'],
                selectedTable: this.selectedSectionName,
                headerText: texts.headerText,
                contentText: texts.contentText,
                status: true,
                id: this.data.id
            }
        }).afterClosed().subscribe(val => {
            if (val && !changeDate) {
                this[content + 'SelectedToAdd'] = this[content + 'SelectedToAdd'].map(value => {
                    return {
                        ...value,
                        selected: false,
                        isNew: true,
                        expiryDate: val.expiryDate,
                        visibleFromDate: val.visibleFromDate,
                        startDate: val.startDate ? val.startDate : '',
                        endDate: val.endDate ? val.endDate : '',
                        isMandatory: val.isMandatory !== null ? val.isMandatory : '',
                        realizationForNewAccount: val.realizationForNewAccount !== null ? val.realizationForNewAccount : '',
                    }
                })
                if (this.selectedSectionName === 'users') {
                    this.assignedUsers = [...this.usersSelectedToAdd, ...this.assignedUsers];
                    this.unassignedUsers = this.unassignedUsers.filter(item => !this.assignedUsers.find(ele => ele.userName === item.userName));
                    this.usersSelectedToAdd = [];
                }

                if (this.selectedSectionName === 'positions') {
                    this.assignedPositions = [...this.positionsSelectedToAdd, ...this.assignedPositions];
                    this.unassignedPositions = this.unassignedPositions.filter(item => !this.assignedPositions.find(ele => ele.name === item.name))
                    this.positionsSelectedToAdd = [];
                }

                if (this.selectedSectionName === 'groups') {
                    this.assignedGroups = [...this.groupsSelectedToAdd, ...this.assignedGroups];
                    this.unassignedGroups = this.unassignedGroups.filter(item => !this.assignedGroups.find(ele => ele.name === item.name))
                    this.groupsSelectedToAdd = [];
                }

                if (this.deviceService.isDesktop()) {
                    this._toastrService.success(this._translate.instant(this.data.toastrAssign));
                }
            }

            if (val && changeDate) {
                if (this.selectedSectionName === 'users') {
                    this.assignedUsers.forEach(item => {
                        if (this[content + 'SelectedToRemove'].find(ele => ele.userName === item.userName)) {
                            item.selected = false;
                            item.expiryDate = val.expiryDate;
                            item.startDate = val.startDate;
                            item.endDate = val.endDate;
                            item.isMandatory = val.isMandatory;
                            item.visibleFromDate = val.visibleFromDate;
                        }
                    })

                    this.usersSelectedToRemove = [];
                }

                if (this.selectedSectionName === 'positions') {
                    this.assignedPositions.forEach(item => {
                        if (this[content + 'SelectedToRemove'].find(ele => ele.name === item.name)) {
                            item.selected = false;
                            item.expiryDate = val.expiryDate;
                            item.startDate = val.startDate;
                            item.endDate = val.endDate;
                            item.isMandatory = val.isMandatory;
                            item.visibleFromDate = val.visibleFromDate;
                            item.realizationForNewAccount = val.realizationForNewAccount;
                        }
                    })

                    this.positionsSelectedToRemove = [];
                }

                if (this.selectedSectionName === 'groups') {
                    this.assignedGroups.forEach(item => {
                        if (this[content + 'SelectedToRemove'].find(ele => ele.name === item.name)) {
                            item.selected = false;
                            item.expiryDate = val.expiryDate;
                            item.startDate = val.startDate;
                            item.endDate = val.endDate;
                            item.isMandatory = val.isMandatory;
                            item.visibleFromDate = val.visibleFromDate;
                            item.realizationForNewAccount = val.realizationForNewAccount;
                        }
                    })

                    this.groupsSelectedToRemove = [];
                }

                if (this.deviceService.isDesktop()) {
                    this._toastrService.success(this._translate.instant(this.data.toastrAssign));
                }
            }

            this.setPreviewUsers(val?.openUserDialog);
        })
    }

    unassign(content: string) {
        const texts = this.choseTextToUnassignedDialog(content);

        this._dialog.open(AssignUnassignDialogComponent, {
            disableClose: true,
            width: this.data.type === 'survey' || this.data.type === 'pool' ? '560px' : '540px',
            panelClass: 'assign-unassign-popup',
            data: {
                type: this.data.type,
                selected: this[content + 'SelectedToRemove'],
                selectedTable: this.selectedSectionName,
                headerText: texts.headerText,
                contentText: texts.contentText,
                status: false,
                id: this.data.id
            }
        }).afterClosed().subscribe(val => {
            this.taskPayload = val;
            this[content + 'SelectedToRemove'] = this[content + 'SelectedToRemove'].map(value => {
                return {
                    ...value,
                    selected: false,
                    isNew: false,
                    expiryDate: null,
                    visibleFromDate: val.visibleFromDate,
                    realizationForNewAccount: val.realizationForNewAccount,
                }
            })
            if (val) {
                if (this.selectedSectionName === 'users') {
                    this.unassignedUsers = [...this.usersSelectedToRemove, ...this.unassignedUsers];
                    this.assignedUsers = this.assignedUsers.filter(item => !this.usersSelectedToRemove.find(ele => ele.userName === item.userName))
                    this.usersSelectedToRemove = [];
                }

                if (this.selectedSectionName === 'positions') {
                    this.unassignedPositions = [...this.positionsSelectedToRemove, ...this.unassignedPositions];
                    this.assignedPositions = this.assignedPositions.filter(item => !this.positionsSelectedToRemove.find(ele => ele.name === item.name))
                    this.positionsSelectedToRemove = [];
                }

                if (this.selectedSectionName === 'groups') {
                    this.unassignedGroups = [...this.groupsSelectedToRemove, ...this.unassignedGroups];
                    this.assignedGroups = this.assignedGroups.filter(item => !this.groupsSelectedToRemove.find(ele => ele.name === item.name))
                    this.groupsSelectedToRemove = [];
                }

                if (this.deviceService.isDesktop()) {
                    this._toastrService.success(this._translate.instant(this.data.toastrUnassign));
                }
            }
            this.setPreviewUsers(val?.openUserDialog);
        })
    }

    getTrainingAssigned(openDialog: boolean = false) {
        this.api.Library.trainingUsersAssigned(this._helper.getUser().id, this.data.id).subscribe(value => {
            this.userToPreview = [...value];
            if (openDialog && this.userToPreview.length > 0) {
                this.showUsers();
            }
        })
    }

    getPathAssigned(openDialog: boolean = false) {
        this.api.Library.pathUsersAssigned(this._helper.getUser().id, this.data.id).subscribe(value => {
            this.userToPreview = [...value];
            if (openDialog && this.userToPreview.length > 0) {
                this.showUsers();
            }
        })
    }

    getPoolAssigned(openDialog: boolean = false) {
        this.api.Library.poolUsersAssigned(this.data.id).subscribe(value => {
            this.userToPreview = [...value];
            if (openDialog && this.userToPreview.length > 0) {
                this.showUsers();
            }
        })
    }

    getSurveyAssigned(openDialog: boolean = false) {
        this.api.Survey.surveyUsersAssigned(this.data.id).subscribe(value => {
            this.userToPreview = [...value];
            if (openDialog) {
                this.showUsers();
            }
        })
    }

    getCategoryAssigned(openDialog: boolean = false) {
        this.api.KnowledgeBaseService.categoryUsersAssigned(this.data.id).subscribe(value => {
            this.userToPreview = [...value];
            if (openDialog) {
                this.showUsers();
            }
        })
    }

    getGamificationAssigned(openDialog: boolean = false) {
        this.api.Gamification.assignedList(this.data.id).subscribe(value => {
            this.userToPreview = [...value];
            if (openDialog && this.userToPreview.length > 0) {
                this.showUsers();
            }
        })
    }

    getTaskAssigned(openDialog: boolean = false) {
        if(this.data.id > 0){
            this.api.Tasks.assignedList(this.data.id).subscribe(value => {
                this.userToPreview = [...value];
                if (openDialog && this.userToPreview.length > 0) {
                    this.showUsers();
                }
            })
        }else{
            this.userToPreview = [];
        }
    }

    selectedAddItems(items: [], content: string) {
        this[content + 'SelectedToAdd'] = [...items];
    }

    selectedRemoveItems(items: [], content: string) {
        this[content + 'SelectedToRemove'] = [...items];
    }

    showUsers() {
        if (this.userToPreview.length) {
            this.sortByAccess();
            this._dialog.open(UsersVisibilityDialogComponent, {
                    disableClose: true,
                    width: '90vw',
                    maxWidth: '90vw',
                    height: '90vh',
                    panelClass: 'assign-unassign-popup',
                    data: {
                        dataToAssign: this.data,
                        headerText: 'AssignDialog.AssignedPreview',
                        name: this.data.name,
                        items: this.userToPreview,
                        isCustomTable: true,
                        type: this.data.type
                    }
                }
            );
        }
    }

    sortByAccess() {
        if (this.data.type === 'pool' || this.data.type === 'survey') {
            this.userToPreview.forEach(value => {
                value.assigned.sort((a, b) => {
                    const dateA = a.startDate ? new Date(a.startDate) : new Date(8640000000000000)
                    const dateB = b.startDate ? new Date(b.startDate) : new Date(8640000000000000)
                    return dateA.getTime() - dateB.getTime();
                });
            })
        } else {
            this.userToPreview.forEach(value => {
                value.assigned.sort((a, b) => {
                    const dateA = a.expiryDate ? new Date(a.expiryDate) : new Date(8640000000000000)
                    const dateB = b.expiryDate ? new Date(b.expiryDate) : new Date(8640000000000000)
                    return dateA.getTime() - dateB.getTime();
                });
            })
        }
    }

    close(){
        if(this.data.type === 'task'){
            this.dialogRef.close({
                users: this.assignedUsers,
                positions: this.assignedPositions,
                groups: this.assignedGroups
            });
        }else{
            this.dialogRef.close();
        }
    }
}

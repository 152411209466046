<div class="preview-image">
    <h1 mat-dialog-title tabIndex="0">
        {{data.imageName}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content class="d-flex">
        <img src="{{data.base64}}" alt="image">
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close(false)" mat-button color="primary"
                class="dolineo-button blue-button">{{(data.cancelText || 'Common.Close') | translate}}</button>
    </div>
</div>
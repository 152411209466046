<div class="file-upload-comp">
    <div class="flex-items" style="justify-content: space-between">
        <button class="download-button" mat-flat-button
                (click)="selectFile()"
                type="button">
            <i class="dolineo-icon dolineo-icon-download2"
               style="margin-bottom: 3px; margin-right: 5px;"></i>
            {{ header | translate }}
        </button>
        <input type="file" #file style="display: none;"
               (change)="onFileChange(null)"/>

        <div class="flex-items mt-3" *ngIf="optionalUrl && !urlFullWidth">
            <i class="dolineo-icon dolineo-icon-link" style="margin-left: auto"></i>
            <label style="width: 110px;margin-top: 4px;margin-left: 6px;"><b>{{ 'Files.PasteLink' | translate}}</b></label>
            <mat-form-field style="width: 100%;">
                <input matInput placeholder="Adres url" [formControl]="urlFormControl">
            </mat-form-field>
        </div>
    </div>

    <div class="drop-down-container" *ngIf="multiFiles || selectedFiles.length < 1">
        <ngx-file-drop
                dropZoneLabel="{{'ArticleCreate.DragAndDropFileMax2GB' | translate}}"
                (onFileDrop)="dropped($event)"
                (click)="selectFile()"
                multiple="false"
                directory="false">
        </ngx-file-drop>
    </div>

    <div *ngIf="fileError" class="error-file">
        <mat-error>{{ fileErrorMessage | translate }}</mat-error>
    </div>

    <div *ngIf="selectedFiles.length"
         cdkDropList
         class="attachments"
         (cdkDropListDropped)="dropFiles($event)">
        <div *ngFor="let file of selectedFiles; let i = index"
             class="attachments__container"
             [cdkDragPreviewClass]="'flex-start-drag'"
             cdkDrag>
            <div class="attachments__container--left">
                <img src="/assets/icons/drag-handler.svg"
                     matTooltip="{{'Common.DragAndDrop' | translate}}"
                     matTooltipPosition="below"
                     matTooltipClass='below-top'
                     cdkDragHandle
                     (mousedown)="removeTooltipWhenDrag()"
                     alt="drag-handler"
                     *ngIf="sortable"
                     class="pointer"
                >
                <i [class]="getIconName(file.name)"></i>
                <span>{{ file.name }}</span>
            </div>

            <div class="attachments__container--right">
                <i (click)="downloadFile(file)"
                   *ngIf="file.path"
                   matTooltip="{{'Common.Download' | translate}}"
                   matTooltipPosition="above"
                   matTooltipClass='above'
                   class="dolineo-icon dolineo-icon-download2 cursor-pointer"></i>
                <i (click)="removeFile(i)"
                   matTooltip="{{'Common.Delete' | translate}}"
                   matTooltipPosition="below"
                   matTooltipClass='below-top'
                   class="dolineo-icon dolineo-icon-trash cursor-pointer"></i>
            </div>
        </div>
    </div>

    <div class="flex-items mt-3 file-container-url" *ngIf="optionalUrl && urlFullWidth">
        <div class="flex-items">
            <i class="dolineo-icon dolineo-icon-link" style="margin-left: auto"></i>
            <label style="width: 110px;margin-top: 4px;margin-left: 6px;"><b>{{ urlHeader | translate}}</b></label>
        </div>
        <mat-form-field style="width: 100%;">
            <input matInput placeholder="Adres url" [formControl]="urlFormControl">
        </mat-form-field>
    </div>
</div>

<div class="crop-image" *ngIf="data">
    <h1 mat-dialog-title tabIndex="0">
        {{(data.title ? data.title : 'Common.AddFile') | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content class="text-center">
        <ng-container *ngIf="!data.addSvgFileText && !data.addPNGJPGJPEGFileText && !imageName;">
            <button *ngIf="!fileIsSelected" (click)="selectFile()" color="primary" mat-raised-button
                    class="btn-w-md">{{'Common.ChoiceGraphicFromDisk' | translate}}</button>

            <button *ngIf="!fileIsSelected && data.showBaseGraphic"
                    style="margin-left: 80px"
                    [ngClass]="{'opacity-30 cursor-default': !baseGraphics.length}"
                    (click)="openBaseGraphics()" color="primary" mat-raised-button
                    class="btn-w-md">{{'Common.ChoiceFromGraphicBase' | translate}}</button>
        </ng-container>

        <ng-container *ngIf="data.addSvgFileText && data.addPNGJPGJPEGFileText && !imageName;">
            <button *ngIf="!fileIsSelected" (click)="selectFile(true)" color="primary" mat-raised-button
                    class="btn-w-md" style="margin-right: 31px">{{data.addSvgFileText | translate}}</button>
            <button *ngIf="!fileIsSelected" (click)="selectFile()" color="primary" mat-raised-button
                    class="btn-w-md">{{data.addPNGJPGJPEGFileText | translate}}</button>
        </ng-container>

        <input type="file" #file style="display: none;" (change)="validateFile($event)"/>
        <mat-error *ngIf="errorMessage !== ''" style="margin-top: 15px">{{ errorMessage | translate}}</mat-error>

        <div class="cropped-wrapper" *ngIf="fileIsSelected && imageChangedEvent">
            <div [ngClass]="{'cropped-image': !noImageError && imageChangedEvent && cropperIsReady}">
                <image-cropper *ngIf="!isSVG"
                               [imageChangedEvent]="imageChangedEvent"
                               [maintainAspectRatio]="true"
                               [aspectRatio]="aspectRatio"
                               [resizeToWidth]="resizeToWidth"
                               [format]="fileExtension"
                               [imageQuality]="100"
                               (imageCropped)="imageCropped($event)"
                               (imageLoaded)="imageLoaded()"
                               (cropperReady)="cropperReady()"
                               (loadImageFailed)="loadImageFailed()">
                </image-cropper>
            </div>
        </div>

        <ng-container *ngIf="imageName">
            <div class="file-container">
                <div class="flex-items">
                    <span> {{ imageName }} </span>
                    <div>
                        <i (click)="downloadFile()"
                           matTooltip="{{'Common.Download' | translate}}"
                           matTooltipPosition="above"
                           matTooltipClass='above'
                           class="dolineo-icon dolineo-icon-download2"></i>
                        <i (click)="removeFile()"
                           matTooltip="{{'Common.Delete' | translate}}"
                           matTooltipPosition="above"
                           matTooltipClass='above'
                           class="dolineo-icon dolineo-icon-trash"></i>
                    </div>
                </div>
            </div>

            <div class="extra-info" *ngIf="data.extraInfo">
                <span class="icon"><img src="assets/icons/info.svg" alt="info"></span>
                <span class="value">{{data.extraInfo | translate}}</span>
            </div>
        </ng-container>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="dialogRef.close(false)" mat-button
                class="cancel-button">{{'Common.Cancel' | translate}}</button>
        <button (click)="acceptCrop()" mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.Save' | translate}}</button>
    </div>
</div>

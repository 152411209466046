<div class="widget-container"
     *ngIf="items && defaultItems"
     [ngClass]="('widget-container-onboarding' + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.background]="('linear-gradient(135deg, '+ hexToRGB('#' + widget.backgroundColor,1) +' 0%, '+ hexToRGB('#' + widget.backgroundColor,0.7) +' 100%)')"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name"
         [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''"
    >{{ 'DashboardCreator.Onboarding' | translate}}</div>

    <div class="widget-container--onboarding">
        <div class="widget-container--onboarding--realization">
            <div class="progress-chart">
                <ngx-charts-pie-chart
                        [view]="viewPie"
                        [scheme]="colorScheme"
                        [doughnut]="true"
                        [tooltipDisabled]="true"
                        [arcWidth]="0.3"
                        [results]="pieChartData">
                </ngx-charts-pie-chart>

                <div class="progress-chart__number" *ngIf="widget.data && widget.data.completedElementsCount" [style.background-color]="!whiteBackground ? hexToRGB('#' + widget.backgroundColor,0.75) : ''">
                    <span [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">
                        {{ widget.data.completedElementsCount === 0 ? 0 : (widget.data.completedElementsCount / widget.data.totalElementsCount * 100) | number: '1.0-0'}}
                    </span>
                    <span [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''"> % </span>
                </div>
            </div>
        </div>
    </div>

    <div class="widget-container__content">
        <div class="widget-container__content--description">
            <div class="widget-container__content--description--text"
                 [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'DashboardWidgetOnboarding.WaitingForRealization' | translate}}</div>
            <div class="widget-container__content--description--onboarding-name"
                 [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ firstNotCompletedElementName }}</div>
        </div>
    </div>
</div>


<div class="widget-container"
     *ngIf="items && defaultItems"
     [ngClass]="('widget-container-employee-missed-deadlines' + ' widget-container-size-' + (setSize ? widget.sizeClass : '') + (preview ? ' no-overflow-table' : ''))"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name">{{ 'DashboardCreator.MissedDeadlines' | translate}}</div>

    <div class="widget-container__content">
        <div class="widget-container__content--missed-deadlines">
            <app-data-grid *ngIf="tableItems.length > 0"
                            [columns]="columns"
                           [items]="tableItems"
                           [request]="request"
                           (loadData)="getData($event)"
                           [noTabs]="false"
                           [selection]="false"
                           [hideHead]="true">
            </app-data-grid>
            <div class="widget-container__content--empty-status" *ngIf="tableItems.length === 0">
                <div class="widget-container__content--empty-status--text">{{ 'DashboardWidgetMissedDeadlines.IsEmptyText1' | translate}}</div>
                <div class="widget-container__content--empty-status--text">{{ 'DashboardWidgetMissedDeadlines.IsEmptyText2' | translate}}</div>
                <img style="margin-right: 10px;" src="/assets/images/dashboard/clock.svg" alt="filter" class="mr-3">
            </div>
        </div>
    </div>
</div>


import { throwError as observableThrowError, of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { capitalizeFirstLetter } from '../../shared/helpers';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { UserService } from '../../api/services/user.service';
import { IChangePassword, IOauthResponse, IUserLogin } from '../../shared/intefaces/interfaces';
import { UserSelfInterface } from '../../shared/intefaces/user-self.interface';
import { HelperService } from '../../shared/services/helper.service';
import {CookieService} from '../../shared/services/cookie.service';

interface AppSession {
    token: string;
    tokenType: string;
    userName: string;
    refreshToken?: string;
    scope?: string;
    aki?: string;
    sak?: string;
    st?: string;
}

interface ISessionEntry {
    user: string;
    session: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    get userName(): string {
        return this.appSession ? this.appSession.userName : '';
    }

    get isAuthenticated(): boolean {
        return !!this.appSession && !!this.appSession.userName;
    }

    get userRoles(): string[] {
        return this.appSession ? this.user.roles : [];
    }

    private appSessionKey: string = 'dolineosession';
    private dolineouser: string = 'dolineouser';
    private appSession: AppSession = null;
    private user: UserSelfInterface = null;

    private baseUrl = environment.apiPath;
    public tokenUrl = '/oauth/v2/token';
    private forgotPasswordUrl = '/reset-password-send-email';
    private resetPasswordUrl = '/reset-password-do';
    private checkTokenUrl = '/check-token';
    public correctResetPassword: boolean = false;
    public correctActivationPassword: boolean = false;

    get authToken(): string {
        return !!this.appSession ? `${capitalizeFirstLetter(this.appSession.tokenType)} ${this.appSession.token}` : null;
    }

    constructor(private http: HttpClient,
                private helperService: HelperService,
                private _router: Router,
                private userApi: UserService,
                private cookie: CookieService) {
        this.tryRestoreSession();
    }

    login(userLogin: IUserLogin): Observable<boolean> {
        this.clearSession();
        const body = {
            'grant_type': 'password',
            'username': userLogin.email,
            'password': JSON.stringify({
                'password': userLogin.password,
                'captcha': userLogin.captcha,
                'subdomain': userLogin.subdomain,
                'platform': userLogin.platform
            }),
            'client_id': environment.clientId,
            'client_secret': environment.clientSecret
        };
        return this.http.post<IOauthResponse>(this.baseUrl + this.tokenUrl, body).pipe(map((resp) => {

            const isAuthenticated = resp.access_token != null;

            if (isAuthenticated) {
                this.trySetSession(userLogin.email, resp.access_token, resp.token_type, resp.refresh_token, resp.scope, resp.aki, resp.sak, resp.st);
            }

            return isAuthenticated;

        }), catchError(this.handleError));
    }

    refreshToken(): Observable<boolean> {
        if (!this.appSession || !this.appSession.refreshToken) {
            return observableOf(false);
        }

        return this.http.post<IOauthResponse>(this.baseUrl + this.tokenUrl, {
            refresh_token: this.appSession.refreshToken,
            grant_type: 'refresh_token',
            client_id: environment.clientId,
            client_secret: environment.clientSecret
        }).pipe(map((resp) => {

                const isAuthenticated = resp.access_token != null;

                if (isAuthenticated) {
                    this.trySetSession(this.appSession.userName, resp.access_token, resp.token_type, resp.refresh_token, resp.scope, resp.aki, resp.sak, resp.st);
                }

                return isAuthenticated;

            }),
            catchError(this.handleError)
        );
    }

    logout(): void {
        this.userApi.logout().subscribe(() => {
            this.helperService.setUser(null)
            this.clearSession();
            this._router.navigate([''])
        });
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof Error) {
            const errMessage = error.error.message;
            return observableThrowError(errMessage);
        } else if (error.status === 0) {
            const errMessage = 'Wystąpił błąd połączenia z serwerem.';
            return observableThrowError(errMessage);
        }
        return observableThrowError(error || 'Server error');
    }

    private clearSession() {
        localStorage.clear();
        this.appSession = null;
        this.cookie.deleteAll();
    }

    public tryRestoreSession() {
        // if (!!this.appSession) {
        //     return;
        // }

        const data = this.cookie.get(this.appSessionKey);

        if (data) {
            this.appSession = JSON.parse(data);
            this.user = JSON.parse(localStorage.getItem('dolineouser'));
        }
    }

    public trySetSession(userName: string, token: string, tokenType: string, refreshToken?: string, scope?: string, aki?: string, sak?: string, st?: string) {
        this.appSession = { userName, token, tokenType, refreshToken, scope, aki, sak, st };
        this.cookie.set(this.appSessionKey, JSON.stringify(this.appSession));
    }

    private getSessionHistory(): ISessionEntry[] {
        const h = localStorage.getItem('sessionHistory');
        if (h != null) {
            return (JSON.parse(h) as ISessionEntry[]) || [];
        } else {
            return [];
        }
    }

    private clearSessionHistory() {
        localStorage.removeItem('sessionHistory');
    }

    private pushSessionHistory(entry: ISessionEntry) {
        const sessionHistory = this.getSessionHistory();
        sessionHistory.push(entry);
        localStorage.setItem('sessionHistory', JSON.stringify(sessionHistory));
    }

    private popSessionHistory(): ISessionEntry {
        const sessionHistory = this.getSessionHistory();
        if (sessionHistory != null && sessionHistory.length) {
            sessionHistory.pop();
            localStorage.setItem('sessionHistory', JSON.stringify(sessionHistory));
            if (sessionHistory.length) {
                return sessionHistory[sessionHistory.length - 1];
            }
        }
        return null;
    }

    /**
     * TODO: Refactor login as user
     * @param redirectUrl
     */
    public backupOldSession(redirectUrl: string): void {
        const oldSession = this.cookie.get(this.appSessionKey);
        const oldUserSession = localStorage.getItem('dolineouser');
        localStorage.setItem('redirectbackurl', redirectUrl);
        this.cookie.set('olddolineosession', oldSession);
        localStorage.setItem('olddolineouser', oldUserSession);

        // #11979
        this.pushSessionHistory({
            session: oldSession,
            user: oldUserSession
        });
    }

    public revertOldSession(): void {
        const oldSession = this.cookie.get('olddolineosession');
        const oldUserSession = localStorage.getItem('olddolineouser');
        this.cookie.set(this.appSessionKey, oldSession);
        localStorage.setItem('dolineouser', oldUserSession);
        this.cookie.delete('olddolineosession');
        localStorage.removeItem('olddolineouser');

        // #11979
        const prevSession = this.popSessionHistory();
        if (prevSession != null) {
            this.cookie.set('olddolineosession', prevSession.session);
            localStorage.setItem('olddolineouser', prevSession.user);
        }
    }

    public oldSessionExists(): boolean {
        return this.cookie.get('olddolineosession') !== '';
    }

    public clearOldSession(): void {
        if (this.oldSessionExists()) {
            this.cookie.delete('olddolineosession');
            localStorage.removeItem('olddolineouser');

            // #11979
            this.clearSessionHistory();
        }
    }

    forgotPassword(forgotBody: IUserLogin): Observable<boolean> {
        return this.http.post<IOauthResponse>(this.baseUrl + this.forgotPasswordUrl, { ...forgotBody }).pipe(map(() => {
            return true;
        }), catchError(this.handleError));
    }

    resetPassword(changePassword: IChangePassword): Observable<any> {
        return this.http.post<any>(this.baseUrl + this.resetPasswordUrl, { ...changePassword });
    }

    checkToken(token): Observable<boolean> {
        const body = {
            'token': token
        };
        return this.http.post<boolean>(this.baseUrl + this.checkTokenUrl, body).pipe(map((resp) => {
            return resp;
        }, catchError(this.handleError)));
    }

    integrationLogin(code: string, subdomain: string): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/integration-login${code}`, { subdomain } );
    }

    auth2FAResendCode(email: string): Observable<boolean> {
        return this.http.post<any>(`${this.baseUrl}/auth2fa/resend`, { email } );
    }

    auth2FACheckCode(email: string, code: string): Observable<boolean> {
        return this.http.post<any>(`${this.baseUrl}/auth2fa/check`, { email, code } );
    }
}

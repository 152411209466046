import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { HelperService } from '../../services/helper.service';
import { ApproveRejectTaskDialogComponent } from '../approve-reject-task-dialog/approve-reject-task-dialog.component';
import {
    displayTrainingType as _displayTrainingType,
    downloadFile,
    downloadZip,
    getIcon,
    normalizedLetter
} from '../../helpers';
import { ToastrService } from 'ngx-toastr';
import { HttpResponse } from '@angular/common/http';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import {ApprovePointsTaskDialogComponent} from '../approve-points-task-dialog/approve-points-task-dialog.component';
import {RejectedTasksDialogComponent} from "../../../onboarding/dialogs/rejected-tasks-dialog/rejected-tasks-dialog.component";
import {RequestInterface} from '../../intefaces/pagination.interface';

@Component({
    selector: 'app-approval-tasks-dialog',
    templateUrl: './approval-tasks-dialog.component.html',
    styleUrls: ['./approval-tasks-dialog.component.scss']
})
export class ApprovalTasksDialogComponent implements OnInit {
    apiUrl = environment.filesPath;

    onboarding: { id: number, name: string }
    approvalOnboarding: any;
    previewOnboarding: any;
    previewGamification: any;

    gamification: { id: number, name: string }

    userName: string;
    showButtons: boolean;
    fromComponent: string;
    userId: number;
    params: string;
    tasks: any[];
    checkList: number[] = [];
    public task: any = null;
    expanded: boolean = true;
    public filenamePreview: string = '';
    preview: boolean;
    onlyForApprove: boolean

    public searchRequest: any = null;
    public request: RequestInterface = {
        sort: {
            field: 'name',
            direction: 'asc'
        },
        pagination: {
            pageNumber: 1,
            pageSize: 50,
            totalElementsCount: 0,
            totalPages: 0
        },
        waitingForData: false
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private _api: ApiService,
                private _toastrService: ToastrService,
                private _helper: HelperService,
                private _dialog: MatDialog,
                private toastrService: ToastrService,
                private _translate: TranslateService,
                public dialogRef: MatDialogRef<ApprovalTasksDialogComponent>) {
        if (this.data.onboarding) {
            this.onboarding = this.data.onboarding;
        }
        if (this.data.gamification) {
            this.gamification = this.data.gamification;
        }
        this.userName = this.data.userName;
        this.fromComponent = this.data.fromComponent;
        this.userId = this.data.userId;
        this.params = this.data.params;
        this.preview = this.data.preview;
    }

    ngOnInit(): void {
        this.getData();
    }

    getData(showTasks: boolean = false) {
        if (!this.preview || showTasks) {
            const req = this.request
            if (this.searchRequest) {
                this.searchRequest.unsubscribe()
            }

            this.request.waitingForData = true;
            if (this.onlyForApprove || this.data.onlyForApprove) {
                this.params = `&onlyForApprove=1` + (this.data.isPreboarding ? `&isPreboarding=1` : '');
            } else {
                this.params = `&onlyForApprove=0` + (this.data.isPreboarding ? `&isPreboarding=1` : '')
            }
            switch (this.fromComponent) {
                case 'org':
                    if (this.onboarding) {
                        this.searchRequest = this._api.Onboarding.getOnboardingApprovalElementsOrg(this._helper.getUser().companyId, this.onboarding.id, this.userId, this.params,
                            req.pagination.pageNumber,
                            req.pagination.pageSize,
                            req.sort.field,
                            req.sort.direction).subscribe(res => {

                                this.request.pagination.totalElementsCount = res.tasks.totalElementsCount
                                this.request.pagination.totalPages = res.tasks.totalPages
                                this.request.waitingForData = false;
                                this.setData(res, req.pagination.pageNumber !== 1)
                        })
                    } else if (this.gamification) {
                        this.searchRequest = this._api.Gamification.getGamificationApprovalElementsOrg(this._helper.getUser().companyId, this.gamification.id, this.userId, this.params,
                            req.pagination.pageNumber,
                            req.pagination.pageSize,
                            req.sort.field,
                            req.sort.direction,).subscribe(res => {

                                this.request.pagination.totalElementsCount = res.tasks.totalElementsCount
                                this.request.pagination.totalPages = res.tasks.totalPages
                                this.request.waitingForData = false;
                                this.setData(res, req.pagination.pageNumber !== 1)
                        })
                    }
                    break;
                case 'team':
                    if (this.onboarding) {
                        this.searchRequest = this._api.Onboarding.getOnboardingApprovalElementsTeam(this._helper.getUser().id, this.onboarding.id, this.userId, this.params,
                            req.pagination.pageNumber,
                            req.pagination.pageSize,
                            req.sort.field,
                            req.sort.direction,).subscribe(res => {
                                this.request.pagination.totalElementsCount = res.tasks.totalElementsCount
                                this.request.pagination.totalPages = res.tasks.totalPages
                                this.request.waitingForData = false;
                                this.setData(res, req.pagination.pageNumber !== 1)
                        })
                    } else if (this.gamification) {
                        this.searchRequest = this._api.Gamification.getGamificationApprovalElementsTeam(this._helper.getUser().id, this.gamification.id, this.userId, this.params,
                            req.pagination.pageNumber,
                            req.pagination.pageSize,
                            req.sort.field,
                            req.sort.direction,).subscribe(res => {
                                this.request.pagination.totalElementsCount = res.tasks.totalElementsCount
                                this.request.pagination.totalPages = res.tasks.totalPages
                                this.request.waitingForData = false;
                                this.setData(res, req.pagination.pageNumber !== 1)
                        })
                    }
                    break;
            }
        } else {
            switch (this.fromComponent) {
                case 'org':
                    if (this.onboarding) {
                        this._api.Onboarding.getOnboardingApprovalPreviewOrg(this._helper.getUser().companyId, this.onboarding.id, this.userId, this.params).subscribe(val => {
                            this.setDataPreview(val)
                        })
                    } else if (this.gamification) {
                        this._api.Gamification.getGamificationApprovalPreviewOrg(this._helper.getUser().companyId, this.gamification.id, this.userId, this.params).subscribe(val => {
                            this.setDataPreview(val)
                        })
                    }
                    break;
                case 'team':
                    if (this.onboarding) {
                        this._api.Onboarding.getOnboardingApprovalPreviewTeam(this._helper.getUser().id, this.onboarding.id, this.userId, this.params).subscribe(val => {
                            this.setDataPreview(val)
                        })
                    } else if (this.gamification) {
                        this._api.Gamification.getGamificationApprovalPreviewTeam(this._helper.getUser().id, this.gamification.id, this.userId, this.params).subscribe(val => {
                            this.setDataPreview(val)
                        })
                    }
                    break;
            }
        }
    }

    loadMore(): void {
        if (this.request.waitingForData === false && this.tasks.length < this.request.pagination.totalElementsCount) {
            this.request.pagination.pageNumber++;
            this.getData();
        }
    }

    changeToogle(event: { source: any, checked: boolean }) {
        this.onlyForApprove = event.checked

        if (!this.data.preview) {
            if (this.onlyForApprove) {
                // this.tasks = [...this.approvalOnboarding.tasks].filter(val => val.approvalStatus === 'pending')
                this.request.pagination.pageNumber = 1;
                this.getData();
            } else {
                // this.tasks = [...this.approvalOnboarding.tasks]
                this.request.pagination.pageNumber = 1;
                this.getData();
            }
        } else {
            this.preview = !this.onlyForApprove;
            this.request.pagination.pageNumber = 1;
            this.getData(this.onlyForApprove)
            this.checkList = [];
        }
    }

    changeCheckbox(event: { source: any, checked: boolean }, id: number) {
        if (event.checked) {
            this.checkList.push(id)
        } else {
            this.checkList = this.checkList.filter(val => val !== id)
        }
    }

    acceptTask(task: any) {
        if (!task.canUserApprove) {
            return;
        }
        if (this.onboarding) {
            this.acceptTaskConfirmed(task);
        } else if (this.gamification) {
            this.acceptTaskPointsDialog(task);
        }
    }

    acceptTaskPointsDialog(task: any) {
        this._dialog.open(ApprovePointsTaskDialogComponent, {
            // width: '559px',
            disableClose: true,
            data: {
                points: task.points
            }
        }).afterClosed().subscribe(value => {
            if (value) {
                const isUpdate = task.approvalStatus !== 'pending';
                task.approvedPoints = value;
                task.pointsRange = true;
                task.pointsRangeFrom = value.valueFrom;
                task.pointsRangeTo = value.valueTo;
                task.userPoints = value.points;
                this.acceptTaskDo(task, isUpdate);
            }
        });
    }

    acceptTaskConfirmed(task: any) {
        this._dialog.open(ApproveRejectTaskDialogComponent, {
            width: '559px',
            disableClose: true,
            data: {
                title: 'Onboarding.AcceptTheTask',
                taskTitle: task.name,
                content: 'Onboarding.AreYouSureYouWantToAcceptTheTaskTheActionIsPermanentAndIrreversible',
                showTextArea: false
            }
        }).afterClosed().subscribe(value => {
            if (value) {
                this.acceptTaskDo(task);
            }
        });
    }

    acceptTaskDo (task: any, isUpdate:boolean = false) {
        const api = this.onboarding ? this._api.Onboarding : this._api.Gamification;
        api.approvalTask(this._helper.getUser().id, {
            ids: task.approvalPendingId ? [task.approvalPendingId] : [task.approvalId],
            comment: '',
            points: this.gamification ? task.approvedPoints : null
        }).subscribe(() => {
            if (isUpdate) {
                this._toastrService.success(this._translate.instant('Gamification.AcceptedTaskToastr'));
            } else {
                this._toastrService.success(this._translate.instant('Onboarding.AcceptedTaskToastr'));
            }
            this.request.pagination.pageNumber = 1;
            this.getData();
            task.approvalStatus = 'approved'

            task.approvalStatusLog.push({
                date: new Date(),
                approver: this._helper.getUser().name + ' ' + this._helper.getUser().surname,
                status: 'approved',
                comment: null
            })

            /*
            if (this.preview) {
                task.approvalStatus = 'approved'

                task.approvalStatusLog.push({
                    date: new Date(),
                    approver: this._helper.getUser().name + ' ' + this._helper.getUser().surname,
                    status: 'approved',
                    comment: null
                })
            } else {
                this.checkList = this.checkList.filter(val => val !== task.approvalPendingId)
                this.approvalOnboarding.tasks.forEach(val => {
                    if (val.approvalPendingId === task.approvalPendingId) {
                        task.approvalStatus = 'approved'

                        task.approvalStatusLog.push({
                            date: new Date(),
                            approver: this._helper.getUser().name + ' ' + this._helper.getUser().surname,
                            status: 'approved',
                            comment: null
                        })
                    }
                })
                task.approvalStatus = 'approved'

                task.approvalStatusLog.push({
                    date: new Date(),
                    approver: this._helper.getUser().name + ' ' + this._helper.getUser().surname,
                    status: 'approved',
                    comment: null
                })
            }
            */
        });
    }

    openRejectedTasks(task: any) {
        this._dialog.open(RejectedTasksDialogComponent, {
            disableClose: true,
            autoFocus: false,
            data: {
                approvalStatusLog: [...task.approvalStatusLog]
            },
            width: '560px'
        })
    }

    rejectTask(task: any) {
        let addBefore;
        this._dialog.open(ApproveRejectTaskDialogComponent, {
            width: '559px',
            disableClose: true,
            data: {
                title: 'Onboarding.RejectTheTask',
                taskTitle: task.name,
                content: 'Onboarding.AreYouSureYouWantToRejectTheTaskThisWillCauseTheUserToPerformTheTaskAgain',
                showTextArea: true
            }
        }).afterClosed().subscribe(value => {
            if (value) {
                const api = this.onboarding ? this._api.Onboarding : this._api.Gamification;
                api.rejectTask(this._helper.getUser().id, {
                    ids: task.approvalPendingId ? [task.approvalPendingId] : [task.approvalId],
                    comment: value.comment
                }).subscribe(() => {
                    this._toastrService.success(this._translate.instant('Onboarding.RejectedTaskToastr'))
                    this.request.pagination.pageNumber = 1;
                    this.getData();
                    task.approvalStatus = 'rejected'
                    task.approvalStatusLog.push({
                        date: new Date(),
                        approver: this._helper.getUser().name + ' ' + this._helper.getUser().surname,
                        status: 'rejected',
                        comment: value.comment
                    })
                    /*
                    if (this.preview) {
                        task.approvalStatus = 'rejected'
                        addBefore = 1;
                        task.approvalStatusLog.push({
                            date: new Date(),
                            approver: this._helper.getUser().name + ' ' + this._helper.getUser().surname,
                            status: 'rejected',
                            comment: value.comment
                        })
                    } else {
                        this.checkList = this.checkList.filter(val => val !== task.approvalPendingId)
                        this.approvalOnboarding.tasks.forEach(val => {
                            if (val.approvalPendingId === task.approvalPendingId) {
                                val.approvalStatus = 'rejected'
                                addBefore = 1;
                                val.approvalStatusLog.push({
                                    date: new Date(),
                                    approver: this._helper.getUser().name + ' ' + this._helper.getUser().surname,
                                    status: 'rejected',
                                    comment: value.comment
                                })
                            }
                        })
                        task.approvalStatus = 'rejected'
                        if(!addBefore) {
                            task.approvalStatusLog.push({
                                date: new Date(),
                                approver: this._helper.getUser().name + ' ' + this._helper.getUser().surname,
                                status: 'rejected',
                                comment: value.comment
                            })
                        }
                    }
                    */
                })

            }
        });
    }

    acceptTaskList() {
        this._dialog.open(ApproveRejectTaskDialogComponent, {
            width: '559px',
            disableClose: true,
            data: {
                title: 'Onboarding.AcceptTheTasks',
                taskTitle: '',
                content: 'Onboarding.AreYouSureYouWantToAcceptChosenTasksTheActionIsPermanentAndIrreversible',
                extraContent: 'Onboarding.TasksThatWillBeAccepted',
                length: this.checkList.length
            }
        }).afterClosed().subscribe(value => {
            if (value) {
                const api = this.onboarding ? this._api.Onboarding : this._api.Gamification;
                api.approvalTask(this._helper.getUser().id, {
                    ids: [...this.checkList],
                    comment: ''
                }).subscribe(() => {
                    this._toastrService.success(this._translate.instant('Onboarding.AcceptedTasksToastr'))

                    /*
                    this.approvalOnboarding.tasks.forEach(val => {
                        if (this.checkList.includes(val.approvalPendingId)) {
                            val.approvalStatus = 'approved'

                            val.approvalStatusLog.push({
                                date: new Date(),
                                approver: this._helper.getUser().name + ' ' + this._helper.getUser().surname,
                                status: 'approved',
                                comment: null
                            })
                        }
                    })
                    this.tasks.forEach(val => {
                        if (this.checkList.includes(val.approvalPendingId)) {
                            val.approvalStatus = 'approved'

                            val.approvalStatusLog.push({
                                date: new Date(),
                                approver: this._helper.getUser().name + ' ' + this._helper.getUser().surname,
                                status: 'approved',
                                comment: null
                            })
                        }
                    })
                    */
                    this.checkList = [];
                    this.request.pagination.pageNumber = 1;
                    this.getData();
                })
            }
        });
    }

    rejectTaskList() {
        this._dialog.open(ApproveRejectTaskDialogComponent, {
            width: '559px',
            disableClose: true,
            data: {
                title: 'Onboarding.RejectTheTasks',
                taskTitle: '',
                content: 'Onboarding.AreYouSureYouWantToRejectChosenTasksThisWillCauseTheUserToPerformTheTasksAgain',
                extraContent: 'Onboarding.TasksThatWillBeRejected',
                length: this.checkList.length
            }
        }).afterClosed().subscribe(value => {
            if (value) {
                const api = this.onboarding ? this._api.Onboarding : this._api.Gamification;
                api.rejectTask(this._helper.getUser().id, {
                    ids: [...this.checkList],
                    comment: ''
                }).subscribe(() => {
                    this._toastrService.success(this._translate.instant('Onboarding.RejectedTasksToastr'))
                    /*
                    this.approvalOnboarding.tasks.forEach(val => {
                        if (this.checkList.includes(val.approvalPendingId)) {
                            val.approvalStatus = 'rejected'

                            val.approvalStatusLog.push({
                                date: new Date(),
                                approver: this._helper.getUser().name + ' ' + this._helper.getUser().surname,
                                status: 'rejected',
                                comment: null
                            })
                        }
                    })
                    this.tasks.forEach(val => {
                        if (this.checkList.includes(val.approvalPendingId)) {
                            val.approvalStatus = 'rejected'

                            val.approvalStatusLog.push({
                                date: new Date(),
                                approver: this._helper.getUser().name + ' ' + this._helper.getUser().surname,
                                status: 'rejected',
                                comment: null
                            })
                        }
                    })
                    */
                    this.checkList = [];
                    this.request.pagination.pageNumber = 1;
                    this.getData();
                })
            }
        });
    }

    nextUser() {
        this.userId = this.approvalOnboarding.nextUserId;
        this.getData();
    }

    previousUser() {
        this.userId = this.approvalOnboarding.prevUserId;
        this.getData();
    }

    setData(val: any, append = false) {
        this.userName = val.username;
        this.approvalOnboarding = {...val}

        // if (!this.data.preview) {
        //     if (this.onlyForApprove) {
        //         this.tasks = [...this.approvalOnboarding.tasks].filter(val2 => val2.approvalStatus === 'pending')
        //     } else {
        //         this.tasks = !this.data.preview ? [...val.tasks] : [...this.approvalOnboarding.tasks].filter(task => task.approvalStatus === 'pending')
        //     }
        // } else {
        //     this.tasks = !this.data.preview ? [...val.tasks] : [...this.approvalOnboarding.tasks].filter(task => task.approvalStatus === 'pending')
        // }
        if (append) {
            this.tasks = this.tasks.concat(!this.data.preview ? [...val.tasks.tasks] : [...this.approvalOnboarding.tasks.tasks]);
        } else {
            this.tasks = !this.data.preview ? [...val.tasks.tasks] : [...this.approvalOnboarding.tasks.tasks];
        }



        this.showButtons = val.total > 1 && this.data.showButtons;
        this.checkList = [];
    }

    setDataPreview(val: any) {
        this.userName = val.username;
        this.approvalOnboarding = { ...val };
        if (this.onboarding) {
            this.previewOnboarding = { ...val.onboarding }
        } else if (this.gamification) {
            this.previewGamification = { ...val.gamification }
        }

        this.showButtons = val.total > 1 && this.data.showButtons;
        this.checkList = [];
    }

    downloadAttachment(userAttachment: { fileName: string, origFileName: string }) {
        this._api.download(userAttachment.fileName).subscribe(blob => {
            downloadFile(blob, userAttachment.origFileName)
        });
    }

    previewTask(id: number) {
        const api = this.onboarding ? this._api.Onboarding : this._api.Gamification;
        api.getTaskPlayer(this.userId, id).subscribe(task => {
            this.task = { ...task }
        })
    }

    setExpanded() {
        this.expanded = !this.expanded;
    }

    downloadFile(attachement: any) {
        this._api.download(attachement.fileName).subscribe(data => {
            downloadFile(data, attachement.origFileName)
            this.toastrService.success(this._translate.instant('KnowledgeBase.DownloadedAttachment'));
        })
    }

    downloadAll() {
        const api = this.onboarding ? this._api.Onboarding : this._api.Gamification;
        api.downloadAllTaskFile(this._helper.getUser().id, this.task.id).subscribe((res: HttpResponse<Blob>) => {
            const dateFile = moment().format('YYYYMMDD_HHmm')
            const taskName = this.task.name.replace(/\s+/g, '_')
            const fileName = normalizedLetter(`${taskName}_${dateFile}`);
            downloadZip(fileName, res);
            this.toastrService.success(this._translate.instant('KnowledgeBase.DownloadedAttachments'));
        })
    }

    downloadInputFile() {
        //
    }

    backToTasks() {
        this.task = null;
    }

    getIconName(mimeType: string): string {
        return getIcon(mimeType);
    }

    isPast(date: string): boolean {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        const d2 = new Date(date);
        d2.setHours(0, 0, 0, 0);
        return !(new Date(date).getTime() >= d.getTime())
    }

    displayTrainingType(objectType: string, trainingType: string, trainingAction: string, isSoundMaterial: any) {
        return _displayTrainingType(objectType, trainingType, trainingAction, isSoundMaterial);
    }
}

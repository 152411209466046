<div class="widget-container"
     *ngIf="items && defaultItems"
     [ngClass]="('widget-container-employee-waiting-for-realization' + ' widget-size-' + widget.sizeClass + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.background]="('linear-gradient(135deg, '+ hexToRGB('#' + widget.backgroundColor,1) +' 0%, '+ hexToRGB('#' + widget.backgroundColor,0.7) +' 100%)')"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'DashboardCreator.WaitingForRealization' | translate}}</div>

    <svg class="background-icon"  width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle [style.fill]="'#' + widget.elementsColor" cx="30" cy="30" r="30" fill="#F37885" fill-opacity="0.2"/>
        <path [style.fill]="'#' + widget.elementsColor" d="M23.4166 43.125H36.5833V38.2083C36.5833 36.3889 35.9409 34.8368 34.6562 33.5521C33.3714 32.2674 31.8194 31.625 29.9999 31.625C28.1805 31.625 26.6284 32.2674 25.3437 33.5521C24.0589 34.8368 23.4166 36.3889 23.4166 38.2083V43.125ZM18.6458 46.25C18.2152 46.25 17.8471 46.0972 17.5416 45.7917C17.236 45.4861 17.0833 45.1181 17.0833 44.6875C17.0833 44.2569 17.236 43.8889 17.5416 43.5833C17.8471 43.2778 18.2152 43.125 18.6458 43.125H20.2916V38.2083C20.2916 36.5278 20.7013 34.9549 21.5208 33.4896C22.3402 32.0243 23.4721 30.8611 24.9166 30C23.4583 29.1528 22.3228 27.9965 21.5103 26.5312C20.6978 25.066 20.2916 23.4861 20.2916 21.7917V16.875H18.6458C18.2152 16.875 17.8471 16.7222 17.5416 16.4167C17.236 16.1111 17.0833 15.7431 17.0833 15.3125C17.0833 14.8819 17.236 14.5139 17.5416 14.2083C17.8471 13.9028 18.2152 13.75 18.6458 13.75H41.3541C41.7846 13.75 42.1527 13.9028 42.4583 14.2083C42.7638 14.5139 42.9166 14.8819 42.9166 15.3125C42.9166 15.7431 42.7638 16.1111 42.4583 16.4167C42.1527 16.7222 41.7846 16.875 41.3541 16.875H39.7083V21.7917C39.7083 23.4861 39.2985 25.066 38.4791 26.5312C37.6596 27.9965 36.5277 29.1528 35.0833 30C36.5277 30.8611 37.6596 32.0243 38.4791 33.4896C39.2985 34.9549 39.7083 36.5278 39.7083 38.2083V43.125H41.3541C41.7846 43.125 42.1527 43.2778 42.4583 43.5833C42.7638 43.8889 42.9166 44.2569 42.9166 44.6875C42.9166 45.1181 42.7638 45.4861 42.4583 45.7917C42.1527 46.0972 41.7846 46.25 41.3541 46.25H18.6458Z" fill="#F37885"/>
    </svg>

    <div class="widget-container__content">
        <div class="widget-container__content--waiting-for-realization--categories"
             *ngIf="waitingForRealization && waitingForRealization.all && waitingForRealization.all > 0"
        >

            <div class="widget-container__content--waiting-for-realization--categories--category" *ngIf="waitingForRealization.preboarding > 0 && (waitingForRealization.preboardingVisible || widget.sizeClass === 'b')">
                <div class="widget-container__content--waiting-for-realization--categories--category--label"
                     (click)="goTo('preboarding')"
                     [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'Common.Preboarding' | translate}}</div>
                <div class="widget-container__content--waiting-for-realization--categories--category--count"
                     [style.color]="!whiteBackground ? '#' + widget.backgroundColor : ''"
                     [style.background-color]="!whiteBackground ? hexToRGB('#' + widget.elementsColor,0.8) : ''">{{ waitingForRealization.preboarding }}
                </div>
                <svg class="widget-container__content--waiting-for-realization--categories--category--svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path [style.fill]="'#' + widget.elementsColor" d="M16.925 15.6641L16.475 15.2016L19.35 12.3266H4.42505V11.6891H19.35L16.4625 8.81406L16.9125 8.35156L20.575 12.0016L16.925 15.6641Z" fill="white"/>
                </svg>
            </div>

            <div class="widget-container__content--waiting-for-realization--categories--category" *ngIf="waitingForRealization.onboarding > 0 && (waitingForRealization.onboardingVisible || widget.sizeClass === 'b')">
                <div class="widget-container__content--waiting-for-realization--categories--category--label"
                     (click)="goTo('onboarding')"
                     [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'Common.Onboarding' | translate}}</div>
                <div class="widget-container__content--waiting-for-realization--categories--category--count"
                     [style.color]="!whiteBackground ? '#' + widget.backgroundColor : ''"
                     [style.background-color]="!whiteBackground ? hexToRGB('#' + widget.elementsColor,0.8) : ''">{{ waitingForRealization.onboarding }}</div>
                <svg class="widget-container__content--waiting-for-realization--categories--category--svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path [style.fill]="'#' + widget.elementsColor" d="M16.925 15.6641L16.475 15.2016L19.35 12.3266H4.42505V11.6891H19.35L16.4625 8.81406L16.9125 8.35156L20.575 12.0016L16.925 15.6641Z" fill="white"/>
                </svg>
            </div>
            
            <div class="widget-container__content--waiting-for-realization--categories--category" *ngIf="waitingForRealization.training > 0 && (waitingForRealization.trainingVisible || widget.sizeClass === 'b')">
                <div class="widget-container__content--waiting-for-realization--categories--category--label"
                     (click)="goTo('training')"
                     [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'Common.Trainings' | translate}}</div>
                <div class="widget-container__content--waiting-for-realization--categories--category--count"
                     [style.color]="!whiteBackground ? '#' + widget.backgroundColor : ''"
                     [style.background-color]="!whiteBackground ? hexToRGB('#' + widget.elementsColor,0.8) : ''">{{ waitingForRealization.training }}</div>
                <svg class="widget-container__content--waiting-for-realization--categories--category--svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path [style.fill]="'#' + widget.elementsColor" d="M16.925 15.6641L16.475 15.2016L19.35 12.3266H4.42505V11.6891H19.35L16.4625 8.81406L16.9125 8.35156L20.575 12.0016L16.925 15.6641Z" fill="white"/>
                </svg>
            </div>

            <div class="widget-container__content--waiting-for-realization--categories--category" *ngIf="waitingForRealization.gamification > 0 && (waitingForRealization.gamificationVisible || widget.sizeClass === 'b')">
                <div class="widget-container__content--waiting-for-realization--categories--category--label"
                     (click)="goTo('gamification')"
                     [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'Common.Gamifications' | translate}}</div>
                <div class="widget-container__content--waiting-for-realization--categories--category--count"
                     [style.color]="!whiteBackground ? '#' + widget.backgroundColor : ''"
                     [style.background-color]="!whiteBackground ? hexToRGB('#' + widget.elementsColor,0.8) : ''">{{ waitingForRealization.gamification }}</div>
                <svg class="widget-container__content--waiting-for-realization--categories--category--svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path [style.fill]="'#' + widget.elementsColor" d="M16.925 15.6641L16.475 15.2016L19.35 12.3266H4.42505V11.6891H19.35L16.4625 8.81406L16.9125 8.35156L20.575 12.0016L16.925 15.6641Z" fill="white"/>
                </svg>
            </div>

            <div class="widget-container__content--waiting-for-realization--categories--category" *ngIf="waitingForRealization.path > 0 && (waitingForRealization.pathVisible || widget.sizeClass === 'b')">
                <div class="widget-container__content--waiting-for-realization--categories--category--label"
                     (click)="goTo('path')"
                     [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'Common.Paths' | translate}}</div>
                <div class="widget-container__content--waiting-for-realization--categories--category--count"
                     [style.color]="!whiteBackground ? '#' + widget.backgroundColor : ''"
                     [style.background-color]="!whiteBackground ? hexToRGB('#' + widget.elementsColor,0.8) : ''">{{ waitingForRealization.path }}</div>
                <svg class="widget-container__content--waiting-for-realization--categories--category--svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path [style.fill]="'#' + widget.elementsColor" d="M16.925 15.6641L16.475 15.2016L19.35 12.3266H4.42505V11.6891H19.35L16.4625 8.81406L16.9125 8.35156L20.575 12.0016L16.925 15.6641Z" fill="white"/>
                </svg>
            </div>

            <div class="widget-container__content--waiting-for-realization--categories--category" *ngIf="waitingForRealization.pool > 0 && (waitingForRealization.poolVisible || widget.sizeClass === 'b')">
                <div class="widget-container__content--waiting-for-realization--categories--category--label"
                     (click)="goTo('pool')"
                     [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'DashboardWidgetRecentlyCreated.PoolsAndSurveys' | translate}}</div>
                <div class="widget-container__content--waiting-for-realization--categories--category--count"
                     [style.color]="!whiteBackground ? '#' + widget.backgroundColor : ''"
                     [style.background-color]="!whiteBackground ? hexToRGB('#' + widget.elementsColor,0.8) : ''">{{ waitingForRealization.pool }}</div>
                <svg class="widget-container__content--waiting-for-realization--categories--category--svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path [style.fill]="'#' + widget.elementsColor" d="M16.925 15.6641L16.475 15.2016L19.35 12.3266H4.42505V11.6891H19.35L16.4625 8.81406L16.9125 8.35156L20.575 12.0016L16.925 15.6641Z" fill="white"/>
                </svg>
            </div>

        </div>
        <div class="widget-container__content--empty-status" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''"
             style="text-align: left;"
             *ngIf="waitingForRealization && waitingForRealization.all === 0">
            <div class="widget-container__content--empty-status--text">{{ 'DashboardWidgetWaitingForRealization.IsEmptyText1' | translate}}</div>
            <div class="widget-container__content--empty-status--text" style="margin-bottom: 10px;">{{ 'DashboardWidgetWaitingForRealization.IsEmptyText2' | translate}}</div>
        </div>
    </div>
</div>


import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';
import { take, filter, catchError, switchMap, finalize } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ChangePasswordComponent } from '../../profile/dialogs/change-password/change-password.component';
import { HelperService } from '../../shared/services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {CookieService} from '../../shared/services/cookie.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private injector: Injector,
                private router: Router,
                private authService: AuthService,
                private helper: HelperService,
                private dialog: MatDialog,
                private _translate: TranslateService,
                private cookie: CookieService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        if (this.authService.authToken) {
            window.setTimeout(() => {

                if (this.helper.getUser() && this.helper.getUser().changePasswordRequired === 1 && !this.helper.changePasswordOpened && !this.authService.oldSessionExists()) {

                    this.helper.changePasswordOpened = true;
                    this.dialog.open(ChangePasswordComponent, {
                        width: '370px',
                        data: {
                            id: this.helper.getUser().id,
                            disableClose: true,
                            currentUser: true
                        },
                        hasBackdrop: true,
                        disableClose: true,
                    }).afterClosed().subscribe(() => {
                        this.helper.changePasswordOpened = false;
                        this.helper.getUser().changePasswordRequired = 0;
                        this.helper.setUser(this.helper.getUser());
                        this.helper.forcedPasswordChanged.next(true);
                    });
                }
            }, 1000);

            return next.handle(this.addToken(req)).pipe(catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    switch ((error as HttpErrorResponse).status) {
                        case 400:
                            return this.handle400Error(error);
                        case 401:
                            /*                            if (error && error.error && error.error.error && error.error.error === 'access_denied' && error.error.error_description) {
                                                            return this.handleAccountDisabledError(error);
                                                        }*/
                            if (req.url.includes(this.authService.tokenUrl)) {
                                this.logoutUser();
                            }
                            return this.tryRefreshToken(req, next);
                        case 441:
                            return this.handle401Error(error);
                    }
                    return observableThrowError(error);
                } else {
                    return observableThrowError(error);
                }
            }));
        } else {
            return next.handle(req).pipe(catchError((error) => {
                    if (error instanceof HttpErrorResponse &&
                        (error as HttpErrorResponse).status === 400) {
                        this.forceLogin();
                    }
                    return observableThrowError(error);
                }
            ));
        }
    }

    addToken(req: HttpRequest<any>): HttpRequest<any> {
        return req.clone({
            setHeaders: {
                Authorization: this.authService.authToken,
                'ngsw-bypass': 'true'
            }
        });
    }

    handle400Error(error) {
        if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
            return this.logoutUser();
        }
        return observableThrowError(error);
    }

    handle401Error(error) {
        if (error && error.status === 401 && error.error && error.error.error === 'Access denied') {
            return this.logoutUser();
        }
        return observableThrowError(error);
    }

    tryRefreshToken(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.authService.refreshToken().pipe(
                switchMap((newToken: boolean) => {
                    if (newToken) {
                        this.tokenSubject.next(newToken);
                        return next.handle(this.addToken(req));
                    }

                    // If we don't get a new token, we are in trouble so logout.
                    return this.logoutUser();
                }),
                catchError(() => {
                    // If there is an exception calling 'refreshToken', bad news so logout.
                    return this.logoutUser();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }),);
        } else {
            return this.tokenSubject.pipe(filter((token) => !!token), take(1), switchMap(() => {
                return next.handle(this.addToken(req));
            }));
        }
    }

    logoutUser() {
        this.forceLogin();
        return observableThrowError('');
    }

    private forceLogin() {
        this.helper.setUser(null);
        localStorage.clear();
        this.cookie.deleteAll();
        this.router.navigate(['login']);
    }
}

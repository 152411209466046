import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CertificatesResponseInterface } from '../../shared/intefaces/certificates.response.interface';
import { TeamFeedbacksList } from '../../team/interfaces/feedback.interface';

@Injectable()
export class TeamService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public users(id: number, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/team/user?sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public usersXLS(id: number, sortBy: string, order: string, params: string, columns: string): string {
        return `/user/${id}/team/user/xls?sortBy=${sortBy}&order=${order}&columns=${columns}&${params}`;
    }

    public paths(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/team/path?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public pathsUsers(id: number, pathId: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string, userId?: number): Observable<any> {
        const headers = this.defaultHeaders;
        params = userId? `params&&userId=${userId}` : params;
        return this.http.get<any>(`${this.basePath}/user/${id}/team/path/${pathId}/users?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public pathsUsersXls(id: number, pathId: number, params: string): string {
        return `/user/${id}/team/path/${pathId}/users/xls?${params}`;
    }

    public getPathName(id: number, pathId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/team/path/${pathId}`, { headers });
    }

    public getTrainingName(id: number, trainingId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/team/training/${trainingId}`, { headers });
    }

    public pools(type: any, id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/team/pools?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public poolsGeneralReport(type: any, id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/team/general?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public downloadPoolsAnswers(type: any, userId: number, id: number): string {
        return `/user/${id}/team/pools-answers/${id}`;
    }

    public poolsXls(type: any, id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): string {
        let url = `/user/${id}/team/pools?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`;
        if (params !== '') {
            url += '&';
        }
        url += 'xls=1';
        // @ts-ignore
        // @ts-disable
        return url;
    }

    public poolsGeneralXls(type: any, id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): string {
        let url = `/user/${id}/team/general?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`;
        if (params !== '') {
            url += '&';
        }
        url += 'xls=1';
        return url;
        // // @ts-ignore
        // // @ts-disable
        // return this.http.post<HttpResponse<Blob>>(url, body,{observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true'});
    }

    public trainings(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${id}/team/training?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public trainingsUsers(id: number, trainingId: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string, userId?: number): Observable<any> {
        const headers = this.defaultHeaders;
        params = userId? `params&&userId=${userId}` : params;
        return this.http.get<any>(`${this.basePath}/user/${id}/team/training/${trainingId}/users?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public trainingsUsersXls(id: number, trainingId: number, params: string): string {
        return `/user/${id}/team/training/${trainingId}/users/xls?${params}`;
    }

    public trainingsInPath(idLoginUser: number, idPath: number, userId: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${idLoginUser}/team/path/${idPath}/elements?pageNumber=${pageNumber}&userId=${userId}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public trainingsInPathXLS(idLoginUser: number, idPath: number, userId: number, params: string): string {
        return `/user/${idLoginUser}/team/path/${idPath}/elements/xls?userId=${userId}&${params}`;
    }

    public certificates(sortBy: string, order: string, pageNumber: any, pageSize: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/team/certificate-list?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public certificatesXLS(sortBy: string, order: string, params: string, columns: string): string {
        return `/team/certificate/xls?sortBy=${sortBy}&order=${order}&columns=${columns}&${params}`;
    }

    public certificateZIP(): string {
        return `/team/certificates/zip`;
        // const headers = this.defaultHeaders;
        // // @ts-ignore
        // // @ts-disable
        // return this.http.post<HttpResponse<Blob>>(`${this.basePath}/team/certificates/zip`, {ids, headers}, {observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true'});
    }

    public certificate(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<CertificatesResponseInterface> {
        const headers = this.defaultHeaders;
        return this.http.get<CertificatesResponseInterface>(`${this.basePath}/certificate?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public getCertificateTemplate(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/certificate/${id}`, {headers});
    }

    public downloadXls(idUser: number, type: 'path' | 'training' | 'certificate' | 'user' | 'feedbacks', params: string): string {
        return `/user/${idUser}/team/${type}/xls?${params}`;
    }

    public downloadFeedbackQuestionnaireAnswersXls(idUser: number, questionnaireId: number): string {
        return `/user/${idUser}/team/feedbacks-answers/${questionnaireId}`;
    }

    public feedbacks(id: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<TeamFeedbacksList> {
        const headers = this.defaultHeaders;
        return this.http.get<TeamFeedbacksList>(`${this.basePath}/user/${id}/team/feedbacks?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public getCompanyTrainingStats(userId: number, groupBy = 'all', positions = null, supervisors = null, groups = null, range = null, rangeFrom = null, rangeTo = null, owner = null, onlyAssigned): Observable<any> {
        const headers = this.defaultHeaders;
        const body = {
            type: groupBy,
            positions,
            supervisors,
            groups,
            range,
            rangeFrom,
            rangeTo,
            owner,
            onlyAssigned
        };
        return this.http.post<any>(`${this.basePath}/user/${userId}/team/training-stats`, body, { headers });
    }

    public getTrainingsStatsPDF(userId: number): string {
        return `/user/${userId}/team/trainings-stats-report`;
    }
}

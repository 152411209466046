import {Component, EventEmitter, Input, Output} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-html-tooltip',
    templateUrl: './html-tooltip.component.html',
    styleUrls: ['./html-tooltip.component.scss']
})
export class HtmlTooltipComponent {
    @Input() content!: SafeHtml;
    @Input() position!: { top: string;};

    @Output() mouseEnter = new EventEmitter<void>();
    @Output() mouseLeave = new EventEmitter<void>();
}

export const environment = {
    production: true,
    hmr: false,
    filesPath: 'http://dolineo5.localhost',
    apiPath: 'http://dolineo5.localhost',
    clientId: 'bb6e6fca44631fad08bf5159e11a7fb2',
    clientSecret: '8f1374402b6a2f8d420dccdb68300a58dc9080c3c842ad3de27711ed552e4e76058ee361b98bb40907f03dba9c5a37ed6bcc880c5fbc779cbbb08112e3ab76fd',
    domain: 'dolineo5-ui.localhost',
    tokenSalt: 'DPUHMiMFgKNv6',
    reCaptchaSiteKey: '6LekD2EnAAAAAJSqDEL_MrnGCBiFwiPHBdTQUC4T',
    useRecaptcha: true,
    appName: 'dolineo',
    storageRegion: 'eu-west-1',
    bucketName: 'smart-dolineo-prod',
    stripePricingTableId: '',
    stripPublishableKey: ''
};

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {HelperService} from "../../services/helper.service";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {LoaderService} from "../../components/loader/loader.service";

@Component({
  selector: 'app-update-task-status-dialog',
  templateUrl: './update-task-status-dialog.component.html',
  styleUrls: ['./update-task-status-dialog.component.scss']
})
export class UpdateTaskStatusDialogComponent implements OnInit {

  public task = null;

  public title: string = '';
  public buttonText: string = 'Tasks.IConfirm';

  public showCompletionRadios: boolean = false;
  public showComment: boolean = true;

  public isCompleted: boolean = false;
  public comment: string = '';
  public value: number = null;

  public loadedFiles: any = [];
  public file: any = null;
  public url: string = '';
  public attachmentError: boolean = false;
  public valueError: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<UpdateTaskStatusDialogComponent>,
              private _helper: HelperService,
              private _toastr: ToastrService,
              private _translate: TranslateService,
              private loader: LoaderService) {
    this.task = data.task;
  }

  ngOnInit(): void {
    if(this.task.status == 'pending'){
      this.title = 'Tasks.BeginRealization';
    }else{
      this.title = 'Tasks.UpdateRealization';

      if(this.task.completionType === 'normal'){
        this.isCompleted = true;
        this.completionChange();
      }
    }

    this.value = this.task.progressValue;

    this.showCompletionRadios = true;

    if(this.task.confirmingFile){
      this.loadedFiles = [this.task.confirmingFile];
    }
  }

  setFiles(files) {
    this.file = files[0];
  }

  setUrl(url){
    this.url = url;
  }

  completionChange(){
    if(this.isCompleted && this.task.acceptanceRequired){
      this.buttonText = 'Tasks.IConfirmSendForAcceptance';
    }else{
      this.buttonText = 'Tasks.IConfirm';
    }
  }

  changeStatus(){
    this.attachmentError = false;
    if(this.isCompleted
        && this.task.attachmentRequired
        && (this.file == null && this.url === '')
    ){
      this.attachmentError = true;
    }

    this.valueError = false;

    if((this.task.completionType === 'percent' || this.task.completionType === 'number') && this.value == null && this.isCompleted){
      this.valueError = true;
    }

    if(this.attachmentError || this.valueError){
      return;
    }

    this.loader.show();

    const payload = {
      isCompleted: this.isCompleted,
      value: this.value,
      comment: this.comment,
      url: this.url,
      file: null
    };

    if(this.file){
      const temporaryStoragePath = this._helper.createUniqueFilename(this.file.name);
      this._helper.uploadToS3(this.file.rawFile, temporaryStoragePath).then(
          (data) => {
            this.file.temporaryStoragePath = temporaryStoragePath;
            this.file.rawFile = null;

            payload.file = this.file

            this.dialogRef.close(payload);
            this.showToast();
          },
          (error) => {
            this.dialogRef.close(false)
          }
      );
    }else{
      this.dialogRef.close(payload);
      this.showToast();
    }
  }

  showToast(){
    if(!this.isCompleted){
      this._toastr.success(this._translate.instant('Tasks.TaskStarted'))
    }else if(this.isCompleted && this.task.acceptanceRequired){
      this._toastr.success(this._translate.instant('Tasks.TaskSendForAcceptance'))
    }else{
      this._toastr.success(this._translate.instant('Tasks.TaskCompleted'))
    }
  }

}

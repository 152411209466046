import {Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {CreatorInfoDialogComponent} from '../../dialogs/creator-info-dialog/creator-info-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Color, Label, MultiDataSet} from 'ng2-charts';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'paths-stats',
    templateUrl: './paths-stats.component.html',
    styleUrls: ['./paths-stats.component.scss']
})

export class PathsStatsComponent implements OnInit, AfterViewInit {
    @ViewChild('chartCanvas', {static: false}) chartCanvas!: ElementRef<HTMLCanvasElement>;

    @Input() data: any;
    @Input() size: any;
    @Input() name: string;
    @Input() label: string;
    @Input() subLabel: string;
    @Input() onlyLabel: string;
    @Input() background: string;
    @Input() class: string;

    isMobile: boolean;
    gradient: any;
    barChartLabels: Label[] = [];
    barChartData: ChartDataSets[] = [];
    viewPie: any[] = [100, 100];
    pieChartData = [];
    colorScheme = {
        domain: [
            '#ffffff',
            '#87b3e4'
        ]
    };

    barChartOptions: ChartOptions = {};

    barChartPlugins = [];
    barChartType: ChartType = 'line';
    barChartLegend = true;
    barChartColors: Color[] = [];
    pathsRealizationDisplay: string = 'chart';
    theMostActiveColumns: any[] = [];
    theMostRealizedColumns: any[] = [];
    theMostRealizedSort = 'completed';

    doughnutChartLabels: Label[] = [];
    doughnutChartData: MultiDataSet = [];
    doughnutChartType: ChartType = 'doughnut';
    doughnutChartColors: Color[] = [
        {
            backgroundColor: [],
            borderWidth: 0
        }
    ];

    colors = ['#8066CC', '#00BECC', '#4A83FC', '#FF8233', '#F6CE6B', '#EE76E2', '#DB5858', '#504141', '#66D283', '#A13BCD'];

    doughnutChartOptions: ChartOptions = {
        legend: {
            display: false
        },
        cutoutPercentage: 75,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        tooltips: {
            callbacks: {
                title: (tooltipItem, data) => {
                    return '';
                },
            },
            displayColors: false,
            backgroundColor: 'rgba(47, 47, 47, 0.7)',
            bodyFontColor: '#fff',
            bodyFontSize: 14,
            bodySpacing: 10,
            xPadding: 15,
            yPadding: 15,
            cornerRadius: 0
        }
    };
    private tooltipEl!: HTMLDivElement;

    constructor(private _helper: HelperService,
                private _api: ApiService,
                private deviceService: DeviceDetectorService,
                private _dialog: MatDialog,
                private _translate: TranslateService,) {
        this.isMobile = deviceService.isMobile();
    }

    ngAfterViewInit(): void {
        if (this.name === 'pathsRealization') {
            if (this.chartCanvas) {
                const canvas = this.chartCanvas.nativeElement;
                const ctx = canvas.getContext('2d');

                if (ctx) {
                    this.barChartOptions = {
                        responsive: true,
                        legend: {
                            display: false,
                        },
                        scales: {
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                },
                                gridLines: {
                                    display: true,
                                },
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: 10,
                                    min: 0,

                                },
                            }],
                            xAxes: [{
                                scaleLabel: {
                                    display: false,
                                },
                                gridLines: {
                                    display: false,
                                    color: '#4A83FC'
                                },
                                ticks: {
                                    callback: function (value, index, values) {
                                        // @ts-ignore
                                        return value.slice(0, 3);
                                    }
                                }
                            }]
                        },
                        tooltips: {
                            enabled: false,
                            custom: function (tooltipModel) {
                                const existingTooltip = document.getElementById('chart-tooltip');
                                if (!existingTooltip) {
                                    this.tooltipEl = document.createElement('div');
                                    this.tooltipEl.id = 'chart-tooltip';
                                    this.tooltipEl.className = 'chart-tooltip';
                                    document.body.appendChild(this.tooltipEl);
                                } else {
                                    this.tooltipEl = existingTooltip as HTMLDivElement;
                                }
                                if (!this.tooltipEl) return;

                                if (tooltipModel.opacity === 0) {
                                    this.tooltipEl.style.opacity = '0';
                                    return;
                                }
                                const title = tooltipModel.title || [];

                                let body = '';
                                let i = 0;
                                tooltipModel.body.forEach(item => {
                                    const text = item.lines[0].split(':');
                                    if (i === 0) {
                                        body = body + `<span style="color: #0E66C9;">${text[0]}:</span><span style="float: right">${text[1]}</span><br>`
                                    } else {
                                        body = body + `<span style="color: #FF9635;">${text[0]}:</span>${text[1]}<br>`
                                    }
                                    i++;
                                });

                                this.tooltipEl.innerHTML = `<span style="font-weight: 400;">${title}</span><br>${body}`;
                                const position = canvas.getBoundingClientRect();
                                let left = position.left + window.pageXOffset + tooltipModel.caretX + 10;
                                let top = position.top + window.pageYOffset + tooltipModel.caretY - 40;
                                if (left + this.tooltipEl.offsetWidth > window.innerWidth) {
                                    left = window.innerWidth - this.tooltipEl.offsetWidth - 10;
                                }
                                if (top < 0) {
                                    top = 10;
                                }

                                this.tooltipEl.style.left = `${left}px`;
                                this.tooltipEl.style.top = `${top}px`;
                                this.tooltipEl.style.opacity = '1';
                                this.tooltipEl.style.zIndex = '999';
                                this.tooltipEl.style.boxShadow = '1';
                                this.tooltipEl.style.border = 'none';
                            },
                            mode: 'index',
                            intersect: false,
                            callbacks: {},
                        },
                        hover: {
                            mode: 'index',
                            intersect: false,
                        },
                    };
                    // @ts-ignore
                    const labels = [];
                    this.data.barChartLabels.forEach(month => {
                        const m = month.split(' ');
                        labels.push(this._translate.instant('Common.' + m[0]) + ' ' + m[1])
                    })
                    this.barChartLabels = labels;
                    this.gradient = ctx.createLinearGradient(0, 0, 0, 400);
                    this.gradient.addColorStop(0, 'rgba(14, 102, 201, 0.5)');
                    this.gradient.addColorStop(1, 'rgba(255, 255, 255, 0.5)');
                    this.barChartColors = [
                        {
                            backgroundColor: this.gradient,
                            pointHoverBackgroundColor: '#0E66C9',
                            pointHoverBorderColor: '#ffffff',
                            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                            pointBorderColor: 'rgba(0, 0, 0, 0)',
                            pointHoverRadius: 7
                        },
                        {
                            borderColor: '#FF9635',
                            backgroundColor: '#FF9635',
                            pointHoverBackgroundColor: '#FF9635',
                            pointHoverBorderColor: '#ffffff',
                            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                            pointBorderColor: 'rgba(0, 0, 0, 0)',
                            pointHoverRadius: 7
                        },
                    ];
                    this.barChartData = [
                        {
                            data: this.data.completedData,
                            label: this._translate.instant('PathStatsWidget.Completed'),
                            barThickness: 22,
                            hoverBackgroundColor: [],
                            fill: true,
                            borderColor: '#0E66C9',
                            backgroundColor: '#0E66C9',
                        },
                        {
                            data: this.data.startedData,
                            label: this._translate.instant('PathStatsWidget.Started'),
                            barThickness: 22,
                            hoverBackgroundColor: [],
                            borderDash: [5, 5],
                            fill: false,
                            borderColor: '#FF9635',
                            backgroundColor: '#FF9635',
                        }
                    ];
                }
            }
        }

        if (this.name === 'realizationRate') {
            this.doughnutChartLabels = [this._translate.instant('PathStats.Finished'), this._translate.instant('PathStats.NotFinished')];
            this.viewPie = [this.data.realization, (100 - this.data.realization - 0.001)]
            this.doughnutChartData = this.viewPie;
            this.doughnutChartColors = [
                {
                    backgroundColor: ['#ffffff', '#87b3e4'],
                    borderColor: ['#0E66C9', '#0E66C9'],
                    borderWidth: 2
                }
            ];
            this.doughnutChartOptions = {
                legend: {
                    display: false
                },
                cutoutPercentage: 65,
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                tooltips: {
                    enabled: false,
                }
            };
        }

        if (this.name === 'theMostActive') {
            let i = 1;
            this.data.users.forEach(user => {
                user.lp = i;
                i++;
            });
            this.createTheMostActiveTable();
        }

        if (this.name === 'theMostRealized') {
            let i = 1;
            this.data.pathsCompleted.forEach(path => {
                path.lp = i + '.';
                i++;
            });
            i = 1;
            this.data.pathsStarted.forEach(path => {
                path.lp = i + '.';
                i++;
            });
            this.createTheMostRealizedTable();
        }

        if (this.name === 'mostPopularCategories') {
            let sum = 0;
            let i = 0;
            const backgroundColors = [];
            this.data.categories.forEach(category => {
                if (i === 10) {
                    i = 0;
                }
                sum += category.numberOfCategories
                this.doughnutChartLabels.push(category.name);
                this.doughnutChartData.push(category.numberOfCategories);
                category.color = this.colors[i];
                backgroundColors.push(this.colors[i])
                i++;
            })
            this.data.categories.sum = sum;

            this.doughnutChartColors = [
                {
                    backgroundColor: backgroundColors,
                    borderWidth: 2
                }
            ];
        }

        if (this.name === 'pathFormat') {
            let sum = 0;
            let i = 0;
            const backgroundColors = [];
            this.data.formats.forEach(format => {
                if (i === 10) {
                    i = 0;
                }
                sum += format.numberOfFormats
                this.doughnutChartLabels.push(this._translate.instant('Common.' + format.type));
                this.doughnutChartData.push(format.numberOfFormats);
                format.color = this.colors[i];
                backgroundColors.push(this.colors[i])
                i++;
            })
            this.data.formats.sum = sum;

            this.doughnutChartColors = [
                {
                    backgroundColor: backgroundColors,
                    borderWidth: 2
                }
            ];
        }

        if (this.name === 'addedPaths') {
            let sum = 0;
            this.data.types.forEach(type => {
                if (type.numberOfOwners > 0) {
                    sum += type.numberOfOwners
                    if (type.owner === 'Dolineo') {
                        this.data.types.dolineo = {
                            numberOfOwners: type.numberOfOwners,
                            owner: type.owner
                        }
                    } else {
                        this.data.types.company = {
                            numberOfOwners: type.numberOfOwners,
                            owner: type.owner
                        }
                    }
                    this.doughnutChartLabels.push(this._translate.instant('Common.' + type.owner));
                    this.doughnutChartData.push(type.numberOfOwners);
                }
            })
            if (this.data.types.company) {
                this.data.types.percent = this.data.types.company.numberOfOwners / sum;
            }
            this.data.types.sum = sum;
        }

        if (this.name === 'pathPercent') {
            let sum = 0;
            this.data.paths.forEach(type => {
                sum += type.numberOfOwners
            })
            this.data.allPaths = sum;
        }

    }

    ngOnInit() {

    }

    hexToRGB(hex, alpha) {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
        } else {
            return 'rgb(' + r + ', ' + g + ', ' + b + ')';
        }
    }

    openInfoPopup(name) {
        this._dialog.open(CreatorInfoDialogComponent, {
            disableClose: true,
            autoFocus: false,
            data: {
                head: 'Common.View',
                info: [
                    {
                        head: 'DashboardCreator.ViewHintOneHeader',
                        content: 'DashboardCreator.ViewHintOneText'
                    },
                ]
            },
            width: '742px'
        });
    }

    setPathsRealizationDisplay(display: string) {
        this.pathsRealizationDisplay = display;
    }

    createTheMostActiveTable() {
        this.theMostActiveColumns = [
            {
                index: 'lp',
                name: '',
                searchable: false,
                sortable: false,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '20px',
                    'padding-right': '10px',
                    'background': '#fff',
                },
                render: (v) => {
                    return '<span class="lp-item">' + v.lp + '</span>'
                }
            },
            {
                index: 'avatarPath',
                name: '',
                searchable: false,
                sortable: false,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'AVATAR',
                css: this.isMobile ? {
                    'min-width': '55px',
                    'padding-right': '10px',
                    'background': '#fff',
                } : {
                    'min-width': '10px',
                    'padding-right': '20px',
                    'background': '#fff',
                },
                size: () => {
                    return 50;
                },
                userName: (v) => {
                    return v.name;
                },
            },
            {
                index: 'name',
                name: '',
                searchable: false,
                sortable: false,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: this.isMobile ? {
                    'min-width': '150px',
                    'padding-right': '10px',
                    'background': '#fff',
                } : {
                    'min-width': '150px',
                    'padding-right': '20px',
                    'background': '#fff',
                },
                class: 'ft-auto',
                render: (v) => {
                    return '<span class="user-item">' + v.name + '</span>' + (v.position ? '<span class="position-item">' + v.position + '</span>' : '')
                }
            },
            {
                index: 'time',
                name: '',
                searchable: false,
                sortable: false,
                visible: !this.isMobile,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '120px',
                    'padding-right': '20px',
                    'background': '#fff',
                },
                render: (v) => {
                    return '<img src="/assets/icons/clock2.svg" class="time-item-svg">' + '<span class="time-item">' + this.formatTime(v.time) + '</span>'
                }
            },
            {
                index: 'paths',
                name: '',
                searchable: false,
                sortable: false,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: this.isMobile ? {
                    'min-width': '75px',
                    'padding-right': '10px',
                    'background': '#fff',
                    'text-align': 'right',
                } : {
                    'min-width': '90px',
                    'padding-right': '20px',
                    'background': '#fff',
                    'text-align': 'right'
                },
                render: (v) => {
                    return '<img src="/assets/icons/path.svg" class="paths-item-svg">' + '<span class="paths-item">' + v.paths + '</span>'
                }
            },
        ];
    }

    createTheMostRealizedTable() {
        this.theMostRealizedColumns = [
            {
                index: 'name',
                name: '',
                searchable: false,
                sortable: false,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: this.isMobile ? {
                    'min-width': '150px',
                    'padding-right': '10px',
                    'background': '#fff',
                } : {
                    'min-width': '150px',
                    'padding-right': '20px',
                    'background': '#fff',
                },
                class: 'ft-auto ft-cell-border most-realized',
                render: (v) => {
                    return '<span class="lp-item">' + v.lp + '</span>' + '<span class="path-name-item">' + v.name + '</span>'
                }
            },
            {
                index: 'started',
                name: '',
                searchable: false,
                sortable: false,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: this.isMobile ? {
                    'min-width': '40px',
                    'padding-right': '10px',
                    'background': '#fff',
                    'border-bottom:': 'solid 1px #e0e2e3'
                } : {
                    'min-width': '50px',
                    'padding-right': '20px',
                    'background': '#fff',
                    'border-bottom:': 'solid 1px #e0e2e3'
                },
                render: (v) => {
                    return '<span class="started-item">' + v.started + '</span>'
                },
                class: 'ft-cell-border',
            },
            {
                index: 'completed',
                name: '',
                searchable: false,
                sortable: false,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: this.isMobile ? {
                    'min-width': '40px',
                    'padding-right': '10px',
                    'background': '#fff',
                    'text-align': 'right',
                    'border-bottom:': 'solid 1px #e0e2e3'
                } : {
                    'min-width': '50px',
                    'padding-right': '20px',
                    'background': '#fff',
                    'text-align': 'right',
                    'border-bottom:': 'solid 1px #e0e2e3'
                },
                render: (v) => {
                    return '<span class="completed-item">' + v.completed + '</span>'
                },
                class: 'ft-cell-border',
            },
        ];
    }

    sortTheMostRealized() {
        if (this.theMostRealizedSort === 'completed') {
            this.theMostRealizedSort = 'started';
        } else {
            this.theMostRealizedSort = 'completed';
        }
    }


    formatTime(minutes) {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        let result = '';
        if (hours > 0) {
            result += `${hours}h `;
        }
        if (remainingMinutes > 0 || hours === 0) {
            result += `${remainingMinutes}m`;
        }

        return result.trim();
    }

}
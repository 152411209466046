import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {FileSystemFileEntry, NgxFileDropEntry} from "ngx-file-drop";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {getIcon, getIconByFilename} from "../../helpers";
import {HelperService} from "../../services/helper.service";
import {environment} from "../../../../environments/environment";
import {ApiService} from "../../../api/api.service";
import {FormControl} from "@angular/forms";

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

    protected basePath = environment.apiPath;

    @ViewChild('file') file;

    @Input() public set files(files: any[]) {
        this.selectedFiles = files;
        this.onFileUpload.emit(this.selectedFiles);
    }
    @Input() selectedFiles: any[] = [];

    @Input() public set url(url: string) {
        this.urlFormControl.setValue(url);
        this.onUrlChange.emit(url);
    }

    @Input() header: string = 'Files.AddAttachment';
    @Input() multiFiles: boolean = false;
    @Input() sortable: boolean = false;
    @Input() imageCropper: boolean = true;
    //@Input() previewImage: boolean = true;
    @Input() aspectRatio: number = 4 / 3;
    @Input() maintainAspectRatio: boolean = true;
    @Input() resizeToWidth: number = 0;
    @Input() allowedExtensions: string = '';
    @Input() rawFile: boolean = false;
    @Input() deletable: boolean = true;

    @Input() optionalUrl: boolean = false;
    @Input() urlFullWidth: boolean = false;
    @Input() urlHeader: string = 'Files.PasteLink';
    urlFormControl: FormControl = new FormControl();

    @Output() onFileUpload = new EventEmitter();
    @Output() onUrlChange = new EventEmitter();

    selectedFile: File = null;
    fileError: boolean = false;
    fileErrorMessage: string = '';
    rawFiles: File[] = [];

    constructor(private _translate: TranslateService,
                private _helper: HelperService,
                private api: ApiService) {
    }

    ngOnInit(): void {
        this.urlFormControl.valueChanges.subscribe(res => {
            this.onUrlChange.emit(res);
        })
    }

    selectFile(): void {
        if (!this.selectedFile) {
            this.file.nativeElement.click();
        }
    }

    onFileChange(file = null) {
        this.fileError = false;
        this.fileErrorMessage = '';

        this.selectedFile = file === null ? this.file.nativeElement.files[0] : file;

        if (!this.selectedFile) {
            return;
        }

        if (this.selectedFile.size > 2147483648) {
            this.fileErrorMessage = this._translate.instant('Common.TheSizeOfTheSelectedFileExceeds').replace('{{size}}', '2 GB');
            this.fileError = true;
            this.selectedFile = null;
            return;
        }

        if (this.allowedExtensions) {
            const fileExtension = this.fileExtension(this.selectedFile.name).toLowerCase();
            if (!this.allowedExtensions.includes(fileExtension)) {
                this.fileErrorMessage = this._translate.instant('Common.InvalidFileFormat');
                this.fileError = true;
                this.selectedFile = null;
                return;
            }
        }

        this.selectedFiles.push({
            id: null,
            name: this.selectedFile.name,
            rawFile: this.selectedFile
        })

        this.selectedFile = null;

        this.onFileUpload.emit(this.selectedFiles);
    }

    public dropped(files: NgxFileDropEntry[]) {
        if (files.length > 0 && files[0].fileEntry.isFile && files[0].relativePath.indexOf('/') === -1) {
            const fileEntry = files[0].fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
                this.onFileChange(file);
            });
        }
    }

    fileExtension(fileName: string) {
        return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2)
    }

    dropFiles(event: CdkDragDrop<any[]>) {
        moveItemInArray(this.selectedFiles, event.previousIndex, event.currentIndex);
        this.onFileUpload.emit(this.selectedFiles);
    }

    removeTooltipWhenDrag() {
        $(document).find('.below-top').css('display', 'none');
    }

    getIconName(filename: string): string {
        return getIconByFilename(filename);
    }

    removeFile(index: number) {
        this.selectedFiles.splice(index, 1);
        this.onFileUpload.emit(this.selectedFiles);
    }

    downloadFile(file) {
        const url = this.basePath + file.path + '?token=' + this._helper.hashUserToken();

        this.api.download(url).subscribe(data => {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(data);
            a.href = objectUrl;
            a.download = file.name;
            a.click();
            URL.revokeObjectURL(objectUrl);
        })
    }

}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    ArticleArchiveList, FeedbackQuestionnaireArchiveList, OnboardingArchiveList,
    PathArchiveList,
    PoolArchiveList,
    SurveyArchiveList,
    TrainingArchiveList
} from '../../archive/archive.interfaces';
import {ISettingsModule} from '../../shared/intefaces/settings.interface';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public homePage(): Observable<ISettingsModule[]> {
        const headers = this.defaultHeaders;
        return this.http.get<ISettingsModule[]>(`${this.basePath}/settings/home-page`, { headers });
    }

    public homePageUpdate(modules: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/settings/home-page`, {modules}, { headers });
    }
}

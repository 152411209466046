import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../api/api.service';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {HelperService} from '../../services/helper.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ReorderElementsComponent} from '../../dialogs/reorder-elements/reorder-elements.component';
import {Overlay} from '@angular/cdk/overlay';
import {FormControl} from '@angular/forms';
import {downloadPdf, normalizedLetter} from '../../helpers';
import { HttpResponse } from '@angular/common/http';
import * as moment from 'moment';
import {ReportService} from '../../services/report.service';

@Component({
    selector: 'app-feedback-compare',
    templateUrl: './feedback-compare.component.html',
    styleUrls: ['./feedback-compare.component.scss']
})
export class FeedbackCompareComponent implements OnInit {
    public filesPath = environment.filesPath;

    @Input() ids = [];
    @Input() level;

    isMobile: boolean = false;
    barHorizontalHeight: number;
    xAxisTicks: number[] = [];
    distributionVerticalFrom = 1; //10

    colors = [
        '#70BEBF',
        '#FB9678',
        '#F6CE6B',
        '#43B3DD',
        '#EA6D85',
        '#F0A354',
        '#F0A354',
    ]
    public data: any;

    categories: any[] = [
        {
            id: 'areas',
            name: 'FeedbackQuestionnaire.Areas'
        },
        {
            id: 'competences',
            name: 'QuestionnaireCreator.Competences'
        },
        {
            id: 'behaviours',
            name: 'QuestionnaireCreator.Behaviors'
        }
    ]
    selectedCategory: any = this.categories[0];


    groupBy: any[] = [
        {
            value: 'all',
            label: 'Common.AllUsers',
            isVisible: true
        },
        {
            value: 'positions',
            label: 'Common.Position',
            isVisible: true
        },
        {
            value: 'supervisors',
            label: 'Common.Supervisor',
            isVisible: true
        },
        {
            value: 'users',
            label: 'Common.employee',
            isVisible: true
        },
        {
            value: 'groups',
            label: 'Common.Group',
            isVisible: true
        },
        // {
        //     value: 'group',
        //     label: 'Common.Group',
        //     isVisible: false
        // },
    ]

    selectedGroupBy: string = 'all';
    maxComperedItems = 4;

    searchRequest: any = null;

    @ViewChild('search') searchTextBox: ElementRef;
    selectFormControl = new FormControl();
    searchTextboxControl = new FormControl();
    selectedValues = [];
    searchableData: any[] = []
    filteredOptions: Observable<any[]>;
    searchPlaceholder = '';
    groupsNameId = {};


    constructor(private _api: ApiService,
                private _router: Router,
                private _dialog: MatDialog,
                private _translate: TranslateService,
                private activatedRoute: ActivatedRoute,
                public _helper: HelperService,
                private overlay: Overlay,
                private deviceService: DeviceDetectorService,
                private reportService: ReportService) {
        this.isMobile = !this.deviceService.isDesktop();
    }

    ngOnInit(): void {
        this.getData();
    }



    selectGroupBy(event: any): void {
        this.selectedGroupBy = event.value ? event.value : null;

        this.searchTextboxControl.patchValue('');
        this.selectedValues = [];
        this.selectFormControl.reset();

        switch (event.value) {
            case 'supervisors': {
                this.searchPlaceholder = this._translate.instant('CompareFeedbacks.SearchForASupervisor');
            } break;
            case 'positions': {
                this.searchPlaceholder = this._translate.instant('CompareFeedbacks.SearchForAPosition');
            } break;
            case 'users': {
                this.searchPlaceholder = this._translate.instant('CompareFeedbacks.SearchForAUser');
            } break;
            case 'groups': {
                this.searchPlaceholder = this._translate.instant('CompareFeedbacks.SearchForAGroup');
            } break;
        }
        this.searchableData = this.data.groupBy[event.value] || [];
        this.filteredOptions = this.searchTextboxControl.valueChanges
            .pipe(
                startWith<string>(''),
                map(name => this._filter(name))
            );
    }

    private _filter(name: string): any[] {
        const filterValue = name.toLowerCase();
        this.setSelectedValues();
        this.selectFormControl.patchValue(this.selectedValues);
        return this.searchableData.filter(option => option.name.toLowerCase().includes(filterValue) === true);
    }

    selectionChange(event) {
        if (event.isUserInput && event.source.selected === false) {
            const index = this.selectedValues.indexOf(event.source.value);
            this.selectedValues.splice(index, 1)
        }
    }

    openedChange(e) {
        this.searchTextboxControl.patchValue('');
        if (e === true) {
            this.searchTextBox.nativeElement.focus();
        }
    }

    clearSearch(event) {
        event.stopPropagation();
        this.searchTextboxControl.patchValue('');
    }

    setSelectedValues() {
        if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
            this.selectFormControl.value.forEach((e) => {
                if (this.selectedValues.indexOf(e) === -1) {
                    this.selectedValues.push(e);
                }
            });
        }

        if (this.selectedValues.length > 0) {
            this.getData();
        }
    }





    getData() {
        if (this.searchRequest) {
            this.searchRequest.unsubscribe();
        }

        this.searchRequest = this.getEndpoint().subscribe((response) => {
            this.xAxisTicks = this.prepareXscale([...Array(response.report.competences.maxValue + 1).keys()], response.report.competences.maxValue);


            this.prepareCompetencesInAreas(response);
            this.prepareAreas(response);

            this.data = response;
            this.data.questionnaires.forEach((questionnaire, index) => {
                questionnaire.color = this.colors[index % this.colors.length]
            });
            this.barHorizontalHeight = !this.data.reportHideNumbers ? 30 : 15;

            this.groupBy.find((e) => e.value === 'positions').isVisible = this.data.groupBy.positions.length > 0;
            this.groupBy.find((e) => e.value === 'supervisors').isVisible = this.data.groupBy.supervisors.length > 0;
            this.groupBy.find((e) => e.value === 'users').isVisible = this.data.groupBy.users.length > 0;
            this.groupBy.find((e) => e.value === 'groups').isVisible = this.data.groupBy.groups.length > 0;
            this.data.report.groups.forEach(g => {
                this.groupsNameId[g.name] = g.id;
            });
        });
    }

    getEndpoint(): Observable<any> {
        let positions = null;
        let supervisors = null;
        let users = null;
        let groups = null;

        switch (this.selectedGroupBy) {
            case 'supervisors': {
                supervisors = this.selectedValues.map(item => item.id);
            } break;
            case 'positions': {
                positions = this.selectedValues.map(item => item.id);
            } break;
            case 'users': {
                users = this.selectedValues.map(item => item.id);
            } break;
            case 'groups': {
                groups = this.selectedValues.map(item => item.id);
            } break;
        }

        if (this.level === 'organization') {
            return this._api.Feedback.getComparisionOrganization(this._helper.getUser().companyId, this.ids, this.selectedGroupBy, positions, supervisors, users, groups);
        } else if (this.level === 'team') {
            return this._api.Feedback.getComparisionTeam(this._helper.getUser().id, this.ids, this.selectedGroupBy, positions, supervisors, users, groups);
        }
    }

    changeOrder() {
        this._dialog.open(ReorderElementsComponent, {
            width: '705px',
            disableClose: true,
            panelClass: 'filter-popup',
            autoFocus: false,
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            data: {
                title: 'CompareFeedbacks.OrderOfCompared',
                elements: [...this.data.questionnaires]
            }
        }).afterClosed().subscribe((val) => {
            if (val) {
                this.ids = val;
                this.getData();
            }
        });
    }


    downloadReport(from = '') {
        let ep: Observable<any>;
        let positions = null;
        let supervisors = null;
        let users = null;
        let groups = null;

        switch (this.selectedGroupBy) {
            case 'supervisors': {
                supervisors = this.selectedValues.map(item => item.id);
            } break;
            case 'positions': {
                positions = this.selectedValues.map(item => item.id);
            } break;
            case 'users': {
                users = this.selectedValues.map(item => item.id);
            } break;
            case 'groups': {
                groups = this.selectedValues.map(item => item.id);
            } break;
        }

        const translations = {
            'FeedbackUserReport.Duration': this._translate.instant('FeedbackUserReport.Duration'),
            'FeedbackUserReport.EvaluatedEmployee': this._translate.instant('FeedbackUserReport.EvaluatedEmployee'),
            'Common.EmailLogin': this._translate.instant('Common.EmailLogin'),
            'Common.Position': this._translate.instant('Common.Position'),
            'Common.Supervisor': this._translate.instant('Common.Supervisor'),
            'FeedbackUserReport.Summary': this._translate.instant('FeedbackUserReport.Summary'),
            'QuestionnaireCreator.Competences': this._translate.instant('QuestionnaireCreator.Competences'),
            'FeedbackStats.TopRatedcompetences': this._translate.instant('FeedbackStats.TopRatedcompetences'),
            'FeedbackStats.LowestRatedcompetences': this._translate.instant('FeedbackStats.LowestRatedcompetences'),
            'Feedback.Scale': this._translate.instant('Feedback.Scale'),
            'FeedbackUserReport.MyCompetenceAverageRating': this._translate.instant('FeedbackUserReport.MyCompetenceAverageRating'),
            'FeedbackUserReport.AverageRating': this._translate.instant('FeedbackUserReport.AverageRating'),
            'FeedbackUserReport.Contents': this._translate.instant('FeedbackUserReport.Contents'),
            'Common.Introduction': this._translate.instant('Common.Introduction'),
            'FeedbackUserReport.Frequency': this._translate.instant('FeedbackUserReport.Frequency'),
            'FeedbackUserReport.AssessmentOfCompetenciesInTheAreas': this._translate.instant('FeedbackUserReport.AssessmentOfCompetenciesInTheAreas'),
            'FeedbackUserReport.DetailedAssessmentOfCompetencies': this._translate.instant('FeedbackUserReport.DetailedAssessmentOfCompetencies'),
            'FeedbackUserReport.BehaviorDetails': this._translate.instant('FeedbackUserReport.BehaviorDetails'),
            'Feedback.Invited': this._translate.instant('Feedback.Invited'),
            'FeedbackUserReport.HasGivenAFeedback': this._translate.instant('FeedbackUserReport.HasGivenAFeedback'),
            'FeedbackUserReport.Rejected': this._translate.instant('FeedbackUserReport.Rejected'),
            'QuestionnaireCreator.Area': this._translate.instant('QuestionnaireCreator.Area'),
            'QuestionnaireCreator.Competence': this._translate.instant('QuestionnaireCreator.Competence'),
            'FeedbackUserReport.ResponseDistribution': this._translate.instant('FeedbackUserReport.ResponseDistribution'),
            'FeedbackUserReport.OpenAnswers': this._translate.instant('FeedbackUserReport.OpenAnswers'),
            'Common.NoAnswer': this._translate.instant('Common.NoAnswer'),
            'Common.NoValueAnswers': this._translate.instant('Common.NoValueAnswers'),
            'Feedback.SelfEsteem': this._translate.instant('Feedback.SelfEsteem'),
            'Common.Team': this._translate.instant('Common.Team'),
            'Common.Workers': this._translate.instant('Common.Workers'),
            'Common.Associates': this._translate.instant('Common.Associates'),
            'Common.FeedbackOthers': this._translate.instant('Common.FeedbackOthers'),
            'FeedbackReport.NotEnoughDataToDisplayTheGraph': this._translate.instant('FeedbackReport.NotEnoughDataToDisplayTheGraph'),
            'FeedbackUserReport.DevelopmentTips': this._translate.instant('FeedbackUserReport.DevelopmentTips'),
            'FeedbackReport.CanNotDisplayTheGraph': this._translate.instant('FeedbackReport.CanNotDisplayTheGraph'),
            'FeedbackReport.CompetenceOutOfAverage': this._translate.instant('FeedbackReport.CompetenceOutOfAverage'),
            'FeedbackReport.CompetenceContainsOnlyOpenQuestions': this._translate.instant('FeedbackReport.CompetenceContainsOnlyOpenQuestions'),
            'CompareFeedbacks.CompareHeaderPdf': this._translate.instant('CompareFeedbacks.CompareHeaderPdf'),
            'CompareFeedbacks.ComparedQuestionnaires': this._translate.instant('CompareFeedbacks.ComparedQuestionnaires'),
            'CompareFeedbacks.CreatedAt': this._translate.instant('CompareFeedbacks.CreatedAt')
        };

        const dateFile = moment().format('YYYYMMDD_HHmm');
        const fileName = normalizedLetter(`${this._translate.instant('TeamFeedbacks.CompareHeader')}_${dateFile}`);
        const body = {
            translations,
            ids: this.ids,
            type: this.selectedGroupBy,
            positions,
            supervisors,
            users,
            groups
        };

        if (this.level === 'organization') {
            const url = this._api.Feedback.getComparisionOrganizationPDF(this._helper.getUser().companyId);
            this.reportService.create('feedback', url, 'post', fileName, 'pdf', body);
            // ep = this._api.Feedback.getComparisionOrganizationPDF(this._helper.getUser().companyId, this.ids, translations, this.selectedGroupBy, positions, supervisors, users, groups);
        } else if (this.level === 'team') {
            const url = this._api.Feedback.getComparisionTeamPDF(this._helper.getUser().id);
            this.reportService.create('feedback', url, 'post', fileName, 'pdf', body);
            // ep = this._api.Feedback.getComparisionTeamPDF(this._helper.getUser().id, this.ids, translations, this.selectedGroupBy, positions, supervisors, users, groups);
        }

        // ep.subscribe((res: HttpResponse<Blob>) => {
        //     const dateFile = moment().format('YYYYMMDD_HHmm');
        //     const fileName = normalizedLetter(`${this._translate.instant('TeamFeedbacks.CompareHeader')}_${dateFile}`);
        //     downloadPdf(fileName, res);
        // })
    }



    xAxisTickFormattingFromZero = (value) => {
        if (!this.data.reportHideNumbers) {
            return value !== 0 ? value - 1 : '';
        } else {
            return '';
        }
    }

    xAxisTickFormattingHiddeNumber = (value) => {
        return !this.data.reportHideNumbers ? value : '';
    }

    yAxisTickFormatting = (value) => {
        return `${value}%`;
    }


    prepareCompetencesInAreas(response) {
        response.report.competencesInAreas.forEach(comp => {
            if (this.selectedGroupBy === 'all') {
                comp.chartData = [];
                comp.chartColors = [];
                comp.chartDataGeneral = [];
                let x = 0;
                for (const [key, value] of Object.entries(comp.data)) {
                    if (value) {
                        const words = key.split('&&');
                        comp.chartData.push({
                            name: words[0],
                            shortname: words[1],
                            value
                        })
                    }
                    comp.chartColors.push(this.colors[x++ % this.colors.length])
                }

                for (const [key, value] of Object.entries(comp.dataGeneral)) {
                    const words = key.split('&&');
                    comp.chartDataGeneral.push({
                        name: words[0],
                        shortname: words[1],
                        value: value ? (Number(value) / response.report.minMax.areas.max * 100).toFixed(2) : 0
                    })
                }

                for (let i = this.maxComperedItems - comp.chartDataGeneral.length; i > 0; i--) {
                    comp.chartDataGeneral.push({
                        name: '',
                        value: 0
                    })
                }
            } else {
                comp.chartData = {};
                comp.chartColors = {};
                comp.chartDataGeneral = {};
                comp.chartDataGrouped = {};

                for (const [groupId, groupData] of Object.entries(comp.data)) {
                    if (!comp.chartData[groupId]) {
                        comp.chartData[groupId] = [];
                        comp.chartColors[groupId] = [];
                        comp.chartDataGeneral[groupId] = [];
                    }

                    let x = 0;
                    for (const [key, value] of Object.entries(groupData)) {
                        if (!comp.chartDataGrouped[key]) {
                            comp.chartDataGrouped[key] = [];
                        }
                        if (value) {
                            comp.chartDataGrouped[key].push({
                                name: this.selectedValues.find(obj => Number(obj.id) === Number(groupId)).name,
                                value
                            })
                        }
                        comp.chartColors[groupId].push(this.colors[x++ % this.colors.length])
                    }


                }



                for (const [groupId, groupData] of Object.entries(comp.dataGeneral)) {
                    for (const [key, value] of Object.entries(groupData)) {
                        const words = key.split('&&');
                        comp.chartDataGeneral[groupId].push({
                            name: words[0],
                            shortname: words[1],
                            value: value ? (Number(value) / response.report.minMax.areas.max * 100).toFixed(2) : 0
                        })
                    }

                    for (let i = this.maxComperedItems - comp.chartDataGeneral[groupId].length; i > 0; i--) {
                        comp.chartDataGeneral[groupId].push({
                            name: '',
                            value: 0
                        })
                    }
                }

                comp.loaded = true;
            }

        })
    }

    prepareAreas(response) {
        let index = 1;
        response.report.areas.forEach(area => {
            area.competences.forEach(comp => {
                if (this.selectedGroupBy === 'all') {
                    comp.chartData = [];
                    comp.chartColors = [];
                    comp.chartDataGeneral = [];
                    comp.index = index;

                    let x = 0;
                    for (const [key, value] of Object.entries(comp.data)) {
                        if (value) {
                            const words = key.split('&&');
                            comp.chartData.push({
                                name: words[0],
                                shortname: words[1],
                                value
                            })
                        }
                        comp.chartColors.push(this.colors[x++ % this.colors.length])
                    }

                    for (const [key, value] of Object.entries(comp.dataGeneral)) {
                        const words = key.split('&&');
                        comp.chartDataGeneral.push({
                            name: words[0],
                            shortname: words[1],
                            value: value ? (Number(value) / response.report.minMax.competences.max * 100).toFixed(2) : 0
                        })
                    }

                    for (let i = this.maxComperedItems - comp.chartDataGeneral.length; i > 0; i--) {
                        comp.chartDataGeneral.push({
                            name: '',
                            value: 0
                        })
                    }
                } else {
                    comp.chartData = {};
                    comp.chartColors = {};
                    comp.chartDataGeneral = {};
                    comp.chartDataGrouped = {};


                    for (const [groupId, groupData] of Object.entries(comp.data)) {
                        if (!comp.chartData[groupId]) {
                            comp.chartData[groupId] = [];
                            comp.chartColors[groupId] = [];
                            comp.chartDataGeneral[groupId] = [];
                        }

                        let x = 0;
                        for (const [key, value] of Object.entries(groupData)) {
                            if (!comp.chartDataGrouped[key]) {
                                comp.chartDataGrouped[key] = [];
                            }
                            if (value) {
                                comp.chartDataGrouped[key].push({
                                    name: this.selectedValues.find(obj => Number(obj.id) === Number(groupId)).name,
                                    value
                                })
                            }
                            comp.chartColors[groupId].push(this.colors[x++ % this.colors.length])
                        }

                    }

                    for (const [groupId, groupData] of Object.entries(comp.dataGeneral)) {
                        for (const [key, value] of Object.entries(groupData)) {
                            const words = key.split('&&');
                            comp.chartDataGeneral[groupId].push({
                                name: words[0],
                                shortname: words[1],
                                value: value ? (Number(value) / response.report.minMax.competences.max * 100).toFixed(2) : 0
                            })
                        }

                        for (let i = this.maxComperedItems - comp.chartDataGeneral[groupId].length; i > 0; i--) {
                            comp.chartDataGeneral[groupId].push({
                                name: '',
                                value: 0
                            })
                        }
                    }

                    comp.loaded = true;
                }


                index = ++index;
                comp.behaviours.forEach(behaviour => {
                    if (this.selectedGroupBy === 'all') {
                        behaviour.chartData = [];
                        behaviour.chartDataGeneral = [];
                        behaviour.chartColors = [];
                        behaviour.distributionArray = [];
                        behaviour.emptyResponsesArray = [];
                        behaviour.noValueAnswersArray = [];
                        const behaviorFromZero = behaviour.answers.find(val => Number(val.value) === 0)
                        behaviour.answers = behaviour.answers; //.sort((a, b) => Number(a.value) - Number(b.value));
                        behaviour.fromZero = behaviour.fromZero || behaviorFromZero;
                        if (behaviour.fromZero) {
                            behaviour.maxValue = behaviour.maxValue + 1;
                        }
                        behaviour.xAxisTicks = this.prepareXscale([...Array(behaviour.maxValue + 1).keys()], behaviour.maxValue);

                        let x1 = 0;
                        for (const [key, value] of Object.entries(behaviour.data)) {
                            if (value) {
                                const words = key.split('&&');
                                behaviour.chartData.push({
                                    name: words[0],
                                    shortname: words[1],
                                    value
                                })
                            }
                            behaviour.chartColors.push(this.colors[x1++ % this.colors.length])

                            if (behaviour.questionType !== 'open' && key !== 'avg' && behaviour.distribution && behaviour.distribution[key]) {
                                if (typeof behaviour.distribution[key] === 'object' && behaviour.distribution[key] !== null) {
                                    const array = [];
                                    for (const [distributionName, distributionValue] of Object.entries(behaviour.distribution[key])) {
                                        array.push(distributionValue)
                                    }
                                    behaviour.distribution[key] = [...array];
                                }

                                const words = key.split('&&');
                                behaviour.distributionArray.push({
                                    name: words[0],
                                    shortname: words[1],
                                    avg: behaviour.distribution[key].reduce((accumulator, currentValue) => accumulator + currentValue),
                                    valueArray: [...behaviour.distribution[key]]
                                })

                                if (behaviour.emptyResponses) {
                                    behaviour.emptyResponsesArray.push(behaviour.emptyResponses[key])
                                }
                                if (behaviour.noValueAnswers) {
                                    behaviour.noValueAnswersArray.push(behaviour.noValueAnswers[key])
                                }
                            }
                        }

                        for (const [key, value] of Object.entries(behaviour.dataGeneral)) {
                            const words = key.split('&&');
                            behaviour.chartDataGeneral.push({
                                name: words[0],
                                shortname: words[1],
                                value: value ? (Number(value) / response.report.minMax.behaviours.max * 100).toFixed(2) : 0
                            })
                        }

                        for (let i = this.maxComperedItems - behaviour.chartDataGeneral.length; i > 0; i--) {
                            behaviour.chartDataGeneral.push({
                                name: '',
                                value: 0
                            })
                        }
                    } else {
                        behaviour.chartData = {};
                        behaviour.chartDataGeneral = {};
                        behaviour.chartColors = {};
                        behaviour.distributionArray = {};
                        behaviour.emptyResponsesArray = {};
                        behaviour.noValueAnswersArray = {};
                        const behaviorFromZero = behaviour.answers.find(val => Number(val.value) === 0)
                        behaviour.answers = behaviour.answers; //.sort((a, b) => Number(a.value) - Number(b.value));
                        behaviour.fromZero = behaviour.fromZero || behaviorFromZero;
                        if (behaviour.fromZero) {
                            behaviour.maxValue = behaviour.maxValue + 1;
                        }
                        behaviour.xAxisTicks = this.prepareXscale([...Array(behaviour.maxValue + 1).keys()], behaviour.maxValue);

                        behaviour.chartDataGrouped = {};

                        for (const [groupId, groupData] of Object.entries(behaviour.data)) {
                            if (!behaviour.chartColors[groupId]) {
                                behaviour.chartData[groupId] = [];
                                behaviour.chartDataGeneral[groupId] = [];
                                behaviour.chartColors[groupId] = [];
                                behaviour.distributionArray[groupId] = [];
                                behaviour.emptyResponsesArray[groupId] = [];
                                behaviour.noValueAnswersArray[groupId] = [];
                            }

                            let x1 = 0;
                            for (const [key, value] of Object.entries(groupData)) {
                                const groupName = this.selectedValues.find(obj => Number(obj.id) === Number(groupId)).name;
                                if (!behaviour.chartDataGrouped[key]) {
                                    behaviour.chartDataGrouped[key] = [];
                                    behaviour.distributionArray[key] = [];
                                    behaviour.emptyResponsesArray[key] = [];
                                    behaviour.noValueAnswersArray[key] = [];
                                }
                                if (value) {
                                    behaviour.chartDataGrouped[key].push({
                                        name: groupName,
                                        value
                                    })
                                }

                                behaviour.chartColors[groupId].push(this.colors[x1++ % this.colors.length])


                                if (behaviour.questionType !== 'open' && key !== 'avg' && behaviour.distribution && behaviour.distribution[groupId][key]) {
                                    if (typeof behaviour.distribution[groupId][key] === 'object' && behaviour.distribution[groupId][key] !== null) {
                                        const array = [];
                                        for (const [distributionName, distributionValue] of Object.entries(behaviour.distribution[groupId][key])) {
                                            array.push(distributionValue)
                                        }
                                        behaviour.distribution[groupId][key] = [...array];
                                    }

                                    behaviour.distributionArray[key].push({
                                        name: groupName,
                                        avg: behaviour.distribution[groupId][key].reduce((accumulator, currentValue) => accumulator + currentValue),
                                        valueArray: [...behaviour.distribution[groupId][key]]
                                    })

                                    if (behaviour.emptyResponses) {
                                        behaviour.emptyResponsesArray[key].push(behaviour.emptyResponses[groupId][key])
                                    }
                                    if (behaviour.noValueAnswers) {
                                        behaviour.noValueAnswersArray[key].push(behaviour.noValueAnswers[groupId][key])
                                    }
                                }

                                // if (behaviour.questionType !== 'open' && key !== 'avg' && behaviour.distribution[groupId] && behaviour.distribution[groupId][key]) {
                                //     if (typeof behaviour.distribution[groupId][key] === 'object' && behaviour.distribution[groupId][key] !== null) {
                                //         const array = [];
                                //         for (const [distributionName, distributionValue] of Object.entries(behaviour.distribution[groupId][key])) {
                                //             array.push(distributionValue)
                                //         }
                                //         behaviour.distribution[groupId][key] = [...array];
                                //     }
                                //
                                //     behaviour.distributionArray[key].push({
                                //         name: groupName,
                                //         avg: behaviour.distribution[groupId][key].reduce((accumulator, currentValue) => accumulator + currentValue),
                                //         valueArray: [...behaviour.distribution[groupId][key]]
                                //     })
                                //
                                //     if (behaviour.emptyResponses[groupId]) {
                                //         behaviour.emptyResponsesArray[key].push(behaviour.emptyResponses[groupId][key])
                                //     }
                                //     if (behaviour.noValueAnswers[groupId]) {
                                //         behaviour.noValueAnswersArray[key].push(behaviour.noValueAnswers[groupId][key])
                                //     }
                                // }
                            }

                        }

                        for (const [groupId, groupData] of Object.entries(behaviour.dataGeneral)) {

                            for (const [key, value] of Object.entries(groupData)) {
                                const words = key.split('&&');
                                behaviour.chartDataGeneral[groupId].push({
                                    name: words[0],
                                    shortname: words[1],
                                    value: value ? (Number(value) / response.report.minMax.competences.max * 100).toFixed(2) : 0
                                })
                            }

                            for (let i = this.maxComperedItems - behaviour.chartDataGeneral[groupId].length; i > 0; i--) {
                                behaviour.chartDataGeneral[groupId].push({
                                    name: '',
                                    value: 0
                                })
                            }
                        }



                        comp.loaded = true;
                    }
                })
            })
        })
    }


    onSelectCategory(category: any): void {
        this.selectedCategory = category;
    }

    protected readonly name = name;

    getValueById(obj, id) {
        return obj[id];
    }

    prepareXscale(scale, maxValue) {

        let ceil = Math.ceil((maxValue/25));
        if(ceil === 0){
            ceil = 1;
        }
        const items = [];
        for (let i = 0; i <= maxValue; i += ceil) {
            items.push(i);
        }
        return items;
    }
}

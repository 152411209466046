import {Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ApiService } from '../../../api/api.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import Quill from 'quill';
import { LoaderService } from '../../components/loader/loader.service';
import { HelperService } from '../../services/helper.service';
import {
    capitalizeFirstLetter,
    downloadFile, validateInternalUrl,
    validateVimeoDomain, validateVimeoUrl,
    validateYoutubeDomain,
    validateYoutubeUrl
} from '../../helpers';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { noWhitespaceValidator } from '../../validators/noWhitespaceValidator';
import { DomSanitizer ,SafeResourceUrl} from '@angular/platform-browser';
const QuillBlock = Quill.import('blots/block');
class QuillBlockDivBlock extends QuillBlock {
    static blockName = 'div';
    static tagName = 'div';
}
Quill.register(QuillBlockDivBlock);

import ImageResize from 'quill-image-resize-module';
import {AuthService} from "../../../core/services/auth.service";
Quill.register('modules/imageResize', ImageResize);

@Component({
    selector: 'app-training-from-disk',
    templateUrl: './training-from-disk.component.html',
    styleUrls: ['./training-from-disk.component.scss']
})
export class TrainingFromDiskComponent implements OnInit, OnDestroy {
    @ViewChild('file') file;
    @Input() urlToValidate: SafeResourceUrl;

    public selectedFile: File = null;
    public apiUrl = environment.filesPath;
    public fileErrorMessage = '';
    public filenamePreview = null;
    training: any = null;
    urlTestCount = 0;
    waiting: boolean = false;
    refreshIntervalId: any;
    public urlIsset = false;

    public form: FormGroup;

    finishBefore: any[] = [
        { value: 0, name: '0 s' },
        { value: 5, name: '5 s' },
        { value: 10, name: '10 s' },
        { value: 15, name: '15 s' },
        { value: 20, name: '20 s' },
    ]

    public trainingFormat = [
        {
            name: 'TrainingFromDisk.HeaderOne',
            info: 'TrainingFromDisk.HeaderOneDescription',
            index: 'videoAudio'
        },
        {
            name: 'TrainingFromDisk.HeaderTwo',
            info: 'TrainingFromDisk.HeaderTwoDescription',
            index: 'scorm'
        },
        {
            name: 'TrainingFromDisk.HeaderThree',
            info: 'TrainingFromDisk.HeaderThreeDescription',
            index: 'h5p'
        },
        {
            name: 'TrainingFromDisk.HeaderFour',
            info: 'TrainingFromDisk.HeaderFourDescription',
            index: 'file'
        },
        {
            name: 'TrainingCreator.FormatHeaderFive',
            info: 'TrainingCreator.FormatHeaderFiveInfo',
            index: 'page'
        },
        {
            name: 'TrainingCreator.FormatHeaderSix',
            info: 'TrainingCreator.FormatHeaderSixInfo',
            index: 'url'
        }
    ];
    public validation = {
        fileUrl: this._translate.instant('Common.ChooseAFile'),
        fileSize: this._translate.instant('Common.TheSizeOfTheSelectedFileExceeds').replace('{{size}}', '2 GB'),
        fileExt: this._translate.instant('Common.InvalidFileFormat'),
        fileDuration: this._translate.instant('TrainingCreator.TheRecordingCannotBeLongerThan30Minutes')
    };
    public fileError: boolean = false;

    selectedFormat = this.trainingFormat[0];
    public format = new FormControl();
    subscriptions: Subscription = new Subscription();
    public urlError: string = '';
    tempFormat = '';
    private pageEditor: Quill = null;
    editorToolbarOptions = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['code-block', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],

        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image'],

        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }]
    ];
    pptMaxSizeInfo = false;

    constructor(private fb: FormBuilder,
                private _dialog: MatDialog,
                public dialogRef: MatDialogRef<TrainingFromDiskComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private api: ApiService,
                private loader: LoaderService,
                private _translate: TranslateService,
                private _helper: HelperService,
                private _auth: AuthService,
                public sanitizer:DomSanitizer) {
        this.form = this.initForm();
    }

    ngOnInit() {
        this.onFormatChanged();
        this.onFormValueChanged();
        this.api.Library.errorScorm.subscribe(value => {
            if (value) {
                this.fileErrorMessage = value;
                this.fileError = true;
            }
        })

        if (this.data && this.data.trainingId) {
            this.getTraining();
        } else {
            this.format.setValue(this.trainingFormat[0].index);
        }
    }

    getTraining() {
        this.api.Library.getTraining(this.data.trainingId).subscribe(value => {
            this.training = value;
            value.duration = value.durationTime;
            if (value.mediaFile) {
                this.filenamePreview = value.mediaFile.origFileName;
            }

            if (value.videoFile) {
                this.format.setValue(this.trainingFormat[0].index);
                this.form.get('fileName').setValue(value.videoFile.origFileName);
                this.form.get('type').setValue(this.trainingFormat[0].index);
                this.form.get('videoUrl').setValue(value.videoFile.videoUrl);
                this.form.get('limitSeek').setValue(value.videoFile.limitSeek);
                this.form.get('increaseSpeed').setValue(value.videoFile.increaseSpeed);
                this.form.get('finishBefore').setValue(value.videoFile.finishBefore);
                this.filenamePreview = value.videoFile.origFileName;
                this.selectedFormat = this.trainingFormat[0]
            } else if (value.scormFile) {
                this.format.setValue(this.trainingFormat[1].index);
                this.form.get('fileName').setValue(value.scormFile.origFileName);
                this.form.get('type').setValue(this.trainingFormat[1].index);
                this.filenamePreview = value.scormFile.origFileName;
                this.selectedFormat = this.trainingFormat[1]
            } else if (value.h5pContent) {
                this.format.setValue(this.trainingFormat[2].index);
                this.filenamePreview = value.h5pContent.filename;
                this.form.get('confirmationRequired').setValue(value.h5pContent.confirmation_required === '1' ?? false);
                this.form.get('pointsRequired').setValue(value.h5pContent.points_required === '1' ?? false);
                this.form.get('points').setValue(value.h5pContent.points);
                this.form.get('fileName').setValue(value.h5pContent.filename);
                this.selectedFormat = this.trainingFormat[2]
            } else if (value.mediaFile) {
                this.format.setValue(this.trainingFormat[3].index);
                this.form.get('fileName').setValue(value.mediaFile.origFileName);
                this.form.get('type').setValue(this.trainingFormat[3].index);
                if (value.mediaFile.origFileName.toLowerCase().indexOf('.pdf') !== -1 && value.mediaFile.action === 'display') {
                    this.form.get('allPagesSeenRequired').enable();
                    this.form.get('allPagesSeenRequired').setValue(value.mediaFile.allPagesSeenRequired);
                    this.form.get('pdfFileDownloadBlock').enable();
                    this.form.get('pdfFileDownloadBlock').setValue(value.mediaFile.pdfFileDownloadBlock);
                } else {
                    this.form.get('allPagesSeenRequired').disable();
                    this.form.get('allPagesSeenRequired').setValue(false);
                    this.form.get('pdfFileDownloadBlock').disable();
                    this.form.get('pdfFileDownloadBlock').setValue(false);
                }

                this.form.get('allPagesSeenRequired').updateValueAndValidity()
                this.form.get('pdfFileDownloadBlock').updateValueAndValidity()

                if (value.mediaFile.origFileName.toLowerCase().indexOf('.pdf') === -1
                    && value.mediaFile.origFileName.toLowerCase().indexOf('.doc') === -1
                    && value.mediaFile.origFileName.toLowerCase().indexOf('.docx') === -1
                    && value.mediaFile.origFileName.toLowerCase().indexOf('.ppt') === -1
                    && value.mediaFile.origFileName.toLowerCase().indexOf('.pptx') === -1) {
                    this.form.get('action').disable();
                }
                this.form.get('action').setValue(value.mediaFile.action);
                this.form.get('action').updateValueAndValidity()

                this.selectedFormat = this.trainingFormat[3]

                if ((value.mediaFile.origFileName.toLowerCase().indexOf('.ppt') !== -1
                    || value.mediaFile.origFileName.toLowerCase().indexOf('.pptx') !== -1) && value.mediaFile.fileSize > 104857600) {
                    this.form.get('action').setValue('download');
                    this.form.get('action').disable();
                    this.pptMaxSizeInfo = true;
                }
            } else if (value.pageContent) {
                this.format.setValue(this.trainingFormat[4].index);
                this.selectedFormat = this.trainingFormat[4];
                this.changeContentTypeToPage(this.selectedFormat.index);

                this.form.get('fileName').clearValidators();
                this.form.get('fileName').setErrors(null);
                this.form.get('fileName').updateValueAndValidity();
            }else if (value.url) {
                this.format.setValue(this.trainingFormat[5].index);
                this.selectedFormat = this.trainingFormat[5];
                this.form.get('url').setValue(value.url);
                this.form.get('display').setValue(value.display);
            }
            this.form.get('type').setValue(this.selectedFormat.index)

            this.form.get('trainingId').setValue(value.id);
            this.form.patchValue(value);
            this.form.get('name').markAsTouched();
            this.format.disable();
        })
    }

    ngOnDestroy() {
        this.pageEditor = null;
        this.subscriptions.unsubscribe();
    }

    onFormatChanged() {
        this.format.valueChanges.subscribe((value) => {
            if (this.tempFormat === '') {
                this.tempFormat = value
            }

            if ((this.form.touched && this.tempFormat !== value) || this.selectedFile) {
                this._dialog.open(ConfirmDialogComponent, {
                    disableClose: true,
                    autoFocus: false,
                    data: {
                        title: 'TrainingCreator.ChangeTheTrainingFormat',
                        content: 'TrainingCreator.AreYouSureYouWantToChangeTheTrainingFormatTheChangesMadeAreNotSaved',
                        okText: 'Common.IConfirm'
                    },
                    width: '486px'
                }).afterClosed().subscribe(value1 => {
                    if (value1) {
                        this.form = this.initForm();
                        this.onFormValueChanged();
                        this.selectedFormat = this.trainingFormat.find(val => val.index === value);
                        this.removeFile();
                        this.form.get('name').markAsTouched();
                        this.form.get('duration').markAsTouched();
                        this.form.get('type').setValue(this.selectedFormat.index);
                        this.form.markAsUntouched();
                    } else {
                        this.format.setValue(this.selectedFormat, { emitEvent: false });
                        this.form.get('type').setValue(this.selectedFormat.index);
                    }
                })
            } else {
                this.selectedFormat = this.trainingFormat.find(val => val.index === value);
                if (!this.data?.trainingId) {
                    this.changeContentTypeToPage(this.selectedFormat.index);
                }
                this.form.get('type').setValue(this.selectedFormat.index);
                this.form.markAsUntouched();
                this.onFormValueChanged();
            }
        });
    }

    initForm(): FormGroup {
        return this.fb.group({
            name: ['', {
                validators: [Validators.required, noWhitespaceValidator]
            }],
            duration: ['', Validators.required],
            trainingId: [''],
            file: [''],
            fileName: [''],
            origFileName: [''],
            videoUrl: [null],
            url: [null],
            display: ['0'],
            confirmationRequired: [false],
            pointsRequired: [false],
            points: [{ value: 100, disabled: true }],
            action: ['display'],
            allPagesSeenRequired: [{ value: false, disabled: true }],
            pdfFileDownloadBlock: [{ value: false, disabled: true }],
            limitSeek: [false],
            increaseSpeed: [false],
            finishBefore: [10],
            type: ['videoAudio'],
            videoFileId: [''],
            mediaFileId: [''],
            scormFileId: [''],
            h5pContentId: [''],
            isSoundMaterial: [0]
        });
    }

    onFormValueChanged() {
        this.form.get('videoUrl').valueChanges.subscribe((value: string) => {
            if (value !== '') {
                this.removeFile();
                this.form.get('fileName').clearValidators();
                this.form.get('fileName').updateValueAndValidity();
            }
            if (value === '') {
                this.form.get('fileName').setValidators(Validators.required)
                this.urlError = '';
            }
            this.validate();
        });

        if (this.format.value === 'url') {
            this.form.get('url').valueChanges.subscribe((value: string) => {
                this.form.get('url').setValidators(Validators.required);
                this.form.get('fileName').clearValidators();
                this.form.get('fileName').updateValueAndValidity();
                this.urlError = '';
                this.validateUrl();
            });
        }

        this.form.get('action').valueChanges.subscribe((value) => {
            if (((this.selectedFile && this.selectedFile.name.toLowerCase().indexOf('.pdf') !== -1) ||
                (this.filenamePreview?.toLowerCase().indexOf('.pdf') !== -1)) && value === 'display') {
                this.form.get('allPagesSeenRequired').enable();
                this.form.get('pdfFileDownloadBlock').enable();
            } else {
                this.form.get('allPagesSeenRequired').disable();
                this.form.get('allPagesSeenRequired').setValue(false);
                this.form.get('pdfFileDownloadBlock').disable();
                this.form.get('pdfFileDownloadBlock').setValue(false);
            }

            this.form.get('allPagesSeenRequired').updateValueAndValidity()
            this.form.get('pdfFileDownloadBlock').updateValueAndValidity()
        });

        this.form.get('pointsRequired').valueChanges.subscribe((value: boolean) => {
            if (value) {
                this.form.get('points').setValidators([Validators.min(1), Validators.required]);
                this.form.get('points').enable();
            } else {
                this.form.get('points').clearValidators();
                this.form.get('points').disable();
            }
            this.form.get('points').updateValueAndValidity();
        });
    }

    public dropped(files: NgxFileDropEntry[], name: string) {
        if (files.length > 0 && files[0].fileEntry.isFile && files[0].relativePath.indexOf('/') === -1) {
            const fileEntry = files[0].fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
                if (name === 'videoAudio') {
                    if (!this.form.get('videoUrl').value) {
                        this.onFileChange(file, name);
                    }
                } else {
                    this.onFileChange(file, name);
                }
            });
        }
    }

    onFileChange(file = null, name: string) {
        this.selectedFile = file === null ? this.file.nativeElement.files[0] : file;

        if (!this.selectedFile) {
            return;
        }

        this.filenamePreview = null;

        if (name === 'videoAudio') {
            this.videoChange();
        }

        if (name === 'scorm') {
            this.scormChange();
        }

        if (name === 'file') {
            this.docChange();
        }

        if (name === 'h5p') {
            this.h5pChange();
        }
    }

    selectFile(): void {
        if (!this.form.get('origFileName').value && !this.filenamePreview && !this.form.get('videoUrl').value) {
            this.file.nativeElement.click();
        }
    }

    removeFile() {
        this.selectedFile = null;
        this.filenamePreview = null;
        this.fileError = false;
        this.pptMaxSizeInfo = false;
        if (this.file) {
            this.file.nativeElement.value = null;
            this.form.get('file').setValue(null);
            this.form.get('fileName').setValue(null);
            this.form.get('origFileName').setValue(null);
        }
        this.form.get('videoFileId').setValue(null);
        this.form.get('mediaFileId').setValue(null);
        this.form.get('scormFileId').setValue(null);
        this.form.get('h5pContentId').setValue(null);
        this.disableAllPagesSeenReqired();
        this.disablePdfFileDownloadBlock();
    }

    disableAllPagesSeenReqired() {
        this.form.get('allPagesSeenRequired').disable();
        this.form.get('allPagesSeenRequired').setValue(false);
        this.form.get('allPagesSeenRequired').updateValueAndValidity()
    }

    disablePdfFileDownloadBlock() {
        this.form.get('pdfFileDownloadBlock').disable();
        this.form.get('pdfFileDownloadBlock').setValue(false);
        this.form.get('pdfFileDownloadBlock').updateValueAndValidity()
    }

    validate() {
        const url = this.form.get('videoUrl').value;
        if ((url !== null && url !== '') && !this.selectedFile) {
            if (!validateYoutubeDomain(url) && !validateVimeoDomain(url)) {
                this.form.get('videoUrl').setErrors({ 'invalid': true });
                this.urlError = this._translate.instant('TrainingCreator.TheLinkIsInvalidLinksToYouTubeAndVimeoAreAllowed');
            } else {
                if (!validateYoutubeUrl(url) && !validateVimeoUrl(url)) {
                    this.form.get('videoUrl').setErrors({ 'invalid': true });
                    this.urlError = this._translate.instant('TrainingCreator.TheLinkIsInvalidPleaseCopyTheEntireLink');
                } else {
                    this.form.get('videoUrl').setErrors({ 'invalid': false });
                    this.form.get('videoUrl').setErrors(null);
                    this.urlError = '';
                }
            }
        } else {
            this.form.get('videoUrl').setErrors({ 'invalid': false });
            this.form.get('videoUrl').setErrors(null);
            this.urlError = '';
        }
    }

    urlExists(url) {
        return fetch(url, {mode: 'no-cors'})
            .then(res => true)
            .catch(err => false)
    }

    validateUrl() {
        const url = this.form.get('url').value;

        clearInterval(this.refreshIntervalId);

        if ((url !== null && url !== '') && (!this.training || (this.training && this.training.url !== url))) {
            if (!validateInternalUrl(url)) {
                this.form.get('url').setErrors({ 'invalid': true });
                this.urlError = this._translate.instant('TrainingCreator.UrlAdressError')
            }else{
                this.refreshIntervalId = setInterval(() => {

                    this.urlExists(url).then(result => result === true ? (this.urlIsset = true) : (this.urlIsset = false));

                    if(this.urlIsset){
                        this.form.get('url').setErrors({ 'invalid': false });
                        this.form.get('url').setErrors(null);
                        this.urlError = '';
                        this.urlTestCount++;
                        if(this.urlTestCount > 10){
                            clearInterval(this.refreshIntervalId);
                            this.urlTestCount = 0;
                        }
                    }else{
                        this.form.get('url').setErrors({ 'invalid': true });
                        this.urlError = this._translate.instant('TrainingCreator.UrlAdressError')
                    }

                }, 500);
            }
            this.form.get('fileName').clearValidators();
        } else if(url === '' || url === null){
            this.form.get('url').setErrors({ 'invalid': true });
            this.urlError = this._translate.instant('Common.FiledCanNotBeEmpty')
        }else {
            this.form.get('url').setErrors({ 'invalid': false });
            this.form.get('url').setErrors(null);
            this.urlError = '';
        }
    }

    videoChange(): void {
        this.filenamePreview = null;
        if (this.selectedFile.name.toLowerCase().indexOf('.mp4') === -1
            && this.selectedFile.name.toLowerCase().indexOf('.webm') === -1
            && this.selectedFile.name.toLowerCase().indexOf('.avi') === -1
            && this.selectedFile.name.toLowerCase().indexOf('.mov') === -1
            && this.selectedFile.name.toLowerCase().indexOf('.wmv') === -1
            && this.selectedFile.name.toLowerCase().indexOf('.ogv') === -1
            && this.selectedFile.name.toLowerCase().indexOf('.mp3') === -1) {
            this.fileErrorMessage = this.validation.fileExt;
            this.fileError = true;
            this.selectedFile = null;
        } else if (this.selectedFile.size > 2147483648) {
            this.fileErrorMessage = this.validation.fileSize;
            this.fileError = true;
            this.selectedFile = null;
        } else {
            this.videoChangeSendFile();
        }
    }

    videoChangeSendFile() {
        this.form.get('file').setValue(this.selectedFile);
        this.form.get('fileName').setValue(this.selectedFile.name);
        this.fileError = false;
        this.filenamePreview = this._helper.stripFileName(this.selectedFile.name, 60);
        this.form.get('name').setValue(this.filenamePreview || this.form.get('origFileName').value);
    }

    scormChange(): void {
        this.filenamePreview = null;

        if (this.selectedFile.name.toLowerCase().indexOf('.zip') === -1) {
            this.fileErrorMessage = this.validation.fileExt;
            this.fileError = true;
            this.selectedFile = null;
            this.filenamePreview = null;
        } else if (this.selectedFile.size > 2147483648) {
            this.fileErrorMessage = this.validation.fileSize;
            this.fileError = true;
            this.selectedFile = null;
            this.filenamePreview = null;
        } else {
            this.form.get('file').setValue(this.selectedFile);
            this.form.get('fileName').setValue(this.selectedFile.name);
            this.fileError = false;
            this.filenamePreview = this._helper.stripFileName(this.selectedFile.name, 60);
            this.form.get('name').setValue(this.filenamePreview || this.form.get('origFileName').value);
        }
    }

    h5pChange(): void {
        this.filenamePreview = null;

        if (this.selectedFile.name.toLowerCase().indexOf('.h5p') === -1) {
            this.fileErrorMessage = this.validation.fileExt;
            this.fileError = true;
            this.selectedFile = null;
            this.filenamePreview = null;
        } else if (this.selectedFile.size > 2147483648) {
            this.fileErrorMessage = this.validation.fileSize;
            this.fileError = true;
            this.selectedFile = null;
            this.filenamePreview = null;
        } else {
            this.form.get('file').setValue(this.selectedFile);
            this.form.get('fileName').setValue(this.selectedFile.name);
            this.fileError = false;
            this.filenamePreview = this._helper.stripFileName(this.selectedFile.name, 60);
            this.form.get('name').setValue(this.filenamePreview || this.form.get('origFileName').value);
        }
    }

    docChange(): void {
        this.filenamePreview = null;
        this.form.get('action').enable();
        if (this.selectedFile.name.toLowerCase().indexOf('.pdf') === -1
            && this.selectedFile.name.toLowerCase().indexOf('.doc') === -1
            && this.selectedFile.name.toLowerCase().indexOf('.docx') === -1
            && this.selectedFile.name.toLowerCase().indexOf('.ppt') === -1
            && this.selectedFile.name.toLowerCase().indexOf('.pptx') === -1) {
            this.form.get('action').setValue('download');
            this.form.get('action').disable();
        } else {
            this.form.get('action').setValue('display');
        }

        if (this.selectedFile.name.toLowerCase().indexOf('.pdf') === -1) {
            this.form.get('allPagesSeenRequired').disable();
            this.form.get('pdfFileDownloadBlock').disable();
        } else {
            this.form.get('allPagesSeenRequired').enable();
            this.form.get('pdfFileDownloadBlock').enable();
        }

        if ((this.selectedFile.name.toLowerCase().indexOf('.ppt') !== -1
            || this.selectedFile.name.toLowerCase().indexOf('.pptx') !== -1) && this.selectedFile.size > 104857600) {
            this.form.get('action').setValue('download');
            this.form.get('action').disable();
            this.pptMaxSizeInfo = true;
        }

        this.form.get('allPagesSeenRequired').setValue(false);
        this.form.get('allPagesSeenRequired').updateValueAndValidity()
        this.form.get('pdfFileDownloadBlock').setValue(false);
        this.form.get('pdfFileDownloadBlock').updateValueAndValidity()


        if (this.selectedFile.size > 2147483648) {
            this.fileErrorMessage = this.validation.fileSize;
            this.fileError = true;
            this.selectedFile = null;
            this.filenamePreview = null;
        } else {
            this.fileError = false;
            this.form.get('file').setValue(this.selectedFile);
            this.form.get('fileName').setValue(this.selectedFile.name);
            this.filenamePreview = this._helper.stripFileName(this.selectedFile.name, 60);
            this.form.get('name').setValue(this.filenamePreview || this.form.get('origFileName').value);
        }
    }

    save() {
        this.form.markAllAsTouched();
        if (!this.form.valid) {
            return;
        }

        let pageContent = null;
        if (this.form.get('type').value === 'page') {
            const pageContentEditor: HTMLElement = document.getElementById('quill-editor-page').firstChild as HTMLElement;
            pageContent = pageContentEditor.innerHTML.toString().replaceAll('ql-cursor', '');
        }

        const payload = {
            ...this.form.value,
            name: capitalizeFirstLetter(this.form.get('name').value ? this.form.get('name').value.trim() : ''),
            availableStandalone: false,
            durationTime: parseInt(this.form.get('duration').value, 10),
            isSoundMaterial: this.selectedFile?.name.toLowerCase().indexOf('.mp3') > -1,
            pageContent
        };

        this.waiting = true;

        if (this.form.get('type').value === 'videoAudio') {
            this.uploadVideo(payload);
        }

        if (this.form.get('type').value === 'file') {
            this.uploadDoc(payload);
        }

        if (this.form.get('type').value === 'scorm') {
            this.uploadScorm(payload);
        }

        if (this.form.get('type').value === 'h5p') {
            this.uploadH5P(payload);
        }

        if (this.form.get('type').value === 'page') {
            this.uploadPage(payload);
        }

        if (this.form.get('type').value === 'url') {
            this.uploadUrl(payload);
        }
    }

    uploadVideoFileToS3(file: File, payload: any) {
        this.loader.show();
        const fileName = this._helper.createUniqueFilename(file.name);

        this._helper.uploadToS3(file, fileName).then(
            (data) => {
                payload.fileSize = file.size;
                payload.temporaryStoragePath = fileName;
                payload.originalFileName = file.name

                this.createPayloadVideo(payload)
            },
            (error) => {
                if(error == 'ExpiredToken: The provided token has expired.'){
                    this._auth.refreshToken().subscribe( res => {
                        if(res){
                            this.uploadVideoFileToS3(file, payload);
                        }
                    })
                }
            }
        );
    }

    uploadVideo(payload: any) {
        if (payload.file) {
            this.uploadVideoFileToS3(payload.file, payload);
        }else{
            this.createPayloadVideo(payload)
        }
    }

    createPayloadVideo(payload: any) {
        if (payload.temporaryStoragePath) {
            const formData = new FormData();
            if (payload.file) {
                formData.append('file', payload.file, payload.fileName);
            }
            formData.append('videoUrl', payload.videoUrl);
            formData.append('limitSeek', payload.limitSeek);
            formData.append('increaseSpeed', payload.increaseSpeed);
            formData.append('finishBefore', payload.finishBefore);
            formData.append('originalFileName', payload.originalFileName);
            formData.append('fileSize', payload.fileSize);
            formData.append('temporaryStoragePath', payload.temporaryStoragePath);

            this.api.Company.trainingUploadVideo(formData).subscribe((resp: any) => {
                if (resp.type === HttpEventType.Response) {
                    payload = {
                        ...payload,
                        videoFileId: resp.body.fileId
                    }

                    this.api.Library.addTraining(payload, null).subscribe((data) => {
                        this.waiting = false;
                        this.dialogRef.close({
                            id: data.trainingId,
                            name: payload.name,
                            duration: payload.durationTime,
                            type: this.getType(),
                            action: payload.action,
                            canEdit: true,
                            isSoundMaterial: payload.isSoundMaterial
                        });
                    });
                }
            }, () => {
                this.waiting = false;
            });
        } else {
            payload = {
                ...payload,
            }

            this.api.Library.addTraining(payload, null).subscribe((data) => {
                this.waiting = false;
                this.dialogRef.close({
                    id: data.trainingId,
                    name: payload.name,
                    duration: payload.durationTime,
                    type: this.getType(),
                    action: payload.action,
                    canEdit: true,
                    isSoundMaterial: payload.isSoundMaterial
                });
            });
        }
    }

    uploadDoc(payload: any) {
        if (payload.file) {
            const formData = new FormData();
            formData.append('file', payload.file, payload.fileName);
            formData.append('action', payload.action);
            formData.append('allPagesSeenRequired', payload.allPagesSeenRequired ?? false);
            formData.append('pdfFileDownloadBlock', payload.pdfFileDownloadBlock ?? false);
            this.api.Company.trainingUploadMedia(formData).subscribe(resp => {
                if (resp.type === HttpEventType.Response) {
                    payload = {
                        ...payload,
                        mediaFileId: resp.body.fileId
                    }

                    this.api.Library.addTraining(payload, null).subscribe((data) => {
                        this.waiting = false;
                        this.dialogRef.close({
                            id: data.trainingId,
                            name: payload.name,
                            duration: payload.durationTime,
                            type: this.getType(),
                            action: payload.action,
                            canEdit: true
                        });
                    });
                }
            }, () => {
                this.waiting = false;
            });
        } else {
            payload = {
                ...payload,
                trainingId: this.training.id,
                mediaFileId: this.training.mediaFileId
            }

            this.api.Library.addTraining(payload, null).subscribe((data) => {
                this.waiting = false;
                this.dialogRef.close({
                    id: data.trainingId,
                    name: payload.name,
                    duration: payload.durationTime,
                    type: this.getType(),
                    action: payload.action,
                    canEdit: true
                });
            });
        }
    }

    uploadScorm(payload: any) {
        if (payload.file) {
            const formData = new FormData();
            formData.append('file', payload.file, payload.fileName);
            this.api.Company.trainingUploadScorm(formData).subscribe(resp => {
                if (resp.type === HttpEventType.Response) {
                    payload = {
                        ...payload,
                        scormFileId: resp.body.fileId
                    }

                    this.api.Library.addTraining(payload, null).subscribe((data) => {
                        this.waiting = false;
                        this.dialogRef.close({
                            id: data.trainingId,
                            name: payload.name,
                            duration: payload.durationTime,
                            type: this.getType(),
                            action: '',
                            canEdit: true
                        });
                    });
                }
            }, (err) => {
                this.api.Library.errorScorm.next(err.error.details);
                this.waiting = false;
            });
        } else {
            payload = {
                ...payload,
                trainingId: this.training.id,
                scormFileId: this.training.scormFileId
            }

            this.api.Library.addTraining(payload, null).subscribe((data) => {
                this.waiting = false;
                this.dialogRef.close({
                    id: data.trainingId,
                    name: payload.name,
                    duration: payload.durationTime,
                    type: this.getType(),
                    action: payload.action,
                    canEdit: true
                });
            });
        }
    }

    uploadH5P(payload: any) {
        if (payload.file) {
            const formData = new FormData();
            formData.append('file', payload.file, payload.fileName);
            formData.append('confirmationRequired', payload.confirmationRequired);
            formData.append('pointsRequired', payload.pointsRequired);
            formData.append('points', payload.points);

            this.api.Company.h5pSave(formData).subscribe(resp => {
                payload = {
                    ...payload,
                    h5pContentId: resp.id,
                }

                this.api.Library.addTraining(payload, null).subscribe((data) => {
                    this.waiting = false;
                    this.dialogRef.close({
                        id: data.trainingId,
                        name: payload.name,
                        duration: payload.durationTime,
                        type: this.getType(),
                        action: '',
                        canEdit: true
                    });
                });
            }, (err) => {
                this.removeFile();
                this.fileErrorMessage = err.error.details;
                this.fileError = true;
                this.waiting = false;
            });
        } else {
            payload = {
                ...payload,
                trainingId: this.training.id,
                h5pContentId: this.training.h5pContentId
            }

            this.api.Library.addTraining(payload, null).subscribe((data) => {
                this.waiting = false;
                this.dialogRef.close({
                    id: data.trainingId,
                    name: payload.name,
                    duration: payload.durationTime,
                    type: this.getType(),
                    action: payload.action,
                    canEdit: true
                });
            });
        }

    }

    uploadPage(payload: any) {
        if (this.training == null) {
            this.api.Library.addTraining(payload, null).subscribe((data) => {
                this.waiting = false;
                this.dialogRef.close({
                    id: data.trainingId,
                    name: payload.name,
                    duration: payload.durationTime,
                    type: this.getType(),
                    action: '',
                    canEdit: true
                });
            });
        } else {
            payload = {
                ...payload,
                trainingId: this.training.id,
            }

            this.api.Library.addTraining(payload, null).subscribe((data) => {
                this.waiting = false;
                this.dialogRef.close({
                    id: data.trainingId,
                    name: payload.name,
                    duration: payload.durationTime,
                    type: this.getType(),
                    action: payload.action,
                    canEdit: true
                });
            });
        }

    }

    uploadUrl(payload: any) {
        if (this.training == null) {
            this.api.Library.addTraining(payload, null).subscribe((data) => {
                this.waiting = false;
                this.dialogRef.close({
                    id: data.trainingId,
                    name: payload.name,
                    duration: payload.durationTime,
                    type: this.getType(),
                    action: '',
                    canEdit: true
                });
            });
        } else {
            payload = {
                ...payload,
                trainingId: this.training.id,
            }

            this.api.Library.addTraining(payload, null).subscribe((data) => {
                this.waiting = false;
                this.dialogRef.close({
                    id: data.trainingId,
                    name: payload.name,
                    duration: payload.durationTime,
                    type: this.getType(),
                    action: payload.action,
                    canEdit: true
                });
            });
        }

    }

    getType() {
        switch (this.form.get('type').value) {
            case 'page' : {
                return 'page';
            }
            case 'videoAudio' : {
                return 'video';
            }
            case 'scorm' : {
                return 'scorm';
            }
            case 'file' : {
                return 'document';
            }
            case 'h5p' : {
                return 'h5p';
            }
            case 'url' : {
                return 'url';
            }
        }
    }

    downloadFile() {
        if (this.selectedFile) {
            saveAs(this.selectedFile, this.selectedFile.name);
        } else if (this.training) {
            switch (this.form.get('type').value) {
                case 'videoAudio' : {
                    let url = this.training.videoFile.fileName;
                    if (this.training.videoFile.sources && this.training.videoFile.sources.length > 0) {
                        url = this.training.videoFile.sources[0].src + '?token=' + this._helper.hashUserToken();
                    }
                    this.api.download(url).subscribe(blob => {
                        downloadFile(blob, this.training.videoFile.origFileName)
                    })
                }
                    break;
                case 'scorm' : {
                    this.api.download(this.training.scormFile.fileName).subscribe(blob => {
                        downloadFile(blob, this.training.scormFile.origFileName)
                    })
                }
                    break;
                case 'file' : {
                    this.api.download(this.training.mediaFile.fileName).subscribe(blob => {
                        downloadFile(blob, this.training.mediaFile.origFileName)
                    })
                }
                    break;
                case 'h5p' : {
                    this.filenamePreview = this._translate.instant('TrainingCreator.DownloadThreeDot');
                    this.api.Library.downloadH5p(this.training.h5pContentId).subscribe((res) => {
                        this.filenamePreview = this.training.h5pContent.filename;
                        this.api.download(environment.filesPath + '/uploads/h5p/exports/' + res.file + '?token=' + this._helper.hashUserToken()).subscribe(blob => {
                            downloadFile(blob, this.training.h5pContent.filename + '.h5p')
                        })
                    }, () => {
                        this.filenamePreview = this.training.h5pContent.filename;
                    });
                }
            }
        }
    }

    changeContentTypeToPage(type) {
        if (type === 'page' && this.pageEditor == null) {
            this.pageEditor = true;
            this.form.get('fileName').clearValidators();
            this.form.get('fileName').setErrors(null);
            this.form.get('fileName').updateValueAndValidity();

            setTimeout(() => {
                if (this.training != null) {
                    document.getElementById('quill-editor-page').innerHTML = this.training.pageContent.replaceAll('ql-cursor', '');
                }

                this.pageEditor = new Quill('#quill-editor-page', {
                    modules: {
                        toolbar: this.editorToolbarOptions,
                        imageResize: {
                            modules: [ 'Resize', 'DisplaySize' ]
                        }
                    },
                    theme: 'snow',
                    scrollingContainer: '.mat-dialog-content',
                    clipboard: {
                        matchVisual: false
                    }
                });
            }, 500);
        } else {
            this.form.get('fileName').setValidators(Validators.required);
            this.form.get('fileName').updateValueAndValidity();
            this.pageEditor = null
        }
    }
}

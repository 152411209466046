import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from '../../../api/api.service';
import { HelperService } from '../../services/helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-users-visibility-dialog',
    templateUrl: './users-visibility-dialog.component.html',
    styleUrls: ['./users-visibility-dialog.component.scss']
})
export class UsersVisibilityDialogComponent implements OnInit {

    columns: any[];
    positions = [];

    constructor(public dialogRef: MatDialogRef<UsersVisibilityDialogComponent>,
                private _dialog: MatDialog,
                private api: ApiService,
                public _helper: HelperService,
                public _translate: TranslateService,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        this.data.items.forEach(u => {
            u.positionsArray = [];
            if (u.position) {
                u.positionsArray = [u.position];
            }
        });

        this.getPositions();
    }

    ngOnInit(): void {
        //
    }

    getPositions() {
        this.api.Company.positions(
            this._helper.getUser().companyId,
            1,
            -159,
            'name',
            'asc',
            '').subscribe((res) => {
            this.positions = res.map(obj => {
                return {
                    id: obj.name,
                    value: obj.name
                };
            });
            if (this.data.isCustomTable) {
                this.createCustomUsersColumn();
                this.addColumns();
            } else {
                this.createUsersColumn();
            }
        });
    }

    addColumns() {
        if (this.data.type === 'pool' || this.data.type === 'survey') {
            this.columns.pop();
            this.columns = [...this.columns,
                {
                    index: 'startDate',
                    name: 'Common.RealisationFrom',
                    searchable: true,
                    sortable: false,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'DATE_RANGE_ARRAY',
                    valueRange: null,
                    timePicker: true,
                    arrayInside: 'assigned',
                    locale: {
                        format: 'YYYY-MM-DD HH:mm',
                        displayFormat: 'DD.MM.YYYY  HH:mm'
                    },
                    css: {
                        'min-width': '130px',
                        'width': '130px'
                    },
                    render: (v) => {
                        let dateDiv = '';
                        v.assigned.forEach(val => {
                            dateDiv += '<div class="m-h-20">' +
                                (val.startDate ? moment(val.startDate).format('DD.MM.YYYY, HH:mm') : '')
                                + '</div>';
                        });

                        return dateDiv;
                    }
                },
                {
                    index: 'endDate',
                    name: 'Common.RealisationTo',
                    searchable: true,
                    sortable: false,
                    visible: true,
                    value: '',
                    defaultValue: '',
                    type: 'DATE_RANGE_ARRAY',
                    valueRange: null,
                    timePicker: true,
                    arrayInside: 'assigned',
                    locale: {
                        format: 'YYYY-MM-DD HH:mm',
                        displayFormat: 'DD.MM.YYYY  HH:mm'
                    },
                    css: {
                        'min-width': '130px',
                        'width': '130px'
                    },
                    render: (v) => {
                        let dateDiv = '';
                        v.assigned.forEach(val => {
                            dateDiv += '<div class="m-h-20">' +
                                (val.endDate ? moment(val.endDate).format('DD.MM.YYYY, HH:mm') : '')
                                + '</div>';
                        });

                        return dateDiv;
                    }
                },
                {
                    index: 'method',
                    name: 'AssignDialog.SaveMethod',
                    type: 'ARRAY',
                    sortable: false,
                    searchable: true,
                    visible: true,
                    multiple: true,
                    isArrayInside: true,
                    arrayInside: 'assigned',
                    options: [
                        { id: 'user', value: 'AssignDialog.UserRecord' },
                        { id: 'position', value: 'AssignDialog.ByPosition' },
                        { id: 'group', value: 'AssignDialog.ByGroup' }
                    ],
                    css: {
                        'min-width': '300px',
                        'width': '300px'
                    },
                    render: (v) => {
                        let access = '';
                        v.assigned.forEach(val => {
                            access += (val.method === 'user' ? `<div class="cut-text m-h-20">${this._translate.instant('AssignDialog.UserRecord')}</div>` : '');
                            access += (val.method === 'position' ? `<div class="cut-text m-h-20"> ${this._translate.instant('AssignDialog.ByPosition')} ${val.name} </div>` : '');
                            access += (val.method === 'group' ? `<div class="cut-text m-h-20"> ${this._translate.instant('AssignDialog.ByGroup')} ${this._translate.instant(val.name)}</div>` : '');
                        });

                        return access;
                    }
                },
                {
                    index: 'isMandatory',
                    name: 'Pool.Mandatory',
                    value: '',
                    defaultValue: '',
                    type: 'ARRAY',
                    sortable: false,
                    searchable: true,
                    visible: true,
                    multiple: false,
                    isArrayInside: true,
                    arrayInside: 'assigned',
                    options: [
                        { id: true, value: 'Common.Yes' },
                        { id: false, value: 'Common.No' }
                    ],
                    css: {
                        'min-width': '100px',
                        'width': '100px',
                        'padding-right': '20px'
                    },
                    render: (v) => {
                        let access = '';
                        v.assigned.forEach(val => {
                            access += (val.isMandatory ?
                                '<div class="cut-text m-h-20">' + this._translate.instant('Common.Yes') + '</div>' :
                                '');
                        });

                        return access;
                    }
                }
            ];
        } else {
            this.columns = [
                ...this.columns,
                {
                    index: 'method',
                    name: 'AssignDialog.SaveMethod',
                    type: 'ARRAY',
                    sortable: false,
                    searchable: true,
                    visible: true,
                    multiple: true,
                    isArrayInside: true,
                    arrayInside: 'assigned',
                    options: [
                        { id: 'user', value: 'AssignDialog.UserRecord' },
                        { id: 'position', value: 'AssignDialog.ByPosition' },
                        { id: 'group', value: 'AssignDialog.ByGroup' }
                    ],
                    css: {
                        'min-width': '300px',
                        'width': '300px'
                    },
                    render: (v) => {
                        let access = '';
                        v.assigned.forEach(val => {
                            access += (val.method === 'user' ? `<div class="cut-text m-h-20">${this._translate.instant('AssignDialog.UserRecord')}</div>` : '');
                            access += (val.method === 'position' ? `<div class="cut-text m-h-20"> ${this._translate.instant('AssignDialog.ByPosition')} ${val.name} </div>` : '');
                            access += (val.method === 'group' ? `<div class="cut-text m-h-20"> ${this._translate.instant('AssignDialog.ByGroup')} ${this._translate.instant(val.name)}</div>` : '');
                        });
                        return access;
                    }
                }
            ];
        }
    }

    createUsersColumn() {
        this.columns = [
            {
                index: 'name',
                name: 'Common.FirstNameLastName',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'padding-left': '20px',
                    'min-width': '400px',
                    'width': '400px'
                }
            },
            {
                index: 'supervisor',
                name: 'Common.Supervisor',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '200px',
                    'width': '200px'
                }
            },
            {
                index: 'position',
                name: 'Common.Position',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                multiple: true,
                isArrayInside: true,
                arrayInside: 'positionsArray',
                options: [...this.positions],
                css: {
                    'min-width': '200px',
                    'width': '200px'
                },
                novalue: true
            },
            {
                index: 'method',
                name: 'Common.Access',
                type: 'ARRAY',
                sortable: false,
                searchable: true,
                visible: true,
                multiple: true,
                isArrayInside: true,
                arrayInside: 'assigned',
                css: {
                    'min-width': '300px'
                },
                class: 'ft-auto',
                options: [
                    { id: 'user', value: 'AssignDialog.UserRecord' },
                    { id: 'position', value: 'AssignDialog.ByPosition' },
                    { id: 'group', value: 'AssignDialog.ByGroup' }
                ],
                render: (v) => {
                    let access = '';
                    v.assigned.forEach(val => {
                        access += (val.method === 'user' ? `<div class="cut-text m-h-20">${this._translate.instant('AssignDialog.UserRecord')}</div>` : '');
                        access += (val.method === 'position' ? `<div class="cut-text m-h-20"> ${this._translate.instant('AssignDialog.ByPosition')} ${val.name} </div>` : '');
                        access += (val.method === 'group' ? `<div class="cut-text m-h-20"> ${this._translate.instant('AssignDialog.ByGroup')} ${this._translate.instant(val.name)}</div>` : '');
                    });

                    return access;
                }
            }
        ];
    }

    createCustomUsersColumn() {
        this.columns = [
            {
                index: 'name',
                name: 'Common.FirstNameLastName',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'padding-left': '20px',
                    'min-width': '200px',
                },
                class: 'ft-auto'
            },
            {
                index: 'supervisor',
                name: 'Common.Supervisor',
                searchable: true,
                sortable: true,
                visible: true,
                value: '',
                defaultValue: '',
                type: 'STRING',
                css: {
                    'min-width': '200px',
                    'width': '200px'
                }
            },
            {
                index: 'position',
                name: 'Common.Position',
                searchable: true,
                visible: true,
                sortable: true,
                value: '',
                defaultValue: '',
                type: 'ARRAY',
                multiple: true,
                isArrayInside: true,
                arrayInside: 'positionsArray',
                options: [...this.positions],
                css: {
                    'min-width': '200px',
                    'width': '200px'
                },
                novalue: true
            },
            {
                index: 'visibleFromDate',
                name: 'Common.VisibleFromDate',
                searchable: true,
                sortable: false,
                visible: this.data.type === 'training' || this.data.type === 'path',
                value: '',
                defaultValue: '',
                type: 'DATE_RANGE_ARRAY',
                valueRange: null,
                timePicker: false,
                arrayInside: 'assigned',
                locale: {
                    format: 'YYYY-MM-DD',
                    displayFormat: 'DD.MM.YYYY'
                },
                css: {
                    'min-width': '130px',
                    'width': '130px'
                },
                render: (v) => {
                    let dateDiv = '';
                    v.assigned.forEach(val => {
                        dateDiv += '<div class="m-h-20">' +
                            (val.visibleFromDate ? moment(val.visibleFromDate).format('DD.MM.YYYY') : '')
                            + '</div>';
                    });

                    return dateDiv;
                }
            },
            {
                index: 'expiryDate',
                name: 'Common.RealisationDate',
                searchable: true,
                sortable: false,
                visible: this.data.type !== 'category' && this.data.type !== 'gamification' && this.data.type !== 'task',
                value: '',
                defaultValue: '',
                type: 'DATE_RANGE_ARRAY',
                valueRange: null,
                timePicker: false,
                arrayInside: 'assigned',
                locale: {
                    format: 'YYYY-MM-DD',
                    displayFormat: 'DD.MM.YYYY'
                },
                css: {
                    'min-width': '130px',
                    'width': '130px'
                },
                render: (v) => {
                    let dateDiv = '';
                    v.assigned.forEach(val => {
                        dateDiv += '<div class="m-h-20">' +
                            (val.expiryDate ? moment(val.expiryDate).format('DD.MM.YYYY') : '')
                            + '</div>';
                    });

                    return dateDiv;
                }
            }
        ];
    }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { OnboardingList } from '../../onboarding-creator/onboarding-creator.interfaces';
import { FinishedOnboardingList } from '../../onboarding/onboarding.interfaces';

@Injectable({
    providedIn: 'root'
})
export class TasksService {
    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public addTask(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/task`, body, {headers});
    }

    public updateTask(taskId: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.put<any>(`${this.basePath}/task/${taskId}`, body, {headers});
    }

    public removeTask(taskId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/task/${taskId}`, {headers});
    }

    public getTask(taskId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/task/${taskId}`, {headers});
    }

    public duplicateTask(taskId: number, name: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/task/${taskId}/duplicate`, {name: name}, {headers});
    }

    public userChecklist(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/task/user-checklist`, {taskId: id},{ headers });
    }

    public assignedList(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/task/${id}/assigned`, { headers });
    }

    public getCommissionedTasks(pageNumber: any, pageSize: any, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/task/commissioned?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getTeamTasks(pageNumber: any, pageSize: any, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/task/team?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getOrganizationTasks(pageNumber: any, pageSize: any, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/task/organization?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public hasTask(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user-task/exist`, { headers });
    }

    public isCarer(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/task/is-carer`, { headers });
    }

    public getUserTasksTable(pageNumber: any, pageSize: any, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user-task/table?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public downloadUserTasksXls(params: string = '', columns: string): string {
        return `/user-task/my/xls?${params}&columns=${columns}`;
    }

    public downloadCommisionedTasksXls(params: string = '', columns: string): string {
        return `/task/commissioned/xls?${params}&columns=${columns}`;
    }

    public downloadTeamTasksXls(params: string = '', columns: string): string {
        return `/task/team/xls?${params}&columns=${columns}`;
    }

    public downloadOrganizationTasksXls(params: string = '', columns: string): string {
        return `/task/organization/xls?${params}&columns=${columns}`;
    }

    public getUserTask(userTaskId): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user-task/${userTaskId}`, { headers });
    }

    public getUserTasksTiles(showActive: boolean = false): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user-task/tiles?active=${showActive}`, { headers });
    }

    public changeTaskStatus(id: number, payload): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user-task/${id}/change-status`, payload,{ headers });
    }

    downloadTaskFiles(id: number): Observable<HttpResponse<Blob>> {
        // @ts-ignore
        // @ts-disable
        return this.http.get<HttpResponse<Blob>>(`${this.basePath}/user-task/${id}/files`, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    public getUserTasksComments(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user-task/${id}/comments`, { headers });
    }

    public getUserTasksHistory(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user-task/${id}/history`, { headers });
    }

    public addComment(id: number, payload): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user-task/${id}/add-comment`, payload,{ headers });
    }

    public removeComment(commentId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/user-task/comment/${commentId}`, {headers});
    }

    public updateComment(commentId: number, payload): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user-task/comment/${commentId}`, payload,{headers});
    }

    public getTaskDetails(taskId: number, pageNumber: any, pageSize: any, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/task/${taskId}/details?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getTaskTeamDetails(taskId: number, pageNumber: any, pageSize: any, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/task/${taskId}/team/details?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public downloadTaskDetailsXls(taskId: number, params: string = '', columns: string): string {
        return `/task/${taskId}/details/xls?${params}&columns=${columns}`;
    }

    public downloadTeamTaskDetailsXls(taskId: number, params: string = '', columns: string): string {
        return `/task/${taskId}/team/details/xls?${params}&columns=${columns}`;
    }

    public acceptUserTask(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user-task/${id}/accept`, {},{headers});
    }

    public rejectUserTask(id: number, reason): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user-task/${id}/reject`, {reason},{headers});
    }

    public acceptUserTaskBulk(id: number, payload): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/task/${id}/accept-bulk`, payload,{headers});
    }

    public rejectUserTaskBulk(id: number, payload): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/task/${id}/reject-bulk`, payload,{headers});
    }

}

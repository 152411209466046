<div class="stay-signed-in-dialog">
    <h1 mat-dialog-title tabIndex="0">
        {{'Profile.StaySignedInHeader' | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           *ngIf="!data.disableClose"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content>
        <form [formGroup]="form">
            <div>
                {{'Profile.StaySignedInDescription' | translate}}
            </div>
            <div>
                <mat-slide-toggle class="mt-3" color="primary" formControlName="disable">
                    {{'Profile.StaySignedInDoNotSHow' | translate}}
                </mat-slide-toggle>
            </div>
        </form>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <button (click)="save(false)" mat-button class="cancel-button">
            {{'Common.No' | translate}}
        </button>
        <button (click)="save(true)" mat-button color="primary"
                class="dolineo-button blue-button">{{'Common.Yes' | translate}}</button>
    </div>
</div>
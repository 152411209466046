import {Component, Inject, OnInit} from '@angular/core';
import {HelperService} from '../../../shared/services/helper.service';
import {ApiService} from '../../../api/api.service';
import {AuthService} from '../../../core/services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-stay-signed-in-dialog',
    templateUrl: './stay-signed-in-dialog.component.html',
    styleUrls: ['./stay-signed-in-dialog.component.scss']
})
export class StaySignedInDialogComponent implements OnInit {

    public form: FormGroup;

    constructor(
        private _helper: HelperService,
        private _api: ApiService,
        public dialogRef: MatDialogRef<ChangePasswordComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _translate: TranslateService,
        private _authService: AuthService,
        private formBuilder: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            disable: [false]
        });
    }


    save(value: boolean) {
        const body = {value, disable: this.form.get('disable').value};

        this._api.User.staySignedIn(this.data.id, body).subscribe((resp) => {
            this.dialogRef.close({ updated: true });
        });
    }
}

import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Onboarding} from '../../../preboarding/preboarding.interfaces';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
  selector: 'preboarding',
  templateUrl: './preboarding.component.html',
  styleUrls: ['./preboarding.component.scss']
})

export class PreboardingComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  widget: any;
  certificatesCount: number = 0;
  data: any;
  waiting = true;
  viewPie: any[] = [100, 100];
  colorScheme: any;
  preboarding: Onboarding = null;
  pieChartData = [];
  whiteBackground: boolean = false;
  isMobile: boolean;
  firstNotCompletedElementName = '';

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private _translate: TranslateService,
              private _router: Router,
              private deviceService: DeviceDetectorService) {
    this.isMobile = deviceService.isMobile();
  }

  ngOnInit() {
    if(!this.preview){
      this.preparePreboardingData();
    }else{
      this.prepareWidgetData()
      this.waiting = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        sizeClass: this.isMobile ? 'a' : (null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize),
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
        data: this.preview ? this.getExampleData() : this.data
      };
      this.whiteBackground = this.widget.backgroundColor.toLowerCase() === 'ffffff';
      this.colorScheme = {
        domain: [
          !this.whiteBackground ? '#' + this.widget.elementsColor : '#7E6AF1',
          !this.whiteBackground ? this.hexToRGB('#' + this.widget.backgroundColor,0.4) : '#dfdefc'
        ]
      };
    }
  }
  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  preparePreboardingData(){
    this._api.Dashboard.getOnboardingData(true).subscribe((res) => {
      this.preboarding = { ...res }
      this.preboarding.sections.forEach(section => {
        section.elements.forEach(element => {
          if(this.firstNotCompletedElementName === '' && !element.finished){
            this.firstNotCompletedElementName = element.name;
          }
        })
      });

      const progress = this.preboarding.completedElementsCount / this.preboarding.totalElementsCount * 100
      this.pieChartData = [
        {
          name: progress.toString(),
          value: progress,
        },
        {
          name: (100 - progress - 0.001).toString(),
          value: 100 - progress - 0.001,
        }
      ]
        this.data = res;
        this.waiting = false;
        this.prepareWidgetData()
    });
  }

  getExampleData(){
    const progress = 50;
    this.pieChartData = [
      {
        name: progress.toString(),
        value: progress,
      },
      {
        name: (100 - progress - 0.001).toString(),
        value: 100 - progress - 0.001,
      }
    ]
    this.firstNotCompletedElementName = this._translate.instant('DashboardWidgetPreboarding.Example');
    return {
      name: this._translate.instant('DashboardWidgetPreboarding.Example'),
      completedElementsCount: 1,
      totalElementsCount: 2
    }
  }
}
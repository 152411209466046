<div class="path-training-tile" *ngIf="data" id="tile">
    <div class="above-tile">
        <div class="above-tile__red"
             *ngIf="data.isMandatory === true || data.isMandatory === 'true' || data.isMandatory === '1' || data.isMandatory === 1">
            {{(data.idPath ? 'Path.MandatoryBadge' : 'Training.MandatoryBadge') | translate}}
        </div>
        <div class="above-tile__green" *ngIf="data.isNew === 1">
            {{'Common.Novelty' | translate}}
        </div>
    </div>

    <div class="path-training-tile__image"
         (click)="data.idPath? previewPath(): previewTraining()">
        <img [src]="data.photoUrl" alt="photo" loading="lazy">

        <div class="dolineo-icon dolineo-icon-white" *ngIf="data.isDolineo">
            <img src="/assets/icons/dolineo.svg" loading="lazy" alt="dolineo">
        </div>
    </div>

    <div class="path-training-tile__container">
        <div class="path-training-tile__container__times">
            <div style="display: flex">
                <span class="calendar" *ngIf="!data.finishedDate">
                    <img src="/assets/icons/calendar2.svg" alt="calendar2"
                         loading="lazy"
                         matTooltip="{{'Common.RealisationDate' | translate}}"
                         matTooltipPosition="below"
                         matTooltipClass='below-top'>
                    <span [ngStyle]="{'color': data.redColor? 'red' : 'black' }">
                        {{ data.expiryDate }}
                    </span>
                </span>

                <span class="finish-green" *ngIf="data.finishedDate">
                    <img src="assets/icons/checked-without-bg.svg"
                         loading="lazy"
                         alt="checked-without-bg">
                    <span>{{ 'Common.FinishedDate' | translate | uppercase }}</span>
                </span>

                <span class="clock" *ngIf="data.durationTime">
                    <img src="/assets/icons/clock.svg" alt="clock"
                         loading="lazy"
                         matTooltip="{{'PathCreator.Duration' | translate}}"
                         matTooltipPosition="below"
                         matTooltipClass='below-top'>
                    <span>{{ data.durationTime }}</span>
                </span>

                <span class="heart" *hasAccess="[]; perms ['user:favourites']">
                    <img [src]="(data.isFavourite? 'assets/icons/heart-like.svg': 'assets/icons/heart-empty.svg')"
                         loading="lazy"
                         (click)="updateLike(data)"
                         alt="heart"
                         matTooltip="{{(data.isFavourite?'Favourite.RemoveFromFavorites': 'Favourite.AddToFavourite')  | translate}}"
                         matTooltipPosition="below"
                         matTooltipClass='below-top'>
                    <span *ngIf="data.favouritesCount > 0">{{ data.favouritesCount }}</span>
                </span>
            </div>

            <div class="three-dots"
                 *ngIf="showMore && ((canEdit && !data.isDolineo) ||
                          canSelfAssign ||
                           canAssign)">
                <button mat-button [matMenuTriggerFor]="more"
                        type="button"
                        matTooltip="{{'Common.More' | translate}}"
                        matTooltipPosition="below"
                        matTooltipClass='below-top'>
                    <img src="/assets/icons/dot_v.svg"
                         loading="lazy"
                         alt="dot_v">
                </button>
                <mat-menu #more="matMenu">
                    <button mat-menu-item (click)="goEdit()" *ngIf="canEdit && !data.isDolineo">
                        {{(data.idPath ? 'Path.EditPath' : 'Training.EditTraining') | translate}}
                    </button>
                    <button mat-menu-item (click)="updateSelf(data)" *ngIf="canSelfAssign">
                        {{(data.assignedDate ? 'Training.SignOff' : 'Common.AssignToYourself') | translate}}
                    </button>
                    <button mat-menu-item (click)="assign(data)" *ngIf="canAssign">
                        {{'Common.UserRecords' | translate}}
                    </button>
                    <button mat-menu-item class="group-user-records-button" (click)="data.idPath? assignPathsToUsers(data) : assignTrainingsToUsers(data)" *ngIf="canAssign">
                        {{'Common.GroupUserRecords' | translate}}
                    </button>
                </mat-menu>
            </div>
        </div>

        <div class="path-training-tile__container__title">
            <span (click)="data.idPath? previewPath(): previewTraining()"
                  [innerHTML]="data.name | yellowSearchText : searchText | safe: 'html'"></span>
        </div>

        <div class="path-training-tile__footer"
             [ngStyle]="{'justify-content': data.totalElementsCount? 'space-between': 'flex-end'}">
            <div class="path-training-tile__progress" *ngIf="data.totalElementsCount">
                <div class="path-training-tile__progress__bar">
                    <div class="progress__bar" [style.width]="data.bar + '%'"></div>
                </div>
                <span> {{data.completedTrainingsCount}} / {{data.totalElementsCount}} </span>
            </div>
            <span (click)="runPath()" class="continue" *ngIf="data.idPath"
                  [ngStyle]="{'font-size': helper.getUser().interfaceLanguageCode == 'de' ? '11px' : '12px'}"
                  >
                {{((data.startedDate && !data.finishedDate) || (data.finishedDate && data.completedTrainingsCount != data.totalElementsCount && data.completedTrainingsCount > 0) ? 'Common.Continue' :
                (data.finishedDate ? 'Common.SeeAgain' : 'Common.Run')) | translate | uppercase}}
            </span>

            <span (click)="runTraining()" class="continue"
                  *ngIf="data.idTraining && (data.allowedAttemptsCount === 0 || (data.allowedAttemptsCount > 0 && data.totalUserAttempts < data.allowedAttemptsCount))">
                {{(data.startedDate && !data.finishedDate ? 'Common.Continue' : (data.finishedDate ? 'Common.SeeAgain' : 'Common.Run')) | translate | uppercase}}
            </span>
        </div>
    </div>
</div>

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { Router } from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { parseError } from '../../shared/helpers';
import { ApiService } from '../../api/api.service';
import { HelperService } from '../../shared/services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppLocaleService } from '../../core/services/locale.service';
import { environment } from '../../../environments/environment';
import { interval, Subscription } from 'rxjs';
import { ClientLoginPage } from '../../shared/intefaces/client-login-page';
import { IUserLogin } from '../../shared/intefaces/interfaces';
import { ToastrService } from 'ngx-toastr';
import { RecaptchaComponent } from 'ng-recaptcha';
import { filter } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import {StaySignedInDialogComponent} from '../../profile/dialogs/stay-signed-in-dialog/stay-signed-in-dialog.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    @ViewChild(RecaptchaComponent) captchaRef: RecaptchaComponent;
    loginForm: FormGroup;

    currentLang: string;
    layout: ClientLoginPage = null;
    subDomain = null;
    public apiUrl = environment.filesPath;
    subscriptions: Subscription = new Subscription();
    checkForUpdateInterval = interval(1000);

    public header = '';
    public description = '';
    public policyUrl = '';
    public termsUrl = '';
    public integrationBtn = '';
    public reCaptchaSiteKey = environment.reCaptchaSiteKey;
    public useRecaptcha: boolean = environment.useRecaptcha;
    public platform = 'web';
    logoUrl: string;
    logoAlt: string;

    auth2FA = false;
    codeForm: FormGroup;
    timerDisplay: string = '10:00'; // Default timer value
    countdown: number = 600; // 10 minutes in seconds
    timerInterval!: any;
    auth2FAError = null;
    auth2FAVerified = false;
    resendClicked = false;
    loginLockedTill = null;

    get codeControls(): FormArray {
        return this.codeForm.get('code') as FormArray;
    }

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                public authService: AuthService,
                private _api: ApiService,
                private _helper: HelperService,
                private _dialog: MatDialog,
                private _translate: TranslateService,
                private _localeService: AppLocaleService,
                private _toastrService: ToastrService,
                private swUpdate: SwUpdate) {

        if (environment.appName === 'dolineo') {
            this.logoUrl = 'assets/images/logo/logo_dolineo.svg';
            this.logoAlt = 'dolineo_icon';
        } else if (environment.appName === 'ontigo') {
            this.logoUrl = 'assets/images/logo/logo_ontigo.svg';
            this.logoAlt = 'ontigo_icon';
        }

        if (this.router.url.includes('app=mobile')) {
            localStorage.setItem('device', 'mobileApp');
        }
        if (localStorage.getItem('device') === 'mobileApp') {
            this.useRecaptcha = false;
            window.dispatchEvent(new CustomEvent('loginload', {detail: document.location.href} ))
        }

        if (this.router.url.includes('version=android')){
            localStorage.setItem('devicePlatform', 'android');
        } else if (this.router.url.includes('version=ios')) {
            localStorage.setItem('devicePlatform', 'ios');
        }

        if (localStorage.getItem('devicePlatform') === 'android'){
            this.platform = 'android';
        } else if (localStorage.getItem('devicePlatform') === 'ios') {
            this.platform = 'ios';
        }

        if (this.swUpdate) {
            this.swUpdate.available.subscribe(event => {
                this.swUpdate.activateUpdate().then(() => {
                    window.location.reload();
                });
            });
        }

        this.checkForUpdate();
        this.startedValue();
        this.initCodeForm();
    }

    initCodeForm() {
        this.codeForm = this.formBuilder.group({
            code: this.formBuilder.array(new Array(6).fill('').map(() => new FormControl('', [Validators.required])))
        });
    }

    startedValue() {
        this._dialog.closeAll();

        this.subDomain = window.location.hostname.replace(environment.domain, '').replace('.', '').toLowerCase();

        this.subscriptions.add(this._api.layout.pipe(
            filter(value => value !== null)
        ).subscribe((value: ClientLoginPage) => {
            this.layout = value;

            const code = this.router.url
                .replace('/login?app=mobile&version=android', '')
                .replace('/login?app=mobile&version=ios', '')
                .replace('/login', '');


            if (this.router.url.includes('sso=1')) {
                this.openIntegrationPage();
                return;
            }

            if (code.includes('state=test') === true) {
                this.router.navigateByUrl('app/integration/oauth' + code);
            } else {
                this.setLang(this.getBrowserLang());
                if (code === '') {
                    this.autoLogin();
                } else if (this.subDomain) {
                    this.authService.integrationLogin(code, this.subDomain).subscribe((date) => {
                        this.authService.trySetSession(date.email, date.access_token, date.token_type, date.refresh_token, date.scope, date.aki, date.sak, date.st);
                        this.getUserDetails();
                    })
                }
            }
        }));
    }

    checkForUpdate() {
        if (this.swUpdate) {
            if (this.swUpdate.isEnabled) {
                this.swUpdate.checkForUpdate().then(() => {
                    console.log('Checking for update');
                });
            }
        }
    }

    ngOnInit() {
        this.initForm();
        setTimeout(() => {
            this.authService.correctResetPassword = false;
            this.authService.correctActivationPassword = false;
        }, 5000);
    }

    getBrowserLang() {
        const selectedLang = this._helper.getUser() && this._helper.getUser().id ? this._helper.getUser().interfaceLanguageCode : localStorage.getItem('startLanguage');
        if (!selectedLang) {
            let language: string = window.navigator.language.split('-')[0];
            if (environment.appName === 'ontigo') {
                language = 'en';
            } else if (!language) {
                language = 'pl';
            }
            return language;
        }
        return selectedLang;
    }

    autoLogin() {
        if (this._helper.getUser() && this._helper.getUser().id) {
            if (this._helper.isSuperAdmin()) {
                this.router.navigate(['app/superadmin/clients']);
            } else if (this._helper.isSysAdmin() && this._helper.getUser().accessLevels.length) {
                this.router.navigate(['app/welcome-sys-admin']);
            } else if (this._helper.isSysAdmin() && !this._helper.getUser().accessLevels.length) {
                this.router.navigate(['app/no-permission-sys-admin']);
            } else {
                this.router.navigate([this._helper.getHomePageUrl()]);
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        // clearInterval(this.checkForUpdateInterval);
    }

    initForm() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required]],
            password: ['', Validators.required],
            captcha: ['']
        });
    }

    submit({ value, valid }: { value: IUserLogin, valid: boolean }) {
        this.loginLockedTill = null;
        this.checkForUpdate();
        this.authService.correctResetPassword = false;
        value = { ...value, subdomain: this.subDomain, platform: this.platform }
        this.subscriptions.add(this.authService.login(value).subscribe((status: boolean) => {
            if (status) {
                this.getUserDetails();
            } else {
                this.captchaRef.reset();
                this._toastrService.error(this._translate.instant('Err.ErrRetry'))
            }
        }, (err: any) => {
            if (err.error.details === '2FA') {
                this.auth2FA = true;
                this.startTimer();
            } else if (err.error.details && err.error.details.includes('Login locked')) {
                this.loginLockedTill = this._translate.instant('Login.AuthLockedTill').replace('{{value}}', err.error.details.split(':')[1]);
                $('.login-locked').slideDown(200);
            } else {
                if (this.platform === 'web') {
                    this.captchaRef.reset();
                }
                const parsedError = parseError(err);

                if (parsedError === 'Invalid username and password combination') {
                    $('.password-error').slideDown(200);
                } else if ((parsedError === 'User account is disabled' || parsedError === 'Forbidden')) {
                    $('.account-disabled').slideDown(200);
                } else {
                    $('.password-error').slideDown(200);
                }
            }
        }));
    }

    getUserDetails(): void {
        this.subscriptions.add(this._api.User.self().subscribe((user) => {
            this._helper.setUser(user);
            this._translate.setDefaultLang(user.interfaceLanguageCode);
            this._translate.use(user.interfaceLanguageCode);
            this._translate.reloadLang(user.interfaceLanguageCode);
            this._localeService.setLocale(user.interfaceLanguageCode);
            this.authService.clearOldSession();
            const url = sessionStorage.getItem('url');

            if (url) {
                this.router.navigate([url]).then(() => {
                    sessionStorage.removeItem('url')
                })
            } else {
                if (user.roles.indexOf('ROLE_SUPERADMIN') > -1) {
                    this.router.navigate(['app/superadmin/clients']);
                } else if (user.roles.indexOf('ROLE_SYSTEMADMIN') > -1 && user.accessLevels.length) {
                    this.router.navigate(['app/welcome-sys-admin']);
                } else if (user.roles.indexOf('ROLE_SYSTEMADMIN') > -1 && !user.accessLevels.length) {
                    this.router.navigate(['app/no-permission-sys-admin']);
                } else {
                    this.router.navigate([this._helper.getHomePageUrl()]);
                }
            }

        }, (err) => {
            if (err.error && err.error.error === 'access_denied' && err.error.error_description === 'User account is disabled') {
                $('.account-disabled').slideDown(200);
            }
        }));
    }

    setLang(lang: string) {
        if (this.currentLang !== lang) {
            this.currentLang = lang;
            this._translate.setDefaultLang(lang);
            this._translate.use(lang);
            this.subscriptions.add(this._translate.reloadLang(lang).subscribe(() => {
                this._helper.createTitle(['Client.LoginPage'])
            }));
            localStorage.setItem('startLanguage', lang);
            this._localeService.setLocale(lang);
            if (this.layout) {
                this.header = this.layout.headers[lang] ? this.layout.headers[lang] : '';
                this.description = this.layout.descriptions[lang] ? this.layout.descriptions[lang] : ''
                this.integrationBtn = this.layout.authRequired && this.layout[lang + 'Btn'] ? this.layout[lang + 'Btn'] : ''
            }
        }
    }

    reCaptchaResolved(captchaResponse: string) {
        this.loginForm.get('captcha').setValue(captchaResponse);
        this.submit(this.loginForm);
    }

    openIntegrationPage() {
        window.open(this.layout.authEndpoint, '_self');
    }

    onInput(event: any, index: number): void {
        const input = event.target as HTMLInputElement;
        const nextInput = input.nextElementSibling as HTMLInputElement;

        if (input.value.length === 1 && nextInput) {
            nextInput.focus();
        }
    }

    onKeyPress(event: KeyboardEvent, index: number) {
        const input = event.target as HTMLInputElement;
        const inputs = Array.from(document.querySelectorAll('.code-container--inputs input')) as HTMLInputElement[];

        console.log('asdasd', event.key);

        if (event.key === 'Backspace') {
            if (!input.value && index > 0) {
                inputs[index - 1].focus();
            } else {
                input.value = ''; // Clear current input
            }
        } else if (event.key === 'Delete') {
            input.value = ''; // Clear current input
            if (index < inputs.length - 1) {
                inputs[index + 1].focus(); // Move to next input
            }
        } else if (event.key === 'ArrowLeft') {
            if (index > 0) {
                inputs[index - 1].focus(); // Move to the previous input
            }
        } else if (event.key === 'ArrowRight') {
            if (index < inputs.length - 1) {
                inputs[index + 1].focus(); // Move to the next input
            }
        }
    }

    onPaste(event: ClipboardEvent): void {
        const clipboardData = event.clipboardData?.getData('text') || '';
        if (clipboardData.length === 6) {
            this.codeControls.controls.forEach((control, i) => {
                control.setValue(clipboardData[i] || '');
            });
        }
    }

    startTimer(): void {
        this.timerInterval = setInterval(() => {
            if (this.countdown > 0) {
                this.countdown--;
                this.updateTimerDisplay();
            } else {
                clearInterval(this.timerInterval);
            }
        }, 1000);
    }

    updateTimerDisplay(): void {
        const minutes = Math.floor(this.countdown / 60);
        const seconds = this.countdown % 60;
        this.timerDisplay = `${this.padZero(minutes)}:${this.padZero(seconds)}`;
    }

    padZero(value: number): string {
        return value < 10 ? `0${value}` : value.toString();
    }

    checkCode() {
        this.auth2FAError = null;
        const code = this.codeControls.value.join('');
        this.authService.auth2FACheckCode(this.loginForm.get('email').value, code).subscribe(res => {
            this.auth2FAVerified = true;
            if (this.useRecaptcha) {
                this.captchaRef.execute();
            } else {
                this.submit(this.loginForm);
            }
        }, err => {
            if (err.error.details.includes('Login locked')) {
                this.auth2FAError = this._translate.instant('Login.AuthCodeLockedTill').replace('{{value}}', err.error.details.split(':')[1]);
            } else {
                this.auth2FAError = this._translate.instant('Login.Auth2FAInvalidCode');
            }

        });
    }

    resendCode() {
        if (this.countdown > 0) {
            return;
        }
        this.auth2FAError = null;
        if (this.resendClicked) {
            return;
        }
        clearInterval(this.timerInterval);
        this.countdown = 0;
        this.timerDisplay = '10:00';
        this.authService.auth2FAResendCode(this.loginForm.get('email').value).subscribe(res => {
            this.countdown = 600;
            this.startTimer();
        })

    }
}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrgFeedbacksList } from '../../organization/interfaces/feedback.interface';

@Injectable()
export class OrganizationService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public users(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string = ''): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${id}/organization/user?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public usersXLS(id: number, sortBy: string, order: string, params: string, columns: string): string {
        return `/company/${id}/organization/user/xls?sortBy=${sortBy}&order=${order}&${params}&columns=${columns}`;
    }

    public paths(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${id}/organization/path?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public pathsUsers(id: number, pathId: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string, userId: number = 0): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${id}/organization/path/${pathId}/users?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}&userId=${userId}`, {headers});
    }

    public pathsUsersXls(companyId: number, pathId: number, params: string, userId: number = 0): string {
        return `/company/${companyId}/organization/path/${pathId}/users/xls?${params}&userId=${userId}`;
    }

    public getPathName(companyId: number, pathId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${companyId}/organization/path/${pathId}`, { headers });
    }

    public getTrainingName(companyId: number, trainingId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${companyId}/organization/training/${trainingId}`, { headers });
    }

    public pools(type: any, id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${id}/organization/pools?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public poolsGeneralReport(type: any, id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${id}/organization/general?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public downloadPoolsAnswers(type: any, companyId: number, id: number): string {
        return `/company/${companyId}/organization/pools-answers/${id}`;
    }

    public poolsXls(type: any, id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): string {
        let url = `/company/${id}/organization/pools?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`;
        if (params !== '') {
            url += '&';
        }
        url += 'xls=1';
        // @ts-ignore
        // @ts-disable
        return `${url}`;
    }

    public poolsGeneralXls(type: any, id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string, body: any = {}): string {
        let url = `/company/${id}/organization/general?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`;
        if (params !== '') {
            url += '&';
        }
        url += 'xls=1';
        return url;
    }

    public downloadPoolsXls(companyId: number, params: string): string {
        return `/company/${companyId}/organization/pools?xls=1${params}`;
    }

    public surveys(companyId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${companyId}/organization/surveys?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getSurveysXLS(companyId: number, sortBy: string, order: string, params: string): string {
        return `/company/${companyId}/organization/surveys/xls?sortBy=${sortBy}&order=${order}&${params}`;
    }

    public trainings(id: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string, userId?: number): Observable<any> {
        const headers = this.defaultHeaders;
        params = userId? `params&&userId=${userId}` : params;
        return this.http.get<any>(`${this.basePath}/company/${id}/organization/training?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public trainingsUsers(id: number, trainingId: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string, userId: number = 0): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${id}/organization/training/${trainingId}/users?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}&userId=${userId}`, {headers});
    }

    public trainingsUsersXls(companyId: number, trainingId: number, params: string, userId:number = 0): string {
        return `/company/${companyId}/organization/training/${trainingId}/users/xls?${params}&userId=${userId}`;
    }

    public trainingsInPath(idCompany: number, pathId: number, userId: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/${idCompany}/organization/path/${pathId}/elements?userId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public trainingsInPathXLS(idCompany: number, pathId: number, userId: number, params: string): string {
        return `/company/${idCompany}/organization/path/${pathId}/elements/xls?userId=${userId}&${params}`;
    }

    public certificates(sortBy: string, order: string, pageNumber: any, pageSize: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/company/organization/certificate-list?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public certificatesXLS(sortBy: string, order: string, params: string, columns: string): string {
        return `/company/organization/certificate/xls?sortBy=${sortBy}&order=${order}&columns=${columns}&${params}`;
    }

    public certificateZIP(): string {
        return `/company/organization/certificates/zip`;
        // const headers = this.defaultHeaders;
        // // @ts-ignore
        // // @ts-disable
        // return this.http.post<HttpResponse<Blob>>(`${this.basePath}/company/organization/certificates/zip`, {ids, headers}, {observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true'});
    }

    public createCertificateTemplate(formParams: any): Observable<any> {
        return this.http.post<any>(`${this.basePath}/certificate-template`, formParams);
    }

    public downloadXls(companyId: number, type: 'path' | 'training' | 'certificate' | 'user'| 'feedbacks', params: string): string {
        return `/company/${companyId}/organization/${type}/xls?${params}`;
    }

    public downloadFeedbackQuestionnaireAnswersXls(companyId: number, questionnaireId: number): string {
        return `/company/${companyId}/organization/feedbacks-answers/${questionnaireId}`;
    }

    public downloadCertificatesZip(companyId: number, ids: any[]): Observable<HttpResponse<Blob>> {
        const formParams = new FormData();
        formParams.append('ids', JSON.stringify(ids));

        // @ts-ignore
        // @ts-disable
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/company/${companyId}/organization/certificates/zip`, formParams, {observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true'});
    }

    public removeAttemptPool(poolId: any): Observable<any> {
        return this.http.delete<any>(`${this.basePath}/user/pools/attempt/${poolId}`);
    }

    public feedbacks(companyId: number, pageNumber: number, pageSize: number, sortBy: string, order: string, params: string): Observable<OrgFeedbacksList> {
        const headers = this.defaultHeaders;
        return this.http.get<OrgFeedbacksList>(`${this.basePath}/company/${companyId}/organization/feedbacks?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, {headers});
    }

    public getCategories(companyId: number, body, sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/company/${companyId}/organization/pools/categories?sortBy=${sortBy}&order=${order}&${params}`,body, { headers });
    }

    public getCategoriesToSelect(companyId: number, poolId: number, translations): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/company/${companyId}/organization/pools/${poolId}/company-categories`,translations, { headers });
    }

    public getGlobalCategoriesToSelect(poolId: number, translations): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/company/organization/pools/${poolId}/company-categories`,translations, { headers });
    }
}

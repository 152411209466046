<h1 mat-dialog-title tabIndex="0">
    {{ 'Tasks.DuplicateTask' | translate }}: {{ data.name }}
    <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
       matTooltip="{{'Common.Close' | translate}}"
       matTooltipPosition="below"
       matTooltipClass='below-top'
       (click)="dialogRef.close()"></i>
</h1>

<div mat-dialog-content>
    <p [innerHTML]="'Tasks.DuplicateTaskDesc' | translate "></p>

    <div [formGroup]="form">
        <div class="w-100 mt-4">
            <mat-form-field class="w-100">
                <input matInput
                       appUpperCaseFirstLetter
                       placeholder="{{ 'Tasks.TaskName' | translate}} *"
                       formControlName="name"
                       maxlength="60">
                <mat-hint align="end">{{ this.form.get('name').value?.length || 0}} / 60</mat-hint>
                <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate}}</mat-error>
            </mat-form-field>
        </div>
    </div>

</div>

<div mat-dialog-actions class="dialog-footer">
    <button (click)="dialogRef.close(false)" mat-button
            class="cancel-button">{{ 'Common.Cancel' | translate }}</button>
    <button mat-button color="primary" (click)="save()"
            class="dolineo-button blue-button">{{ 'Common.IConfirm' | translate }}</button>
</div>
import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import {RequestInterface} from '../../intefaces/pagination.interface';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {getActivityName} from '../../helpers';

@Component({
  selector: 'missed-deadlines',
  templateUrl: './missed-deadlines.component.html',
  styleUrls: ['./missed-deadlines.component.scss']
})

export class MissedDeadlinesComponent implements OnInit, OnChanges {
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  widget: any;
  waiting = true;
  columns: any[] = [];
  tableItems: any[] = [];
  isMobile: boolean;
  types: any[];

  public request: RequestInterface = {
    sort: {
      field: 'id',
      direction: 'DESC'
    },
    pagination: {
      pageNumber: 1,
      pageSize: 50,
      totalElementsCount: 0,
      totalPages: 0
    },
    waitingForData: false
  };

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private _router: Router,
              private _translate: TranslateService,
              private deviceService: DeviceDetectorService) {
    this.isMobile = deviceService.isMobile();
  }

  ngOnInit() {
    this.setTypes();
    this.createTable();
    if(!this.preview){
      this.getData();
    }else{
      this.getExampleData();
      this.prepareWidgetData()
      this.waiting = false;
    }
  }

  setTypes() {
    this.types = [
      { id: 'training', value: 'Activities.Training' },
      { id: 'path', value: 'Activities.Path' },
      { id: 'test', value: 'Activities.Test' },
      { id: 'survey', value: 'Activities.Survey' },
      { id: 'onboarding', value: 'Common.onboarding' },
      { id: 'preboarding', value: 'Common.preboarding' },
      { id: 'gamification', value: 'Common.gamification' },
    ];
  }

  getData(filter = ''): void {
    this.request.waitingForData = true;
    if(this.preview){
      this.request.pagination.totalElementsCount = 5;
      this.request.pagination.totalPages = 1;
      this.request.waitingForData = false;
    }else{
      this._api.Dashboard.getMissedDeadlinesData().subscribe((res) => {
        this.tableItems = res.elements;
        this.waiting = false;
        this.prepareWidgetData()
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        sizeClass: null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize,
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
      };
    }
  }

  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  getExampleData(){

    const tableItems = [];
    tableItems.push(
        {
          user: this._translate.instant('DashboardWidgetExample.UserName1'),
          name: this._translate.instant('DashboardWidgetExample.Name1'),
          daysAfterDeadline: 7,
          avatarPath: '',
          type: 'onboarding'
        },
        {
          user: this._translate.instant('DashboardWidgetExample.UserName2'),
          name: this._translate.instant('DashboardWidgetExample.Name2'),
          daysAfterDeadline: 7,
          avatarPath: '',
          type: 'gamification'
        },
        {
          user: this._translate.instant('DashboardWidgetExample.UserName3'),
          name: this._translate.instant('DashboardWidgetExample.Name3'),
          daysAfterDeadline: 5,
          avatarPath: '',
          type: 'preboarding'
        },
        {
          user: this._translate.instant('DashboardWidgetExample.UserName4'),
          name: this._translate.instant('DashboardWidgetExample.Name4'),
          daysAfterDeadline: 3,
          avatarPath: '',
          type: 'onboarding'
        },
        {
          user: this._translate.instant('DashboardWidgetExample.UserName5'),
          name: this._translate.instant('DashboardWidgetExample.Name5'),
          daysAfterDeadline: 2,
          avatarPath: '',
          type: 'onboarding'
        },
    );
    this.tableItems = tableItems;
  }

  createTable() {
    this.columns = [
      {
        index: 'name',
        name: 'MissedDeadlines.ElementName',
        searchable: true,
        sortable: true,
        value: '',
        defaultValue: '',
        visible: true,
        type: 'STRING',
        css: {
          'min-width': '200px',
          'padding-left': '20px',
          'padding-right': '20px'
        },
        class: 'ft-auto',
      },
      {
        index: 'daysAfterDeadline',
        name: 'MissedDeadlines.DaysAfterDeadline',
        searchable: true,
        sortable: true,
        visible: true,
        value: '',
        defaultValue: '',
        valueRange: null,
        type: 'NUMBER',
        timePicker: false,
        hasMinutes: false,
        css: {
          'min-width': '200px',
          'width': '150px',
          'padding-right': '20px'
        },
        render: (v) => {
          return v.daysAfterDeadline + ' ' + this._translate.instant('MissedDeadlines.DaysAfterDeadline');
        }
      },
      {
        index: 'avatarPath',
        name: '',
        searchable: false,
        sortable: false,
        value: '',
        defaultValue: '',
        visible: true,
        type: 'AVATAR',
        css: {
          'padding-left': '10px',
          'min-width': '45px',
        },
        userName: (v) => {
          return v.userName;
        },
        size: () => {
          return 28;
        },
        isActive: () => {
          return false
        },
      },
      {
        index: 'type',
        name: 'MissedDeadlines.ElementType',
        searchable: true,
        sortable: true,
        value: '',
        defaultValue: '',
        visible: true,
        type: 'ARRAY',
        multiple: true,
        options: [...this.types],
        css: {
          'min-width': '150px',
          'padding-left': '20px',
          'padding-right': '20px'
        },
        render: (v) => {
          return '<span class="type '+ v.type +'">' + this._translate.instant(getActivityName(v.type)) + '</span>';
        }
      },
    ]
  }
}
<div class="widget-container"
     *ngIf="items && defaultItems"
     [ngClass]="('widget-container-favorite' + ' widget-container-size-' + (setSize ? widget.sizeClass : '') + (preview ? ' no-overflow-table' : ''))"
     [style.background]="'#ffffff'"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name">{{ 'DashboardCreator.Tasks' | translate}}</div>
    <div class="widget-container__filters">
        <div class="tiles-filters">
            <button mat-button [matMenuTriggerFor]="status" style="color: #8E94A0; text-align: right;">
                <img style="margin-right: 10px;" src="/assets/icons/filter.svg" alt="filter" class="mr-3">
                <span style="width: 70px; display: inline-block;">{{currentObject.name | translate}}</span>
            </button>
            <mat-menu #status="matMenu">
                <button mat-menu-item *ngFor="let filter of filters"
                        class="widget-tasks-mat-menu"
                        (click)="setFilter(filter)">
                    <img src="/assets/icons/checked.svg" alt="sort" *ngIf="filter.name === currentObject.name">
                    <span [style.padding-left]="filter.name === currentObject.name? '2px': '22px'">{{ filter.name | translate }}</span>
                </button>
            </mat-menu>
        </div>
    </div>

    <div class="widget-container__content">
        <div class="widget-container__content--tasks">
            <app-data-grid *ngIf="filteredTableItems.length > 0"
                    [columns]="columns"
                           [items]="filteredTableItems"
                           [request]="request"
                           (loadData)="getData()"
                           [noTabs]="false"
                           [selection]="false"
                           [hideHead]="true">
            </app-data-grid>
            <div class="widget-container__content--empty-status" *ngIf="filteredTableItems.length === 0">
                <div class="widget-container__content--empty-status--text">{{ 'DashboardWidgetTasks.IsEmptyText1' | translate}}</div>
                <div class="widget-container__content--empty-status--text">{{ 'DashboardWidgetTasks.IsEmptyText2' | translate}}</div>
                <img style="margin-right: 10px;" src="/assets/images/dashboard/clock.svg" alt="filter" class="mr-3">
            </div>
        </div>
    </div>
</div>


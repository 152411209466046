import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrgFeedbacksList } from '../../organization/interfaces/feedback.interface';

@Injectable()
export class OrganizationReportService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public report(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string = '', team = false): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/organization-report?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&team=${team}&${params}`, {headers});
    }

    public reportXLS(sortBy: string, order: string, params: string, team = false): string {
        return `/organization-report/xls?sortBy=${sortBy}&order=${order}&team=${team}&${params}`;
    }


}

import { Injectable } from '@angular/core';
import { UserSelfInterface } from '../intefaces/user-self.interface';
import { environment } from '../../../environments/environment';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AccessLevelsType } from '../models/access-levels.type';
import { RolesType } from '../models/roles.type';
import { ApiService } from '../../api/api.service';
import { SubscriptionType } from '../models/subscription.type';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import {PutObjectCommand, S3Client} from "@aws-sdk/client-s3";
import {CookieService} from './cookie.service';

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    private userBehaviorSubject: BehaviorSubject<UserSelfInterface>;

    public selectedCategory: string;
    public searchText: string;
    public searchResults: BehaviorSubject<any> = new BehaviorSubject<any>({});

    public changePasswordOpened: boolean;
    public staySignedInOpened: boolean;

    public gamificationAssignTab = false;

    public poolInfo: any = {
        visible: false,
        title: null,
        attempt: null,
        attempts: null,
        run: false
    };

    public surveyInfo: any = {
        visible: false,
        title: null,
        attempt: null,
        attempts: null,
        duration: null,
        run: false
    };

    public footerVisible = true;
    public forcedPasswordChanged = new Subject<boolean>();

    constructor(private _datePipe: DatePipe,
                private _translate: TranslateService,
                private _title: Title,
                private _dialog: MatDialog,
                private _api: ApiService,
                private cookie: CookieService) {

        this.userBehaviorSubject = new BehaviorSubject<UserSelfInterface>({
            id: null,
            name: '',
            roles: [],
            accessLevels: [],
            avatarUrl: '',
            surname: '',
            username: '',
            companyId: null,
            isManager: false,
            companyLogoUrl: 'assets/images/dolineo_icon.png',
            isVisibileLogo: false,
            changePasswordRequired: 0,
            isSupervisor: false,
            subscriptions: [],
            companyDefaultLanguageId: 1,
            interfaceLanguageId: 1,
            interfaceLanguageCode: 'pl',
            homepage: null
        });

        if (localStorage.getItem('dolineouser')) {
            this.refreshUser();
        }
    }

    user(): Observable<UserSelfInterface> {

        return this.userBehaviorSubject.asObservable();
    }

    getUser(): UserSelfInterface {
        return this.userBehaviorSubject.value;
    }

    setUser(user: UserSelfInterface): void {
        if (user && user.roles.length > 1) {
            const idx = user.roles.indexOf('ROLE_USER');
            user.roles.splice(idx, 1);
        }
        if (!user) {
            this.userBehaviorSubject.next({
                id: null,
                name: '',
                roles: [],
                accessLevels: [],
                avatarUrl: '',
                surname: '',
                username: '',
                companyId: null,
                isManager: false,
                companyLogoUrl: 'assets/images/dolineo_icon.png',
                isVisibileLogo: false,
                changePasswordRequired: 0,
                isSupervisor: false,
                subscriptions: [],
                companyDefaultLanguageId: 1,
                interfaceLanguageId: 1,
                interfaceLanguageCode: 'pl'
            });
        } else {
            localStorage.setItem('dolineouser', JSON.stringify(user));
            this.userBehaviorSubject.next(user);
        }
    }

    getTableHeaderTranslate(arr: any[]): any {
        const translations = {};
        arr.forEach(val => {
            translations[val.name] = this._translate.instant(val.name)
        })
        return translations;
    }

    refreshUser(): void {
        this.userBehaviorSubject.next(JSON.parse(localStorage.getItem('dolineouser')));
    }

    public hasPermission(perm: AccessLevelsType): boolean {
        return this.getUser().accessLevels.indexOf(perm) > -1;
    }

    public hasPermissions(perms: AccessLevelsType[]): boolean {
        if (perms.length > 0) {
            for (const permission of perms) {
                if (this.hasPermission(permission)) {
                    return true;
                }
            }
        }
        return false;
    }

    public hasRole(role: RolesType): boolean {
        return this.getUser().roles.indexOf(role) > -1;
    }

    public isAdmin(): boolean {
        return this.getUser().roles.indexOf('ROLE_ADMIN') > -1;
    }

    public isSuperAdmin(): boolean {
        return this.getUser().roles.indexOf('ROLE_SUPERADMIN') > -1;
    }

    public isSysAdmin(): boolean {
        return this.getUser().roles.indexOf('ROLE_SYSTEMADMIN') > -1 && this.getUser().roles.indexOf('ROLE_SUPERADMIN') === -1;
    }

    convertToApiDate(date): string {
        return this._datePipe.transform(date, 'yyyy-MM-dd');
    }

    /**
     * Check if string is valid email.
     * @param email
     */
    checkIfStringIsEmail(email: string): boolean {
        const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/;
        const plRegularExpression = /[ąęśćźżółń]/;
        return regularExpression.test(email.toLowerCase()) && !plRegularExpression.test(email.toLowerCase());
    }

    passwordValidation(value: string): string[] {
        const errors = [];
        const expr = {
            containsNumber: /\d+/,
            containsAlphabetLowercase: /[a-z]/,
            containsAlphabetUppercase: /[A-Z]/,
            containsSpecialChar: /[!@#$%^&*()+=-?;,./{}|":<>\[\]\\' ~_\-]/,
        }

        if (value === null || (value && value.length < 8)) {
            errors.push('PasswordValidationLength');
        }
        if (value !== null && !expr.containsNumber.test(value)) {
            errors.push('PasswordValidationDigit');
        }
        if (value !== null && !expr.containsAlphabetLowercase.test(value)) {
            errors.push('PasswordValidationLower');
        }
        if (value !== null && !expr.containsAlphabetUppercase.test(value)) {
            errors.push('PasswordValidationUpper');
        }
        if (value !== null && !expr.containsSpecialChar.test(value)) {
            errors.push('PasswordValidationSpecialChar');
        }

        return errors;
    }

    stripFileName(filename: string, maxLength: number): string {
        if (filename.length > maxLength) {
            return filename.substring(0, maxLength);
        } else {
            return filename;
        }
    }

    isCarer(carersArray: number[]): boolean {
        if (carersArray && carersArray.length > 0) {
            for (const carerId of carersArray) {
                if (carerId === this.getUser().id) {
                    return true;
                }
            }
        }
        return false;
    }

    getUserToken() {
        return JSON.parse(this.cookie.get('dolineosession')).token;
    }

    hashUserToken(): string {
        // const salt = environment.tokenSalt;
        // const token = this.getUserToken();
        // return btoa(token + salt);
        return this.getUser().fileToken;
    }

    hasSubscription(subscription: SubscriptionType): boolean {
        return this.getUser().subscriptions.indexOf(subscription) >= 0;
    }

    createTitle(texts: string[]) {
        const title = texts.length > 1 ? texts.reduce(
            (previousValue, currentValue) => this._translate.instant(previousValue) + ' - ' + this._translate.instant(currentValue)
        ) : this._translate.instant(texts[0]);
        this._title.setTitle(title);
    }

    getHomePageUrl() {
        const homepage = this.getUser().homepage;
        if (homepage === 'elearning') {
            return '/app/myprogress';
        }

        if (homepage === 'gamification') {
            return this.hasPermission('user:gamification:view') ? '/app/gamification/my-gamifications': '/app/gamification/assign-users';
        }

        if (homepage === 'onboarding') {
            return this.hasPermission('user:onboarding:view') ? '/app/onboarding/my-onboarding': '/app/onboarding/assign-users';
        }

        if (homepage === 'preboarding') {
            return this.hasPermission('user:preboarding:view') ? '/app/preboarding/my-preboarding': '/app/preboarding/assign-users';
        }

        if (homepage === 'knowledge_base') {
            return '/app/library/knowledge-base';
        }

        if (homepage === 'feedback') {
            return '/app/feedback';
        }

        if (this.hasRole('ROLE_ADMIN')) {
            return '/app/admin/manage-users/users';
        }

        if (this.hasSubscription('elearning') && this.hasPermissions(['content:path:add', 'content:path:edit', 'carer:path:manage'])) {
            return '/app/paths-creator/lists/path-list/self';
        }
        if (this.hasSubscription('elearning') && this.hasPermissions(['content:training:add', 'content:training:edit', 'carer:training:manage'])) {
            return '/app/trainings-creator/lists/self';
        }
        if (this.hasSubscription('elearning') && this.hasPermissions(['content:pools:add', 'content:pools:edit', 'carer:pool:manage'])) {
            return '/app/pools-creator/lists/self';
        }
        if (this.hasSubscription('elearning') && this.hasPermissions(['content:survey:add', 'content:survey:edit', 'carer:survey:manage'])) {
            return '/app/surveys-creator/lists/self';
        }
        if (this.hasSubscription('knowledge_base') && this.hasPermissions(['content:article:add', 'carer:article-category:manage', 'content:article:edit', 'content:article-category:manage', 'carer:article:manage', 'content:article-category:assign'])) {
            return '/app/knowledge-base-creator/list';
        }
        if (this.hasSubscription('onboarding') && this.hasPermissions(['content:onboarding:add', 'content:onboarding:edit', 'carer:onboarding:manage'])) {
            return '/app/onboarding-creator/list';
        }
        if (this.hasSubscription('gamification') && this.hasPermissions(['content:gamification:add', 'content:gamification:edit', 'carer:gamification:manage'])) {
            return '/app/gamification-creator/list';
        }
        if (this.hasSubscription('feedback') && this.hasPermissions(['content:feedback-questionnaire:add',
            'content:feedback-questionnaire:edit',
            'carer:feedback-questionnaire:manage',
            'content:feedback:assign',
            'content:feedback:manage',
            'carer:feedback:manage'])) {
            return '/app/feedback-creator/lists/campaigns';
        }
        if (this.hasSubscription('elearning') && this.hasPermissions(['content:certificate:manage'])) {
            return '/app/certificates-creator/list';
        }
        if (this.hasPermissions(['content:archive:manage'])) {
            return '/app/certificates-creator/list';
        }

        return '/app/empty-page';
        //
        //
        // knowledge_base
        // feedback
    }

    createUniqueFilename(name: string) {
        return Date.now()+(Math.random() + 1).toString(36).substring(2)+'.'+name.split('.').pop();
    }

    uploadToS3(file: File, fileName: string): Promise<any> {
        const storageCredentials = JSON.parse(this.cookie.get('dolineosession'));

        const client = new S3Client({
            region: environment.storageRegion,
            credentials: {
                accessKeyId: storageCredentials.aki,
                secretAccessKey: storageCredentials.sak,
                sessionToken: storageCredentials.st
            }
        });

        const command = new PutObjectCommand({
            Bucket: environment.bucketName,
            Key: 'tbm/'+fileName,
            Body: file
        });

        return client.send(command);
    }

    public refreshUserFromApi() {
        this._api.User.self(true).subscribe((loggedUser) => {
            localStorage.setItem('dolineouser', JSON.stringify(loggedUser));
            this.refreshUser();
        });
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from "moment";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {ChangePasswordComponent} from "../../../profile/dialogs/change-password/change-password.component";
import {
    UpdateTaskStatusDialogComponent
} from "../../dialogs/update-task-status-dialog/update-task-status-dialog.component";
import {ApiService} from "../../../api/api.service";
import {HelperService} from "../../services/helper.service";
import {CreateTaskDialogComponent} from "../../../tasks/dialog/create-task-dialog/create-task-dialog.component";
import {ToastrService} from "ngx-toastr";
import {ConfirmDialogComponent} from "../../dialogs/confirm-dialog/confirm-dialog.component";
import {
    DuplicateTaskDialogComponentComponent
} from "../../../tasks/dialog/duplicate-task-dialog-component/duplicate-task-dialog-component.component";
import {Router} from "@angular/router";

@Component({
    selector: 'app-task-tile',
    templateUrl: './task-tile.component.html',
    styleUrls: ['./task-tile.component.scss']
})
export class TaskTileComponent implements OnInit {

    @Input() task = null;
    @Output() statusChanged = new EventEmitter();

    expired: boolean = false;
    highlight: boolean = false;
    repeatInfo: string = '';
    taskClass: string = '';

    canBegin: boolean = false;
    canUpdate: boolean = false;
    canGoTo: boolean = false;
    canEdit: boolean = false;

    searchText: string = '';

    constructor(private _translate: TranslateService,
                private _dialog: MatDialog,
                private _api: ApiService,
                private _helper: HelperService,
                private _toastr: ToastrService,
                private _router: Router) {
        this._helper.searchResults.subscribe((value: any) => {
            this.searchText = value.phrase ? value.phrase : '';
        })
    }

    ngOnInit(): void {
        if (new Date(this.task.realizationFrom).getTime() > new Date().getTime()
            || (new Date(this.task.realizationTo).getTime() < new Date().getTime() && this.task.blockedAfterExpiration)) {
            this.task.blocked = true;
        }

        if (this.task.realizationTo) {
            this.expired = new Date(this.task.realizationTo).getTime() < new Date().getTime();
            if (this.expired) {
                this.taskClass = this.task.status;
            }
        }

        const sevenDaysPeriod = new Date(this.task.realizationTo);
        sevenDaysPeriod.setDate(sevenDaysPeriod.getDate() - 7);
        if (this.task.realizationTo && !this.task.isCompleted && !this.task.blocked && (new Date().getTime() > sevenDaysPeriod.getTime())) {
            this.highlight = true;
        }

        if (this.task.status === 'pending'
            && (!this.task.realizationTo || new Date(this.task.realizationTo).getTime() > new Date().getTime())
            && !this.task.blocked
        ) {
            this.canBegin = true;
        }

        if (this.task.status === 'in_progress'
            && (!this.task.realizationTo || new Date(this.task.realizationTo).getTime() > new Date().getTime())
            && !this.task.blocked
            && !this.task.forAcceptance
        ) {
            this.canUpdate = true;
        }

        if(this.task.isCompleted){
            this.canGoTo = true;
        }

        this.canEdit = this.task.authorId == this._helper.getUser().id;

        this.repeatInfo = this.getRepeatInfo();
    }

    changeTaskStatus(){
        this._api.Tasks.getUserTask(this.task.id).subscribe( userTask => {
            this._dialog.open(UpdateTaskStatusDialogComponent, {
                width: '578px',
                data: {
                    task: userTask
                }
            }).afterClosed().subscribe((payload) => {
                if(payload){
                    this._api.Tasks.changeTaskStatus(this.task.id, payload).subscribe( res => {
                        this.statusChanged.emit(true);
                    })
                }
            });
        })
    }

    goToTask(){
        this._router.navigate(['app/tasks/user-task/'+this.task.id])
    }

    getRepeatInfo() {
        const defaultLocale = {
            daysOfWeek: [
                this._translate.instant('Common.ShortSunday'),
                this._translate.instant('Common.ShortMonday'),
                this._translate.instant('Common.ShortTuesday'),
                this._translate.instant('Common.ShortWednesday'),
                this._translate.instant('Common.ShortThursday'),
                this._translate.instant('Common.ShortFriday'),
                this._translate.instant('Common.ShortSaturday')
            ],
            monthNames: [
                this._translate.instant('Common.January'),
                this._translate.instant('Common.February'),
                this._translate.instant('Common.March'),
                this._translate.instant('Common.April'),
                this._translate.instant('Common.May'),
                this._translate.instant('Common.June'),
                this._translate.instant('Common.July'),
                this._translate.instant('Common.August'),
                this._translate.instant('Common.September'),
                this._translate.instant('Common.October'),
                this._translate.instant('Common.November'),
                this._translate.instant('Common.December')
            ],
            cancelLabel: this._translate.instant('Common.Cancel'),
            applyLabel: this._translate.instant('Common.Accept'),
            clearLabel: this._translate.instant('Common.Clear')
        };

        let info = this._translate.instant('Tasks.InfoRealization');

        switch (this.task.repetitionType) {
            case 'daily':
                info = info.replace('{{type}}', this._translate.instant('Tasks.DailyRepeat').toLowerCase())
                if (this.task.taskRealizationTo) {
                    info += ' (' + moment(this.task.taskRealizationFrom).format('HH:mm') + '-' + moment(this.task.taskRealizationTo).format('HH:mm') + ')';
                }
                break;
            case 'weekly':
                info = info.replace('{{type}}', this._translate.instant('Tasks.WeeklyRepeat').toLowerCase())

                const day = new Date(this.task.taskRealizationFrom).getDay();
                info += ' (' + defaultLocale.daysOfWeek[day].toLowerCase();
                if (this.task.taskRealizationTo) {
                    info += ', ' + moment(this.task.taskRealizationFrom).format('HH:mm') + '-' + moment(this.task.taskRealizationTo).format('HH:mm');
                }
                info += ')';

                break;
            case 'monthly':
                info = info.replace('{{type}}', this._translate.instant('Tasks.MonthlyRepeat').toLowerCase());
                info += ' (' + this._translate.instant('Tasks.MonthlyEveryDay').replace('{{x}}', moment(this.task.taskRealizationFrom).format('DD'));
                if (this.task.taskRealizationTo) {
                    info += ', ' + moment(this.task.taskRealizationFrom).format('HH:mm') + '-' + moment(this.task.taskRealizationTo).format('HH:mm');
                }
                info += ')';
                break;
            case 'yearly':
                info = info.replace('{{type}}', this._translate.instant('Tasks.YearlyRepeat').toLowerCase())

                const month = new Date(this.task.taskRealizationFrom).getMonth();
                info += ' (' + this._translate.instant('Tasks.YearlyEveryDay').replace('{{x}}', moment(this.task.taskRealizationFrom).format('D') + ' ' + defaultLocale.monthNames[month].toLowerCase());
                if (this.task.taskRealizationTo) {
                    info += ', ' + moment(this.task.taskRealizationFrom).format('HH:mm') + '-' + moment(this.task.taskRealizationTo).format('HH:mm');
                }
                info += ')';
                break;
        }

        info += ', ' + this._translate.instant('Tasks.InfoBegin') + ' ' + moment(this.task.taskRealizationFrom).format('DD.MM.YYYY');
        if (this.task.taskEndDate) {
            info += ' ' + this._translate.instant('Tasks.InfoDateTo') + ' ' + moment(this.task.taskEndDate).format('DD.MM.YYYY');
        }
        info += '.';

        return info;
    }

    onEdit(){
        this._dialog.open(CreateTaskDialogComponent, {
            disableClose: true,
            width: '820px',
            data: {
                id: this.task.taskId
            }
        }).afterClosed().subscribe((result) => {
            this.statusChanged.emit(true);
        });
    }

    onDuplicate(){
        this._dialog.open(DuplicateTaskDialogComponentComponent, {
            disableClose: true,
            autoFocus: false,
            data: {
                name: this.task.name
            },
            width: '486px'
        }).afterClosed().subscribe(name => {
            if(name){
                this._api.Tasks.duplicateTask(this.task.taskId, name).subscribe( res => {
                    this._toastr.success(this._translate.instant('Tasks.TaskDuplicated'));
                })
            }
        });
    }

    onRemove(){
        this._dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            autoFocus: false,
            data: {
                title: 'Tasks.RemoveTask',
                content: 'Tasks.RemoveTaskDesc',
                okText: 'Common.IConfirm'
            },
            width: '486px'
        }).afterClosed().subscribe(result => {
            if(result){
                this._api.Tasks.removeTask(this.task.taskId).subscribe( res => {
                    this.statusChanged.emit(true);
                    this._toastr.success(this._translate.instant('Tasks.TaskDeleted'));
                })
            }
        });
    }

}

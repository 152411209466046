<div class="table-wrapper" [ngClass]="{'no-tabs': noTabs}" data-private>
    <div class="box box-default table-box">
        <div #tableHeader style="display: none;">
        </div>
        <div class="flex-table">
            <div #flexTableHead class="ft-head" *ngIf="!!_items"
                 [ngStyle]="hideHead ? {'display' : 'none'} : {}">
                <div class="ft-cell ft-center"
                     style="width: 45px; min-width: 45px; align-items: center; flex-direction: column; justify-content: center;"
                     *ngIf="selection">
                    <mat-checkbox (change)="selectAll($event)" [checked]="_selectedAll" color="primary" style="margin-left: 5px">&nbsp;
                    </mat-checkbox>
                    <div class="number-of-selected"
                         matTooltip="{{'Common.SelectedElements' | translate}}"
                         matTooltipPosition="below"
                         matTooltipClass='below-top'>{{(selectedItems - disabledSelectionCount)}}</div>
                </div>
                <div class="ft-cell ft-center" style="width: 45px;min-width: 45px;" *ngIf="radioSelect">
                </div>
                <ng-container *ngFor="let column of columns; let idx  = index;">
                    <div *ngIf="column.visible"
                         [className]="'ft-cell ' + (column.class || '')"
                         [ngClass]="{'active-ft-sort': request.sort.field === column.index}"
                         [ngStyle]="column.css || ''">
                        <ng-container *ngIf="column.searchable && column.visible">
                            <!-- STRING -->
                            <mat-form-field *ngIf="column.type === 'STRING'" appearance="fill" [floatLabel]="'always'"
                                            [ngClass]="{'selected-input': column.value}">
                                <mat-label>{{ (column.name || column.index) | translate}}</mat-label>
                                <input [(ngModel)]="column.value"
                                       matInput
                                       placeholder="{{ 'Common.Enter' | translate}}"
                                       (keyup)="search($event.target.value)">
                                <button mat-icon-button
                                        matSuffix
                                        aria-label="Clear"
                                        *ngIf="column.value"
                                        (click)="column.value=''; search(column.value)"
                                        color="primary">
                                    <i class="material-icons clear-button"
                                       matTooltip="{{'Common.Clear' | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'
                                    >close</i>
                                </button>
                            </mat-form-field>

                            <!-- NUMBER -->
                            <mat-form-field *ngIf="column.type === 'NUMBER'" appearance="fill" [floatLabel]="'always'"
                                            [ngClass]="{'selected-input': column.value}">
                                <mat-label>{{ (column.name || column.index) | translate}}</mat-label>
                                <input [(ngModel)]="column.value"
                                       matInput
                                       readonly="readonly"
                                       [matMenuTriggerFor]="numberDialog"
                                       placeholder="{{ 'Common.Choose' | translate}}">
                                <button mat-icon-button
                                        matSuffix
                                        aria-label="Clear"
                                        *ngIf="column.value"
                                        (click)="column.value=''; column.valueRange=''; search(column.value)"
                                        color="primary">
                                    <i class="material-icons clear-button"
                                       matTooltip="{{'Common.Clear' | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'
                                    >close</i>
                                </button>
                            </mat-form-field>

                            <!-- PROGRESS-->
                            <mat-form-field *ngIf="column.type === 'PROGRESS'" appearance="fill" [floatLabel]="'always'"
                                            [ngClass]="{'selected-input': column.value}">
                                <mat-label>{{ (column.name || column.index) | translate}}</mat-label>
                                <input [(ngModel)]="column.value"
                                       matInput
                                       readonly="readonly"
                                       [matMenuTriggerFor]="numberDialog"
                                       placeholder="{{ 'Common.Choose' | translate}}">
                                <button mat-icon-button
                                        matSuffix
                                        aria-label="Clear"
                                        *ngIf="column.value"
                                        (click)="column.value=''; column.valueRange=''; search(column.value)"
                                        color="primary">
                                    <i class="material-icons clear-button"
                                       matTooltip="{{'Common.Clear' | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'
                                    >close</i>
                                </button>
                            </mat-form-field>

                            <mat-menu #numberDialog="matMenu">
                                <app-selection-of-numbers-dialog
                                        [title]="(column.name || column.index) | translate"
                                        [hasMinutes]="column.hasMinutes"
                                        [isClearInput]="column.value"
                                        (saveNumber)="searchNumber(column, $event)">
                                </app-selection-of-numbers-dialog>
                            </mat-menu>

                            <!-- ARRAY -->
                            <mat-form-field *ngIf="column.type === 'ARRAY' || column.type === 'STATUS'" appearance="fill" [floatLabel]="'always'"
                                            [ngClass]="{'selected-input': column.value}">
                                <mat-label>{{ (column.name || column.index) | translate}}</mat-label>
                                <mat-select [(ngModel)]="column.value"
                                            placeholder="{{'Common.Choose' | translate}}"
                                            [multiple]="column.multiple === undefined || column.multiple === true ? '' : null"
                                            (selectionChange)="search()">
                                    <mat-option value="novalue" *ngIf="column.novalue">{{ 'Common.NoValue' | translate }}</mat-option>
                                    <mat-option *ngFor="let option of column.options || []"
                                                [value]="option.id || option.value">{{ option.value | translate}}</mat-option>
                                </mat-select>
                                <button mat-icon-button
                                        matSuffix
                                        aria-label="Clear"
                                        *ngIf="column.value"
                                        (click)="column.value=''; search(column.value)"
                                        color="primary">
                                    <i class="material-icons clear-button"
                                       matTooltip="{{'Common.Clear' | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'
                                    >close</i>
                                </button>
                            </mat-form-field>

                            <!-- ARRAY_SEARCH -->
                            <mat-form-field *ngIf="column.type === 'ARRAY_SEARCH'" appearance="fill" [floatLabel]="'always'"
                                            [ngClass]="{'selected-input': column.value}">
                                <mat-label>{{ (column.name || column.index) | translate}}</mat-label>
                                <mat-select [(ngModel)]="column.value"
                                            placeholder="{{'Common.Choose' | translate}}"
                                            [multiple]="column.multiple === undefined || column.multiple === true ? '' : null"
                                            (selectionChange)="search()">
                                    <mat-form-field class="full-width pl-3 pr-3 pt-2 table-array-search-input">
                                        <input matInput maxlength="50" (keyup)="onGroupSearch(column, name.value)"
                                               (keydown)="$event.stopPropagation()"
                                               #name
                                               placeholder="{{'Common.Group' | translate}}">
                                        <button mat-icon-button
                                                matSuffix
                                                aria-label="Clear"
                                                *ngIf="name.value"
                                                (click)="name.value=''; onGroupSearch(column, name.value)"
                                                color="primary">
                                            <i class="material-icons clear-button"
                                               matTooltip="{{'Common.Clear' | translate}}"
                                               matTooltipPosition="below"
                                               matTooltipClass='below-top'
                                            >close</i>
                                        </button>
                                    </mat-form-field>
                                    <mat-option value="novalue" *ngIf="column.novalue">{{ 'Common.NoValue' | translate }}</mat-option>
                                    <mat-option *ngFor="let option of column.options || []"
                                                [ngStyle]="{'display': option.visible === 0 ? 'none' : 'flex'}"
                                                [value]="option.id || option.value">{{ option.value | translate}}</mat-option>
                                </mat-select>
                                <button mat-icon-button
                                        matSuffix
                                        aria-label="Clear"
                                        *ngIf="column.value"
                                        (click)="column.value=''; search(column.value)"
                                        color="primary">
                                    <i class="material-icons clear-button"
                                       matTooltip="{{'Common.Clear' | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'
                                    >close</i>
                                </button>
                            </mat-form-field>

                            <!-- TOGGLE -->
                            <mat-form-field *ngIf="column.type === 'TOGGLE'" appearance="fill" [floatLabel]="'always'"
                                            [ngClass]="{'selected-input': column.value}">
                                <mat-label>{{ (column.name || column.index) | translate}}</mat-label>
                                <mat-select [(ngModel)]="column.value" placeholder="{{'Common.Choose' | translate}}"
                                            (selectionChange)="search()">
                                    <mat-option [value]="1">{{'Common.Yes' | translate}}</mat-option>
                                    <mat-option [value]="0">{{'Common.No' | translate}}</mat-option>
                                </mat-select>
                                <button mat-icon-button
                                        matSuffix
                                        aria-label="Clear"
                                        *ngIf="(column.value !== '' && column.value !== null)"
                                        (click)="column.value=''; search(column.value)"
                                        color="primary">
                                    <i class="material-icons clear-button"
                                       matTooltip="{{'Common.Clear' | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'
                                    >close</i>
                                </button>
                            </mat-form-field>

                            <!-- SELECT -->
                            <mat-form-field *ngIf="column.type === 'SELECT'" appearance="fill" [floatLabel]="'always'"
                                            [ngClass]="{'selected-input': column.value}">
                                <mat-label>{{ (column.name || column.index) | translate}}</mat-label>
                                <mat-select [(ngModel)]="column.value"
                                            placeholder="{{'Common.Choose' | translate}}"
                                            [multiple]="column.multiple === undefined || column.multiple === true ? '' : null"
                                            (selectionChange)="search()">
                                    <mat-option value="novalue" *ngIf="column.novalue">{{ 'Common.NoValue' | translate }}</mat-option>
                                    <mat-option *ngFor="let option of column.options || []"
                                                [value]="option.id || option.value">{{ option.value | translate}}</mat-option>
                                </mat-select>
                                <button mat-icon-button
                                        matSuffix
                                        aria-label="Clear"
                                        *ngIf="column.value"
                                        (click)="column.value=''; search(column.value)"
                                        color="primary">
                                    <i class="material-icons clear-button"
                                       matTooltip="{{'Common.Clear' | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'
                                    >close</i>
                                </button>
                            </mat-form-field>

                            <!-- DATE -->
                            <mat-form-field *ngIf="column.type === 'DATE'" appearance="fill" [floatLabel]="'always'"
                                            [ngClass]="{'selected-input': column.value}">
                                <mat-label>{{ (column.name || column.index) | translate}}</mat-label>
                                <input [(ngModel)]="column.value"
                                       matInput [matDatepicker]="idx"
                                       placeholder="{{'Common.Choose' | translate}}"
                                       (dateChange)="search()"
                                       (keyup)="searchDate($event.target.value)">
                                <mat-datepicker-toggle matSuffix [for]="idx"></mat-datepicker-toggle>
                                <mat-datepicker #idx></mat-datepicker>
                                <button mat-icon-button
                                        matSuffix
                                        aria-label="Clear"
                                        *ngIf="column.value"
                                        (click)="column.value=''; search(column.value)"
                                        color="primary">
                                    <i class="material-icons clear-button"
                                       matTooltip="{{'Common.Clear' | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'
                                    >close</i>
                                </button>
                            </mat-form-field>

                            <!-- DATE_RANGE -->
                            <mat-form-field *ngIf="column.type === 'DATE_RANGE'" appearance="fill"
                                            [floatLabel]="'always'" [ngClass]="{'selected-input': column.value}">
                                <mat-label>{{ (column.name || column.index) | translate}}</mat-label>
                                <input [(ngModel)]="column.value === 'novalue' ? 'Brak daty' : column.value"
                                       (click)="setDateRange(column)"
                                       matInput
                                       readonly="readonly"
                                       [matMenuTriggerFor]="dateRangeDialog"
                                       placeholder="{{ 'Common.Choose' | translate}}">
                                <button mat-icon-button
                                        matSuffix
                                        aria-label="Clear"
                                        *ngIf="column.value"
                                        (click)="column.value=''; column.valueDateRange='' ; dateRangeSearch(column)"
                                        color="primary">
                                    <i class="material-icons clear-button"
                                       matTooltip="{{'Common.Clear' | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'
                                    >close</i>
                                </button>
                            </mat-form-field>

                            <mat-menu #dateRangeDialog="matMenu">
                                <div class="date-range-popup">
                                    <div (click) = "$event.stopPropagation()" class="date-range-popup__date-picker">
                                        <div class="date-range-popup__title">{{ (column.name || column.index) | translate}}</div>
                                        <mat-form-field>
                                            <mat-label>{{ 'Common.SelectDateRange' | translate}}</mat-label>
                                            <input
                                                   matInput
                                                   ngxDaterangepickerMd
                                                   showCancel="true"
                                                   showClearButton="true"
                                                   placeholder="{{ 'Common.SelectDateRange' | translate}}"
                                                   readonly="readonly"
                                                   (click)="openDatePicker('dt_date_range' + idx, column)"
                                                   [timePicker]="column.timePicker || false"
                                                   [timePicker24Hour]="true"
                                                   [locale]="getLocale(column)"
                                                   [(ngModel)]="column.valueDateRange"
                                                   [showDropdowns]="true"
                                                   [lockStartDate]="false"
                                                   [customRangeDirection]="false"
                                                   (ngModelChange)="setDateRangeSearch(column, $event)"
                                                   [disabled]="dateRangeValues[column.index] && dateRangeValues[column.index]['toggle']"
                                                   >
                                            <img (click)="openDatePicker('dt_date_range' + idx, column)"
                                                    src="/assets/icons/calendar-filter.svg" alt="calendar2">
                                        </mat-form-field>

                                        <mat-slide-toggle color="primary"
                                            [checked]="column.value === 'novalue'"
                                            (change)="changeNoDate(column, $event)">
                                            {{'Common.NoDate' | translate}}
                                        </mat-slide-toggle>

                                    </div>
                                    <div class="date-range-popup__buttons">
                                        <button mat-flat-button class="cancel-button">{{'Common.Cancel' | translate}}</button>
                                        <button mat-flat-button
                                                (click)="applyChanges(column)"
                                                color="primary"
                                                class="dolineo-button blue-button">
                                            {{ 'Common.Apply' | translate}}
                                        </button>
                                    </div>
                                </div>
                            </mat-menu>
                        </ng-container>

                        <span class="header-text" *ngIf="!column.searchable"
                              [innerHTML]="((column.type !== 'TOGGLE' && column.type !== 'CHECKBOX'||
                               column.type === 'AVATAR' && column.name) ? column.name : '') | translate">
                        </span>

                        <span class="header-text" *ngIf="column.isVisibleItemsNumber">
                            {{(column.isVisibleItemsNumber ? _items.length : '')}}
                        </span>

                        <div *ngIf="column.sortable" (click)="changeSort(column.index)" class="ft-sort"
                             [ngStyle]="{'display': request.sort.field === column.index?  'block' : 'none' }">
                            <i [ngClass]="(request.sort.field !== column.index || request.sort.field === column.index && request.sort.direction === 'asc') ?
                            'arrow_downward' : 'arrow_upward'"
                               matTooltip="{{'Common.Sort' | translate}}"
                               matTooltipPosition="below"
                               matTooltipClass='below-top'
                            ></i>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="ft-body" *ngIf="!!_items && !dragAndDrop" tableWrapper (callback)="loadMore()"
                 appDataGridScroll>
                <div class="ft-row" *ngFor="let item of _items; let rowIdx = index;"
                     [ngClass]="{'selectedRow': (rowIdx === selectedIndex || item.selected) && (!item.disabledSelection || (item.disabledSelection && item.disabledSelection !== true))}">
                    <div class="ft-cell ft-center" style="width: 45px;min-width: 45px;" *ngIf="selection">
                        <mat-checkbox [ngModel]="item.selected" (change)="selectedCheckbox($event, rowIdx)"
                                      *ngIf="(!item.disabledSelection || (item.disabledSelection && item.disabledSelection !== true))"
                                      color="primary">
                            &nbsp;
                        </mat-checkbox>
                    </div>

                    <div class="ft-cell ft-center" style="width: 45px;min-width: 45px;" *ngIf="radioSelect"
                         [ngClass]="{'selectedRow': rowIdx === selectedIndex}">
                        <mat-radio-button (change)="selectRadio(item, rowIdx)" color="primary">&nbsp;</mat-radio-button>
                    </div>

                    <ng-container *ngFor="let column of columns; let colIdx = index;">
                        <div *ngIf="column.visible" [className]="'ft-cell ' + (column.class || '')"
                             [ngClass]="{'ft-cell-menu': column.type === 'MENU', 'ft-disabled': column.disabled ? column.disabled(item) : false}"
                             [ngStyle]="column.css || ''">
                            <ng-container *ngIf="column.type !== 'MENU' && column.type !== 'TOGGLE' &&
                             column.type !== 'CHECKBOX' && column.type !== 'AVATAR' && column.type !== 'STATUS' &&
                             column.type !== 'SELECT'">

                                <span *ngIf="!column.click && column.render" class="cut-text-table"
                                      [innerHTML]="column.render(item)"></span>

                                <span *ngIf="column.click && column.render" class="cut-text-table"
                                      (click)="column.click(item)"
                                      [innerHTML]="column.render(item)"></span>

                                <span *ngIf="!column.render && column.click" (click)="column.click(item)"
                                      class="cut-text-table">{{ item[column.index] | translate}}</span>
                                <span *ngIf="!column.render && !column.click"
                                      class="cut-text-table">{{ item[column.index] | translate}}</span>
                            </ng-container>

                            <ng-container *ngIf="column.type == 'STATUS'">
                                <img [src]="'/assets/icons/status_'+item[column.index]+'.svg'"
                                     matTooltip="{{ 'Tasks.Status'+item[column.index] | translate}}"
                                     matTooltipPosition="below"
                                     matTooltipClass='below-top'
                                >
                            </ng-container>

                            <ng-container *ngIf="column.type === 'TOGGLE'">
                                <mat-slide-toggle color="primary" [(ngModel)]="item[column.index]"
                                                  [disabled]="column.disabled? column.disabled(item) : false"
                                                  (click)="column.click(item)">
                                </mat-slide-toggle>
                            </ng-container>

                            <ng-container *ngIf="column.type === 'CHECKBOX'">
                                <mat-checkbox color="primary" [(ngModel)]="item[column.index]"
                                              (click)="column.click(item)"></mat-checkbox>
                            </ng-container>

                            <ng-container *ngIf="column.type === 'AVATAR'">
                                <div class="avatar">
                                    <ngx-avatar [src]="item['avatarPath']"
                                                [name]="column.userName(item)"></ngx-avatar>
                                    <button class="login-button" *ngIf="column.isActive(item) && column.click"
                                            (click)="column.click(item)" mat-icon-button color="primary">
                                        <i class="material-icons">vpn_key</i>
                                    </button>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="column.type === 'PROGRESS'">
                                <div class="progress-grid">
                                    <div class="data-progress">
                                        <div class="data-progress__progress">
                                            <div class="data-progress__progress__bar" >
                                                <div class="progress-done"
                                                     [style.width]="(column.elementsFinishedCount(item) === 0 || column.totalElementsCount(item) === 0 ? 0 : ((column.elementsFinishedCount(item)/column.totalElementsCount(item)) * 100)) + '%'"></div>
                                            </div>
                                            <div class="data-progress__progress__number">
                                                <span>{{ column.elementsFinishedCount(item) }}</span>
                                                <span> / {{ column.totalElementsCount(item) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="column.type === 'AVATARS'">
                                <div class="avatars">
                                    <div class="avatar" *ngFor="let avatar of item.users; let index = index;"
                                         [ngStyle]="item.users.length > 3 && index > 1 ? {'display' : 'none', 'margin-left' : '0'} : {}">
                                        <ngx-avatar [src]="avatar['avatarPath']"
                                                    [name]="avatar['userFullName']"
                                                    size="28"
                                                    matTooltip="{{ avatar['userFullName'] }}"
                                                    matTooltipPosition="above"
                                                    matTooltipClass='above'>
                                        </ngx-avatar>
                                    </div>
                                    <span *ngIf="item.users.length > 3" class="empty-avatar">
                                        <span>{{ '+' + (item.users.length - 2) }}</span>
                                    </span>
                                </div>
                            </ng-container>

                            <div *ngIf="column.type === 'SELECT' && (column.hidden ? column.hidden(item) : false)"
                                 class="table-wrapper__select">
                                <mat-form-field class="full-width cursor-pointer" [matMenuTriggerFor]="selectConfig">
                                    <input readonly matInput placeholder="{{ item.cyclical ? ('Notifications.InCycle' | translate) : (column.placeholder | translate)}}"
                                           [value]="setStringFromArray(item[column.index])"
                                           class="table-wrapper__select__input">
                                    <span class="table-wrapper__select__text" mat-icon-button></span>
                                </mat-form-field>

                                <mat-menu #selectConfig="matMenu">
                                    <div class="table-wrapper__select__mat-menu">
                                        <ng-container *ngIf="!item.cyclical">
                                            <span>{{column.matMenuTitle | translate}}</span>
                                            <mat-checkbox [disabled]="column.disabled? column.disabled(item) : false" *ngFor="let option of item[column.index] || []"
                                                          color="primary"
                                                          [(ngModel)]="option.selected"
                                                          (click)="$event.stopPropagation();
                                                       column.click(option, rowIdx)">
                                                {{option.value | translate}}
                                            </mat-checkbox>
                                        </ng-container>
                                        <ng-container *ngIf="item.cyclical">
                                            <span>{{ 'Notifications.SendInCycle' | translate}}</span>
                                            <mat-radio-button *ngFor="let option of item[column.index] || []"
                                                              color="primary"
                                                              [value]="option.id"
                                                              [checked]="option.selected"
                                                              (change)="column.change(option, rowIdx)">
                                                            {{option.value | translate}}
                                            </mat-radio-button>
                                        </ng-container>
                                    </div>
                                </mat-menu>
                            </div>

                            <ng-container *ngIf="column.type === 'MENU'">
                                <ng-container *ngFor="let menuItem of column.items; let menuIdx = index;">
                                    <a *ngIf="!menuItem.items && (menuItem.visible === undefined || menuItem.visible(item) === true)"
                                       class="ft-button"
                                       matTooltip="{{menuItem.name | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'
                                       (click)="menuItem.click(item)"
                                       [innerHTML]="menuItem.icon"></a>
                                    <ng-container
                                            *ngIf="menuItem.items?.length && (menuItem.visible === undefined || menuItem.visible(item) === true)">
                                        <a class="ft-button"
                                           [matMenuTriggerFor]="menu"
                                           [innerHTML]="menuItem.icon"
                                           matTooltip="{{menuItem.name | translate}}"
                                           matTooltipPosition="below"
                                           matTooltipClass='below-top'
                                           style="justify-content: center"></a>
                                        <mat-menu #menu="matMenu" class="mat-menu-small">
                                            <ng-container *ngFor="let subMenuItem of menuItem.items;">
                                                <button *ngIf="subMenuItem.name !== '-' && (subMenuItem.visible === undefined || subMenuItem.visible(item) === true)"
                                                        mat-menu-item (click)="subMenuItem.click(item)">
                                                    {{ subMenuItem.name | translate }}
                                                </button>
                                                <mat-divider *ngIf="subMenuItem.name === '-'"></mat-divider>
                                            </ng-container>
                                        </mat-menu>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>

<!--                    <div [className]="'ft-cell ft-auto'" style="min-width: 1px">-->
<!--                    </div>-->
                </div>
            </div>

            <div class="ft-body" *ngIf="!!_items && dragAndDrop" cdkDropList (cdkDropListDropped)="drop($event)"
                 tableWrapper (callback)="loadMore()">
                <div class="ft-row" *ngFor="let item of _items; let rowIdx = index;"
                     [ngClass]="{'selectedRow': rowIdx === selectedIndex}"
                     [cdkDragPreviewClass]="'flex-start-drag'"
                     cdkDrag>
                    <div class="ft-cell ft-center" style="width: 45px;min-width: 45px;" *ngIf="selection === true">
                        <mat-checkbox [(ngModel)]="item.selected" color="primary"
                                      (change)="selectedCheckbox($event, rowIdx)">&nbsp;
                        </mat-checkbox>
                    </div>
                    <div class="ft-cell ft-center" style="width: 45px;min-width: 45px;" *ngIf="radioSelect">
                        <mat-radio-button (change)="selectRadio(item, rowIdx)" color="primary">&nbsp;</mat-radio-button>
                    </div>
                    <ng-container *ngFor="let column of columns; let colIdx = index;">
                        <div *ngIf="column.visible" [className]="'ft-cell ' + (column.class || '')"
                             [ngClass]="{'ft-cell-menu': column.type === 'MENU', 'selectedRow': rowIdx === selectedIndex, 'selectedRow': item.selected}"
                             [ngStyle]="column.css || ''">
                            <ng-container
                                    *ngIf="column.type !== 'MENU' && column.type !== 'TOGGLE' && column.type !== 'CHECKBOX'">
                                <span *ngIf="!column.click && column.render" [innerHTML]="column.render(item)"></span>
                                <span *ngIf="column.click && column.render" (click)="column.click(item)"
                                      [innerHTML]="column.render(item)"></span>

                                <span *ngIf="!column.render && column.click" (click)="column.click(item)"
                                      class="cut-text-table">{{ item[column.index] | translate}}</span>

                                <span *ngIf="!column.render && !column.click"
                                      class="cut-text-table">{{ item[column.index] | translate}}</span>
                            </ng-container>

                            <ng-container *ngIf="column.type === 'TOGGLE'">
                                <mat-slide-toggle color="primary" [(ngModel)]="item[column.index]"
                                                  (click)="column.click(item)">
                                </mat-slide-toggle>
                            </ng-container>

                            <ng-container *ngIf="column.type === 'CHECKBOX'">
                                <mat-checkbox color="primary" [(ngModel)]="item[column.index]"
                                              (click)="column.click(item)"></mat-checkbox>
                            </ng-container>

                            <ng-container *ngIf="column.type === 'DRAG_AND_DROP'">
                                <img matTooltip="{{'Common.DragAndDrop' | translate}}"
                                     matTooltipPosition="below"
                                     matTooltipClass='below-top'
                                     loading="lazy"
                                     (mousedown)="removeTooltipWhenDrag()"
                                     cdkDragHandle
                                     class="cursor-pointer"
                                     src="/assets/icons/drag-handler.svg" alt="drag-handler">
                            </ng-container>

                            <ng-container *ngIf="column.type === 'AVATAR'">
                                <div class="avatar" style="width: 80px;min-width: 80px;">
                                    <ngx-avatar [src]="column.avatarPath"></ngx-avatar>
                                    <button class="login-button" *ngIf="column.isActive" (click)="column.click(item)"
                                            mat-icon-button color="primary">
                                        <i class="material-icons">vpn_key</i>
                                    </button>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="column.type === 'MENU'">
                                <ng-container *ngFor="let menuItem of column.items; let menuIdx = index;">
                                    <a *ngIf="!menuItem.items && (menuItem.visible === undefined || menuItem.visible(item) === true)"
                                       matTooltip="{{menuItem.name | translate}}"
                                       matTooltipPosition="below"
                                       matTooltipClass='below-top'
                                       class="ft-button" (click)="menuItem.click(item)" [innerHTML]="menuItem.icon"></a>

                                    <ng-container
                                            *ngIf="menuItem.items?.length && (menuItem.visible === undefined || menuItem.visible(item) === true)">
                                        <a class="ft-button" [matMenuTriggerFor]="menu" [innerHTML]="menuItem.icon"
                                           matTooltip="{{menuItem.name | translate}}"
                                           matTooltipPosition="below"
                                           matTooltipClass='below-top'
                                           style="justify-content: center"></a>
                                        <mat-menu #menu="matMenu" class="mat-menu-small">
                                            <ng-container *ngFor="let subMenuItem of menuItem.items;">
                                                <button *ngIf="subMenuItem.name !== '-' && (subMenuItem.visible === undefined || subMenuItem.visible(item) === true)"
                                                        mat-menu-item (click)="subMenuItem.click(item)">
                                                    {{ subMenuItem.name | translate }}</button>
                                                <mat-divider *ngIf="subMenuItem.name === '-'"></mat-divider>
                                            </ng-container>
                                        </mat-menu>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                    <!--                    <div [className]="'ft-cell ft-auto'" style="min-width: 1px">-->
                    <!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IntegrationInterface } from '../../integration/interfaces/integration.interface';

@Injectable()
export class AdminService {

    protected basePath = environment.apiPath;
    public defaultHeaders = new HttpHeaders();

    constructor(protected http: HttpClient) {
        this.defaultHeaders = this.defaultHeaders.append('Accept', 'application/json');
        this.defaultHeaders = this.defaultHeaders.append('Content-Type', 'application/json');
    }

    public profileData(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/company`, { headers });
    }

    public agreements(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/company/agreement?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public dolineoPaths(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/dolineo-path?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public updateDolineoPath(body: any){
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/update-dolineo-path`, body, { headers });
    }

    public groupUpdateDolineoPath(body: any){
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/update-dolineo-paths`, body, { headers });
    }

    public updateDolineoTraining(body: any){
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/update-dolineo-training`, body, { headers });
    }

    public groupUpdateDolineoTraining(body: any){
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/update-dolineo-trainings`, body, { headers });
    }

    public dolineoPools(type: string, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/dolineo-pools?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getPool(id: number | string, translations: any = null, time: any = null): Observable<any> {
        translations = JSON.stringify(translations)
        const headers = this.defaultHeaders;
        let params = '';
        if(translations){
            params = params + `translations=${translations}`;
        }
        if(time){
            params += params === '' ? `time=${time}`: `&time=${time}`;
        }
        return this.http.get<any>(`${this.basePath}/superadmin/pools/${id}?${params}`, { headers });
    }

    public getPoolAttempt(userId, poolId, attemptId): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/user/${userId}/pools/${poolId}/attempt/${attemptId}`, { headers });
    }

    public getPoolAnswersStats(id): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/pools/${id}/summary`, { headers });
    }

    public getPoolStats(id): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/pools/${id}/summary/stats`, { headers });
    }

    public getPoolStatsPdf(id): string {
        return `/pools/${id}/summary/stats/pdf`;
    }

    public poolCreate(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.put<any>(`${this.basePath}/superadmin/pools`, body, { headers });
    }

    public poolUpdate(body: any, id): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/pools/${id}`, body, { headers });
    }

    public poolCheckName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/superadmin/pool/check-name`, body, { headers });
    }

    public poolImportQuestions(file: any, translations: any, poolId: any, time: any): Observable<any> {
        const formParams = new FormData();
        formParams.append('import', file);
        formParams.append('poolId', poolId);
        formParams.append('time', time);
        formParams.append('translations', JSON.stringify(translations));
        return this.http.post<any>(`${this.basePath}/superadmin/pool/import`, formParams, { reportProgress: true, observe: 'events' });
    }

    public poolExportQuestions(body: any): Observable<HttpResponse<Blob>> {
        // @ts-ignore
        // @ts-disable
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/superadmin/pool/export`, body, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    public generateQuestionsByOpenAi(file: any, parameters: any): Observable<any> {
        const formParams = new FormData();
        formParams.append('import', file);
        formParams.append('parameters', JSON.stringify(parameters));

        return this.http.post<any>(`${this.basePath}/superadmin/pool/open-ai`, formParams, { reportProgress: true, observe: 'events' });
    }

    public templatePoolXls(body: any): Observable<HttpResponse<Blob>> {
        // @ts-ignore
        // @ts-disable
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/template/xls/pools`, body, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    public selfPaths(pageNumber: any, pageSize: any, sortBy: string, order: string, params: string, showSections?: number, excludedIds?: any[]): Observable<any> {
        const headers = this.defaultHeaders;
        let url = `${this.basePath}/admin/path?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}&showSections=${showSections}`;
        if (excludedIds?.length > 0) {
            excludedIds.forEach(excludedId => {
                url += `&eid[]=${excludedId}`;
            });
        }
        return this.http.get<any>(url, { headers });
    }

    public selfPathsByIds(ids: any[]): Observable<any> {
        const headers = this.defaultHeaders;
        const url = `${this.basePath}/admin/path-by-ids?isCreator=1&withDolineo=1&showSections=1`;

        return this.http.post<any>(url, {ids}, { headers });
    }

    public selfPools(type: string, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string, excludedIds?: any[]): Observable<any> {
        const headers = this.defaultHeaders;
        let url = `${this.basePath}/admin/pools?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`;
        if (excludedIds?.length > 0) {
            excludedIds.forEach(excludedId => {
                url += `&eid[]=${excludedId}`;
            });
        }
        return this.http.get<any>(url, { headers });
    }

    public automaticPublicationDolineoPath(status: boolean): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/dolineo-path/automatic-publication`, { isAutomatic: status }, { headers });
    }

    public dolineoTrainings(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/dolineo-training?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public selfTrainings(pageNumber: any, pageSize: any, sortBy: string, order: string, params: string, excludedIds?: any[]): Observable<any> {
        const headers = this.defaultHeaders;
        let url = `${this.basePath}/admin/training?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`;
        if (excludedIds?.length > 0) {
            excludedIds.forEach(excludedId => {
                url += `&eid[]=${excludedId}`;
            });
        }
        return this.http.get<any>(url, { headers });
    }

    public getAllTrainingsToReorder(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/training/editor-list-by-weight`, { headers });
    }

    public getAllPathsToReorder(params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/path/editor-list-by-weight${params}`, { headers });
    }

    public reorderTrainings(trainingIds: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/training/reorder`, { ids: [...trainingIds] }, { headers });
    }

    public reorderPaths(pathIds: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/path/reorder`, { ids: [...pathIds] }, { headers });
    }

    public automaticPublicationDolineoTraining(status: boolean): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/dolineo-training/automatic-publication`, { isAutomatic: status }, { headers });
    }

    public toggleActiveDolineoPool(poolId: number, status: boolean): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/dolineo-pool/${poolId}/activity`, { isActive: status }, { headers });
    }

    public automaticPublicationDolineoPool(status: boolean): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/dolineo-pool/automatic-publication`, { isAutomatic: status }, { headers });
    }

    public users(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/user?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public deleteInactiveUsers(companyId: any, ids: any): Observable<any> {
        const headers = this.defaultHeaders;
        const body: any = {
            companyId,
            ids
        };
        return this.http.post<any>(`${this.basePath}/admin/user/remove-inactive`, body, { headers });
    }

    public anonymizationUsers(ids: any[]): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/user/anonymize`, { ids }, { headers });
    }

    public bulkChangeType(ids: any[], type: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/user/change-type`, { ids, type }, { headers });
    }

    public bulkChangeUserPosition(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/user/bulk-change-position`, body, { headers });
    }

    public bulkChangeUserSupervisor(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/user/bulk-change-supervisor`, body, { headers });
    }

    public bulkChangeUserActivation(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/user/bulk-activation`, body, { headers });
    }

    public bulkUserSendInvitation(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/user/bulk-send-invitation`, body, { headers });
    }

    public bulkChangeUserLanguage(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/user/bulk-change-language`, body, { headers });
    }

    public bulkChangeUserGroup(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/user/bulk-change-group`, body, { headers });
    }

    public bulkDeleteUserFromGroup(body: any): Observable<any> {
        const options = {
            headers: this.defaultHeaders,
            body
        };
        return this.http.delete<any>(`${this.basePath}/admin/user/bulk-change-group`, options);
    }

    public toggleActiveUser(userId: number, status: boolean): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/user/${userId}/activation`, { isActive: status }, { headers });
    }

    public loginAsUser(userId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/user/${userId}/login`, { headers });
    }

    public groupUsers(idGroup: number, pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/group/${idGroup}/user?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public addGroup(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/group`, body, { headers });
    }

    public deleteGroup(idGroup: number, option: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/admin/group/${idGroup}${option}`, { headers });
    }

    public roles(prefix = '', pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/${prefix}admin/access-role?sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public accessLevels(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/access-level`, { headers });
    }

    public saveRole(body: any, prefix = ''): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/${prefix}admin/access-role`, body, { headers });
    }

    public deleteRole(idRole: number, prefix = ''): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/${prefix}admin/access-role/${idRole}`, { headers });
    }

    public getRole(idRole: number, prefix = ''): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/${prefix}admin/access-role/${idRole}`, { headers });
    }

    public checkRoleName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/role-check-name`, body, { headers });
    }

    public activities(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/user/activity?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public downloadActivitiesXls(params: string, columns: string): string {
        return `/admin/user/activity/xls?${params}&columns=${columns}`;
    }

    public certificates(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/certificate-template?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public getNotifications(prefix = ''): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/${prefix}admin/notification`, { headers });
    }

    public getNotificationById(notificationId: any, id: any, prefix = ''): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/${prefix}admin/notification/${notificationId}/${id}`, { headers });
    }

    public selfStatus(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/company/self`, { headers });
    }

    public saveNotification(notificationId: any, id: any, body: any, prefix = ''): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/${prefix}admin/notification/${notificationId}/${id}`, body, { headers });
    }

    public addUsersToGroup(groupId: any, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/group/${groupId}/user`, body, { headers });
    }

    public checkGroupName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/group/check-name`, body, { headers });
    }

    public getCompanyPositions(sortBy: string, order: string, params: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/position?sortBy=${sortBy}&order=${order}&${params}`, { headers });
    }

    public createCompanyPosition(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/position`, body, { headers });
    }

    public updateCompanyPosition(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/position/${id}`, body, { headers });
    }

    public createCompanyPoolCategory(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/pool/category`, body, { headers });
    }

    public updateCompanyPoolCategory(id: number, body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/pool/category/${id}`, body, { headers });
    }

    public deleteCompanyPoolCategoryById(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/admin/pool/category/${id}`, { headers });
    }

    public checkPositionName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/position-check-name`, body, { headers });
    }

    public checkPoolCategoryName(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/pool-category-check-name`, body, { headers });
    }

    public deleteCompanyPositionById(id: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.delete<any>(`${this.basePath}/admin/position/${id}`, { headers });
    }

    public importUserXls(file: any, body: any, translations: any, customFields: any[] = []): Observable<any> {
        const formParams = new FormData();
        formParams.append('import', file);
        formParams.append('name', body.name);
        formParams.append('surname', body.surname);
        formParams.append('email', body.email);
        formParams.append('position', body.position);
        formParams.append('group', body.group);
        formParams.append('supervisor', body.supervisor);
        formParams.append('createdate', body.createDate);
        formParams.append('expirydate', body.expiryDate);
        formParams.append('isactive', body.isActive);
        formParams.append('update', body.doUpdate);
        formParams.append('delete', body.doDelete);
        formParams.append('language', body.language);
        if (body.type) {
            formParams.append('type', body.type);
        }
        customFields.forEach( (field: any) => {
            formParams.append('customField_' + field.id, body['customField_' + field.id]);
        });
        formParams.append('translations', JSON.stringify(translations));
        return this.http.post<any>(`${this.basePath}/admin/user/import`, formParams, { reportProgress: true, observe: 'events' });
    }

    public getStatistics(pageNumber: any, pageSize: any, sortBy: any, order: any, params: string, company: string): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/statistics/learning?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&${params}${company}`, { headers });
    }

    public getChartStatisticsData(startDate: any, endDate: any, company, viewType: any = 1): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/statistics/activity?startDate=${startDate}&endDate=${endDate}${company}&viewType=${viewType}`, { headers });
    }

    public downloadAgreementsXls(params: string): string {
        return `/admin/company/agreement/xls?${params}`;
    }

    public downloadUsersXls(params: string = '', columns: string): string {
        return `/admin/user/xls?${params}&columns=${columns}`;
    }

    public downloadTemplateUsersXls(body: any = {}): Observable<HttpResponse<Blob>> {
        // @ts-disable
        // @ts-ignore
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/template/xls/users`, body, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    public downloadStatisticsXls(startDate: any, endDate: any, params: string, company: any = '',): string {
        return `/admin/statistics/learning/xls?startDate=${startDate}&endDate=${endDate}&${params}${company}`;
    }

    public importGroupsXls(file: any, body: any): Observable<any> {
        const formParams = new FormData();
        formParams.append('import', file);
        formParams.append('name', body.name);
        formParams.append('parent', body.parent);
        return this.http.post<any>(`${this.basePath}/admin/group/import`, formParams, { reportProgress: true, observe: 'events' });
    }

    public downloadTemplateGroupsXls(body: any = {}): Observable<HttpResponse<Blob>> {
        // @ts-disable
        // @ts-ignore
        return this.http.post<HttpResponse<Blob>>(`${this.basePath}/template/xls/groups`, body, { observe: 'response', responseType: 'blob', headers: 'X-Downloadable:true' });
    }

    public getVersion(): Observable<any> {
        return this.http.get<any>(`${this.basePath}/version`);
    }

    public getGuide(): Observable<any> {
        return this.http.get<any>(`${this.basePath}/guide`);
    }

    public setGuideSeen(id: number): Observable<any> {
        return this.http.post<any>(`${this.basePath}/guide-seen`, { id }, { reportProgress: true, observe: 'events' });
    }

    public emailValidation(email: string, userId: number = null): Observable<any> {
        const headers = this.defaultHeaders;
        const body = {
            email,
            userid: userId
        };
        return this.http.post<any>(`${this.basePath}/user/email-validation`, body, { headers });
    }

    getBaseGraphics(type: string = ''): Observable<any> {
        const headers = this.defaultHeaders;
        type = !!type ? `?type=${type}` : ''

        return this.http.get<any>(`${this.basePath}/cover${type}`, { headers });
    }

    public getAvailabilityPathUsers(pathId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/path/${pathId}/availability`, { headers });
    }

    public getAvailabilityTrainingUsers(trainingId: number): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/training/${trainingId}/availability`, { headers });
    }

    public saveAvailabilityPathUsers(pathId: number, availability: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/path/${pathId}/availability`, { availability }, { headers });
    }

    public saveGroupAvailabilityPathUsers(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/path/group-availability`, body, { headers });
    }

    public saveGroupAvailabilityTrainingUsers(body: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/training/group-availability`, body, { headers });
    }

    public saveAvailabilityTrainingUsers(trainingId: number, availability: any): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/training/${trainingId}/availability`, { availability }, { headers });
    }

    public getIntegration(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/integration`, { headers });
    }

    public saveIntegration(body: IntegrationInterface): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/integration`, body, { headers });
    }

    public testIntegration(body: { email?: string, password?: string, code?: string }): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.post<any>(`${this.basePath}/admin/integration-test`, body, { headers });
    }

    public stripe(): Observable<any> {
        const headers = this.defaultHeaders;
        return this.http.get<any>(`${this.basePath}/admin/stripe/portal`, { headers });
    }
}

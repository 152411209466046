import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {Subscription} from 'rxjs';
import {ApiService} from '../../../api/api.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})

export class CalendarComponent implements OnInit, OnChanges {
    @Input() setSize: any;
    @Input() change: any;
    @Input() items: any;
    @Input() defaultItems: any;
    @Input() preview: boolean;
    @Input() border: boolean = true;

    widget: any;
    activities: any[] = [];
    subscriptions: Subscription = new Subscription();
    userId: number;
    records: any[] = [];
    typeFilter: string[] = [];
    statusFilter: string[] = [];
    myDate = new Date();
    localStorageFilter: string = '';

    constructor(private _helper: HelperService,
                private _api: ApiService,
                private router: Router,
                private _translate: TranslateService) {
    }

    ngOnInit() {
        this.prepareWidgetData()
        this.userId = this._helper.getUser().id;
        this.applyFilters();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.prepareWidgetData();
    }

    prepareWidgetData() {
        if (this.items) {
            this.widget = {
                sizeClass: null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize,
                backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
                elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
            };
        }
    }

    applyFilters() {
        const statusParams = this.statusFilter.length ? 'status[]=' + this.statusFilter.join('&status[]=').replace('Calendar.', '') : '';
        let typeParams = this.typeFilter.length ? 'type[]=' + this.typeFilter.join('&type[]=').replace('Calendar.', '') : '';
        if (statusParams) {
            typeParams = '&' + typeParams;
        }
        const params = '?' + statusParams + typeParams + '&limit=20';
        this.getCalendarEntries(params);
        localStorage.setItem(this.localStorageFilter, JSON.stringify({
            statusFilter: this.statusFilter,
            typeFilter: this.typeFilter
        }));
    }

    getCalendarEntries(params: string) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        let hasToday = false;
        this.records = [];
        if (this.preview) {
            const activities: any = [];
            activities.push({
                    relatedObjectName: this._translate.instant('DashboardWidgetCalendar.Example1'),
                    date: '2024-10-04',
                    hasToday: true
                },
                {
                    relatedObjectName: this._translate.instant('DashboardWidgetCalendar.Example2'),
                    date: '2024-10-01',
                },
                {
                    relatedObjectName: this._translate.instant('DashboardWidgetCalendar.Example3'),
                    date: '2024-09-28',
                },
                {
                    relatedObjectName: this._translate.instant('DashboardWidgetCalendar.Example4'),
                    date: '2024-09-26',
                },
                {
                    relatedObjectName: this._translate.instant('DashboardWidgetCalendar.Example5'),
                    date: '2024-09-20',
                },
                {
                    relatedObjectName: this._translate.instant('DashboardWidgetCalendar.Example6'),
                    date: '2024-09-16',
                })

            for (const activity of activities) {
                const act = {...activity};
                act.date = new Date(act.date);
                if (act.date.getFullYear() === today.getFullYear() &&
                    act.date.getMonth() === today.getMonth() &&
                    act.date.getDate() === today.getDate()) {
                    hasToday = true;
                }
                this.records.push(act);
            }

            this.records.sort((a, b) => {
                return b.date.getTime() - a.date.getTime();
            });

            this.activities = [...this.records];
        } else {
            this.subscriptions.add(this._api.User.calendar(this.userId, params).subscribe((activities) => {
                if (activities.length > 0) {
                    this.activities = [...activities];
                    let i = 0;
                    for (const activity of activities) {
                        if (i < 20) {
                            const act = {...activity};
                            act.date = new Date(act.date);
                            if (act.date.getFullYear() === today.getFullYear() &&
                                act.date.getMonth() === today.getMonth() &&
                                act.date.getDate() === today.getDate()) {
                                hasToday = true;
                            }
                            this.records.push(act);
                        }
                        i++;
                    }

                    if (!hasToday && this.activities.length) {
                        this.records.push({
                            date: today,
                            message: '',
                            relatedObjectId: 0,
                            relatedObjectName: '',
                            relatedObjectType: '',
                            type: ''
                        });
                    }

                    this.records.sort((a, b) => {
                        return b.date.getTime() - a.date.getTime();
                    });

                    this.activities = [...this.records];
                } else {
                    const curentDate = new Date();
                    activities.push({
                        relatedObjectName: this._translate.instant('DashboardWidgetCalendar.YouHaveNoTasksScheduledForToday'),
                        date: curentDate.toISOString().split('T')[0],
                        hascurentDate: true,
                        empty: true
                    });
                  curentDate.setDate(curentDate.getDate() + 1);
                    activities.push({
                        relatedObjectName: this._translate.instant('Wszystkie zaplanowane zadania zostały zrealizowane'),
                        relatedObjectName2: this._translate.instant('Sprawdź dostępne szkolenia i rozwijaj swoje umiejętności!'),
                        date: curentDate.toISOString().split('T')[0],
                      empty: true
                    });
                  curentDate.setDate(curentDate.getDate() + 1);
                    activities.push({
                        relatedObjectName: '',
                        date: curentDate.toISOString().split('T')[0],
                      empty: true
                    });
                  curentDate.setDate(curentDate.getDate() + 1);
                    activities.push({
                        relatedObjectName: '',
                        date: curentDate.toISOString().split('T')[0],
                      empty: true
                    });
                  curentDate.setDate(curentDate.getDate() + 1);
                    activities.push({
                        relatedObjectName: '',
                        date: curentDate.toISOString().split('T')[0],
                      empty: true
                    });
                  curentDate.setDate(curentDate.getDate() + 1);
                    activities.push({
                        relatedObjectName: '',
                        date: curentDate.toISOString().split('T')[0],
                      empty: true
                    });

                  for (const activity of activities) {
                    const act = {...activity};
                    act.date = new Date(act.date);
                    if (act.date.getFullYear() === today.getFullYear() &&
                        act.date.getMonth() === today.getMonth() &&
                        act.date.getDate() === today.getDate()) {
                      hasToday = true;
                    }
                    this.records.push(act);
                  }
                  this.activities = [...this.records];
                }

            }));
        }

    }

    isDifferentYearOrMonth(i: number): boolean {
        if (i === 0) return true;
        const d1 = this.activities[i - 1].date;
        const d2 = this.activities[i].date;
        return !(d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth());
    }

    getTimeClass(i: number, hasToday: boolean): string {
        if (hasToday) {
            return 'today'
        }
        if (this.isPast(i)) {
            return 'past'
        }

        if (this.isToday(i)) {
            return 'today'
        }

        if (this.isFuture(i)) {
            return 'future'
        }
    }

    isPast(i: number): boolean {
        return !(this.isFuture(i) || this.isToday(i));
    }

    isToday(i: number): boolean {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        const d2 = new Date();
        d2.setTime(this.activities[i].date.getTime());
        d2.setHours(0, 0, 0, 0);
        return d.getTime() === d2.getTime();
    };

    isFuture(i: number): boolean {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        d.setDate(d.getDate() + 1);
        return this.activities[i].date.getTime() >= d.getTime();
    }

    isSameDay(i: number): boolean {
        if (i === 0) return false;
        const d = new Date();
        d.setTime(this.activities[i].date.getTime());
        d.setHours(0, 0, 0, 0);
        const d2 = new Date();
        d2.setTime(this.activities[i - 1].date.getTime());
        d2.setHours(0, 0, 0, 0);
        return d.getTime() === d2.getTime();
    }

    showActivity(activity) {

        if (this.preview) {
            return '';
        }

        if (activity.relatedObjectType === 'training') {
            this.router.navigateByUrl('/app/training/player/' + activity.relatedObjectId);
            return;
        }

        if (activity.relatedObjectType === 'test') {
            this.router.navigateByUrl('/app/pool/player/' + activity.relatedObjectId);
            return;
        }

        if (activity.relatedObjectType === 'path') {
            this.router.navigateByUrl('/app/path/path-view/' + activity.relatedObjectId);
            return;
        }

        if (activity.relatedObjectType === 'survey') {
            this.router.navigateByUrl('/app/survey/player/' + activity.relatedObjectId);
            return;
        }

        if (activity.relatedObjectType === 'knowledge-base-entry') {
            this.router.navigateByUrl('/app/library/knowledge-base/article/player/' + activity.relatedObjectId);
            return;
        }

        if (activity.relatedObjectType === 'onboarding') {
            this._api.Onboarding.getOnboardingPlayer(this._helper.getUser().id, activity.relatedObjectId, false).subscribe((onboarding) => {
                this.router.navigateByUrl(`/app/onboarding/onboarding-player/${activity.relatedObjectId}/${onboarding.sections[0].elements[0].id}`);
                return;
            })
        }

        if (activity.relatedObjectType === 'preboarding') {
            this._api.Onboarding.getOnboardingPlayer(this._helper.getUser().id, activity.relatedObjectId, false).subscribe((preboarding) => {
                this.router.navigateByUrl(`/app/preboarding/preboarding-player/${activity.relatedObjectId}/${preboarding.sections[0].elements[0].id}`);
                return;
            })
        }

        if (activity.relatedObjectType === 'task') {
            this.router.navigateByUrl(`/app/onboarding/onboarding-player/${activity.onboardingId}/${activity.relatedObjectId}`);
            return;
        }

        if (activity.relatedObjectType === 'onboarding-section') {
            this._api.Onboarding.getOnboardingPlayer(this._helper.getUser().id, activity.relatedObjectId, false).subscribe((onboarding) => {
                const elementId = onboarding.sections.find(val => val.id === activity.relatedObjectId).elements[0].id
                this.router.navigateByUrl(`/app/onboarding/onboarding-player/${activity.onboardingId}/${elementId}`);
                return;
            })
        }

        if (activity.relatedObjectType === 'preboarding-section') {
            this._api.Onboarding.getOnboardingPlayer(this._helper.getUser().id, activity.relatedObjectId, false).subscribe((preboarding) => {
                const elementId = preboarding.sections.find(val => val.id === activity.relatedObjectId).elements[0].id
                this.router.navigateByUrl(`/app/preboarding/preboarding-player/${activity.onboardingId}/${elementId}`);
                return;
            })
        }

        if (activity.relatedObjectType === 'preboarding-task') {
            this.router.navigateByUrl(`/app/preboarding/preboarding-player/${activity.onboardingId}/${activity.relatedObjectId}`);
            return;
        }

        if (activity.relatedObjectType === 'gamification') {
            this._api.Gamification.getGamificationPlayer(this._helper.getUser().id, activity.relatedObjectId, false).subscribe((gamification) => {
                this.router.navigateByUrl(`/app/gamification/gamification-player/${activity.relatedObjectId}/${gamification.sections[0].elements[0].id}`);
                return;
            })
        }

        if (activity.relatedObjectType === 'gamification-task') {
            this.router.navigateByUrl(`/app/gamification/gamification-player/${activity.gamificationId}/${activity.relatedObjectId}`);
            return;
        }

        if (activity.relatedObjectType === 'gamification-section') {
            this._api.Gamification.getGamificationPlayer(this._helper.getUser().id, activity.gamificationId, false).subscribe((gamification) => {
                const elementId = gamification.sections.find(val => val.id === activity.relatedObjectId).elements[0].id
                this.router.navigateByUrl(`/app/gamification/gamification-player/${activity.gamificationId}/${elementId}`);
                return;
            })
        }

        if (activity.relatedObjectType === 'gamification-section-element') {
            this.router.navigateByUrl(`/app/gamification/gamification-player/${activity.gamificationId}/${activity.relatedObjectId}`);
            return;
        }

        if (activity.relatedObjectType === 'certificate') {
            this.router.navigateByUrl('/app/profile/certificates');
            return;
        }

        if (activity.relatedObjectType === 'feedback' || activity.relatedObjectType === 'feedback-questionnaire' || activity.relatedObjectType === 'feedback-questionnaire-self-assessment') {
            this.router.navigateByUrl('/app/feedback');
            return;
        }
    }

}
import { NgModule } from '@angular/core';
import { EChartsDirective } from './directives/echarts.directive';
import { SlimScrollDirective } from './directives/slim-scroll.directive';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { TrimPipe } from './pipes/trim.pipe';
import { SortByDirective } from './directives/sortby.directive';
import { RangeToPipe } from './pipes/range-to.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from './dialogs/info-dialog/info-dialog.component';
import { TextCapDirective } from './directives/text-cap.directive';
import { SafePipe } from './pipes/safe.pipe';
import { HasAccessDirective } from './directives/has-access.directive';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AssignDialogComponent } from './dialogs/assign-dialog/assign-dialog.component';
import {AssignGroupDialogComponent} from './dialogs/assign-group-dialog/assign-group-dialog.component';
import { Nl2BrPipe } from './pipes/nl2br.pipe';
import { FormatTimePipe } from './pipes/format-time-pipe';
import { DigitOnlyDirective } from './directives/digit-only.directive';
import { AvatarModule } from 'ngx-avatar';
import { RemoveSpacesPipe } from './pipes/remove-spaces.pipe';
import { GuideDialogComponent } from './components/guide-dialog/guide-dialog.component';
import { BlockWhiteSpaceDirective } from './directives/block-white-space.directive';
import { FlexTableDirective } from './directives/flex-table.directive';
import { ImagePreloadDirective } from './directives/image-preload.directive';
import { TableHeaderDirective } from './directives/table-header.directive';
import { RoleTooltipPipe } from './pipes/role-tooltip.pipe';
import { CalendarViewComponent } from './components/calendar-view/calendar-view.component';
import { DateFromNowPipe } from './pipes/date-from-now.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { RouterModule } from '@angular/router';
import { AutoCloseMobileNavDirective } from './directives/auto-close-mobile-nav.directive';
import { AccordionNavDirective } from './directives/accordion-nav.directive';
import { AppendSubmenuIconDirective } from './directives/append-submenu-icon.directive';
import { HighlightActiveItemsDirective } from './directives/highlight-active-items.directive';
import { NoPermissionComponent } from './components/no-permission/no-permission.component';
import { PathTrainingTileComponent } from './components/path-training-tile/path-training-tile.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryDialogComponent } from './dialogs/category-dialog/category-dialog.component';
import { DownloadDialogComponent } from './dialogs/download-dialog/download-dialog.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ConfigTableDialogComponent } from './dialogs/config-table-dialog/config-table-dialog.component';
import { ChoiceMethodDialogComponent } from './dialogs/choice-method-dialog/choice-method-dialog.component';
import {
    ChoiceBasePathTrainingDialogComponent
} from './dialogs/choice-base-path-training-dialog/choice-base-path-training-dialog.component';
import { ArchiveConfirmDialogComponent } from './dialogs/archive-confirm-dialog/archive-confirm-dialog.component';
import { CreatorInfoDialogComponent } from './dialogs/creator-info-dialog/creator-info-dialog.component';
import { CreateStickyFooterDirective } from '../configuration/roles/role-create/create-sticky-footer.directive';
import { ChoiceCategoryDialogComponent } from './dialogs/choice-category-dialog/choice-category-dialog.component';
import { UpperCaseFirstLetterDirective } from './directives/upper-case-first-letter.directive';
import {
    ConfigCertificateDialogComponent
} from './dialogs/config-certificate-dialog/config-certificate-dialog.component';
import { ShowHiddenCategoryDirective } from './directives/show-hidden-category.directive';
import {
    TrainingPathVisibilityDialogComponent
} from './dialogs/training-path-visibility-dialog/training-path-visibility-dialog.component';
import {
    PublishPathTrainingDialogComponent
} from './dialogs/publish-path-training-dialog/publish-path-training-dialog.component';
import { HighlightDirective } from './directives/highlight-active-box.directive';
import {
    AddRemoveVisibilityDialogComponent
} from './dialogs/add-remove-visibility-dialog/add-remove-visibility-dialog.component';
import { ShowAtMostPipe } from './pipes/show-at-most.pipe';
import { TableWithDataComponent } from './components/table-with-data/table-with-data.component';
import { UsersVisibilityDialogComponent } from './dialogs/users-visibility-dialog/users-visibility-dialog.component';
import { HumanizeTimePipe } from './pipes/humanize-time.pipe';
import {
    ChoiceBaseCertificateDialogComponent
} from './dialogs/choice-base-certificate-dialog/choice-base-certificate-dialog.component';
import { AssignUnassignDialogComponent } from './dialogs/assign-unassign-dialog/assign-unassign-dialog.component';
import { TestTileComponent } from './components/test-tile/test-tile.component';
import { EmptyTileComponent } from './components/empty-tile/empty-tile.component';
import { ShowHiddenCarouselDirective } from './directives/show-hidden-carousel.directive';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MultiStatusViewPipe } from './pipes/multi-status-view.pipe';
import { ChoiceBasePoolDialogComponent } from './dialogs/choice-base-pool-dialog/choice-base-pool-dialog.component';
import { ProductGroupsDialogComponent } from './dialogs/product-groups-dialog/product-groups-dialog.component';
import {
    SelectionOfNumbersDialogComponent
} from './dialogs/selection-of-numbers-dialog/selection-of-numbers-dialog.component';
import { DataGridScrollDirective } from './directives/data-grid-scroll.directive';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateToPipe } from './pipes/translate-to.pipe';
import { SendManualEmailDialogComponent } from './dialogs/send-manual-email-dialog/send-manual-email-dialog.component';
import {
    SendManualSystemNotificationDialogComponent
} from './dialogs/send-manual-system-notification-dialog/send-manual-system-notification-dialog.component';
import { NoConnectionComponent } from './components/no-connection/no-connection.component';
import { PreviewImageDialogComponent } from './dialogs/preview-image/preview-image.component';
import { CustomeSelectComponent } from './components/custome-select/custome-select.component';
import { ParseUrlPipe } from './pipes/parse-url.pipe';
import { UpperCaseEveryLetterDirective } from './directives/upper-case-every-letter.directive';
import { DatepickerSingleDateDirective } from './directives/datepicker-single-date.directive';
import { LowerCaseDirective } from './directives/lower-case.directive';
import {
    CertificatePreviewModalComponent
} from './dialogs/certificate-preview-modal/certificate-preview-modal.component';
import { CertificatePreviewComponent } from './components/certificate-preview/certificate-preview.component';
import { AppMaterialModule } from './material/app.material.module';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AssignCarerDialogComponent } from './dialogs/assign-carer-dialog/assign-carer-dialog.component';
import {
    AssignCarerActionDialogComponent
} from './dialogs/assign-carer-action-dialog/assign-carer-action-dialog.component';
import {
    FilterStatusTypeDialogComponent
} from './dialogs/filter-status-type-dialog/filter-status-type-dialog.component';
import { FilterStatusDialogComponent } from './dialogs/filter-status-dialog/filter-status-dialog.component';
import { SortDialogComponent } from './dialogs/sort-dialog/sort-dialog.component';
import { SideBarScrollDirective } from './directives/side-bar-scroll.directive';
import {
    ChoiceBaseSurveyDialogComponent
} from './dialogs/choice-base-survey-dialog/choice-base-survey-dialog.component';
import { NgxPieChartZeroMarginDirective } from './directives/ngx-charts-pie-chart.directive';
import { SurveyQuestionStatsComponent } from './components/survey-question-stats/survey-question-stats.component';
import {
    SurveyOtherAnswersDialogComponent
} from './dialogs/survey-other-answers-dialog/syrvey-other-answers-dialog.component';
import { NextTrainingComponent } from './components/next-training/next-training.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {
    TeamOrgUserStatsDialogComponent
} from './dialogs/team-org-user-stats-dialog/team-org-user-stats-dialog.component';
import { SurveyQuestionSummaryComponent } from './components/survey-question-summary/survey-question-summary.component';
import { AttachmentComponent } from './dialogs/attachment/attachment.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { IncludeIconComponent } from './dialogs/include-icon/include-icon.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UserComponent } from './dialogs/user/user.component';
import { ReorderElementsComponent } from './dialogs/reorder-elements/reorder-elements.component';
import { MaintenanceModePageComponent } from './components/maintenance-mode-page/maintenance-mode-page.component';
import { CropImageComponent } from './dialogs/crop-image/crop-image.component';
import { ChoiceBaseGraphicComponent } from './dialogs/choice-base-graphic/choice-base-graphic.component';
import {
    ChoiceBaseArticleDialogComponent
} from './dialogs/choice-base-article-dialog/choice-base-article-dialog.component';
import { ResizableWidthDirective } from './directives/resizable-width.directive';
import { ResizableHeightDirective } from './directives/resizable-height.directive';
import { ShowHiddenNumberCategoryArticleDirective } from './directives/show-hidden-number-category-article.directive';
import { ImagePreviewComponent } from './dialogs/image-preview/image-preview.component';
import { YellowSearchTextPipe } from './pipes/yellow-search-text.pipe';
import { NoPermissionSysAdminComponent } from './components/no-permission-sys-admin/no-permission-sys-admin.component';
import { WelcomePageSysAdminComponent } from './components/welcome-page-sys-admin/welcome-page-sys-admin.component';
import {
    ChoiceBaseOnboardingDialogComponent
} from './dialogs/choice-base-onboarding-dialog/choice-base-onboarding-dialog.component';
import { ApprovalTasksDialogComponent } from './dialogs/approval-tasks-dialog/approval-tasks-dialog.component';
import {
    ApproveRejectTaskDialogComponent
} from './dialogs/approve-reject-task-dialog/approve-reject-task-dialog.component';
import { AddFromLibraryComponent } from './dialogs/add-from-library/add-from-library.component';
import { TrainingFromDiskComponent } from './dialogs/training-from-disk/training-from-disk.component';
import {
    ChoiceBaseQuestionnaireDialogComponent
} from './dialogs/choice-base-questionnaire-dialog/choice-base-questionnaire-dialog.component';
import { YellowSearchTextDirective } from './directives/yellow-search-text.directive';
import { FeedbackCompareComponent } from './components/feedback-compare/feedback-compare.component';
import { ChartsModule } from 'ng2-charts';
import { RemoveMinusPipe } from './pipes/remove-minus.pipe';
import { GaugeChartComponent } from './components/gauge-chart/gauge-chart.component';
import { EmptyPageComponent } from './components/empty-page/empty-page.component';
import { OnboardingCoordinatorTaskDialogComponent } from './dialogs/onboarding-coordinator-task-dialog/onboarding-coordinator-task-dialog.component';
import {
    OnboardingCoordinatorTaskConfirmationDialogComponent
} from './dialogs/onboarding-coordinator-task-confirmation-dialog/onboarding-coordinator-task-confirmation-dialog.component';
import { GeneratePoolQuestionsByAiDialogComponent } from './dialogs/generate-pool-questions-by-ai-dialog/generate-pool-questions-by-ai-dialog.component';
import {
    ChoiceBaseGamificationDialogComponent
} from './dialogs/choice-base-gamification-dialog/choice-base-gamification-dialog.component';
import {
    ApprovePointsTaskDialogComponent
} from './dialogs/approve-points-task-dialog/approve-points-task-dialog.component';
import {FeedbackUserReportComponent} from './components/feedback-user-report/feedback-user-report.component';
import {TruncatePipe} from './pipes/truncate.pipe';
import {ChoiceBaseMultiPathTrainingDialogComponent} from './dialogs/choice-base-multi-path-training-dialog/choice-base-path-training-dialog.component';
import {GroupAssignUnassignDialogComponent} from './dialogs/group-assign-unassign-dialog/group-assign-unassign-dialog.component';
import {AssignExpiryDateDialogComponent} from './dialogs/assign-expiry-date-dialog/assign-expiry-date-dialog.component';
import { ReportComponent } from './components/report/report.component';
import { ReportDialogComponent } from './components/report/dialogs/report-dialog/report-dialog.component';
import {VisibilityGroupDialogComponent} from './dialogs/visibility-group-dialog/visibility-group-dialog.component';
import {GroupAddRemoveVisibilityDialogComponent} from './dialogs/group-add-remove-visibility-dialog/group-add-remove-visibility-dialog.component';
import {FeedbackDevelopmentTipsComponent} from './components/feedback-development-tips/feedback-development-tips.component';
import {ChoiceBaseSelectPathTrainingDialogComponent} from './dialogs/choice-base-select-path-training-dialog/choice-base-select-path-training-dialog.component';
import {WelcomeComponent} from './widgets/welcome/welcome.component';
import {TrainingNeedsComponent} from './widgets/training-needs/training-needs.component';
import {FavoriteComponent} from './widgets/favorite/favorite.component';
import {CalendarComponent} from './widgets/calendar/calendar.component'
import {UserShortcutComponent} from './widgets/user-shortcut/user-shortcut.component';
import {UrlComponent} from './widgets/url/url.component';
import {CertificateComponent} from './widgets/certificate/certificate.component';
import {FeedbackComponent} from './widgets/feedback/feedback.component';
import {KnowledgeBaseComponent} from './widgets/knowledge-base/knowledge-base.component';
import {OnboardingComponent} from './widgets/onboarding/onboarding.component';
import {PreboardingComponent} from './widgets/preboarding/preboarding.component';
import {GamificationComponent} from './widgets/gamification/gamification.component';
import {NewPathsTrainingsComponent} from './widgets/new-paths-trainings/new-paths-trainings.component';
import {TasksComponent} from './widgets/tasks/tasks.component';
import {EmployeeOnboardingComponent} from './widgets/employee-onboarding/employee-onboarding.component';
import {EmployeePreboardingComponent} from './widgets/employee-preboarding/employee-preboarding.component';
import {NewEmployeesComponent} from './widgets/new-employees/new-employees.component';
import {EmployeeLogsComponent} from './widgets/employee-logs/employee-logs.component';
import {EmployeeCertificatesComponent} from './widgets/employee-certificates/employee-certificates.component';
import {EmployeeFeedbackComponent} from './widgets/employee-feedback/employee-feedback.component';
import {MessageForTeamComponent} from './widgets/message-for-team/message-for-team.component';
import {DolineoSubscriptionComponent} from './widgets/dolineo-subscription/dolineo-subscription.component';
import {WaitingForRealizationComponent} from './widgets/waiting-for-realization/waiting-for-realization.component';
import {RecentlyCreatedComponent} from './widgets/recently-created/recently-created.component';
import {TrainingsStatsComponent} from './widgets/trainings-stats/trainings-stats.component';
import {PathsStatsComponent} from './widgets/paths-stats/paths-stats.component';
import {ShowHiddenStatsCategoryDirective} from './directives/show-hidden-stats-category.directive';
import {HtmlTooltipDirective} from './directives/html-tooltip.directive';
import {HtmlTooltipComponent} from './components/html-tooltip/html-tooltip.component';
import {MissedDeadlinesComponent} from './widgets/missed-deadlines/missed-deadlines.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { TaskTileComponent } from './components/task-tile/task-tile.component';
import { UpdateTaskStatusDialogComponent } from './dialogs/update-task-status-dialog/update-task-status-dialog.component';


const avatarColors = ['#959D9F', '#E8883A', '#DA4E58', '#DB6196', '#7B68EE', '#1D619F', '#008ABE', '#0AB4FF', '#08C7E0', '#00B5B5', '#1DB954'];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AppMaterialModule,
        TranslateModule,
        NgxDaterangepickerMd.forRoot(),
        AvatarModule.forRoot({
            colors: avatarColors
        }),
        RouterModule,
        CarouselModule,
        NgxChartsModule,
        NgxFileDropModule,
        ImageCropperModule,
        ChartsModule
    ],
    declarations: [
        EChartsDirective,
        SlimScrollDirective,
        CapitalizePipe,
        TrimPipe,
        SortByDirective,
        RangeToPipe,
        ConfirmDialogComponent,
        InfoDialogComponent,
        FilterPipe,
        TextCapDirective,
        SafePipe,
        FlexTableDirective,
        ImagePreloadDirective,
        UploadFileComponent,
        CropImageComponent,
        TableHeaderDirective,
        HasAccessDirective,
        BackButtonComponent,
        DataGridComponent,
        AssignDialogComponent,
        AssignGroupDialogComponent,
        Nl2BrPipe,
        FormatTimePipe,
        DigitOnlyDirective,
        RemoveSpacesPipe,
        GuideDialogComponent,
        BlockWhiteSpaceDirective,
        RoleTooltipPipe,
        CalendarViewComponent,
        DateFromNowPipe,
        LoaderComponent,
        AutoCloseMobileNavDirective,
        AccordionNavDirective,
        AppendSubmenuIconDirective,
        HighlightActiveItemsDirective,
        NoPermissionComponent,
        PathTrainingTileComponent,
        CategoriesComponent,
        CategoryDialogComponent,
        DownloadDialogComponent,
        NotificationsComponent,
        ConfigTableDialogComponent,
        ArchiveConfirmDialogComponent,
        ChoiceMethodDialogComponent,
        ChoiceBasePathTrainingDialogComponent,
        ChoiceBaseMultiPathTrainingDialogComponent,
        CreatorInfoDialogComponent,
        CreateStickyFooterDirective,
        ChoiceCategoryDialogComponent,
        UpperCaseFirstLetterDirective,
        UpperCaseEveryLetterDirective,
        LowerCaseDirective,
        ConfigCertificateDialogComponent,
        ShowHiddenCategoryDirective,
        TrainingPathVisibilityDialogComponent,
        PublishPathTrainingDialogComponent,
        HighlightDirective,
        AddRemoveVisibilityDialogComponent,
        ShowAtMostPipe,
        TableWithDataComponent,
        UsersVisibilityDialogComponent,
        ChoiceBaseCertificateDialogComponent,
        ChoiceBasePoolDialogComponent,
        HumanizeTimePipe,
        AssignUnassignDialogComponent,
        TestTileComponent,
        EmptyTileComponent,
        ShowHiddenCarouselDirective,
        MultiStatusViewPipe,
        ProductGroupsDialogComponent,
        SelectionOfNumbersDialogComponent,
        DataGridScrollDirective,
        NoConnectionComponent,
        DataGridScrollDirective,
        ProductGroupsDialogComponent,
        TranslateToPipe,
        SendManualEmailDialogComponent,
        SendManualSystemNotificationDialogComponent,
        PreviewImageDialogComponent,
        CustomeSelectComponent,
        ParseUrlPipe,
        DatepickerSingleDateDirective,
        CertificatePreviewModalComponent,
        CertificatePreviewComponent,
        NotFoundComponent,
        CertificatePreviewComponent,
        AssignCarerDialogComponent,
        AssignCarerActionDialogComponent,
        FilterStatusTypeDialogComponent,
        FilterStatusDialogComponent,
        SortDialogComponent,
        SideBarScrollDirective,
        ChoiceBaseSurveyDialogComponent,
        NgxPieChartZeroMarginDirective,
        SurveyQuestionStatsComponent,
        SurveyOtherAnswersDialogComponent,
        NextTrainingComponent,
        TeamOrgUserStatsDialogComponent,
        SurveyQuestionSummaryComponent,
        AttachmentComponent,
        ScrollToTopComponent,
        IncludeIconComponent,
        UserComponent,
        ChoiceBaseArticleDialogComponent,
        ResizableWidthDirective,
        ResizableHeightDirective,
        ShowHiddenNumberCategoryArticleDirective,
        ImagePreviewComponent,
        ReorderElementsComponent,
        MaintenanceModePageComponent,
        ChoiceBaseGraphicComponent,
        YellowSearchTextPipe,
        ChoiceBaseGraphicComponent,
        NoPermissionSysAdminComponent,
        WelcomePageSysAdminComponent,
        ChoiceBaseOnboardingDialogComponent,
        ChoiceBaseGamificationDialogComponent,
        ApprovalTasksDialogComponent,
        ApproveRejectTaskDialogComponent,
        ApprovePointsTaskDialogComponent,
        AddFromLibraryComponent,
        TrainingFromDiskComponent,
        ChoiceBaseQuestionnaireDialogComponent,
        TrainingFromDiskComponent,
        YellowSearchTextDirective,
        FeedbackUserReportComponent,
        FeedbackDevelopmentTipsComponent,
        FeedbackCompareComponent,
        RemoveMinusPipe,
        GaugeChartComponent,
        EmptyPageComponent,
        OnboardingCoordinatorTaskDialogComponent,
        OnboardingCoordinatorTaskConfirmationDialogComponent,
        GeneratePoolQuestionsByAiDialogComponent,
        TruncatePipe,
        GroupAssignUnassignDialogComponent,
        AssignExpiryDateDialogComponent,
        ReportComponent,
        ReportDialogComponent,
        VisibilityGroupDialogComponent,
        GroupAddRemoveVisibilityDialogComponent,
        ChoiceBaseSelectPathTrainingDialogComponent,
        WelcomeComponent,
        TrainingNeedsComponent,
        FavoriteComponent,
        CalendarComponent,
        UserShortcutComponent,
        UrlComponent,
        CertificateComponent,
        FeedbackComponent,
        KnowledgeBaseComponent,
        OnboardingComponent,
        PreboardingComponent,
        GamificationComponent,
        NewPathsTrainingsComponent,
        TasksComponent,
        EmployeeOnboardingComponent,
        EmployeePreboardingComponent,
        NewEmployeesComponent,
        EmployeeLogsComponent,
        EmployeeCertificatesComponent,
        EmployeeFeedbackComponent,
        MessageForTeamComponent,
        DolineoSubscriptionComponent,
        WaitingForRealizationComponent,
        RecentlyCreatedComponent,
        TrainingsStatsComponent,
        PathsStatsComponent,
        ShowHiddenStatsCategoryDirective,
        HtmlTooltipDirective,
        HtmlTooltipComponent,
        MissedDeadlinesComponent,
        ChoiceBaseSelectPathTrainingDialogComponent,
        AssignExpiryDateDialogComponent,
        ReportComponent,
        ReportDialogComponent,
        FileUploadComponent,
        TaskTileComponent,
        UpdateTaskStatusDialogComponent,
    ],
    exports: [
        TranslateModule,
        EChartsDirective,
        SlimScrollDirective,
        UploadFileComponent,
        CommonModule,
        FormsModule,
        AppMaterialModule,
        CapitalizePipe,
        TrimPipe,
        SortByDirective,
        RangeToPipe,
        ConfirmDialogComponent,
        BackButtonComponent,
        InfoDialogComponent,
        FilterPipe,
        SafePipe,
        FlexTableDirective,
        ImagePreloadDirective,
        TableHeaderDirective,
        HasAccessDirective,
        DataGridComponent,
        AssignDialogComponent,
        AssignGroupDialogComponent,
        AssignExpiryDateDialogComponent,
        Nl2BrPipe,
        FormatTimePipe,
        DigitOnlyDirective,
        AvatarModule,
        RemoveSpacesPipe,
        GuideDialogComponent,
        BlockWhiteSpaceDirective,
        RoleTooltipPipe,
        CalendarViewComponent,
        DateFromNowPipe,
        LoaderComponent,
        AutoCloseMobileNavDirective,
        AccordionNavDirective,
        AppendSubmenuIconDirective,
        HighlightActiveItemsDirective,
        PathTrainingTileComponent,
        CategoriesComponent,
        CategoryDialogComponent,
        NotificationsComponent,
        ConfigTableDialogComponent,
        ArchiveConfirmDialogComponent,
        ChoiceMethodDialogComponent,
        ChoiceBasePathTrainingDialogComponent,
        ChoiceBaseMultiPathTrainingDialogComponent,
        CreatorInfoDialogComponent,
        CreateStickyFooterDirective,
        ChoiceCategoryDialogComponent,
        UpperCaseFirstLetterDirective,
        UpperCaseEveryLetterDirective,
        LowerCaseDirective,
        ConfigCertificateDialogComponent,
        ShowHiddenCategoryDirective,
        TrainingPathVisibilityDialogComponent,
        HighlightDirective,
        AddRemoveVisibilityDialogComponent,
        ShowAtMostPipe,
        TableWithDataComponent,
        HumanizeTimePipe,
        AssignUnassignDialogComponent,
        TestTileComponent,
        EmptyTileComponent,
        ShowHiddenCarouselDirective,
        CarouselModule,
        MultiStatusViewPipe,
        ProductGroupsDialogComponent,
        DataGridScrollDirective,
        NoConnectionComponent,
        DataGridScrollDirective,
        ProductGroupsDialogComponent,
        TranslateToPipe,
        SendManualEmailDialogComponent,
        SendManualSystemNotificationDialogComponent,
        CustomeSelectComponent,
        ParseUrlPipe,
        DatepickerSingleDateDirective,
        CertificatePreviewModalComponent,
        CertificatePreviewComponent,
        NotFoundComponent,
        CertificatePreviewComponent,
        AssignCarerDialogComponent,
        AssignCarerActionDialogComponent,
        FilterStatusTypeDialogComponent,
        SideBarScrollDirective,
        NgxChartsModule,
        NgxPieChartZeroMarginDirective,
        SurveyQuestionStatsComponent,
        SurveyOtherAnswersDialogComponent,
        NextTrainingComponent,
        TeamOrgUserStatsDialogComponent,
        SurveyQuestionSummaryComponent,
        AttachmentComponent,
        ScrollToTopComponent,
        CropImageComponent,
        IncludeIconComponent,
        UserComponent,
        ResizableWidthDirective,
        ResizableHeightDirective,
        ShowHiddenNumberCategoryArticleDirective,
        ImagePreviewComponent,
        ReorderElementsComponent,
        YellowSearchTextPipe,
        ApprovalTasksDialogComponent,
        ApprovePointsTaskDialogComponent,
        AddFromLibraryComponent,
        TrainingFromDiskComponent,
        YellowSearchTextDirective,
        FeedbackUserReportComponent,
        FeedbackDevelopmentTipsComponent,
        FeedbackCompareComponent,
        RemoveMinusPipe,
        GaugeChartComponent,
        OnboardingCoordinatorTaskDialogComponent,
        OnboardingCoordinatorTaskConfirmationDialogComponent,
        TruncatePipe,
        GroupAssignUnassignDialogComponent,
        AssignExpiryDateDialogComponent,
        ReportComponent,
        ReportDialogComponent,
        GroupAddRemoveVisibilityDialogComponent,
        ChoiceBaseSelectPathTrainingDialogComponent,
        WelcomeComponent,
        TrainingNeedsComponent,
        FavoriteComponent,
        CalendarComponent,
        UserShortcutComponent,
        UrlComponent,
        CertificateComponent,
        FeedbackComponent,
        KnowledgeBaseComponent,
        OnboardingComponent,
        PreboardingComponent,
        GamificationComponent,
        NewPathsTrainingsComponent,
        TasksComponent,
        EmployeeOnboardingComponent,
        EmployeePreboardingComponent,
        NewEmployeesComponent,
        EmployeeLogsComponent,
        EmployeeCertificatesComponent,
        EmployeeFeedbackComponent,
        MessageForTeamComponent,
        DolineoSubscriptionComponent,
        WaitingForRealizationComponent,
        RecentlyCreatedComponent,
        TrainingsStatsComponent,
        PathsStatsComponent,
        ShowHiddenStatsCategoryDirective,
        HtmlTooltipDirective,
        HtmlTooltipComponent,
        MissedDeadlinesComponent,
        ChoiceBaseSelectPathTrainingDialogComponent,
        ReportComponent,
        ReportDialogComponent,
        FileUploadComponent,
        TaskTileComponent,
    ],
    entryComponents: [InfoDialogComponent],
    providers: [
        DatePipe,
        {
            provide: MatDialogRef,
            useValue: {}
        }
    ]
})

export class SharedModule {
}

import { Component, Inject, OnInit } from '@angular/core';
import { HelperService } from '../../../shared/services/helper.service';
import { ApiService } from '../../../api/api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../core/services/auth.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    public isLoading = false;
    public hasError = false;
    public errorMessage = '';

    public positions = [];

    public user = {
        currentPassword: '',
        newPassword: '',
        newPassword2: ''
    };

    public form: FormGroup;
    public validation = {
        current_password: '',
        password: '',
        new_password: ''
    };

    constructor(private _helper: HelperService,
                private _api: ApiService,
                public dialogRef: MatDialogRef<ChangePasswordComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private _translate: TranslateService,
                private _authService: AuthService,
                private formBuilder: FormBuilder,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            current_password: [''],
            password: ['', Validators.required],
            confirm_password: ['', Validators.required]
        });

        if (this.data.currentUser === true) {
            this.form.get('current_password').setValidators(Validators.required);
        }

        this.formControlValueChanged();
    }

    formControlValueChanged() {
        const passwordControl = this.form.get('password');
        const confirmPasswordControl = this.form.get('confirm_password');

        this.form.get('password').valueChanges.subscribe(
            (value: string) => {
                const errors = this._helper.passwordValidation(value);
                if (!value.length) {
                    this.validation.password = this._translate.instant('Common.FiledCanNotBeEmpty');
                    passwordControl.setErrors({ 'invalid': true });
                } else if (errors.length) {
                    this.validation.password = this._translate.instant('Profile.PasswordValidation');
                    passwordControl.setErrors({ 'invalid': true });
                } else {
                    this.validation.password = '';
                    passwordControl.setErrors(null);
                }
            });

        this.form.get('confirm_password').valueChanges.subscribe(
            (value: string) => {
                if (value !== passwordControl.value) {
                    this.validation.new_password = this._translate.instant('Profile.PassMissmatch');
                    confirmPasswordControl.setErrors({ 'invalid': true });
                }
            });
    }

    save(): void {
        const currentPasswordControl = this.form.get('current_password');
        const passwordControl = this.form.get('password');

        this.form.markAllAsTouched();

        if (!this.form.valid) {
            return;
        }

        this.user.currentPassword = this.form.value.current_password;
        this.user.newPassword = this.form.value.password;
        this.user.newPassword2 = this.form.value.confirm_password;

        this.isLoading = true;

        this._api.User.changePassword(this.data.id, this.user).subscribe((resp) => {
            this.authService.trySetSession(this.authService.userName, resp.access_token, resp.token_type, resp.refresh_token, resp.scope, resp.aki, resp.sak, resp.st);
            this.dialogRef.close({ updated: true, data: this.user });
        }, (err) => {
            if (err.error.message === 'Current password is invalid' || err.error.details === 'Current password is invalid') {
                this.validation.current_password = this._translate.instant('Profile.InvalidPass');
                currentPasswordControl.setErrors({ 'invalid': true });
            } else if (err.error.message === 'Password has been used in the past' || err.error.details === 'Password has been used in the past') {
                this.validation.password = this._translate.instant('Profile.InvalidPassUsedInPast');
                passwordControl.setErrors({ 'invalid': true });
            }


            this.isLoading = false;
        });
    }

    logout(): void {
        this._authService.logout();
        this.dialogRef.close();
    }
}
import {Component, OnInit, Input, OnChanges, SimpleChanges, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {ApiService} from '../../../api/api.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {OwlOptions, SlidesOutputData} from 'ngx-owl-carousel-o';

@Component({
  selector: 'gamification',
  templateUrl: './gamification.component.html',
  styleUrls: ['./gamification.component.scss']
})

export class GamificationComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('widgetContainerGamification') myDiv!: ElementRef;
  @Input() setSize: any;
  @Input() change: any;
  @Input() items: any;
  @Input() defaultItems: any;
  @Input() preview: boolean;
  @Input() border: boolean = true;

  widget: any;
  certificatesCount: number = 0;
  data: any;
  waiting = true;
  viewPie: any[] = [100, 100];
  colorScheme: any;
  gamifications: any[] = [];
  isMobile: boolean;
  activeGamificationId: number = 0;

  pagination = {
    pageNumber: 1,
    pageSize: -159,
    totalElementsCount: 0,
    totalPages: 0
  };
  pieChartData = [];
    whiteBackground: boolean = false;

  customCarouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    dotsEach: 1,
    responsive: {
      0: {
        items: 1,
        mouseDrag: true,
        touchDrag: true,
        mergeFit: true,
        margin: 180,
        dotsEach: 1,
      },
    },
    nav: true
  }

  dotsEach: number = 1;
  divWidth = 0;
  objLength = 0;

  gamificationCarousel: OwlOptions = null;

  constructor(private _helper: HelperService,
              private _api: ApiService,
              private _translate: TranslateService,
              private _router: Router,
              private el: ElementRef) {}

  ngOnInit() {
    if(!this.preview){
      this.prepareGamificationsData();
    }else{
      this.getExampleData();
      this.prepareWidgetData()
      this.waiting = false;
    }
  }

  changeColor(color: string) {
    this.el.nativeElement.style.setProperty('--main-color', color);
  }

  ngAfterViewInit() {
    setInterval(() => {
      this.checkdWidth()
    }, 100);
  }

  checkdWidth() {
    if(this.myDiv){
      const divWidth = this.myDiv.nativeElement.offsetWidth;
      if (divWidth !== this.divWidth && this.gamifications) {
        this.prepareDotEach()
        this.customCarouselOptions = {
          loop: true,
          mouseDrag: false,
          touchDrag: false,
          pullDrag: false,
          dots: true,
          navSpeed: 700,
          dotsEach: this.dotsEach,
          autoplay: false,
          autoplayHoverPause: true,
          autoplayMouseleaveTimeout: 3000,
          responsive: {
            0: {
              items: 1,
              mouseDrag: true,
              touchDrag: true,
              mergeFit: true,
              margin: 0,
              dotsEach: this.dotsEach,
            },
          },
          nav: true
        };
        this.changeWidth(divWidth)
      }
    }
  }

  prepareDotEach(){
    this.dotsEach = this.gamifications.length > 10 ? Math.ceil(this.gamifications.length / 10) : 1
    this.objLength = Math.ceil(this.gamifications.length / this.dotsEach)
  }

  changeWidth(width) {
    this.divWidth = null;
    setTimeout(() => {
      this.divWidth = width;
      this.gamificationCarousel = {
        ...this.customCarouselOptions,
        navText: [`<div style="border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;" class="arrow-margin-left` + this.gamifications.length + `"><span class="chevron" style="border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;"></span></div>`,
          `<div><span class="chevron"></span></div>`],
      }
    }, 100);
  }

  getPassedData(data: SlidesOutputData) {
    this.activeGamificationId = data.startPosition;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepareWidgetData();
  }

  prepareWidgetData(){
    if(this.items) {
      this.widget = {
        sizeClass: null !== this.items.size && this.items.size !== undefined ? this.items.size : this.defaultItems.defaultSize,
        backgroundColor: null !== this.items.backgroundColor && this.items.backgroundColor !== undefined ? this.items.backgroundColor : this.defaultItems.defaultBackgroundColor,
        elementsColor: null !== this.items.elementsColor && this.items.elementsColor !== undefined ? this.items.elementsColor : this.defaultItems.defaultElementsColor,
      };
      this.whiteBackground = this.widget.backgroundColor.toLowerCase() === 'ffffff';
      this.changeColor(!this.whiteBackground ? '#' + this.widget.elementsColor : '#000');
      this.colorScheme = {
        domain: [
          !this.whiteBackground ? '#' + this.widget.elementsColor : '#00A6B3',
          !this.whiteBackground ? this.hexToRGB('#' + this.widget.backgroundColor,0.4) : '#ccedf0'
        ]
      };
    }
  }
  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  prepareGamificationsData(){
    this._api.Dashboard.getGamificationsData().subscribe((res) => {
      this.gamifications = [];
      this.gamifications = this.gamifications.concat(res);

      this.gamifications.forEach(gam => {
        const progress = gam.elementsFinishedCount / gam.totalElementsCount * 100;
        gam.pieChartData = [
          {
            name: progress.toString(),
            value: progress,
          },
          {
            name: (100 - progress - 0.001).toString(),
            value: 100 - progress - 0.001,
          }
        ]
      });

      // this.gamificationCarousel = {
      //   ...this.customCarouselOptions,
      //   navText: [`<div class="arrow-margin-left-` + this.gamifications.length + `"><img src="assets/icons/left-arrow.svg" alt="arrow-left"></div>`,
      //     `<div class="arrow-margin-right-` + this.gamifications.length + `"><img src="assets/icons/right-arrow.svg" alt="arrow-right"></div>`],
      // };

      this.waiting = false;
      this.prepareWidgetData()
    });
  }

  getExampleData(){
    const progress1 = 50;
    const pieChartDataExample1 = [
      {
        name: progress1.toString(),
        value: progress1,
      },
      {
        name: (100 - progress1 - 0.001).toString(),
        value: 100 - progress1 - 0.001,
      }
    ];
    const progress2 = 25;
    const pieChartDataExample2 = [
      {
        name: progress2.toString(),
        value: progress2,
      },
      {
        name: (100 - progress2 - 0.001).toString(),
        value: 100 - progress2 - 0.001,
      }
    ];
    this.gamifications = [
      {
        id: 0,
        name: this._translate.instant('DashboardWidgetGamification.Example1'),
        ranking: [0,1],
        rankingPlace: 1,
        totalElementsCount: 2,
        elementsFinishedCount: 1,
        totalPoints: 400,
        pieChartData: pieChartDataExample1
      },
      {
        id: 0,
        name: this._translate.instant('DashboardWidgetGamification.Example2'),
        ranking: [0,1,2],
        rankingPlace: 2,
        totalElementsCount: 4,
        elementsFinishedCount: 1,
        totalPoints: 10,
        pieChartData: pieChartDataExample2
      }
    ];
  }

  goTo(){
    if(!this.preview){
      this._router.navigateByUrl('/app/gamification/my-gamifications');
    }
  }
}
<div class="widget-container"
     *ngIf="items && defaultItems"
     [ngClass]="('widget-container-welcome' + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.background]="('linear-gradient(90deg, '+ hexToRGB('#' + widget.backgroundColor,0.7) +' 10%, '+ hexToRGB('#' + widget.backgroundColor,1) +' 100%)')"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
     (click)="goTo()"
>
    <div class="widget-container__name" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ widget.name }}</div>

    <svg class="background-icon" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle [style.fill]="'#' + widget.elementsColor" cx="30" cy="30" r="30" fill="#0051FF" fill-opacity="0.2"/>
        <path [style.fill]="'#' + widget.elementsColor" d="M28.3333 38.3346H21.6666C19.361 38.3346 17.3958 37.5221 15.7708 35.8971C14.1458 34.2721 13.3333 32.3069 13.3333 30.0013C13.3333 27.6957 14.1458 25.7305 15.7708 24.1055C17.3958 22.4805 19.361 21.668 21.6666 21.668H28.3333V25.0013H21.6666C20.2777 25.0013 19.0971 25.4874 18.1249 26.4596C17.1527 27.4319 16.6666 28.6124 16.6666 30.0013C16.6666 31.3902 17.1527 32.5707 18.1249 33.543C19.0971 34.5152 20.2777 35.0013 21.6666 35.0013H28.3333V38.3346ZM23.3333 31.668V28.3346H36.6666V31.668H23.3333ZM31.6666 38.3346V35.0013H38.3333C39.7221 35.0013 40.9027 34.5152 41.8749 33.543C42.8471 32.5707 43.3333 31.3902 43.3333 30.0013C43.3333 28.6124 42.8471 27.4319 41.8749 26.4596C40.9027 25.4874 39.7221 25.0013 38.3333 25.0013H31.6666V21.668H38.3333C40.6388 21.668 42.6041 22.4805 44.2291 24.1055C45.8541 25.7305 46.6666 27.6957 46.6666 30.0013C46.6666 32.3069 45.8541 34.2721 44.2291 35.8971C42.6041 37.5221 40.6388 38.3346 38.3333 38.3346H31.6666Z" fill="#0051FF"/>
    </svg>

    <div class="widget-container__content">
        <div class="widget-container__content--description" [style]="isMobile  && null !== widget.description && widget.description.length > 50 ? 'width: 77%;' : ''">{{(isMobile && null !== widget.description && widget.description.length > 50) ? (widget.description | slice:0:50) + '...' : (widget.description)}}</div>

    </div>
    <style>
        .owl-prev{
            color: whiteBackground;
        }
    </style>
</div>
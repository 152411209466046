import {Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {noWhitespaceValidator} from "../../../shared/validators/noWhitespaceValidator";
import {pathNameValidator} from "../../../shared/validators/path-name.validator";
import * as moment from "moment";
import {TranslateService} from "@ngx-translate/core";
import {DaterangepickerDirective} from "ngx-daterangepicker-material";
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
import {AttachmentComponent} from "../../../shared/dialogs/attachment/attachment.component";
import {AssignDialogComponent} from "../../../shared/dialogs/assign-dialog/assign-dialog.component";
import {Overlay} from "@angular/cdk/overlay";
import {TasksService} from "../../../api/services/tasks.service";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../shared/services/helper.service";
import {FileSystemFileEntry, NgxFileDropEntry} from "ngx-file-drop";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {getIcon} from "../../../shared/helpers";
import {ApiService} from "../../../api/api.service";
import {LoaderService} from "../../../shared/components/loader/loader.service";
import {AuthService} from "../../../core/services/auth.service";

const QuillBlock = Quill.import('blots/block');

class QuillBlockDivBlock extends QuillBlock {
    static blockName = 'div';
    static tagName = 'div';
}

Quill.register(QuillBlockDivBlock);
Quill.register('modules/imageResize', ImageResize);

@Component({
    selector: 'app-create-task-dialog',
    templateUrl: './create-task-dialog.component.html',
    styleUrls: ['./create-task-dialog.component.scss']
})
export class CreateTaskDialogComponent implements OnInit {

    @ViewChildren(DaterangepickerDirective) datePickers: QueryList<DaterangepickerDirective>;
    @ViewChild('quillEditor') private quillEditor: ElementRef;

    toolbarOptions = [
        ['bold', 'italic'],
        ['underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],

        [{'header': [1, 2, 3, 4, 5, 6, false]}],

        [{'color': []}, {'background': []}],
        [{'align': []}, 'image']
    ];
    defaultLocale = {
        daysOfWeek: [
            this._translate.instant('Common.ShortSunday'),
            this._translate.instant('Common.ShortMonday'),
            this._translate.instant('Common.ShortTuesday'),
            this._translate.instant('Common.ShortWednesday'),
            this._translate.instant('Common.ShortThursday'),
            this._translate.instant('Common.ShortFriday'),
            this._translate.instant('Common.ShortSaturday')
        ],
        monthNames: [
            this._translate.instant('Common.January'),
            this._translate.instant('Common.February'),
            this._translate.instant('Common.March'),
            this._translate.instant('Common.April'),
            this._translate.instant('Common.May'),
            this._translate.instant('Common.June'),
            this._translate.instant('Common.July'),
            this._translate.instant('Common.August'),
            this._translate.instant('Common.September'),
            this._translate.instant('Common.October'),
            this._translate.instant('Common.November'),
            this._translate.instant('Common.December')
        ],
        cancelLabel: this._translate.instant('Common.Cancel'),
        applyLabel: this._translate.instant('Common.Accept'),
        clearLabel: this._translate.instant('Common.Clear')
    };

    public form: FormGroup;

    minStartDate = moment();
    minEndDate = moment();

    repeatOptions = [
        {
            value: 'never',
            label: 'Tasks.NeverRepeat'
        },
        {
            value: 'daily',
            label: 'Tasks.DailyRepeat'
        },
        {
            value: 'weekly',
            label: 'Tasks.WeeklyRepeat'
        },
        {
            value: 'monthly',
            label: 'Tasks.MonthlyRepeat'
        },
        {
            value: 'yearly',
            label: 'Tasks.YearlyRepeat'
        }
    ];
    dailyOptions = [
        {
            value: 1,
            label: 'Common.ShortMonday'
        },
        {
            value: 2,
            label: 'Common.ShortTuesday'
        },
        {
            value: 3,
            label: 'Common.ShortWednesday'
        },
        {
            value: 4,
            label: 'Common.ShortThursday'
        },
        {
            value: 5,
            label: 'Common.ShortFriday'
        },
        {
            value: 6,
            label: 'Common.ShortSaturday'
        },
        {
            value: 7,
            label: 'Common.ShortSunday'
        },
    ];
    endOptions = [
        {
            value: '',
            label: 'Tasks.RepeatEndNever'
        },
        {
            value: 'date',
            label: 'Tasks.RepeatEndDay'
        },
    ];

    showMore: boolean = false;

    canRequireAcceptor: boolean = false;
    users: any[] = [];
    usersDuplicate: any[] = [];

    private editor: Quill = null;

    public assignments = {
        groups: [],
        positions: [],
        users: [],
        assignedGroups: [],
        assignedPositions: [],
        assignedUsers: [],
    }

    public loadedFiles: any = [];
    public files: any = [];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<CreateTaskDialogComponent>,
                private _fb: FormBuilder,
                private _translate: TranslateService,
                private _dialog: MatDialog,
                private overlay: Overlay,
                private _api: ApiService,
                private _toastr: ToastrService,
                private _helper: HelperService,
                private loader: LoaderService,
                private _auth: AuthService) {
        this.canRequireAcceptor = _helper.hasPermissions(['task:add:team', 'task:add:company', 'task:manager:add:team', 'task:manager:add:company', 'task:admin:add:company', 'task:admin:edit:company'])
    }

    ngOnInit(): void {
        this.initForm();
        this.onFormControlValueChanges();
        this.getUsers();

        const contentEditor: HTMLElement = document.getElementById('quill-editor').firstChild as HTMLElement
        if (contentEditor == null) {
            setTimeout(() => {
                this.createQuillEditor();
            }, 100);
        }

        if (this.data.id) {
            this.form.get('id').setValue(this.data.id);
            this._api.Tasks.getTask(this.data.id).subscribe(task => {
                this.fillEditData(task)
            })
        }

        this._api.Tasks.userChecklist(this.form.get('id').value).subscribe((data) => {
            this.assignments.users = data.users;
            this.assignments.groups = data.groups.map(value => {
                return {
                    ...value,
                    name: this._translate.instant(value.name)
                }
            });
            this.assignments.positions = data.positions;

            this.assignments.assignedUsers = this.assignments.users.filter(u => u.isAssigned);
            this.assignments.assignedPositions = this.assignments.positions.filter(p => p.isAssigned);
            this.assignments.assignedGroups = this.assignments.groups.filter(g => g.isAssigned);

            if(this.data.self){
                const assignmentItem = {
                    email: '',
                    endDate: '',
                    expiryDate: undefined,
                    isAssigned: true,
                    isAvailable: true,
                    isMandatory: undefined,
                    isNew: true,
                    position: '',
                    positionsArray: [],
                    selected: false,
                    startDate: '',
                    supervisor: '',
                    userId: this._helper.getUser().id,
                    userName: this._helper.getUser().name + ' ' + this._helper.getUser().surname
                };
                this.assignments.assignedUsers.push(assignmentItem);
                this.assignments.users.push(assignmentItem)
            }
        })
    }

    initForm() {
        this.form = this._fb.group({
            id: 0,
            name: ['', {validators: [Validators.required, noWhitespaceValidator]}],
            realizationFrom: [{
                startDate: moment(this.minStartDate),
                endDate: moment(this.minEndDate),
            }, {validators: [Validators.required]}],
            realizationTo: [''],
            repeat: 'never',
            days: '',
            end: '',
            endDay: '',
            url: '',
            completionType: 'normal',
            requiredValue: '',
            attachmentRequired: '0',
            requireAcceptor: false,
            acceptorId: '',
            blockedAfterExpiration: false
        });
    }

    fillEditData(task) {
        this.form.get('id').setValue(task.id);
        this.form.get('name').setValue(task.name);
        this.form.get('realizationFrom').setValue({
            startDate: moment(task.realizationFrom),
            endDate: moment(task.realizationFrom),
        })
        if (task.realizationTo) {
            this.form.get('realizationTo').setValue({
                startDate: moment(task.realizationTo),
                endDate: moment(task.realizationTo),
            })
        }
        this.form.get('repeat').setValue(task.repeat);
        if (task.days) {
            this.form.get('days').setValue(task.days);
        }
        if (task.endDay) {
            this.form.get('end').setValue('date');
            this.form.get('endDay').setValue({
                startDate: moment(task.endDay),
                endDate: moment(task.endDay),
            })
        }
        this.form.get('completionType').setValue(task.completionType);
        this.form.get('requiredValue').setValue(task.requiredValue);
        this.form.get('attachmentRequired').setValue(task.attachmentRequired.toString());
        if (task.acceptorId) {
            this.form.get('requireAcceptor').setValue(true);
            this.form.get('acceptorId').setValue(task.acceptorId);
        }
        this.form.get('blockedAfterExpiration').setValue(task.blockedAfterExpiration);

        const contentEditor: HTMLElement = document.getElementById('quill-editor').firstChild as HTMLElement
        contentEditor.insertAdjacentHTML('afterbegin', task.description);

        this.form.get('url').setValue(task.url);
        this.loadedFiles = task.files;
    }

    onFormControlValueChanges() {
        this.form.get('repeat').valueChanges.subscribe(value => {
            if (value === 'daily') {
                this.form.get('days').setValue(['1', '2', '3', '4', '5', '6', '7'])
            } else {
                this.form.get('days').setValue([])
            }
        })

        this.form.get('end').valueChanges.subscribe(value => {
            if (value === 'date') {
                this.form.get('endDay').setValidators([Validators.required]);
            } else {
                this.form.get('endDay').setValidators([]);
                this.form.get('endDay').clearValidators();
                this.form.get('endDay').updateValueAndValidity();
            }
        })

        this.form.get('completionType').valueChanges.subscribe(value => {
            if (value === 'number') {
                this.form.get('requiredValue').setValidators([Validators.required]);
            } else {
                this.form.get('requiredValue').setValidators([]);
                this.form.get('requiredValue').clearValidators();
                this.form.get('requiredValue').updateValueAndValidity();
            }
        })

        this.form.get('requireAcceptor').valueChanges.subscribe((value: boolean) => {
            if (value) {
                this.form.get('acceptorId').setValidators([Validators.required]);
            } else {
                this.form.get('acceptorId').setValidators([]);
                this.form.get('acceptorId').clearValidators();
                this.form.get('acceptorId').updateValueAndValidity();
            }
        })
    }

    onAssign() {
        this._dialog.open(AssignDialogComponent, {
            panelClass: 'assigned-users-dialog',
            autoFocus: false,
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            disableClose: true,
            data: {
                type: 'task',
                id: this.form.get('id').value,
                name: this.form.get('name').value,
                users: this.assignments.users,
                groups: this.assignments.groups,
                positions: this.assignments.positions,
                toastrAssign: 'Tasks.AssignedToTask',
                toastrUnassign: 'Tasks.OutOfTheTask',
                closeButton: 'Tasks.UpdateAssignment'
            },
            width: '90vw',
            maxWidth: '90vw',
            maxHeight: '95vh'
        }).afterClosed().subscribe((assignments) => {
            sessionStorage.removeItem('task');
            if (assignments) {
                this.assignments.users.map(i => i.isAssigned = false);
                this.assignments.positions.map(i => i.isAssigned = false);
                this.assignments.groups.map(i => i.isAssigned = false);

                assignments.users.forEach(user => {
                    this.assignments.users.find(item => item.userId === user.userId).isAssigned = true;
                })
                assignments.positions.forEach(position => {
                    this.assignments.positions.find(item => item.id === position.id).isAssigned = true;
                })
                assignments.groups.forEach(group => {
                    this.assignments.groups.find(item => item.id === group.id).isAssigned = true;
                })

                this.assignments.assignedUsers = this.assignments.users.filter(u => u.isAssigned);
                this.assignments.assignedPositions = this.assignments.positions.filter(p => p.isAssigned);
                this.assignments.assignedGroups = this.assignments.groups.filter(g => g.isAssigned);
            }
        });
    }

    clear(c, name: string) {
        this.form.get('realizationTo').setErrors(null);

        if (c.startDate === null) {
            this.form.get(name).setValue('');
        }

        if (this.form.get('realizationTo').value !== '') {
            if (new Date(this.form.get('realizationFrom').value.startDate._d).getTime() > new Date(this.form.get('realizationTo').value.startDate._d).getTime()) {
                this.form.get('realizationTo').setErrors({'invalid': true});
            }
        }
    }

    openDatePicker(id) {
        this.datePickers.forEach(d => {
            const t = {
                elementRef: {
                    nativeElement: {
                        id: null
                    }
                }
            };
            Object.assign(t, d);
            if (id === t.elementRef.nativeElement.id) {
                d.open();
                return;
            }
        });
    }

    repeatInfo() {
        let info = this._translate.instant('Tasks.InfoRealization');

        switch (this.form.get('repeat').value) {
            case 'daily':
                info = info.replace('{{type}}', this._translate.instant('Tasks.DailyRepeat').toLowerCase())
                if (this.form.get('realizationFrom').value !== '' && this.form.get('realizationTo').value !== '') {
                    info += ' (' + moment(this.form.get('realizationFrom').value.startDate).format('HH:mm') + '-' + moment(this.form.get('realizationTo').value.startDate).format('HH:mm') + ')';
                }
                break;
            case 'weekly':
                info = info.replace('{{type}}', this._translate.instant('Tasks.WeeklyRepeat').toLowerCase())

                const day = new Date(this.form.get('realizationFrom').value.startDate._d).getDay();
                info += ' (' + this.defaultLocale.daysOfWeek[day].toLowerCase();
                if (this.form.get('realizationFrom').value !== '' && this.form.get('realizationTo').value !== '') {
                    info += ', ' + moment(this.form.get('realizationFrom').value.startDate).format('HH:mm') + '-' + moment(this.form.get('realizationTo').value.startDate).format('HH:mm');
                }
                info += ')';

                break;
            case 'monthly':
                info = info.replace('{{type}}', this._translate.instant('Tasks.MonthlyRepeat').toLowerCase());
                info += ' (' + this._translate.instant('Tasks.MonthlyEveryDay').replace('{{x}}', moment(this.form.get('realizationFrom').value.startDate).format('DD'));
                if (this.form.get('realizationFrom').value !== '' && this.form.get('realizationTo').value !== '') {
                    info += ', ' + moment(this.form.get('realizationFrom').value.startDate).format('HH:mm') + '-' + moment(this.form.get('realizationTo').value.startDate).format('HH:mm');
                }
                info += ')';
                break;
            case 'yearly':
                info = info.replace('{{type}}', this._translate.instant('Tasks.YearlyRepeat').toLowerCase())

                const month = new Date(this.form.get('realizationFrom').value.startDate._d).getMonth();
                info += ' (' + this._translate.instant('Tasks.YearlyEveryDay').replace('{{x}}', moment(this.form.get('realizationFrom').value.startDate).format('D') + ' ' + this.defaultLocale.monthNames[month].toLowerCase());
                if (this.form.get('realizationFrom').value !== '' && this.form.get('realizationTo').value !== '') {
                    info += ', ' + moment(this.form.get('realizationFrom').value.startDate).format('HH:mm') + '-' + moment(this.form.get('realizationTo').value.startDate).format('HH:mm');
                }
                info += ')';
                break;
        }

        info += ', ' + this._translate.instant('Tasks.InfoBegin') + ' ' + moment(this.form.get('realizationFrom').value.startDate).format('DD.MM.YYYY');
        if (this.form.get('endDay').value !== '') {
            info += ' ' + this._translate.instant('Tasks.InfoDateTo') + ' ' + moment(this.form.get('endDay').value.startDate).format('DD.MM.YYYY');
        }
        info += '.';

        return info;
    }

    onShowMore() {
        this.showMore = !this.showMore;
    }

    createQuillEditor() {
        const options = {
            modules: {
                toolbar: this.toolbarOptions,
                imageResize: {
                    modules: ['Resize', 'DisplaySize']
                }
            },
            theme: 'snow',
            scrollingContainer: '.mat-horizontal-content-container',
            clipboard: {
                matchVisual: false
            }
        };

        //document.getElementById('quill-editor').innerHTML = this.userIndividualDevTip;

        this.editor = new Quill('#quill-editor', options);
        this.editor.getModule('toolbar').addHandler('image', () => {
            this.setAttachment(null);
        });
    }

    setAttachment(task: any) {
        let range = null;
        if (this.editor?.getSelection()) {
            range = this.editor.getSelection();
        }
        this._dialog.open(AttachmentComponent, {
            data: {
                maxSize: 5242880,
                maxSizeText: this._translate.instant('Common.TheMaximumFileSizeExceeds').replace('{{size}}', '5 MB')
            },
            disableClose: true,
            width: '690px',
        }).afterClosed().subscribe((data) => {
            // if (data && data.updated === true && guide) {
            //     guide.image = data.image;
            //     guide.imagePath = data.url;
            // }
            if (task === null && data && data.image && range) {
                this.insertToEditor(data.image, range)
            }
        });
    }

    insertToEditor(file: any, range: any) {
        const base64String = `data:${file.type};base64,${file.base}`

        this.editor.insertEmbed(range.index, 'image', base64String);
        this.editor.setSelection(range.index + 1);
    }

    getUsers() {
        if (this.canRequireAcceptor) {
            this._api.Admin.users(1, 10000, 'name', 'asc', 'isActive=1&onlyNames=1').subscribe((res) => {
                this.users = [...res.elements];
                this.usersDuplicate = this.users;
            });
        }
    }

    onUserSearch(value: string) {
        const filter = value.toLowerCase();
        this.users = this.usersDuplicate.filter((option) => {
            if (option.userName.toLowerCase().includes(filter)) {
                return option;
            }
        });
    }

    setFiles(files) {
        this.files = files;
    }

    setUrl(url){
        this.form.get('url').setValue(url)
    }

    save() {
        this.form.markAllAsTouched();

        if (!this.form.valid) {
            return;
        }

        const contentEditor: HTMLElement = document.getElementById('quill-editor').firstChild as HTMLElement;

        const payload = this.form.value;
        if (this.form.get('realizationFrom').value !== '') {
            payload.realizationFrom = this.form.get('realizationFrom').value.startDate;
        }
        if (this.form.get('realizationTo').value !== '') {
            payload.realizationTo = this.form.get('realizationTo').value.startDate;
        }
        if (this.form.get('end').value === 'date') {
            payload.endDay = this.form.get('endDay').value.startDate;
        }
        payload.description = contentEditor.innerHTML.toString().replaceAll('ql-cursor', '');
        payload.usersAssigned = this.assignments.users.filter(u => u.isAssigned).map(u => u.userId);
        payload.groupsAssigned = this.assignments.groups.filter(g => g.isAssigned).map(g => g.id);
        payload.positionsAssigned = this.assignments.positions.filter(p => p.isAssigned).map(p => p.id);

        this.uploadFiles(payload);
    }

    uploadFiles(payload) {
        if (this.files.length == 0) {
            this.createOrUpdate(payload);
        } else {
            const filesToUpload = this.files.length;
            let fileUploaded = 0;

            this.files.forEach( (file, index) => {
                if (file.id) {
                    fileUploaded++;

                    if(filesToUpload == fileUploaded){
                        this.createOrUpdate(payload);
                    }
                } else {
                    this.loader.show();

                    const temporaryStoragePath = this._helper.createUniqueFilename(file.name);
                    this._helper.uploadToS3(file.rawFile, temporaryStoragePath).then(
                        (data) => {
                            this.files[index].temporaryStoragePath = temporaryStoragePath;
                            this.files[index].rawFile = null;
                            fileUploaded++;

                            if(filesToUpload == fileUploaded){
                                this.createOrUpdate(payload);
                            }
                        },
                        (error) => {
                            if (error == 'ExpiredToken: The provided token has expired.') {
                                this._auth.refreshToken().subscribe(res => {
                                    if (res) {
                                        this.uploadFiles(payload);
                                    }
                                })
                            }
                        }
                    );
                }
            })
        }
    }

    createOrUpdate(payload) {
        payload.files = this.files;

        if (payload.id) {
            this._api.Tasks.updateTask(payload.id, payload).subscribe(res => {
                this._toastr.success(this._translate.instant('Tasks.TaskUpdated'));
                this.dialogRef.close(true);
            })
        } else {
            this._api.Tasks.addTask(payload).subscribe(res => {
                this._toastr.success(this._translate.instant('Tasks.TaskAdded'));
                this.dialogRef.close(true);
            })
        }
    }

}

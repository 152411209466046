import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {noWhitespaceValidator} from "../../../shared/validators/noWhitespaceValidator";
import * as moment from "moment";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-duplicate-task-dialog-component',
  templateUrl: './duplicate-task-dialog-component.component.html',
  styleUrls: ['./duplicate-task-dialog-component.component.scss']
})
export class DuplicateTaskDialogComponentComponent implements OnInit {

  public newName: string = '';

  public form: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DuplicateTaskDialogComponentComponent>,
              private _fb: FormBuilder,
              private _translate: TranslateService) {
  }

  ngOnInit(): void {
      this.form = this._fb.group({
        name: ['', {validators: [Validators.required, noWhitespaceValidator]}],
      });

      this.form.get('name').setValue(this._translate.instant('Common.CopyName')+this.data.name);
  }

  save(){
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.dialogRef.close(
        this.form.get('name').value
    )
  }

}

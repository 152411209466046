<div class="widget-container"
     *ngIf="items && defaultItems"
     [ngClass]="('widget-container-feedback' + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.background]="('linear-gradient(135deg, '+ hexToRGB('#' + widget.backgroundColor,1) +' 0%, '+ hexToRGB('#' + widget.backgroundColor,0.7) +' 100%)')"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">
        {{ 'DashboardCreator.Feedback' | translate}}
        <span class="widget-container__name--subname"  *ngIf="widget.data && widget.data.daysLeft && widget.data.daysLeft > 0"  [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">
            <span>{{ 'DashboardWidgetFeedback.ToEnd' | translate}}</span>
            <span>{{ widget.data.daysLeft }}</span>
            <span *ngIf="widget.data.daysLeft === 1">{{ 'DashboardWidgetFeedback.Day' | translate}}</span>
            <span *ngIf="widget.data.daysLeft !== 1">{{ 'DashboardWidgetFeedback.Days' | translate}}</span>
        </span>
    </div>

    <div class="widget-container__content">
        <div class="feedback-progress" *ngIf="widget.sizeClass === 'b' && widget.data && widget.data.invitations && widget.data.invitations > 0">
            <ng-container>
                <div class="feedback-progress__info" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{'Feedback.GivesFeedback'| translate}}:</div>
                <div class="feedback-progress__progress">
                    <div class="feedback-progress__progress__bar" [style.background]="!whiteBackground ? hexToRGB('#' + widget.elementsColor, 0.5) : ''">
                        <div class="progress-done" [style.background]="!whiteBackground ? '#' + widget.elementsColor : ''"
                             [style.width]="((widget.data.invitationsCompleted/widget.data.invitations) * 100) + '%'"></div>
                    </div>
                    <div class="feedback-progress__progress__number" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">
                        <span>{{widget.data.invitationsCompleted}}</span>
                        <span> / {{widget.data.invitations}}</span>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="feedback-progress-space" *ngIf="widget.sizeClass === 'b' && widget.data && widget.data.collectFeedback > 0"></div>
        <div class="feedback-progress" [ngClass]="(widget.sizeClass === 'a' ? 'size-a' : '')">
            <ng-container *ngIf="widget.data && widget.data.collectFeedback && widget.data.collectFeedback > 0">
                <div class="feedback-progress__info" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{'Feedback.CollectFeedback'| translate}}:</div>
                <div class="feedback-progress__progress">
                    <div class="feedback-progress__progress__bar" [style.background]="!whiteBackground ? hexToRGB('#' + widget.elementsColor, 0.5) : ''">
                        <div class="progress-done" [style.background]="!whiteBackground ? '#' + widget.elementsColor : ''"
                             [style.width]="((widget.data.collectFeedbackCompleted/widget.data.collectFeedback) * 100) + '%'"></div>
                    </div>
                    <div class="feedback-progress__progress__number" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">
                        <span>{{widget.data.collectFeedbackCompleted}}</span>
                        <span> / {{widget.data.collectFeedback}}</span>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="widget-container__content--description" *ngIf=" widget.data && widget.data.invitations && widget.data.invitations > 0">
            <span class="widget-container__content--description--label" *ngIf="widget.data.usersWaitingForFeedback && widget.data.usersWaitingForFeedback.length == 0"
                  [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">
                {{ 'DashboardWidgetFeedback.EmptyText' | translate}}</span>
            <span class="widget-container__content--description--label" *ngIf="widget.data.usersWaitingForFeedback && widget.data.usersWaitingForFeedback.length > 0"
                  [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">
                {{ 'DashboardWidgetFeedback.ForMyFeedbackWaiting' | translate}}:</span>
            <span class="widget-container__content--description--user-avatar"
                  *ngFor="let user of widget.data.usersWaitingForFeedback"
                  matTooltip="{{ user.userFullName }}"
                  matTooltipPosition="above"
                  matTooltipClass='above'>
                <ngx-avatar [src]="user.avatarUrl"
                            [name]="user.userFullName"
                            size="28">
                </ngx-avatar>
            </span>
            <span *ngIf="widget.sizeClass === 'a' && (widget.data.invitations - widget.data.invitationsCompleted) > 3" class="widget-container__content--description--empty-avatar">
                <span>{{ '+' + (preview ? 3 : (widget.data.invitations - widget.data.invitationsCompleted - 2)) }}</span>
            </span>
        </div>
    </div>
</div>

import { AfterViewChecked, Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Directive({
    selector: '[appShowHiddenStatsCategory]'
})
export class ShowHiddenStatsCategoryDirective implements AfterViewChecked, OnInit, OnDestroy {
    resizeObservable$: Observable<Event>;
    resizeSubscription$: Subscription;

    el: ElementRef;
    items: number;
    @Input() parentClassName: string = '.category-buttons'
    @Input() outerWidth: number = 0;
    @Input() setMaxWidth: boolean = true;

    constructor(el: ElementRef) {
        this.el = el;
    }

    ngOnInit(): void {
        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
            this.recalcWidths();
        });
    }

    ngOnDestroy() {
        this.resizeSubscription$.unsubscribe();
    }

    ngAfterViewChecked(): void {
        this.recalcWidths();
    }

    recalcWidths() {
        const $el = $(this.el.nativeElement);
        const container = $el[0];
        const maxWidth = container.parentElement.offsetWidth - this.outerWidth; // 72 is outer width of button with number
        let width = 0;
        let fixedWidth = 0;
        this.items = 0;
        let i = 0;
        $el[0].style.maxWidth = maxWidth + 'px';
        container.childNodes.forEach(value => {
            if (container.childNodes.length && value.offsetWidth) {
                // console.log(value);
                width = width + value.offsetWidth;
                if (width >= maxWidth) {
                    this.items++;
                } else {
                    this.items = 0;
                    if (this.setMaxWidth) {
                        fixedWidth += value.offsetWidth;
                    }
                }
                if (this.items > 0) {
                    $(this.parentClassName).find('.more').css('display', 'flex').text(`+${this.items}`);
                    $(this.parentClassName).find('.category-item' + (i - 1) + ' img').css('display', 'none');
                } else {
                    $(this.parentClassName).find('.more').css('display', 'none');
                }
                i++;
            }
        });
        if (fixedWidth > 0 && this.setMaxWidth) {
            $el[0].style.maxWidth = fixedWidth + 'px';
        }
    }
}

<div (click)="goTo()" class="gamification-go-to" *ngIf="!preview"></div>
<div class="widget-container"
     #widgetContainerGamification
     *ngIf="items && defaultItems"
     [ngClass]="('widget-container-gamification' + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.background]="('linear-gradient(135deg, '+ hexToRGB('#' + widget.backgroundColor,1) +' 0%, '+ hexToRGB('#' + widget.backgroundColor,0.7) +' 100%)')"
     [style.color]="'#' + widget.elementsColor"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name"
         [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''"
    >{{ 'DashboardCreator.Gamification' | translate}}</div>

    <div class="widget-container__content" *ngIf="!preview && gamifications">
        <div class="widget-container__content--description">
            <owl-carousel-o *ngIf="divWidth" [options]="gamificationCarousel"
                            (translated)="getPassedData($event)"
                            appShowHiddenCarousel
                            lengthTile="{{ gamifications.length }}">
                <ng-template carouselSlide *ngFor="let gamification of gamifications">
                    <div class="slide">
                        <div class="widget-container__content--description--text"
                             [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'DashboardWidgetGamification.Ranking' | translate}}
                        <span class="completed" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ gamification.rankingPlace }}</span>
                        <span [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">/ {{ gamification.ranking.length }}</span>
                        </div>
                        <div class="widget-container__content--description--gamification-name"
                             [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ gamification.name }}</div>
                    </div>
                    <div class="widget-container--gamification">
                        <div class="widget-container--gamification--realization">
                            <div class="progress-chart">
                                <ngx-charts-pie-chart
                                        [view]="viewPie"
                                        [scheme]="colorScheme"
                                        [doughnut]="true"
                                        [tooltipDisabled]="true"
                                        [arcWidth]="0.3"
                                        [results]="gamification.pieChartData">
                                </ngx-charts-pie-chart>

                                <div class="progress-chart__number" [style.background-color]="!whiteBackground ? hexToRGB('#' + widget.backgroundColor,0.75) : ''">
                                    <span class="progress-chart__number--result" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">
                                        {{ gamification.elementsFinishedCount / gamification.totalElementsCount * 100 | number: '1.0-0'}}
                                        <span [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''"> % </span>
                                    </span>
                                    <span class="progress-chart__number--points" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">
                                        <div class="number" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ gamification.totalPoints}}</div>
                                        <div class="label" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ ('GamificationCreator.PointsShortcut' | translate) }}</div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="widget-container__content" *ngIf="preview">
        <div class="widget-container__content--description preview">
                <div class="slide">
                    <div class="widget-container__content--description--text"
                         [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ 'DashboardWidgetGamification.Ranking' | translate}}
                        <span class="completed" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ gamifications[0].rankingPlace }}</span>
                        <span [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">/ {{ gamifications[0].ranking.length }}</span>
                    </div>
                    <div class="widget-container__content--description--gamification-name"
                         [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ gamifications[0].name }}</div>
                </div>
                <div class="widget-container--gamification">
                    <div class="widget-container--gamification--realization">
                        <div class="progress-chart">
                            <ngx-charts-pie-chart
                                    [view]="viewPie"
                                    [scheme]="colorScheme"
                                    [doughnut]="true"
                                    [tooltipDisabled]="true"
                                    [arcWidth]="0.3"
                                    [results]="gamifications[0].pieChartData">
                            </ngx-charts-pie-chart>

                            <div class="progress-chart__number" [style.background-color]="!whiteBackground ? hexToRGB('#' + widget.backgroundColor,0.75) : ''">
                                    <span class="progress-chart__number--result" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">
                                        {{ gamifications[0].elementsFinishedCount / gamifications[0].totalElementsCount * 100 | number: '1.0-0'}}
                                        <span [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''"> % </span>
                                    </span>
                                <span class="progress-chart__number--points" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">
                                        <div class="number" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ gamifications[0].totalPoints}}</div>
                                        <div class="label" [style.color]="!whiteBackground ? '#' + widget.elementsColor : ''">{{ ('GamificationCreator.PointsShortcut' | translate) }}</div>
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>


<div class="test-tile" *ngIf="data" id="tile">
    <div class="above-tile">
        <div class="above-tile__red"
             *ngIf="data.isMandatory === true || data.isMandatory === 'true' || data.isMandatory === 1">
            {{(data.type === 'test' ? 'Pool.Mandatory' : 'Survey.Mandatory') | translate}}
        </div>
    </div>

    <div class="test-tile__image"
         [routerLink]="(data.type==='test'?'/app/pool/player/': '/app/survey/player/') + data.id"
         [queryParams]="(!isMyProgress? {preview: 1}: {})">
        <img [src]="data.photoUrl" alt="photo"
             loading="lazy"
             class="test-tile__image--background">

        <div class="dolineo-icon dolineo-icon-white" *ngIf="data.isDolineo">
            <img src="/assets/icons/dolineo.svg"
                 loading="lazy"
                 alt="dolineo">
        </div>
    </div>

    <div class="test-tile--container">
        <div class="test-tile--container--times" *ngIf="isMyProgress">
            <ng-container *ngIf="data.finishedDate === null || data.status === 'notPassed'">
                <ng-container
                        *ngIf="data.startDate !== _translate.instant('Common.NoDeadline') || data.endDate !== _translate.instant('Common.NoDeadline'); else emptyDate">
                    <div style="display: flex">
                        <span class="calendar">
                            <img src="/assets/icons/calendar2.svg" alt="calendar2"
                                 loading="lazy"
                                 matTooltip="{{'Survey.SetDeadlineFrom' | translate}}"
                                 matTooltipPosition="above"
                                 matTooltipClass='above'>
                            <span>{{ data.startDate }}</span>
                        </span>
                    </div>
                    <div style="display: flex">
                        <span class="calendar">
                            <img src="/assets/icons/calendar2.svg" alt="calendar2"
                                 loading="lazy"
                                 matTooltip="{{'Survey.SetDeadlineBy' | translate}}"
                                 matTooltipPosition="above"
                                 matTooltipClass='above'>
                            <span [ngStyle]="{'color': data.redColor? 'red' : 'black' }">{{ data.endDate }}</span>
                        </span>
                    </div>
                </ng-container>

                <ng-template #emptyDate>
                    <div style="display: flex">
                        <span class="calendar">
                            <img src="/assets/icons/calendar2.svg" alt="calendar2"
                                 loading="lazy"
                                 matTooltip="{{'Common.RealisationDate' | translate}}"
                                 matTooltipPosition="above"
                                 matTooltipClass='above'>
                            <span>{{ data.startDate }}</span>
                        </span>
                    </div>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="(data.type === 'test' && data.status === 'passed' && data.finishedDate !== null) || (data.type !== 'test' && data.finishedDate !== null)">
                <span class="finish-green">
                    <img src="assets/icons/checked-without-bg.svg"
                         loading="lazy"
                         alt="checked-without-bg">
                <span>{{ 'Common.FinishedDate' | translate | uppercase}}</span>
                </span>
            </ng-container>
        </div>

        <div class="test-tile--container--options" *ngIf="!isMyProgress">
            <button mat-button (click)="assign(data)"
                    *ngIf="canAssignUsers">
                <img src="/assets/icons/add_user.svg"
                     loading="lazy"
                     alt="add_user" style="margin-right: 5px; height: 13px;">
                <span style="font-size: 12px;line-height: 13px;">{{'Common.UserRecords' | translate}}</span>
            </button>

            <button mat-button [matMenuTriggerFor]="more"
                    class="three-dots"
                    *ngIf="(canEdit && !data.isDolineo) || canSelfAssign"
                    matTooltip="{{'Common.More' | translate}}"
                    matTooltipPosition="below"
                    matTooltipClass='below-top'>
                <img src="/assets/icons/dot_v.svg"
                     loading="lazy"
                     alt="dot_v">
            </button>
            <mat-menu #more="matMenu">
                <button mat-menu-item (click)="goEdit()" *ngIf="canEdit && !data.isDolineo">
                    {{(data.type === 'test' ? 'Pool.EditTheTest' : 'Survey.EditTheSurvey') | translate}}
                </button>
                <button mat-menu-item (click)="updateSelf()"
                        *ngIf="canSelfAssign">
                    {{(!data.assignedDate ? 'Common.AssignToYourself' : (data.type === 'test' ? 'Pool.UnsubscribeFromTheTest' : 'Survey.UnsubscribeFromTheSurvey')) | translate}}
                </button>
            </mat-menu>
        </div>

        <div class="test-tile--container--title">
               <span [routerLink]="(data.type==='test'?'/app/pool/player/': '/app/survey/player/')+ data.id"
                     [queryParams]="(!isMyProgress? {preview: 1}: {})"
                     [innerHTML]="data.name | yellowSearchText : searchText | safe: 'html'"></span>
        </div>

        <div class="test-tile--footer">
            <span [ngClass]="data.type === 'test'? 'test-tile--footer__purple' : 'test-tile--footer__orange'">
                {{(data.type === 'test' ? "Common.pool" : "Common.survey") | translate}}
            </span>
            <span [routerLink]="'/app/pool/player/'+ data.id" class="continue"
                  *ngIf="isMyProgress && data.type === 'test'">
                {{(!data.startedDate && !outOfDate ? 'Common.Run' :
                data.allowedAttemptsCount == data.totalUserAttempts || outOfDate ? 'Common.Details' : 'Pool.Restart') | translate}}
            </span>
            <span [routerLink]="(data.type==='test'?'/app/pool/player/': '/app/survey/player/')+ data.id" [queryParams]="{preview: 1}"
                  class="continue"
                  *ngIf="!isMyProgress">
                {{'Common.Preview' | translate}}
            </span>

            <span [routerLink]="'/app/survey/player/'+ data.id" class="continue"
                  *ngIf="isMyProgress && data.type === 'survey'">
                {{(!data.startedDate && !outOfDate ? 'Common.Run' :
                (data.finishedDate || outOfDate ? 'Common.Details' : 'Common.Continue')) | translate}}
            </span>
        </div>
    </div>
</div>

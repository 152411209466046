<h1 mat-dialog-title tabIndex="0" class="task-status-title">
    {{ title | translate }}
    <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
       matTooltip="{{'Common.Close' | translate}}"
       matTooltipPosition="below"
       matTooltipClass='below-top'
       (click)="dialogRef.close()"></i>
</h1>

<div mat-dialog-content class="task-status-content">
    <div class="task-completion-radios" >
        <div class="completion-value" *ngIf="task.completionType == 'number'">
            <span>{{ 'Tasks.UpdateRealization' | translate }}: </span>
            <mat-form-field style="width: 50px;">
                <input matInput
                       [(ngModel)]="value"
                       digitOnly
                       maxlength="4"
                       (change)= "value < 0 ? value = 1 : null">
                <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate}}</mat-error>
            </mat-form-field>
            <span>{{ 'Common.Of' | translate }} {{task.requiredValue}}</span>
            <div *ngIf="valueError" class="value-error">
                <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate}}</mat-error>
            </div>
        </div>

        <div class="completion-value" *ngIf="task.completionType == 'percent'">
            <span>{{ 'Tasks.UpdateRealization' | translate }}: </span>
            <mat-form-field style="width: 50px;">
                <input matInput
                       [(ngModel)]="value"
                       digitOnly
                       maxlength="4"
                       (change)= "value < 0 ? value = 1 : null">
                <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate}}</mat-error>
            </mat-form-field>
            <span>%</span>
            <div *ngIf="valueError" class="value-error">
                <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate}}</mat-error>
            </div>
        </div>

        <div class="radio-group" *ngIf="showCompletionRadios"  style="margin-top: 12px;">
            <span>{{ 'Tasks.TaskIsCompleted' | translate }}:</span>
            <mat-radio-group [(ngModel)]="isCompleted" (change)="completionChange()">
                <mat-radio-button [value]="false" color="primary">{{ 'Common.No' | translate }}</mat-radio-button>
                <mat-radio-button [value]="true" color="primary"
                                  style="margin-left: 24px">{{ 'Common.Yes' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="task-comment" *ngIf="showComment">
        <span> {{ 'Tasks.Comment' | translate }} </span>
        <mat-form-field appearance="none">
                <textarea [(ngModel)]="comment"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="3"
                          cdkAutosizeMaxRows="5"
                          [maxLength]="500"
                          matInput></textarea>
        </mat-form-field>
    </div>

    <div class="" *ngIf="task.attachmentRequired">
        <app-file-upload
                [header]="'Tasks.ConfirmationAttachment'"
                [files]="loadedFiles"
                [multiFiles] = false
                [sortable] = false
                [imageCropper] = true
                [allowedExtensions]="'pdf, doc, docx, ppt, pptx, xls, xlsx, png, jpg, jpeg, svg, mp4, webm, avi, mow, wmv, ogv, mp3, zip, rar'"
                (onFileUpload)="setFiles($event)"
                [optionalUrl] = true
                [urlFullWidth]="true"
                [urlHeader]="'Tasks.FileLink'"
                (onUrlChange)="setUrl($event)"
                [url]="url"
        ></app-file-upload>

        <mat-error *ngIf="attachmentError"
                class="error-message">
            {{ 'Tasks.AttachmentRequired' | translate }}
        </mat-error>
    </div>

</div>

<div mat-dialog-actions class="dialog-footer">
    <button (click)="dialogRef.close(false)" mat-button
            class="cancel-button">{{ 'Common.Cancel' | translate}}</button>
    <button mat-button color="primary"
            *ngIf="buttonText"
            (click)="changeStatus()"
            class="dolineo-button blue-button">{{ buttonText | translate}}</button>
</div>
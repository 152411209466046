import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../api/api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserRequest } from './user.interface';
import { Subscription } from 'rxjs';
import { HelperService } from '../../services/helper.service';
import { noWhitespaceValidator } from '../../validators/noWhitespaceValidator';
import { positionNameValidator } from '../../validators/position-name.validator';
import { PositionInterface } from '../../../admin/positions/position.interface';
import {customFieldUniqueValueValidator} from '../../validators/custom-field-unique-value.validator';


@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
    @ViewChild('expiry') expiryField: ElementRef;

    get emailControl(): FormControl {
        return this.form.get('email') as FormControl;
    }

    get passwordControl(): FormControl {
        return this.form.get('password') as FormControl;
    }

    get positionNameControl(): FormControl {
        return this.form.get('positionName') as FormControl;
    }

    get changePasswordRequiredControl(): FormControl {
        return this.form.get('changePasswordRequired') as FormControl;
    }

    get createWithoutPasswordAndInvitationControl(): FormControl {
        return this.form.get('createWithoutPasswordAndInvitation') as FormControl;
    }

    get sendInvitationControl(): FormControl {
        return this.form.get('sendInvitation') as FormControl;
    }

    get setPasswordControl(): FormControl {
        return this.form.get('setPassword') as FormControl;
    }

    get usernameIsEmail(): FormControl {
        return this.form.get('usernameIsEmail') as FormControl;
    }

    form: FormGroup;

    public languages = [];
    public hidePassword = true;
    public isLoading: boolean;
    public positions: PositionInterface[] = [];
    public positions2: PositionInterface[] = [];
    public positionsDuplicate: any[];
    public positionsFiltered: any[];
    public groups = [];
    public groups2 = [];
    public groupsDuplicate: any[];
    public groupsFiltered: any[];
    public selectedGroups = [];
    public idUser = null;
    public isAdmin: boolean;
    public expiryDate = null;
    public activeChange: boolean;

    public user: UserRequest = {
        username: '',
        name: '',
        surname: '',
        email: '',
        positionId: -1,
        positionName: '',
        password: '',
        confirm_password: '',
        isAdmin: false,
        expiryDate: null,
        supervisorid: null,
        removesupervisor: 0,
        setPassword: false,
        sendInvitation: false,
        changePasswordRequired: false,
        createWithoutPasswordAndInvitation: true,
        expiryDateUnlimited: true,
        interfaceLanguageId: 1,
        type: 'manual',
        groups: '',
        usernameIsEmail: true
    };

    public accountTypes = [
        {
            id: 'manual',
            name: 'Common.ManualType'
        },
        {
            id: 'external',
            name: 'Common.ExternalType'
        }
    ];

    public validation = null;

    private hasSupervisor = false;

    public users = [];
    public superiors = [];
    subscriptions: Subscription = new Subscription();
    emailRemoteSubscription: Subscription;

    constructor(private fb: FormBuilder,
                protected _helper: HelperService,
                private _api: ApiService,
                public dialogRef: MatDialogRef<UserComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private _translate: TranslateService) {
        this.activeChange = data.activeChange;

        this.validation = {
            name: this._translate.instant('Common.FiledCanNotBeEmpty'),
            surname: this._translate.instant('Common.FiledCanNotBeEmpty'),
            positionId: this._translate.instant('Common.FiledCanNotBeEmpty'),
            email: this._translate.instant('Common.FiledCanNotBeEmpty'),
            password: this._translate.instant('Common.FiledCanNotBeEmpty'),
            createAccount: this._translate.instant('AdminUsers.FormCreateAccountValidation'),
        };

        this.subscriptions.add(this._api.User.getInterfaceLanguages().subscribe(languages => {
            this.languages = languages;
        }));

        this.getGroups();
    }

    ngOnInit() {
        this.idUser = this.data.idUser;

        this.form = this.initForm();

        this.subscriptions.add(this._api.Admin.users(1, -159, 'name', 'asc', 'isActive=1&onlyNames=1').subscribe((res) => {
            this.users = res.elements.filter((item) => {
                return this.idUser !== item.idUser;
            }).map((item) => {
                return { id: item.idUser, name: item.name };
            });
            this.users = [{ id: -1, name: this._translate.instant('Common.Lack') }, ...this.users];
            this.superiors = this.users;
        }));

        this.subscriptions.add(this._api.Company.positions(this._helper.getUser().companyId,
            1,
            -159,
            'name',
            'asc',
            ''
        ).subscribe((positions: PositionInterface[]) => {
            this.positions = [
                { id: 0, name: 'Positions.AddNew' },
                ...positions
            ];
            this.positions.forEach(p=>{
                p.visible = 1;
            });

            this.positionsDuplicate = [...this.positions]
            if (this.idUser !== null) {
                this.setUser();
            }
        }));

        if (this.form.get('setPassword').value === true && this.idUser === null) {
            this.passwordControl.setValidators([Validators.required]);
        }

        this.formControlValueChanged();

        if (this.activeChange) {
            this.expiryField.nativeElement.focus();
        }

        this.isAdmin = this.data.isAdmin;
    }

    setUser() {
        this.user = {
            ...this.data,
            positionId: String(this.data.positionId ? this.data.positionId : '-1'),
            supervisorid: String(this.data.supervisorId ? this.data.supervisorId : '-1'),
            removesupervisor: 0,
            setPassword: false,
            sendInvitation: false,
            changePasswordRequired: false,
            createWithoutPasswordAndInvitation: false,
            expiryDateUnlimited: this.data.expiryDate == null,
            groups: this.data.selectedGroups.map(String)
        };

        if (this.user.supervisorid !== null) {
            this.hasSupervisor = true;
        }
        this.expiryDate = this.data.expiryDate;
        this.form.patchValue(this.user);

        if (this.data.customFields) {
            this.data.customFields.forEach( userFieldValue => {
                this.form.get(`customField_${userFieldValue.fieldId}`).setValue(userFieldValue.value);
            })
        }
    }

    initForm(): FormGroup {

        const form = this.fb.group({
            name: ['', [Validators.required, noWhitespaceValidator]],
            surname: ['', [Validators.required, noWhitespaceValidator]],
            positionId: ['-1'],
            positionName: [''],
            email: ['', [Validators.required, Validators.email, noWhitespaceValidator]],
            setPassword: [false],
            password: [''],
            supervisorid: ['-1'],
            expiryDate: [''],
            groups: [[]],
            isAdmin: [false],
            changePasswordRequired: [false],
            sendInvitation: [false],
            createWithoutPasswordAndInvitation: [true],
            expiryDateUnlimited: [true],
            interfaceLanguageId: [this._helper.getUser().companyDefaultLanguageId],
            type: [this.accountTypes[0].id],
            usernameIsEmail: [true]
        });


        this._helper.getUser().customFields.forEach( (field: any) => {
            const validators = [];
            const asyncValidators = [];
            if (field.type === 'text') {
                validators.push(noWhitespaceValidator);
            }
            if (field.isRequired === true) {
                validators.push(Validators.required);
            }
            if (field.isUnique === true) {
                asyncValidators.push(customFieldUniqueValueValidator(this._api.CustomFields, field.id,  this.idUser));
            }
            form.addControl(`customField_${field.id}`, new FormControl(field.type === 'list' ? ( field.isRequired === true ? null : 0) : '', validators, asyncValidators));
        });

        return form;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    emailValidaton(value: string) {
        if (this.usernameIsEmail.value) {
            this.emailControl.setValidators([Validators.required, Validators.email, noWhitespaceValidator]);
        } else {
            this.emailControl.setValidators([Validators.required, noWhitespaceValidator]);
        }

        this.validation.email = '';
        this.emailControl.setErrors(null);
        if (value === '') {
            this.validation.email = this._translate.instant('Common.FiledCanNotBeEmpty');
            this.emailControl.setErrors({ invalid: true });
        } else if (!this._helper.checkIfStringIsEmail(value) && this.usernameIsEmail.value) {
            this.validation.email = this._translate.instant('Common.InvalidEmail');
            this.emailControl.setErrors({ invalid: true });
        }

        if (this.usernameIsEmail.value.length > 0 && (value.includes('@') || !this.usernameIsEmail.value)) {
            if (this.emailRemoteSubscription) {
                this.emailRemoteSubscription.unsubscribe();
            }
            this.emailRemoteSubscription = this._api.Admin.emailValidation(value, this.idUser).subscribe((res) => {
                if (res === false) {
                    this.emailControl.setErrors({ invalid: true });
                    this.validation.email = this._translate.instant('AdminUsers.FormEmailAlreadyExists');
                }
            });
            this.subscriptions.add(this.emailRemoteSubscription);
        }
    }

    formControlValueChanged() {
        this.subscriptions.add(this.emailControl.valueChanges.subscribe(
            (value: string) => {
                this.emailValidaton(value);
            }));

        this.subscriptions.add(this.usernameIsEmail.valueChanges.subscribe(
            (value: string) => {
                this.emailValidaton(this.emailControl.value);
            }));

        this.subscriptions.add(this.form.get('setPassword').valueChanges.subscribe(
            (value: boolean) => {
                if (value === true) {
                    this.passwordControl.setValue('');
                    this.passwordControl.clearValidators();
                    this.passwordControl.updateValueAndValidity();
                    this.createWithoutPasswordAndInvitationControl.setValue(false);
                    this.sendInvitationControl.setValue(false);
                } else {
                    if (this.idUser !== null) {
                        this.passwordControl.setValidators([Validators.required]);
                    } else {
                        this.passwordControl.setErrors(null);
                    }
                    this.changePasswordRequiredControl.setValue(false);
                }
            }));

        this.subscriptions.add(this.form.get('createWithoutPasswordAndInvitation').valueChanges.subscribe(
            (value: boolean) => {
                if (value === true) {
                    this.setPasswordControl.setValue(false);
                    this.sendInvitationControl.setValue(false);
                }
            }));

        this.subscriptions.add(this.form.get('sendInvitation').valueChanges.subscribe(
            (value: boolean) => {
                if (value === true) {
                    this.setPasswordControl.setValue(false);
                    this.createWithoutPasswordAndInvitationControl.setValue(false);
                }
            }));

        this.subscriptions.add(this.form.get('positionId').valueChanges.subscribe((positionId: string) => {
            if (positionId === '0') {
                this.positionNameControl.setValidators([Validators.required]);
                this.positionNameControl.setAsyncValidators(positionNameValidator(this._api, null));
            } else {
                this.positionNameControl.clearValidators();
                this.positionNameControl.clearAsyncValidators();
            }

            this.positionNameControl.updateValueAndValidity();
        }));

        this.subscriptions.add(this.form.get('password').valueChanges.subscribe(
            (value: string) => {
                const errors = this._helper.passwordValidation(value);
                if (!value.length) {
                    this.validation.password = this._translate.instant('Common.FiledCanNotBeEmpty');
                    this.passwordControl.setErrors({ 'invalid': true });
                } else if (errors.length) {
                    this.validation.password = this._translate.instant('Profile.PasswordValidation');
                    this.passwordControl.setErrors({ 'invalid': true });
                } else {
                    this.validation.password = '';
                    this.passwordControl.setErrors(null);
                }
            }));
    }

    save() {
        const position = this.positions.find(p => p.name.toLocaleLowerCase() === this.positionNameControl.value.trim().toLocaleLowerCase());

        this.form.markAllAsTouched();

        if (!this.form.valid) {
            return;
        }

        if (this.idUser === null &&
            this.form.get('createWithoutPasswordAndInvitation').value === false &&
            this.form.get('sendInvitation').value === false && this.form.get('setPassword').value === false) {
            return;
        }

        this.user.name = this.form.get('name').value.trim();
        this.user.surname = this.form.get('surname').value.trim();
        this.user.email = this.emailControl.value.trim().toLowerCase();
        if (position) {
            this.user.positionId = position.id;
            this.user.positionName = '';
        } else {
            this.user.positionId = this.form.get('positionId').value;
            this.user.positionName = this.form.get('positionId').value !== '0' ? '' : this.positionNameControl.value;
        }
        this.user.isAdmin = this.form.get('isAdmin').value;
        if (this.form.get('expiryDate').value) {
            this.user.expiryDate = moment(this.form.get('expiryDate').value).format('YYYY-MM-DD');
        } else {
            this.user.expiryDate = null;
        }
        this.user.supervisorid = this.form.get('supervisorid').value;
        this.user.password = this.passwordControl.value;
        this.user.setPassword = this.form.get('setPassword').value;
        this.user.sendInvitation = this.form.get('sendInvitation').value;
        this.user.changePasswordRequired = this.form.get('changePasswordRequired').value;
        this.user.createWithoutPasswordAndInvitation = this.form.get('createWithoutPasswordAndInvitation').value;
        this.user.expiryDateUnlimited = this.form.get('expiryDateUnlimited').value;
        this.user.interfaceLanguageId = this.form.get('interfaceLanguageId').value;
        this.user.type = this.form.get('type').value;
        this.user.usernameIsEmail = this.form.get('usernameIsEmail').value;

        this.user.customFields = [];
        this._helper.getUser().customFields.forEach( (field: any) => {
            this.user.customFields.push({fieldId: field.id, value: this.form.get(`customField_${field.id}`).value});
        });

        this.groups = this.form.get('groups').value;

        this.user.groups = this.groups;
        this.isLoading = true;

        if (this.idUser === null) {
            this.subscriptions.add(this._api.User.create(this.user).subscribe(() => {
                this.dialogRef.close({ created: true });
            }, (err) => {
                if (err && err.error && err.error.message) {
                    if (err.error.message.substr(0, 20) === 'Email already exists') {
                        this.validation.email = this._translate.instant('AdminUsers.FormEmailAlreadyExists');
                        this.emailControl.setErrors({ 'invalid': true });
                    }
                }

                this.isLoading = false;
            }));
        } else {
            this.user.removesupervisor = 0;
            if (this.hasSupervisor && Number(this.user.supervisorid) === -1) {
                this.user.removesupervisor = 1;
            }

            this.subscriptions.add(this._api.User.update(this.idUser, this.user).subscribe(() => {
                this.dialogRef.close({ updated: true });
            }, (err) => {
                if (err && err.error && err.error.message) {
                    if (err.error.message.substr(0, 20) === 'Email already exists') {
                        this.validation.email = this._translate.instant('AdminUsers.FormEmailAlreadyExists');
                        this.emailControl.setErrors({ 'invalid': true });
                    }
                }
                this.isLoading = false;
            }));
        }
    }

    openDatePicker(element) {
        element.open();
    }

    onSuperiorSearch(value) {
        this.form.get('supervisorid').setValue('');
        const filter = value.toLowerCase();
        this.superiors = this.users.filter((option) => {
            if (option.name.toLowerCase().includes(filter)) {
                return option;
            }
        });
    }

    onGroupSearch(value: string) {
        this.groups2 = [];
        const filter = value.toLowerCase();
        this.groupsFiltered = this.groupsDuplicate.filter((option) => {
            if (option.name.toLowerCase().includes(filter)) {
                 option.visible = 1;
                this.groups2.push(option);
            }else{
                option.visible = 0;
                this.groups2.push(option);
            }
        });
        this.groups = this.groups2;

    }

    onPositionSearch(value: string) {
        this.positions2 = [];
        const filter = value.toLowerCase();
        this.positionsFiltered = this.positionsDuplicate.filter((option) => {
            if (option.name.toLowerCase().includes(filter)) {
                 option.visible = 1;
                this.positions2.push(option);
            }else{
                option.visible = 0;
                this.positions2.push(option);
            }
        });
        this.positions = this.positions2;

    }

    getGroups() {
        this._api.Company.groups(
            this._helper.getUser().companyId,
            1,
            -159,
            'name',
            'asc',
            '').subscribe((res) => {
            this.groups = res.filter(g => g.isEditable === 1).map(val => {
                return {
                    id: val.groupId,
                    name: val.groupName,
                    visible: 1
                }
            });

            this.groupsDuplicate = [...this.groups]
        });
    }
}

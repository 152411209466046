import { HttpResponse } from '@angular/common/http';
import {IFeedbackQuestionnairePreparedData, IFilter} from './intefaces/interfaces';

export function parseError(r: any): string {
    if (typeof r === 'string' || r instanceof String) {
        return r.toString();
    }

    if (r && r.error) {
        return r.error.message || r.error.error_description || 'wystąpił błąd';
    } else if (+r.status === 0) {
        return 'Błąd połączenia z serwerem';
    } else {
        return '';
    }
}

export function capitalizeFirstLetter(text: string): string {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : '';
}

export function clickOnDocument() {
    setTimeout(() => {
        const evt = document.createEvent('MouseEvents');
        evt.initMouseEvent('click', true, true, window,
            0, 0, 0, 0, 0,
            false, false, false, false,  // ctrl, alt, shift, meta
            0, null);
        document.dispatchEvent(evt);
    });
}

export function humanizeMinutes(minutes: number): string {
    if (minutes > 0) {
        const hours = Math.floor(minutes / 60);
        const min = minutes % 60;
        return hours ? `${hours ? hours + 'h' : ''} ${min ? min + 'm' : ''}` : `${min ? min + 'm' : ''}`;
    } else {
        return '';
    }
}

export function activesArray(): { id: string, value: string }[] {
    return [
        { id: 'assign', value: 'Activities.Assign' },
        { id: 'unassign', value: 'Activities.UnAssign' },
        { id: 'started', value: 'Activities.Started' },
        { id: 'finished', value: 'Activities.Finished' },
        { id: 'created', value: 'Activities.Created' },
        { id: 'updated', value: 'Activities.Updated' },
        { id: 'received', value: 'Activities.Received' },
        { id: 'deleted', value: 'Activities.Deleted' },
        { id: 'archived', value: 'Activities.Archived' },
        { id: 'enabled', value: 'Activities.Enabled' },
        { id: 'disabled', value: 'Activities.Disabled' },
        { id: 'password-changed', value: 'Activities.PasswordChanged' },
        { id: 'password-reset', value: 'Activities.PasswordReset' },
        { id: 'unarchived', value: 'Activities.Unarchived' },
        { id: 'login', value: 'Activities.Login' },
        { id: 'logout', value: 'Activities.Logout' },
        { id: 'login-as', value: 'Activities.LoginAs' },
        { id: 'login-attempt', value: 'Activities.LoginAttempt' },
        { id: 'display', value: 'Activities.Display' },
        { id: 'anonymize', value: 'Activities.Anonymized' },
        { id: 'accepted', value: 'Activities.Accepted' },
        { id: 'rejected', value: 'Activities.Rejected' },
        { id: 'deleted-attempt', value: 'Common.deleted-attempt' },
    ]
}

export function setLocalFilter(filter: string, table: any[], request: any, localName: string) {
    localStorage.removeItem(localName);
    const tableFilters = table.filter(value => !!value.value).map(value => {
        return {
            index: value.index,
            value: value.value
        };
    });
    localStorage.setItem(localName, JSON.stringify({
        tableFilters: [...tableFilters],
        filter,
        sort: { ...request.sort }
    }));
}

export function setValueHeader(filterName: string, table: any): any[] {
    const tableFormLocal = JSON.parse(localStorage.getItem(filterName)).tableFilters;
    table.forEach(val => {
        const tabRow = tableFormLocal.find(row => row.index === val.index);
        if (!!tabRow) {
            val.value = tabRow.value;
            val.default = tabRow.value;
        }
    });
    return table;
}

export function buildFiltersUrl(filters: IFilter[]): string {
    let index = 0;
    let paramsURI = '';
    for (const filter of filters) {

        if (filter.value === filter.defaultValue) {
            continue;
        }

        if (!filter.value) {
            continue;
        }

        if (filter.type === 'ARRAY') {
            if (filter.value.length === 0) {
                continue;
            }
        }

        if (index > 0) {
            paramsURI += `&`;
        }

        let value = filter.value;

        if (filter.type === 'DATE') {
            value = this.helper.convertToApiDate(filter.value);
        }

        if (filter.type === 'ARRAY') {
            let i = 0;
            for (const v of filter.value) {
                if (i > 0) {
                    paramsURI += '&';
                }
                paramsURI += `${filter.name + '[]'}=${encodeURIComponent(String(v))}`;
                i++;
            }
        } else {
            paramsURI += `${filter.name}=${encodeURIComponent(String(value))}`;
        }

        index++;
    }

    return paramsURI;
}

export function setTableFromLocalStorage(table: any, name: string): any[] {
    const newTable = [];
    const tableFormLocal = JSON.parse(localStorage.getItem(name));
    tableFormLocal.forEach(val => {
        const oldRow = table.find(row => row.index === val.index);
        newTable.push({
            ...oldRow,
            defaultValue: val.value,
            visible: val.visible,
        });
    });
    return newTable;
}

export function categoryText(data: any): string {
    const moreCategories = data.categories.length > 2 ? `(${data.categories.length - 2})` : '';
    return `${data.categories[0] ? data.categories[0] + (data.categories[1] ? ', <br>' + data.categories[1] : '') : ''} ${moreCategories}`;
}

export function groupsText(data: any): string {
    const moreGroups = data.groups.length > 2 ? `(${data.groups.length - 2})` : '';
    return `${data.groups[0] ? data.groups[0] + (data.groups[1] ? ', <br>' + data.groups[1] : '') : ''} ${moreGroups}`;
}

export function carersText(data: any): string {
    const moreCarers = data.carers.length > 2 ? `(${data.carers.length - 2})` : '';
    return `${data.carers[0] ? data.carers[0] + (data.carers[1] ? ', <br>' + data.carers[1] : '') : ''} ${moreCarers}`;
}

export function companiesText(data: any): string {
    const moreCompanies = data.groups.length > 2 ? `(${data.groups.length - 2})` : '';
    return `${data.groups[0] ? data.groups[0] + (data.groups[1] ? ', <br>' + data.groups[1] : '') : ''} ${moreCompanies}`;
}

export function rolesText(data: any): string {
    const moreRoles = data.length > 2 ? `(${data.length - 2})` : '';
    return `${data[0] ? data[0] + (data[1] ? ', <br>' + data[1] : '') : ''} ${moreRoles}`;
}

export function issuesText(data: any): string {
    const moreGroups = data.issues.length > 2 ? `(${data.issues.length - 2})` : '';
    return `${data.issues[0] ? data.issues[0] + (data.issues[1] ? ', <br>' + data.issues[1] : '') : ''} ${moreGroups}`;
}


export function relatedTypeName(type: string) {
    switch (type) {
        case 'training':
            return 'Common.training';
        case 'path':
            return 'Common.path';
        case 'user':
            return 'Common.user';
        case 'certificate':
            return 'Common.certificate';
        case 'role':
            return 'Common.role';
        case 'group':
            return 'Common.Group';
        case 'test':
            return 'Common.test';
        case 'survey':
            return 'Common.survey';
        case 'knowledge-base-entry':
            return 'Common.knowledge-base-entry';
        case 'knowledge-base-category':
            return 'Common.knowledge-base-category';
        case 'onboarding':
            return 'Common.onboarding';
        case 'preboarding':
            return 'Common.preboarding';
        case 'task':
            return 'Common.task';
        case 'feedback':
            return 'Feedback.Campaign';
        case 'feedback-questionnaire':
            return 'Feedback.Questionnaire';
        case 'feedback-questionnaire-invitation':
        case 'feedback-questionnaire-invite-team':
        case 'feedback-questionnaire-invite-employee':
        case 'feedback-questionnaire-invite-coworker':
        case 'feedback-questionnaire-invite-supervisor':
            return 'Feedback.QuestionnaireInvitation';
        case 'help-entry':
            return 'Common.Help';
        case 'help-category':
            return 'Common.HelpCategory';
        case 'position':
            return 'Common.Position';
        case 'api-app':
            return 'Common.ApiApp';
        default:
            return 'Common.' + type;
    }
}

export function traingType(type: string): string {
    switch (type) {
        case 'scorm':
            return 'Common.Scorm';
        case 'h5p':
            return 'Common.H5P';
        case 'video':
            return 'Common.Video';
        case 'document':
            return 'Common.Document';
        case 'page':
            return 'Common.Page';
        case 'url':
            return 'Common.Url';
        default:
            return 'Common.' + type;
    }
}

export function getActivityName(activityName: string): string {
    switch (activityName) {
        case 'assign':
            return 'Activities.Assign';
        case 'unassign':
            return 'Activities.UnAssign';
        case 'started':
            return 'Activities.Started';
        case 'finished':
            return 'Activities.Finished';
        case 'created':
            return 'Activities.Created';
        case 'updated':
            return 'Activities.Updated';
        case 'received':
            return 'Activities.Received';
        case 'deleted':
            return 'Activities.Deleted';
        case 'enabled':
            return 'Activities.Enabled';
        case 'disabled':
            return 'Activities.Disabled';
        case 'password-changed':
            return 'Activities.PasswordChanged';
        case 'password-reset':
            return 'Activities.PasswordReset';
        case 'login':
            return 'Activities.Login';
        case 'logout':
            return 'Activities.Logout';
        case 'login-as':
            return 'Activities.LoginAs';
        case 'login-attempt':
            return 'Activities.LoginAttempt';
        case 'first-invitation':
            return 'Activities.FirstInvitation';
        case 'next-invitation':
            return 'Activities.NextInvitation';
        case 'display':
            return 'Activities.Display';
        case 'unarchived':
            return 'Activities.Unarchived';
        case 'archived':
            return 'Activities.Archived';
        case 'anonymize':
            return 'Activities.Anonymized';
        case 'accepted':
            return 'Activities.Accepted';
        case 'rejected':
            return 'Activities.Rejected';
        default:
            return 'Common.' + activityName;
    }
}

export function openXLS(name: string, res: HttpResponse<Blob>): void {
    const newBlob = new Blob([res.body], { type: 'application/vnd.ms-excel' });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob, name + '.xls');
        return;
    }

    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = name + '.xls';

    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
    }, 100);
}

export function openXLSX(name: string, res: HttpResponse<Blob>): void {
    const newBlob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob, name + '.xlsx');
        return;
    }

    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = name + '.xlsx';

    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
    }, 100);
}

export function downloadPdf(name: string, res: HttpResponse<Blob>): void {
    const newBlob = new Blob([res.body], { type: 'application/pdf' });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob, name + '.pdf');
        return;
    }

    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = name + '.pdf';

    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
    }, 100);
}

export function downloadZip(name: string, res: HttpResponse<Blob>): void {
    const newBlob = new Blob([res.body], { type: 'application/zip' });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob, name + '.zip');
        return;
    }

    const data = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = data;
    link.download = name + '.zip';

    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
    }, 100);
}

export function downloadFile(blob: any, name: string) {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = name;
    a.click();
    URL.revokeObjectURL(objectUrl);
}

export function getIcon(mimeType: string): string {
    if (mimeType.includes('msword') || mimeType.includes('vnd.openxmlformats-officedocument.wordprocessingml.document') || mimeType.includes('ms-doc')) {
        return 'dolineo-icon-doc';
    } else if (mimeType.includes('image')) {
        return 'dolineo-icon-img';
    } else if (mimeType.includes('video')) {
        return 'dolineo-icon-move';
    } else if (mimeType.includes('audio')) {
        return 'dolineo-icon-mp3';
    } else if (mimeType.includes('pdf')) {
        return 'dolineo-icon-pdf';
    } else if (mimeType.includes('mspowerpoint') ||
        mimeType.includes('powerpoint') ||
        mimeType.includes('vnd.ms-powerpoint') ||
        mimeType.includes('x-mspowerpoint') ||
        mimeType.includes('vnd.openxmlformats-officedocument.presentationml.presentation')) {
        return 'dolineo-icon-ppt';
    } else {
        return 'dolineo-icon-file';
    }
}

export function getIconByFilename(filename: string): string {
    const extension = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2)
    let fileSrc = '';

    switch (extension) {
        case 'doc':
            fileSrc = 'dolineo-icon-doc';
            break;
        case 'docx':
            fileSrc = 'dolineo-icon-doc';
            break;
        case 'xls':
            fileSrc = 'dolineo-icon-doc';
            break;
        case 'xlsx':
            fileSrc = 'dolineo-icon-doc';
            break;
        case 'ppt':
            fileSrc = 'dolineo-icon-ppt';
            break;
        case 'pptx':
            fileSrc = 'dolineo-icon-ppt';
            break;
        case 'pdf':
            fileSrc = 'dolineo-icon-pdf';
            break;
        case 'txt':
            fileSrc = 'dolineo-icon-file';
            break;
        case 'mp3':
            fileSrc = 'dolineo-icon-mp3';
            break;
        case 'mp4':
            fileSrc = 'dolineo-icon-move';
            break;
        case 'webm':
            fileSrc = 'dolineo-icon-move';
            break;
        case 'avi':
            fileSrc = 'dolineo-icon-move';
            break;
        case 'mow':
            fileSrc = 'dolineo-icon-move';
            break;
        case 'wmv':
            fileSrc = 'dolineo-icon-move';
            break;
        case 'ogv':
            fileSrc = 'dolineo-icon-move';
            break;
        case 'jpg':
            fileSrc = 'dolineo-icon-img';
            break;
        case 'jpeg':
            fileSrc = 'dolineo-icon-img';
            break;
        case 'png':
            fileSrc = 'dolineo-icon-img';
            break;
        default:
            fileSrc = 'dolineo-icon-file';
            break;
    }

    return fileSrc;
}

export function validateYoutubeDomain(url) {
    const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be|youtube\.com)(.+?)?$/;
    const matches = url.match(p);
    return !!matches;
}

export function validateVimeoDomain(url) {
    const p = /^(?:https?\:\/\/)?(?:www\.)?(?:vimeo\.com)(.+?)?$/;
    const matches = url.match(p);
    return !!matches;
}

export function validateYoutubeUrl(url) {
    const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const matches = url.match(p);
    return !!matches;
}

export function validateVimeoUrl(url) {
    const p = /^(?:https?\:\/\/)?(?:www\.)?(?:vimeo\.com\/)([0-9]+)?$/;
    const matches = url.match(p);
    return !!matches;
}

export function validateInternalUrl(url) {
    const host = window.location.host;
    if(url.includes(host)){
        return false;
    }
    if(url.startsWith('https://') || url.startsWith('http://')){
        const p = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/;
        const matches = url.match(p);
        return !!matches;
    }

    return false;
}

export function validateNip(nip) {
    const nipWithoutDashes = nip.replace(/-/g, '');
    const reg = /^[0-9]{10}$/;
    if (reg.test(nipWithoutDashes) === false) {
        return false;
    } else {
        const digits = ('' + nipWithoutDashes).split('');
        const checksum = (6 * parseInt(digits[0], 10) + 5 * parseInt(digits[1], 10) + 7 * parseInt(digits[2], 10) + 2 * parseInt(digits[3], 10) + 3 * parseInt(digits[4], 10) + 4 * parseInt(digits[5], 10) + 5 * parseInt(digits[6], 10) + 6 * parseInt(digits[7], 10) + 7 * parseInt(digits[8], 10)) % 11;

        return (parseInt(digits[9], 10) === checksum);
    }
}

export function validateRegon9(regon) {
    const reg = /^[0-9]{9}$/;
    if (!reg.test(regon)) {
        return false;
    } else {
        const digits = ('' + regon).split('');
        let checksum = (8 * parseInt(digits[0], 10) + 9 * parseInt(digits[1], 10) + 2 * parseInt(digits[2], 10) + 3 * parseInt(digits[3], 10) + 4 * parseInt(digits[4], 10) + 5 * parseInt(digits[5], 10) + 6 * parseInt(digits[6], 10) + 7 * parseInt(digits[7], 10)) % 11;
        if (checksum === 10) {
            checksum = 0;
        }

        return (parseInt(digits[8], 10) === checksum);
    }
}

export async function getBlobDuration(blob: any) {
    const tempVideoEl = document.createElement('video');

    const durationP = new Promise((resolve, reject) => {
            tempVideoEl.addEventListener('loadedmetadata', () => {
                if (tempVideoEl.duration === Infinity) {
                    tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER;
                    tempVideoEl.ontimeupdate = () => {
                        tempVideoEl.ontimeupdate = null;
                        resolve(tempVideoEl.duration);
                        tempVideoEl.currentTime = 0;
                    };
                }
                // Normal behavior
                else {
                    resolve(tempVideoEl.duration);
                }
            });

            tempVideoEl.addEventListener('error', (err) => {
                reject(err);
            });
        }
    );

    // @ts-ignore
    tempVideoEl.src = typeof blob === 'string' || blob instanceof String
        ? blob
        : window.URL.createObjectURL(blob);

    return durationP;
}

export function displayTrainingType(objectType: string, trainingType: string, trainingAction: string, isSoundMaterial: any) {
    switch (objectType) {
        case 'training' : {
            switch (trainingType) {
                case 'page':
                    return 'Common.Page';
                case 'url':
                    return 'Common.Url';
                case 'h5p':
                case 'scorm':
                    return 'PathCreator.LessonOnline';
                case 'video':
                    return isSoundMaterial === 1 || isSoundMaterial === true ? 'Path.SoundMaterial' : 'Path.Video';
                case 'document':
                    switch (trainingAction) {
                        case 'display' :
                            return 'Path.LessonDisplay';
                        case 'download' :
                            return 'PathCreator.LessonDownload';
                        default :
                            return 'Common.Document';
                    }
                default:
                    return trainingType;
            }
        }
        case 'pools' :
            return 'PathCreator.LessonTest';
        case 'survey' :
            return 'Common.survey';
        case 'knowledge-base-category':
            return 'Common.knowledge-base-category';
        case 'knowledge-base-entry':
            return 'Common.knowledge-base-entry';
        case 'task':
            return 'Common.task';
        default :
            return objectType;
    }
}

export function normalizedLetter(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/\u0142/g, 'l');
}


export function feedbackQuestionnairePrepareData(value: any): IFeedbackQuestionnairePreparedData {
    const feedback: any = { ...value };
    const feedbackArray: any[] = [];
    let behaviorCount: number;

    let behaviourIndex = 0
    let areaIndex = 0
    value.areas.forEach((area) => {
        if(!area.textBlock) {
            area.competences.forEach((competence, competenceIndex) => {
                feedbackArray.push({
                    id: competence.id,
                    competenceDescription: competence.description,
                    descriptionSelf: competence.descriptionSelfAssessment,
                    descriptionSelfIsVisible: competence.descriptionSelfAssessmentIsVisible,
                    type: 'description',
                    areaName: area.name,
                    areaIndex: areaIndex + 1,
                    hideAreaName: area.hideName,
                    competenceIndex: competenceIndex + 1
                })
                competence.behaviours.forEach(behaviour => {
                    if (behaviour.questionType !== 'spacer') {
                        ++behaviourIndex
                    }
                    feedbackArray.push({
                        ...behaviour,
                        competenceDescription: competence.description,
                        descriptionSelf: competence.descriptionSelfAssessment,
                        descriptionSelfIsVisible: competence.descriptionSelfAssessmentIsVisible,
                        areaName: area.name,
                        areaIndex: areaIndex + 1,
                        hideAreaName: area.hideName,
                        competenceIndex: competenceIndex + 1,
                        behaviourIndex: behaviour.questionType !== 'spacer' ? behaviourIndex : null,
                    })
                })
            })
            areaIndex++;
        }else{
            if((value.userName && area.shareBlockInOthersAssessment) || (!value.userName && area.shareBlockInSelfAssessment)){
                feedbackArray.push({
                    id: area.id,
                    descriptionSelf: area.name,
                    descriptionSelfIsVisible: null,
                    areaName: area.name,
                    hideAreaName: area.hideName,
                    competenceIndex: 0,
                    textBlock: true,
                    questionType: 'spacer',
                    description: area.name,
                    shareBlockInOthersAssessment: area.shareBlockInOthersAssessment,
                    shareBlockInSelfAssessment: area.shareBlockInSelfAssessment
                })
            }
        }
    })
    behaviorCount = behaviourIndex;

    return {
        feedback,
        feedbackArray,
        behaviorCount
    }
}

export function truncateString(str: string, num: number): string {
    if (str.length > num) {
        return str.slice(0, num) + '...';
    } else {
        return str;
    }
}


export function calcWorkingDays(fromDate: Date, days: number): Date {
    let count = 0;
    while (count < days) {
        fromDate.setDate(fromDate.getDate() + 1);
        if (fromDate.getDay() !== 0 && fromDate.getDay() !== 6) {
            count++;
        }
    }
    return new Date(fromDate);
}
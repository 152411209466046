<div class="widget-container"
     *ngIf="items && defaultItems"
     [ngClass]="('widget-container-calendar' + ' widget-container-size-' + (setSize ? widget.sizeClass : ''))"
     [style.border]="(border ? 'solid 1px #ebebeb' : 'none')"
>
    <div class="widget-container__name">{{ 'DashboardCreator.Calendar' | translate}}</div>
    <div class="widget-calendar-activities" *ngIf="activities.length">
        <div class="calendar-item" *ngFor="let activity of activities; let i = index">
            <div class="record {{activity.status}} cal-date-{{activity.date | date: 'dd-MM-y'}} {{getTimeClass(i, activity.hasToday)}}"
                 [ngClass]="{'same-day': isSameDay(i), 'empty': !activity.relatedObjectName}">
                <div class="date">
                    <span class="day">{{ activity.date | date: 'dd.MM'}}</span>
                    <span class="dayName">{{ activity.date | date: 'EEE' }}</span>
                    <span class="color-gray" style="max-width: 65px;">{{'Calendar.Today' | translate |uppercase}}</span>
                    <span class="icon">
                        <span class="dot"></span>
                    </span>
                </div>

                <div class="details" *ngIf="!activity.empty && activity.relatedObjectName; else emptyDetails">
                    <div class="main-details">
                        <h2 class="desktop" (click)="showActivity(activity)">{{ activity.relatedObjectName }}</h2>
                        <h2 class="mobile" (click)="showActivity(activity)">{{ (activity.relatedObjectName.length > 32) ? (activity.relatedObjectName | slice:0:32) + '...' : (activity.relatedObjectName) }}</h2>
                    </div>
                </div>

                <div class="details-empty" *ngIf="activity.empty" [style]="(activity.relatedObjectName2 ? 'padding: 4px 0' : '')">
                    <div class="main-details" style="flex-direction: column">
                        <h2 class="desktop">{{ activity.relatedObjectName }}</h2>
                        <h2 class="mobile">{{ (activity.relatedObjectName.length > 32) ? (activity.relatedObjectName | slice:0:32) + '...' : (activity.relatedObjectName) }}</h2>
                        <h2 class="desktop">{{ activity.relatedObjectName2 }}</h2>
                        <h2 class="mobile">{{ (activity.relatedObjectName2 && activity.relatedObjectName2.length > 32) ? (activity.relatedObjectName2 | slice:0:32) + '...' : (activity.relatedObjectName2) }}</h2>
                    </div>
                </div>

                <ng-template *ngIf="!activity.empty" #emptyDetails>
                    <div class="emptyDetails"></div>
                </ng-template>
            </div>
        </div>
    </div>
    <ng-template #emptyCalendar>
        <div class="widget-calendar-activities empty-calendar"
             *ngIf="typeFilter.length || statusFilter.length; else detectiveSearch">
            <img src="assets/images/default/empty_page_filter.png" alt="empty_page_filter">
            <span class="empty-calendar__text">{{'Common.NoDataToDisplayWithFilters' | translate}}</span>
        </div>

        <ng-template #detectiveSearch>
            <div class="calendar-activities empty-calendar">
                <div class="calendar-item">

                    <div class="record today">
                        <div class="date">
                            <span class="day">{{ myDate | date: 'dd'}}</span>
                            <span class="dayName">{{ myDate | date: 'EEE' }}</span>
                            <span class="color-gray"
                                  style="max-width: 65px;">{{'Calendar.Today' | translate |uppercase}}</span>
                            <span class="icon">
                                <span class="dot"></span>
                            </span>
                        </div>

                        <div class="details">
                            <div class="details--border-box">
                                <p>{{'Calendar.YourActivitiesAndUpcomingEventsWillAppearHere' | translate}}</p>
                                <p>{{'Calendar.EGDeadlinesForTheImplementationOfTheAssignedTraining' | translate}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>

<div class="close-dialog cursor-pointer" (click)="dialogRef.close()">
    <img src="assets/images/default/icon_close.svg" alt="icon_close">
</div>
<div class="assign-unassign">
    <h1 mat-dialog-title tabIndex="0" *ngIf="dateToExit === null">
        {{ data.headerText | translate}}
        <i class="dolineo-icon dolineo-icon-close dolineo-icon-gray"
           matTooltip="{{'Common.Close' | translate}}"
           matTooltipPosition="below"
           matTooltipClass='below-top'
           (click)="dialogRef.close()"></i>
    </h1>

    <div mat-dialog-content [ngStyle]="{'margin-top' : dateToExit !== null ? '75px' : '0'}">
        <ng-container *ngIf="dateToExit === null">
            <form [formGroup]="form">
                <span *ngIf="data.type === 'certificate'" class="assign-unassign--info-text">
                    {{ data.infoText | translate}}
                </span>
                <div class="assign-unassign--length">
                    <span>
                        {{ data.contentText | translate}}
                    </span>
                    <div class="number-selected">
                        {{data.selected.length}}
                    </div>
                </div>
                <div class="assign-unassign--info"  *ngIf="data.type === 'certificate'">
                    <img src="/assets/icons/info.svg" alt="format-selection">
                    <span >{{ data.infoFooterText | translate}}</span>
                </div>
                <div class="choice-data"
                     *ngIf="data.status && data.type !== 'pool' && data.type !== 'survey' && data.type !== 'category' && data.type !== 'gamification' && data.type !== 'task'">

                    <div class="choice-data--row">
                        <mat-slide-toggle color="primary" formControlName="isMandatory">
                            {{'AssignDialog.MandatoryDeadline' | translate}}
                        </mat-slide-toggle>

                        <mat-form-field *ngIf="form.get('isMandatory').value">
                            <input matInput
                                   [matDatepicker]="expiryDate"
                                   (click)="openExpiryDateDialog()"
                                   readonly="readonly"
                                   formControlName="expiryDate"
                                   placeholder="{{'AdminStats.SelectDateTo' | translate}} *">
                            <mat-datepicker-toggle matSuffix (click)="openExpiryDateDialog()"></mat-datepicker-toggle>
<!--                            <mat-datepicker #expiryDate></mat-datepicker>-->
                            <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="choice-data--row" *ngIf="expiryDateLtVisibleFromDate">
                        <mat-error *ngIf="data.type === 'training'">{{'AssignDialog.TrainingExpiryDateLtToday'| translate}}</mat-error>
                        <mat-error *ngIf="data.type === 'path'">{{'AssignDialog.PathExpiryDateLtToday'| translate}}</mat-error>
                    </div>

                    <div class="choice-data--row">
                        <div class="more-info-button" (click)="form.get('moreParameters').setValue(!form.get('moreParameters').value)">
                            {{(form.get('moreParameters').value ? 'Common.LessParams' : 'Common.MoreParams') | translate}}
                        </div>
                    </div>

                    <div class="choice-data--row more-parameters" *ngIf="form.get('moreParameters').value">
                        <span *ngIf="data.type === 'training'">{{'AssignDialog.TrainingVisibleFromDate' | translate}}</span>
                        <span *ngIf="data.type === 'path'">{{'AssignDialog.PathVisibleFromDate' | translate}}</span>

                        <mat-form-field>
                            <input matInput
                                   [matDatepicker]="visibleFromDate"
                                   [min]="minVisibleFromDate"
                                   (click)="visibleFromDate.open()"
                                   readonly="readonly"
                                   formControlName="visibleFromDate"
                                   placeholder="{{'AdminStats.SelectDateFrom' | translate}} *">
                            <mat-datepicker-toggle matSuffix [for]="visibleFromDate"></mat-datepicker-toggle>
                            <mat-datepicker #visibleFromDate></mat-datepicker>
                            <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="mandatory-for-new-employees text-wrap-popup"  *ngIf="(data.selectedTable === 'groups' || data.selectedTable === 'positions') && form.get('moreParameters').value">
                        <mat-slide-toggle color="primary" formControlName="isMandatoryForNewEmployees" style="max-width: 400px;">
                            {{ (data.selectedTable === 'groups' ? 'AssignDialog.MandatoryDeadlineForNewEmployeesGroup' : 'AssignDialog.MandatoryDeadlineForNewEmployeesPosition') | translate}}
                        </mat-slide-toggle>

                        <div class="mandatory-for-new-employees__days">
                            <span style="position: relative; width: 65px; display: inline-block; height: 41px;">
                                <mat-form-field>
                                    <input digitOnly [decimal]="true" matInput
                                           [readonly]="!form.get('isMandatoryForNewEmployees').value"
                                           placeholder="{{'Common.Number' | translate}} *"
                                           formControlName="expiryDateForNewEmployees"  style="width: 50px;">
                                    <mat-error>{{ 'Common.FiledCanNotBeEmpty' | translate }}</mat-error>
                                </mat-form-field>
                            </span>
                            <span class="mandatory-for-new-employees__days--label">{{'AssignDialog.MandatoryDeadlineExpiryDays' | translate}}</span>
                        </div>
                    </div>

                    <div class="choice-data--row more-parameters-info" *ngIf="form.get('moreParameters').value">
                        <img src="/assets/icons/info.svg" alt="format-selection">
                        <span *ngIf="data.type === 'training'">{{'AssignDialog.TrainingVisibleFromDateInfo' | translate}}</span>
                        <span *ngIf="data.type === 'path'">{{'AssignDialog.PathVisibleFromDateInfo' | translate}}</span>
                    </div>
                </div>

                <div *ngIf="data.status && (data.type === 'pool' || data.type === 'survey')">
                    <div class="realization-date">
                        <mat-slide-toggle color="primary" formControlName="realizationDate">
                            {{'Common.RealisationDate' | translate}}
                        </mat-slide-toggle>
                        <div class="realization-date__inputs" *ngIf="form.get('realizationDate').value">
                            <mat-form-field>
                                <input [id]="'startDate'"
                                       matInput
                                       ngxDaterangepickerMd
                                       showCancel="true"
                                       showClearButton="true"
                                       placeholder="{{'AdminStats.SelectDateFrom' | translate}}"
                                       readonly="readonly"
                                       formControlName="startDate"
                                       [timePicker]="true"
                                       [minDate]="minStartDate"
                                       [timePicker24Hour]="true"
                                       [locale]="defaultLocale"
                                       [singleDatePicker]="true"
                                       [showDropdowns]="true"
                                       [lockStartDate]="false"
                                       [customRangeDirection]="false"
                                       (datesUpdated)="clear($event, 'startDate')"/>
                            </mat-form-field>
                            <span style="white-space: pre" *ngIf="form.get('realizationDate').value"> - </span>
                            <mat-form-field>
                                <input [id]="'endDate'"
                                       matInput
                                       ngxDaterangepickerMd
                                       showCancel="true"
                                       showClearButton="true"
                                       placeholder="{{'AdminStats.SelectDateTo' | translate}}"
                                       readonly="readonly"
                                       formControlName="endDate"
                                       [timePicker]="true"
                                       [minDate]="minEndDate"
                                       [timePicker24Hour]="true"
                                       [locale]="defaultLocale"
                                       [singleDatePicker]="true"
                                       [showDropdowns]="true"
                                       [lockStartDate]="false"
                                       [customRangeDirection]="false"
                                       (datesUpdated)="clear($event, 'endDate')"/>
                            </mat-form-field>
                        </div>
                    </div>
                    <mat-error *ngIf="isError">{{'AssignDialog.OneOfTheTwoDatesIsMissing'| translate}}</mat-error>
                    <div>
                        <mat-slide-toggle color="primary" formControlName="isMandatory">
                            {{(data.type === 'pool' ? 'AssignDialog.MandatoryTest' : 'AssignDialog.MandatorySurvey') | translate}}
                        </mat-slide-toggle>
                    </div>
                </div>
            </form>
        </ng-container>

        <ng-container *ngIf="dateToExit !== null">
            <div style="padding-top: 30px; display: flex; justify-content: center; align-items: center">
                <img src="assets/icons/checked-green.svg" alt="checked-green" style="margin-right: 8px;">
                <span *ngIf="data.status">{{(data.type === 'pool' ? 'Pools.AssignedToTheTest' : data.type === 'survey' ? 'Surveys.AssignedToTheSurvey' : data.type === 'training' ? 'Training.AssignedToTraining' : 'Path.AssignedToPath') | translate}}</span>
                <span *ngIf="data.status === false">{{'Common.UnsubscribedFrom' | translate}} {{(data.type === 'pool' ? 'Pools.UnsubscribedFromTheTest' : data.type === 'survey' ? 'Surveys.UnsubscribedFromTheSurvey' : data.type === 'training' ? 'Training.OutOfTheTraining' : 'Path.OutOfThePath') | translate}}</span>
            </div>
        </ng-container>
    </div>

    <div mat-dialog-actions class="dialog-footer">
        <ng-container *ngIf="dateToExit === null">
            <button mat-flat-button (click)="dialogRef.close()" color="primary"
                    class="cancel-button">{{'Common.Cancel' | translate}}</button>
            <button (click)="assign()" [disabled]="expiryDateLtVisibleFromDate"
                    mat-button color="primary"
                    class="dolineo-button blue-button">{{(data.status ? 'Common.Assign' : 'Common.IConfirm') | translate}}</button>
        </ng-container>
        <div *ngIf="dateToExit !== null" style="display: flex; justify-content: space-between; width: 100%">
            <button mat-flat-button class="dolineo-button white-button"
                    (click)="closeMobileDialog(false)">
                {{'AssignDialog.ContinueToSignUp' | translate}}
            </button>

            <button mat-flat-button class="dolineo-button"
                    (click)="closeMobileDialog(true)"
                    color="primary">
                {{'AssignDialog.ViewAssigned' | translate}}
            </button>
        </div>
    </div>
</div>
